import { css } from 'styled-components';

export const unitMapping = {
  0: '0px',
  0.5: 'var(--half-unit)',
  1: 'var(--unit)',
  1.5: 'var(--intermediate-unit)',
  2: 'var(--double-unit)',
  2.5: 'var(--double-and-a-half-unit)',
  3: 'var(--triple-unit)',
  4: 'var(--quadruple-unit)',
  5: 'calc(5 * var(--unit))',
  6: 'calc(6 * var(--unit))',
  8: 'calc(8 * var(--unit))',
} as const;

export const absCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
