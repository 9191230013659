import styled, { css } from 'styled-components';
import { KnownTarget } from 'styled-components/dist/types';

import { tabletAndAbove } from 'style/mediaQuery';
import { Color } from 'style/types';

type BoldProps = { bold?: boolean };
type BrandProps = { brand?: boolean };
type ColorProps = { color?: Color };
type UppercaseProps = { uppercase?: boolean };

export type MandatoryAsProp = {
  as: KnownTarget;
};
export type TypographyProps = BoldProps &
  BrandProps &
  ColorProps &
  UppercaseProps;

const variant = ({ bold, uppercase }: BoldProps & UppercaseProps) => css`
  ${bold
    ? css`
        font-weight: var(--t-bold);
      `
    : null}
  ${uppercase
    ? css`
        text-transform: uppercase;
      `
    : null}
`;
const titleVariant = ({ uppercase }: UppercaseProps) => css`
  ${uppercase
    ? css`
        text-transform: uppercase !important;
        font-style: italic !important;
        font-weight: var(--t-bold) !important;
      `
    : null}
`;

export type Props = ColorProps & BoldProps & UppercaseProps;

const getColorsStyle = ({ color }: ColorProps) => {
  return {
    style: { color },
  };
};

// Mixins to use when helper component isn't appropriate
export const title1 = css<UppercaseProps>`
  font: var(--t-bold) var(--t-32);
  @media ${tabletAndAbove} {
    font: var(--t-bold) var(--t-48);
  }
  ${titleVariant}
`;
export const title2 = css<UppercaseProps>`
  font: var(--t-bold) var(--t-24);
  @media ${tabletAndAbove} {
    font: var(--t-bold) var(--t-32);
  }
  ${titleVariant}
`;
export const title3 = css<UppercaseProps>`
  font: var(--t-bold) var(--t-20);
  @media ${tabletAndAbove} {
    font: var(--t-bold) var(--t-24);
  }
  ${titleVariant}
`;
export const title4 = css`
  font: var(--t-bold) var(--t-18);
  @media ${tabletAndAbove} {
    font: var(--t-bold) var(--t-20);
  }
`;
export const title5 = css`
  font: var(--t-bold) var(--t-18);
`;
export const title6 = css`
  font: var(--t-bold) var(--t-16);
`;
export const text20 = css<BoldProps & UppercaseProps>`
  font: var(--t-18-26);
  ${variant}
`;
export const text18 = css<BoldProps & UppercaseProps>`
  font: var(--t-18-26);
  ${variant}
`;
export const text16 = css<BoldProps & UppercaseProps>`
  font: var(--t-16);
  ${variant}
`;
export const text14 = css<BoldProps & UppercaseProps>`
  font: var(--t-14);
  ${variant}
`;
export const button16 = css`
  text-transform: uppercase;
  font: italic var(--t-bold) var(--t-16);
`;
export const button14 = css`
  text-transform: uppercase;
  font: italic var(--t-bold) var(--t-14);
`;
export const button12 = css`
  text-transform: uppercase;
  font: italic var(--t-bold) var(--t-12);
`;
export const overline = css<BoldProps & UppercaseProps>`
  text-transform: uppercase;
  font: var(--t-14-16);
  ${variant}
`;
export const caption = css<BoldProps & UppercaseProps>`
  font: var(--t-12);
  ${variant}
`;

// Helper component that can be used 90% of the time.
export const Title1 = styled.h1<UppercaseProps>`
  ${title1};
`;
export const Title2 = styled.h2.attrs<Props>(getColorsStyle)<Props>`
  ${title2};
`;
export const Title3 = styled.h3.attrs<Props>(getColorsStyle)<Props>`
  ${title3};
`;
export const Title4 = styled.h4.attrs<Props>(getColorsStyle)<Props>`
  ${title4};
`;
export const Title5 = styled.h5.attrs<Props>(getColorsStyle)<Props>`
  ${title5};
`;
export const Title6 = styled.h6.attrs<Props>(getColorsStyle)<Props>`
  ${title6};
`;
export const Text20 = styled.p.attrs<Props>(getColorsStyle)<Props>`
  ${text20};
`;
export const Text18 = styled.p.attrs<Props>(getColorsStyle)<Props>`
  ${text18};
`;
export const Text16 = styled.p.attrs<Props>(getColorsStyle)<Props>`
  ${text16};
`;
export const Text14 = styled.p.attrs<Props>(getColorsStyle)<Props>`
  ${text14};
`;
export const Overline = styled.p.attrs<Props>(getColorsStyle)<Props>`
  ${overline};
`;
export const Caption = styled.p.attrs<Props>(getColorsStyle)<Props>`
  ${caption};
`;

export type TypographyVariant =
  | typeof Title1
  | typeof Title2
  | typeof Title3
  | typeof Title4
  | typeof Title5
  | typeof Title6
  | typeof Text20
  | typeof Text18
  | typeof Text16
  | typeof Text14
  | typeof Overline
  | typeof Caption;

const NullComponent = () => null;
export type NullableTypographyVariant =
  | TypographyVariant
  | typeof NullComponent;

const brandVariant = ({ brand }: BrandProps) => css`
  ${brand
    ? css`
        font-family: var(--brand-font);
        text-transform: uppercase;
      `
    : null}
`;

type TitleProps = BrandProps & ColorProps & MandatoryAsProp;

export const HeadlineXL = styled.div.attrs<TitleProps>(
  getColorsStyle
)<TitleProps>`
  font: var(--t-headline-l);
  ${brandVariant}
  @media ${tabletAndAbove} {
    font: var(--t-headline-xl);
    ${brandVariant}
  }
`;
export const HeadlineL = styled.div.attrs<TitleProps>(
  getColorsStyle
)<TitleProps>`
  font: var(--t-headline-m);
  ${brandVariant}
  @media ${tabletAndAbove} {
    font: var(--t-headline-l);
    ${brandVariant}
  }
`;
export const HeadlineM = styled.div.attrs<TitleProps>(
  getColorsStyle
)<TitleProps>`
  font: var(--t-headline-s);
  ${brandVariant}
  @media ${tabletAndAbove} {
    font: var(--t-headline-m);
    ${brandVariant}
  }
`;
export const HeadlineS = styled.div.attrs<TitleProps>(
  getColorsStyle
)<TitleProps>`
  font: var(--t-headline-xs);
  ${brandVariant}
  @media ${tabletAndAbove} {
    font: var(--t-headline-s);
    ${brandVariant}
  }
`;
export const HeadlineXS = styled.div.attrs<TitleProps>(
  getColorsStyle
)<TitleProps>`
  font: var(--t-headline-xs);
  ${brandVariant}
`;

export const BodyL = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-body-l);
  ${variant}
`;
export const BodyM = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-body-m);
  ${variant}
`;
export const BodyS = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-body-s);
  ${variant}
`;

export const LabelL = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-label-l);
  ${variant}
`;
export const LabelM = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-label-m);
  ${variant}
`;
export const LabelS = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-label-s);
  ${variant}
`;
export const LabelXS = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-label-xs);
  ${variant}
`;

export const DisplayL = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-display-l);
  letter-spacing: -2%;
`;
export const DisplayM = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-display-m);
  letter-spacing: -2%;
`;
export const DisplayS = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--t-display-s);
`;
