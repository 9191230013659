/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { animated, useChain, useSpring, useSpringRef } from 'react-spring';

import { Rarity, Sport } from '@sorare/core/src/__generated__/globalTypes';

import { CardDetailsQuery } from '__generated__/World.graphql';
import clubs from 'clubs';

import styles from './styles';

const useStyles = makeStyles(styles);

export type AnyCard = CardDetailsQuery['anyCard'];
export type User = NonNullable<
  NonNullable<CardDetailsQuery['anyCard']['tokenOwner']>['account']
>['owner'] & { __typename: 'User' };

interface Props {
  card: AnyCard;
  previous?: boolean;
}

const Card = ({ card, previous }: Props) => {
  const classes = useStyles();

  const user = card.tokenOwner?.account?.owner as User;

  const previousAnimStyle = useSpring({
    from: {
      transform: 'translateY(0px)',
      opacity: 1,
      rotateX: 0,
    },
    to: {
      transform: 'translateY(750px)',
      opacity: 0,
      rotateX: 180,
    },
    config: { duration: 500 },
    reset: true,
  });

  const animStyle = useSpring({
    from: {
      transform: `translateX(${window.innerWidth / 2}px) translateY(${
        window.innerHeight / 2 - 208
      }px) scale(0)`,
      opacity: 0,
    },
    to: {
      transform: 'translateX(0px) translateY(0) scale(1)',
      opacity: 1,
    },
    config: { duration: 500 },
    reset: true,
  });

  const flipStyleRef = useSpringRef();
  const flipStyle = useSpring({
    ref: flipStyleRef,
    from: { rotateY: 360, rotateZ: 10 },
    to: [
      { rotateY: 360, rotateZ: -10 },
      { rotateY: 0, rotateZ: 0 },
    ],
    config: {
      delay: 200,
      duration: 500,
    },
    reset: true,
  });

  const shineStyleRef = useSpringRef();
  const shineStyle = useSpring({
    ref: shineStyleRef,
    from: {
      opacity: 0,
      transform: 'translateY(-100%) translateX(-100%) rotate(45deg)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(100%) translateX(100%) rotate(45deg)',
    },
    config: {
      duration: 1000,
    },
    loop: true,
    reset: true,
  });

  useChain([flipStyleRef, shineStyleRef]);

  const sportPrefix =
    card.sport === Sport.FOOTBALL
      ? ''
      : card.sport === Sport.BASEBALL
        ? 'mlb/'
        : 'nba/';
  const href = `https://sorare.com/${sportPrefix}cards/${card.slug}`;

  const country = (clubs as any)[card.anyTeam.slug];
  const backPath =
    card.rarityTyped === Rarity.custom_series
      ? `custom_series/${country?.name || 'common'}.png`
      : `${card.rarityTyped}.svg`;

  return (
    <a
      className={cx(classes.cardContainer, previous && 'previous')}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <animated.div style={previous ? previousAnimStyle : animStyle}>
        <animated.div className={classes.card} style={flipStyle}>
          <img
            src={card.pictureUrl!}
            alt={card.slug}
            className={classes.cardPicture}
          />
          <img
            src={`https://frontend-assets.sorare.com/cards/back/${backPath}`}
            alt={`background of ${card.slug}`}
            className={classes.backgroundPicture}
          />
          <div className={classes.shineEffectContainer}>
            <animated.div style={shineStyle} className={classes.shineEffect} />
          </div>
        </animated.div>
        {user && (
          <div className={classes.cardProperties}>
            by <strong>{user.nickname}</strong>
          </div>
        )}{' '}
      </animated.div>
    </a>
  );
};

export default Card;
