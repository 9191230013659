import WebfontLoader from '@dr-kobros/react-webfont-loader';
import { ReactNode, useMemo, useState } from 'react';

import useFontFaceObserver, { FontFace } from '@sorare/use-font-face-observer';

import WebfontsContextProvider from '.';

interface Props {
  config:
    | {
        typekit: { id: string };
      }
    | {
        custom: {
          families: string[];
          urls?: string[];
        };
      }
    | {
        google: {
          families: string[];
        };
      };
  children: ReactNode;
  blockRenderingOn?: FontFace[];
}

export const WebfontsProvider = ({
  children,
  config,
  blockRenderingOn = [],
}: Props) => {
  const [fontStatus, updateStatus] = useState<string>('');
  const actualConfig = useMemo(() => {
    if (blockRenderingOn.length) {
      return {
        ...config,
        timeout: 30000,
      };
    }
    return config;
  }, [config, blockRenderingOn]);

  const fontLoaded = useFontFaceObserver(blockRenderingOn, { timeout: 30000 });

  const loading = fontStatus === 'loading';
  const active = fontStatus === 'active' || fontLoaded === 'active';

  return (
    <WebfontsContextProvider value={{ active, loading }}>
      <WebfontLoader config={actualConfig} onStatus={updateStatus} />

      {blockRenderingOn.length ? fontLoaded === 'active' && children : children}
    </WebfontsContextProvider>
  );
};

export default WebfontsProvider;
