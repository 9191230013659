import { ReactNode } from 'react';

import SentryContextProvider, { SentryContext } from '.';

interface Props {
  dsn?: string;
  identifyUser?: SentryContext['identifyUser'];
  sendSafeError?: SentryContext['sendSafeError'];
  children: ReactNode;
}

export const SentryProvider = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dsn = '',
  identifyUser = () => {
    /* nothing */
  },
  sendSafeError = () => {
    /* nothing */
  },
  children,
}: Props) => {
  return (
    <SentryContextProvider
      value={{
        identifyUser,
        sendSafeError,
      }}
    >
      {children}
    </SentryContextProvider>
  );
};

export default SentryProvider;
