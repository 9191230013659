import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    cardContainer: {
      position: 'absolute',
      top: '30px',
      left: '30px',
      [theme.breakpoints.down('sm')]: {
        top: '100px',
      },
      filter: 'drop-shadow(0px 0px 10px #C9C9FF)',
      width: '257px',
      height: '416px',
      [theme.breakpoints.down('xs')]: {
        width: '128px',
        height: '208px',
      },
      perspective: '1000px',
      '&> div': {
        position: 'relative',
        width: '100%',
        height: '100%',
      },
      zIndex: 2,
      '&.previous': {
        zIndex: 1,
      },
    },
    card: {
      position: 'relative',
      width: '100%',
      height: '100%',
      transformStyle: 'preserve-3d',
    },
    cardProperties: {
      textAlign: 'center',
      fontFamily: 'apercu-pro, system-ui, sans-serif',
      color: 'white',
      margin: '10px',
    },
    cardPicture: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backfaceVisibility: 'hidden',
    },
    backgroundPicture: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backfaceVisibility: 'hidden',
      transform: 'rotateY(180deg)',
    },
    shineEffectContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backfaceVisibility: 'hidden',
      zIndex: 1,
      overflow: 'hidden',
      borderRadius: '20px',
    },
    shineEffect: {
      position: 'absolute',
      width: '500%',
      height: '500%',
      background:
        'linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
    },
    logo: {
      position: 'absolute',
      top: '50px',
      right: '50px',
      [theme.breakpoints.down('md')]: {
        top: 'auto',
        bottom: '80px',
        right: '10px',
      },
    },
    price: {
      position: 'absolute',
      bottom: '5%',
      right: '0',
      left: '0',
      textAlign: 'center',
      fontSize: '36px',
      color: 'white',
      fontWeight: 'bold',
      opacity: '0.4',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        bottom: 'auto',
        top: '5%',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
    hiring: {
      position: 'absolute',
      bottom: '30px',
      right: '50px',
      width: '200px',
      [theme.breakpoints.down('md')]: {
        right: '20px',
        bottom: '10px',
        width: '120px',
      },
    },
  });
