const countries = {
  al: { lat: 41, lng: 20, name: 'al' },
  dz: { lat: 28, lng: 3, name: 'dz' },
  as: { lat: -14.3333, lng: -170, name: 'as' },
  ad: { lat: 42.5, lng: 1.6, name: 'ad' },
  ao: { lat: -12.5, lng: 18.5, name: 'ao' },
  ai: { lat: 18.25, lng: -63.1667, name: 'ai' },
  aq: { lat: -90, lng: 0, name: 'aq' },
  ag: { lat: 17.05, lng: -61.8, name: 'ag' },
  ar: { lat: -34, lng: -64, name: 'ar' },
  am: { lat: 40, lng: 45, name: 'am' },
  aw: { lat: 12.5, lng: -69.9667, name: 'aw' },
  au: { lat: -27, lng: 133, name: 'au' },
  at: { lat: 47.3333, lng: 13.3333, name: 'at' },
  az: { lat: 40.5, lng: 47.5, name: 'az' },
  bs: { lat: 24.25, lng: -76, name: 'bs' },
  bh: { lat: 26, lng: 50.55, name: 'bh' },
  bd: { lat: 24, lng: 90, name: 'bd' },
  bb: { lat: 13.1667, lng: -59.5333, name: 'bb' },
  by: { lat: 53, lng: 28, name: 'by' },
  be: { lat: 50.8333, lng: 4, name: 'be' },
  bz: { lat: 17.25, lng: -88.75, name: 'bz' },
  bj: { lat: 9.5, lng: 2.25, name: 'bj' },
  bm: { lat: 32.3333, lng: -64.75, name: 'bm' },
  bt: { lat: 27.5, lng: 90.5, name: 'bt' },
  bo: { lat: -17, lng: -65, name: 'bo' },
  ba: { lat: 44, lng: 18, name: 'ba' },
  bw: { lat: -22, lng: 24, name: 'bw' },
  bv: { lat: -54.4333, lng: 3.4, name: 'bv' },
  br: { lat: -10, lng: -55, name: 'br' },
  io: { lat: -6, lng: 71.5, name: 'io' },
  bn: { lat: 4.5, lng: 114.6667, name: 'bn' },
  bg: { lat: 43, lng: 25, name: 'bg' },
  bf: { lat: 13, lng: -2, name: 'bf' },
  bi: { lat: -3.5, lng: 30, name: 'bi' },
  kh: { lat: 13, lng: 105, name: 'kh' },
  cm: { lat: 6, lng: 12, name: 'cm' },
  ca: { lat: 60, lng: -95, name: 'ca' },
  cv: { lat: 16, lng: -24, name: 'cv' },
  ky: { lat: 19.5, lng: -80.5, name: 'ky' },
  cf: { lat: 7, lng: 21, name: 'cf' },
  td: { lat: 15, lng: 19, name: 'td' },
  cl: { lat: -30, lng: -71, name: 'cl' },
  cn: { lat: 35, lng: 105, name: 'cn' },
  cx: { lat: -10.5, lng: 105.6667, name: 'cx' },
  cc: { lat: -12.5, lng: 96.8333, name: 'cc' },
  co: { lat: 4, lng: -72, name: 'co' },
  km: { lat: -12.1667, lng: 44.25, name: 'km' },
  cg: { lat: -1, lng: 15, name: 'cg' },
  cd: { lat: 0, lng: 25, name: 'cd' },
  ck: { lat: -21.2333, lng: -159.7667, name: 'ck' },
  cr: { lat: 10, lng: -84, name: 'cr' },
  ci: { lat: 8, lng: -5, name: 'ci' },
  hr: { lat: 45.1667, lng: 15.5, name: 'hr' },
  cu: { lat: 21.5, lng: -80, name: 'cu' },
  cy: { lat: 35, lng: 33, name: 'cy' },
  cz: { lat: 49.75, lng: 15.5, name: 'cz' },
  dk: { lat: 56, lng: 10, name: 'dk' },
  dj: { lat: 11.5, lng: 43, name: 'dj' },
  dm: { lat: 15.4167, lng: -61.3333, name: 'dm' },
  do: { lat: 19, lng: -70.6667, name: 'do' },
  ec: { lat: -2, lng: -77.5, name: 'ec' },
  eg: { lat: 27, lng: 30, name: 'eg' },
  sv: { lat: 13.8333, lng: -88.9167, name: 'sv' },
  gq: { lat: 2, lng: 10, name: 'gq' },
  er: { lat: 15, lng: 39, name: 'er' },
  ee: { lat: 59, lng: 26, name: 'ee' },
  et: { lat: 8, lng: 38, name: 'et' },
  fk: { lat: -51.75, lng: -59, name: 'fk' },
  fo: { lat: 62, lng: -7, name: 'fo' },
  fj: { lat: -18, lng: 175, name: 'fj' },
  fi: { lat: 64, lng: 26, name: 'fi' },
  fr: { lat: 46, lng: 2, name: 'fr' },
  gf: { lat: 4, lng: -53, name: 'gf' },
  pf: { lat: -15, lng: -140, name: 'pf' },
  tf: { lat: -43, lng: 67, name: 'tf' },
  ga: { lat: -1, lng: 11.75, name: 'ga' },
  gm: { lat: 13.4667, lng: -16.5667, name: 'gm' },
  ge: { lat: 42, lng: 43.5, name: 'ge' },
  de: { lat: 51, lng: 9, name: 'de' },
  gh: { lat: 8, lng: -2, name: 'gh' },
  gi: { lat: 36.1833, lng: -5.3667, name: 'gi' },
  gr: { lat: 39, lng: 22, name: 'gr' },
  gl: { lat: 72, lng: -40, name: 'gl' },
  gd: { lat: 12.1167, lng: -61.6667, name: 'gd' },
  gp: { lat: 16.25, lng: -61.5833, name: 'gp' },
  gu: { lat: 13.4667, lng: 144.7833, name: 'gu' },
  gt: { lat: 15.5, lng: -90.25, name: 'gt' },
  gg: { lat: 49.5, lng: -2.56, name: 'gg' },
  gn: { lat: 11, lng: -10, name: 'gn' },
  gw: { lat: 12, lng: -15, name: 'gw' },
  gy: { lat: 5, lng: -59, name: 'gy' },
  ht: { lat: 19, lng: -72.4167, name: 'ht' },
  hm: { lat: -53.1, lng: 72.5167, name: 'hm' },
  va: { lat: 41.9, lng: 12.45, name: 'va' },
  hn: { lat: 15, lng: -86.5, name: 'hn' },
  hk: { lat: 22.25, lng: 114.1667, name: 'hk' },
  hu: { lat: 47, lng: 20, name: 'hu' },
  is: { lat: 65, lng: -18, name: 'is' },
  in: { lat: 20, lng: 77, name: 'in' },
  id: { lat: -5, lng: 120, name: 'id' },
  ir: { lat: 32, lng: 53, name: 'ir' },
  iq: { lat: 33, lng: 44, name: 'iq' },
  ie: { lat: 53, lng: -8, name: 'ie' },
  im: { lat: 54.23, lng: -4.55, name: 'im' },
  il: { lat: 31.5, lng: 34.75, name: 'il' },
  it: { lat: 42.8333, lng: 12.8333, name: 'it' },
  jm: { lat: 18.25, lng: -77.5, name: 'jm' },
  jp: { lat: 36, lng: 138, name: 'jp' },
  je: { lat: 49.21, lng: -2.13, name: 'je' },
  jo: { lat: 31, lng: 36, name: 'jo' },
  kz: { lat: 48, lng: 68, name: 'kz' },
  ke: { lat: 1, lng: 38, name: 'ke' },
  ki: { lat: 1.4167, lng: 173, name: 'ki' },
  kp: { lat: 40, lng: 127, name: 'kp' },
  kr: { lat: 37, lng: 127.5, name: 'kr' },
  kw: { lat: 29.3375, lng: 47.6581, name: 'kw' },
  kg: { lat: 41, lng: 75, name: 'kg' },
  la: { lat: 18, lng: 105, name: 'la' },
  lv: { lat: 57, lng: 25, name: 'lv' },
  lb: { lat: 33.8333, lng: 35.8333, name: 'lb' },
  ls: { lat: -29.5, lng: 28.5, name: 'ls' },
  lr: { lat: 6.5, lng: -9.5, name: 'lr' },
  ly: { lat: 25, lng: 17, name: 'ly' },
  li: { lat: 47.1667, lng: 9.5333, name: 'li' },
  lt: { lat: 56, lng: 24, name: 'lt' },
  lu: { lat: 49.75, lng: 6.1667, name: 'lu' },
  mo: { lat: 22.1667, lng: 113.55, name: 'mo' },
  mk: { lat: 41.8333, lng: 22, name: 'mk' },
  mg: { lat: -20, lng: 47, name: 'mg' },
  mw: { lat: -13.5, lng: 34, name: 'mw' },
  my: { lat: 2.5, lng: 112.5, name: 'my' },
  mv: { lat: 3.25, lng: 73, name: 'mv' },
  ml: { lat: 17, lng: -4, name: 'ml' },
  mt: { lat: 35.8333, lng: 14.5833, name: 'mt' },
  mh: { lat: 9, lng: 168, name: 'mh' },
  mq: { lat: 14.6667, lng: -61, name: 'mq' },
  mr: { lat: 20, lng: -12, name: 'mr' },
  mu: { lat: -20.2833, lng: 57.55, name: 'mu' },
  yt: { lat: -12.8333, lng: 45.1667, name: 'yt' },
  mx: { lat: 23, lng: -102, name: 'mx' },
  fm: { lat: 6.9167, lng: 158.25, name: 'fm' },
  md: { lat: 47, lng: 29, name: 'md' },
  mc: { lat: 43.7333, lng: 7.4, name: 'mc' },
  mn: { lat: 46, lng: 105, name: 'mn' },
  me: { lat: 42, lng: 19, name: 'me' },
  ms: { lat: 16.75, lng: -62.2, name: 'ms' },
  ma: { lat: 32, lng: -5, name: 'ma' },
  mz: { lat: -18.25, lng: 35, name: 'mz' },
  mm: { lat: 22, lng: 98, name: 'mm' },
  na: { lat: -22, lng: 17, name: 'na' },
  nr: { lat: -0.5333, lng: 166.9167, name: 'nr' },
  np: { lat: 28, lng: 84, name: 'np' },
  nl: { lat: 52.5, lng: 5.75, name: 'nl' },
  an: { lat: 12.25, lng: -68.75, name: 'an' },
  nc: { lat: -21.5, lng: 165.5, name: 'nc' },
  nz: { lat: -41, lng: 174, name: 'nz' },
  ni: { lat: 13, lng: -85, name: 'ni' },
  ne: { lat: 16, lng: 8, name: 'ne' },
  ng: { lat: 10, lng: 8, name: 'ng' },
  nu: { lat: -19.0333, lng: -169.8667, name: 'nu' },
  nf: { lat: -29.0333, lng: 167.95, name: 'nf' },
  mp: { lat: 15.2, lng: 145.75, name: 'mp' },
  no: { lat: 62, lng: 10, name: 'no' },
  om: { lat: 21, lng: 57, name: 'om' },
  pk: { lat: 30, lng: 70, name: 'pk' },
  pw: { lat: 7.5, lng: 134.5, name: 'pw' },
  ps: { lat: 32, lng: 35.25, name: 'ps' },
  pa: { lat: 9, lng: -80, name: 'pa' },
  pg: { lat: -6, lng: 147, name: 'pg' },
  py: { lat: -23, lng: -58, name: 'py' },
  pe: { lat: -10, lng: -76, name: 'pe' },
  ph: { lat: 13, lng: 122, name: 'ph' },
  pn: { lat: -24.7, lng: -127.4, name: 'pn' },
  pl: { lat: 52, lng: 20, name: 'pl' },
  pt: { lat: 39.5, lng: -8, name: 'pt' },
  pr: { lat: 18.25, lng: -66.5, name: 'pr' },
  qa: { lat: 25.5, lng: 51.25, name: 'qa' },
  re: { lat: -21.1, lng: 55.6, name: 're' },
  ro: { lat: 46, lng: 25, name: 'ro' },
  ru: { lat: 60, lng: 100, name: 'ru' },
  rw: { lat: -2, lng: 30, name: 'rw' },
  sh: { lat: -15.9333, lng: -5.7, name: 'sh' },
  kn: { lat: 17.3333, lng: -62.75, name: 'kn' },
  lc: { lat: 13.8833, lng: -61.1333, name: 'lc' },
  pm: { lat: 46.8333, lng: -56.3333, name: 'pm' },
  vc: { lat: 13.25, lng: -61.2, name: 'vc' },
  ws: { lat: -13.5833, lng: -172.3333, name: 'ws' },
  sm: { lat: 43.7667, lng: 12.4167, name: 'sm' },
  st: { lat: 1, lng: 7, name: 'st' },
  sa: { lat: 25, lng: 45, name: 'sa' },
  sn: { lat: 14, lng: -14, name: 'sn' },
  rs: { lat: 44, lng: 21, name: 'rs' },
  sc: { lat: -4.5833, lng: 55.6667, name: 'sc' },
  sl: { lat: 8.5, lng: -11.5, name: 'sl' },
  sg: { lat: 1.3667, lng: 103.8, name: 'sg' },
  sk: { lat: 48.6667, lng: 19.5, name: 'sk' },
  si: { lat: 46, lng: 15, name: 'si' },
  sb: { lat: -8, lng: 159, name: 'sb' },
  so: { lat: 10, lng: 49, name: 'so' },
  za: { lat: -29, lng: 24, name: 'za' },
  gs: { lat: -54.5, lng: -37, name: 'gs' },
  es: { lat: 40, lng: -4, name: 'es' },
  lk: { lat: 7, lng: 81, name: 'lk' },
  sd: { lat: 15, lng: 30, name: 'sd' },
  sr: { lat: 4, lng: -56, name: 'sr' },
  sj: { lat: 78, lng: 20, name: 'sj' },
  sz: { lat: -26.5, lng: 31.5, name: 'sz' },
  se: { lat: 62, lng: 15, name: 'se' },
  ch: { lat: 47, lng: 8, name: 'ch' },
  sy: { lat: 35, lng: 38, name: 'sy' },
  tw: { lat: 23.5, lng: 121, name: 'tw' },
  tj: { lat: 39, lng: 71, name: 'tj' },
  tz: { lat: -6, lng: 35, name: 'tz' },
  th: { lat: 15, lng: 100, name: 'th' },
  tl: { lat: -8.55, lng: 125.5167, name: 'tl' },
  tg: { lat: 8, lng: 1.1667, name: 'tg' },
  tk: { lat: -9, lng: -172, name: 'tk' },
  to: { lat: -20, lng: -175, name: 'to' },
  tt: { lat: 11, lng: -61, name: 'tt' },
  tn: { lat: 34, lng: 9, name: 'tn' },
  tr: { lat: 39, lng: 35, name: 'tr' },
  tm: { lat: 40, lng: 60, name: 'tm' },
  tc: { lat: 21.75, lng: -71.5833, name: 'tc' },
  tv: { lat: -8, lng: 178, name: 'tv' },
  ug: { lat: 1, lng: 32, name: 'ug' },
  ua: { lat: 49, lng: 32, name: 'ua' },
  ae: { lat: 24, lng: 54, name: 'ae' },
  gb: { lat: 54, lng: -2, name: 'gb' },
  us: { lat: 38, lng: -97, name: 'us' },
  um: { lat: 19.2833, lng: 166.6, name: 'um' },
  uy: { lat: -33, lng: -56, name: 'uy' },
  uz: { lat: 41, lng: 64, name: 'uz' },
  vu: { lat: -16, lng: 167, name: 'vu' },
  ve: { lat: 8, lng: -66, name: 've' },
  vn: { lat: 16, lng: 106, name: 'vn' },
  vg: { lat: 18.5, lng: -64.5, name: 'vg' },
  vi: { lat: 18.3333, lng: -64.8333, name: 'vi' },
  wf: { lat: -13.3, lng: -176.2, name: 'wf' },
  eh: { lat: 24.5, lng: -13, name: 'eh' },
  ye: { lat: 15, lng: 48, name: 'ye' },
  zm: { lat: -15, lng: 30, name: 'zm' },
  zw: { lat: -20, lng: 30, name: 'zw' },
  af: { lat: 33, lng: 65, name: 'af' },
  xk: { lat: 42.6026, lng: 20.903, name: 'xk' },
  cw: { lat: 12.209208, lng: -68.945576, name: 'cw' },
};

export default countries;
