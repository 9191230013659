import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import { ReactNode, memo, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import {
  FormatNumberOptions,
  IntlProvider as ReactIntlProvider,
  useIntl,
} from 'react-intl';
import { useHref, useLocation } from 'react-router-dom';

import { ORIGIN } from 'config';
import { useBasenameLocale } from 'hooks/useBasenameLocale';
import useTranslations, { PREFIXES } from 'i18n/useTranslations';
import { toLanguageCode } from 'lib/i18n';
import { RoundingMode, fromWei } from 'lib/wei';

import IntlContextProvider, {
  AvailableLocale,
  DisplayOptions,
  localeConfig,
} from '.';
import { polyfillDateTimeFormat } from './polyfill';

interface InnerProps {
  children: ReactNode;
  setLocale: (newLocale: string) => void;
  testMode: boolean;
}

const noop = () => {};

const InnerComponent = ({ children, setLocale, testMode }: InnerProps) => {
  const intl = useIntl();
  const locale = intl.locale as AvailableLocale;
  const config = localeConfig[locale];
  const [dateLocaleConfig, setDateLocaleConfig] = useState();

  const formatEth = (
    value: number | string,
    options?: FormatNumberOptions,
    displayOptions?: DisplayOptions
  ) => {
    const opts = { minimumFractionDigits: 3, ...options };
    return `${intl.formatNumber(Number.parseFloat(value.toString()), opts)}${
      displayOptions?.hideSymbol ? '' : '\xa0ETH'
    }`;
  };

  const formatCardBonus = (value: number) =>
    intl.formatNumber(value, {
      style: 'percent',
      maximumFractionDigits: 1,
      signDisplay: 'always',
    });

  const formatWei = (
    value: string | bigint,
    roundingMode?: RoundingMode,
    options?: FormatNumberOptions,
    displayOptions?: DisplayOptions
  ) => {
    // We display a precision of 4 digits OR at least 2 significant numbers
    // 1Eth is 10^18wei, the length of the string is the power of 10
    const precision = Math.max(4, 18 - value.toString().length + 2);

    const ethValue = fromWei(value, precision, roundingMode);

    // We want to display the significant digits, discarding trailing 0
    const [, fractionnal] = ethValue.toString().split('.');
    return formatEth(
      ethValue,
      {
        minimumFractionDigits: fractionnal?.length,
        ...options,
      },
      displayOptions
    );
  };

  const formatDistanceToNowWithLocale = (
    date: Date,
    { addSuffix } = { addSuffix: true }
  ) =>
    formatDistanceToNow(date, {
      addSuffix,
      locale: dateLocaleConfig,
    });

  const formatDistanceToNowStrictWithLocale = (
    date: Date,
    { addSuffix } = { addSuffix: true }
  ) =>
    formatDistanceToNowStrict(date, {
      addSuffix,
      locale: dateLocaleConfig,
    });

  useEffect(() => {
    if (!testMode && config) {
      config.date().then(c => setDateLocaleConfig(c.default));

      const root = document.querySelector('html');
      if (root) root.dir = config.dir;
    }
  }, [config, testMode]);

  useEffect(() => {
    const html = window.document.documentElement;

    if (html) html.lang = locale.toString();
  }, [locale, config]);

  return (
    <IntlContextProvider
      value={{
        ...intl,
        dir: config?.dir || 'ltr',
        formatEth,
        formatWei,
        formatCardBonus,
        setLocale,
        formatDistanceToNow: formatDistanceToNowWithLocale,
        formatDistanceToNowStrict: formatDistanceToNowStrictWithLocale,
      }}
    >
      {children}
    </IntlContextProvider>
  );
};

interface Props {
  children: ReactNode;
  testMode?: boolean;
  timeZone?: string;
  locale: ReturnType<typeof useBasenameLocale>['locale'];
  setLocale: (newLocale: string) => void;
}

export const HrefLang = () => {
  const { pathname, search, hash } = useLocation();

  const canonical = useHref({ pathname });

  return (
    <Helmet>
      {Object.entries(PREFIXES).map(([key, value]) => (
        <link
          key={key}
          rel="alternate"
          hrefLang={
            // Specify specific region only for en-GB
            value === 'en-GB' ? value : toLanguageCode(value)
          }
          href={`${ORIGIN}${key}${pathname}${search}${hash}`}
        />
      ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${ORIGIN}${pathname}${search}${hash}`}
      />
      <link rel="canonical" href={`${ORIGIN}${canonical}`} />
    </Helmet>
  );
};

export const IntlProvider = ({
  children,
  testMode = false,
  timeZone,
  locale,
  setLocale,
}: Props) => {
  const { translations } = useTranslations(locale, testMode);

  useEffect(() => {
    if (testMode) {
      return;
    }
    polyfillDateTimeFormat(locale.split('-')[0]); // We still need this polyfill to support formatRange even though we don't use it in the codebase.
  }, [locale, testMode]);

  return (
    <ReactIntlProvider
      locale={locale}
      defaultLocale="en"
      key={locale}
      messages={translations}
      onError={noop}
      timeZone={timeZone}
    >
      <InnerComponent setLocale={setLocale} testMode={testMode}>
        {children}
      </InnerComponent>
    </ReactIntlProvider>
  );
};

export const TestIntlProvider = ({ children }: Pick<Props, 'children'>) => {
  const { locale, setLocale } = useBasenameLocale();
  return (
    <IntlProvider testMode locale={locale} setLocale={setLocale} timeZone="UTC">
      {children}
    </IntlProvider>
  );
};

export default memo(IntlProvider);
