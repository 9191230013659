// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core';
import { animated, config, useSpring } from 'react-spring';

import { OwnerTransfer } from '@sorare/core/src/__generated__/globalTypes';

import ReferralReward from '@sorare/marketplace/src/components/transfers/ReferralReward';
import Reward from '@sorare/marketplace/src/components/transfers/Reward';
import Transfer from '@sorare/marketplace/src/components/transfers/Transfer';

import styles from './styles';

const useStyles = makeStyles(styles);

interface Props {
  amount: string;
  transferType: OwnerTransfer;
}

const CardTransferTypeIcon = ({ transferType }: { transferType: string }) => {
  if (transferType === OwnerTransfer.REFERRAL) {
    return <ReferralReward />;
  }
  if (transferType === OwnerTransfer.REWARD) {
    return <Reward />;
  }
  return <Transfer />;
};

const Price = ({ amount, transferType }: Props) => {
  const classes = useStyles();

  const animStyle = useSpring({
    from: {
      transform: `scale(0)`,
    },
    to: {
      transform: 'scale(4)',
    },
    config: config.stiff,
    reset: true,
  });

  return (
    <animated.div className={classes.price} style={animStyle}>
      <CardTransferTypeIcon transferType={transferType} /> {amount}
    </animated.div>
  );
};

export default Price;
