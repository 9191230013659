import countries from './countries';

const clubs = {
  // SO5
  '07-vestur-midvagur-vagar': countries.fo,
  '1-de-agosto-luanda': countries.ao,
  '1-dezembro-sintra': countries.pt,
  '1074-cankirispor-cankiri': countries.tr,
  '12-bingolspor-bingol': countries.tr,
  '12-de-octubre-itaugua': countries.py,
  '1860-munchen-ii-munchen': countries.de,
  '1860-munchen-munchen': countries.de,
  '1860-munchen-u19-munchen': countries.de,
  '1860-rosenheim-rosenheim': countries.de,
  '1926-bulancakspor-bulancak': countries.tr,
  '1926-polatli-belediye-ankara': countries.tr,
  '1966-edremitspor-edremit-balikesir': countries.tr,
  '1985-derecikspor-derecik-akcaabat': countries.tr,
  '2-de-mayo-pedro-juan-caballero': countries.py,
  '24-erzincanspor-erzincan': countries.tr,
  '3-de-febrero-ciudad-del-este': countries.py,
  '4-de-julho-piripiri-piaui': countries.br,
  '68-aksaray-belediyespor-aksaray': countries.tr,
  '76-igdir-belediyespor-igdir': countries.tr,
  '9-de-julio-chacabuco': countries.ar,
  '9-de-julio-morteros-morteros-provincia-de-cordoba': countries.ar,
  '9-de-julio-rafaela-rafaela-provincia-de-santa-fe': countries.ar,
  '9-de-octubre': countries.ec,
  'a-league-all-stars': countries.au,
  'aa-iguacu-uniao-da-vitoria-parana': countries.br,
  'aab-aalborg': countries.dk,
  'aalen-aalen': countries.de,
  'aalesund-alesund': countries.no,
  'aalesund-ii-alesund': countries.no,
  'aarau-aarau': countries.ch,
  'aarhus-fremad-arhus': countries.dk,
  'ab-argir-streymoy': countries.fo,
  'ab-soborg': countries.dk,
  'abahani-chittagong-chittagong': countries.bd,
  'abahani-dhaka': countries.bd,
  'abc-natal-rio-grande-do-norte': countries.br,
  'abdish-ata-kant': countries.kg,
  'aberdeen-aberdeen': countries.gb,
  'aberystwyth-town-aberystwyth-ceredigion': countries.gb,
  'abha-abha': countries.sa,
  'abia-warriors-umuahia': countries.ng,
  'ac-connecticut-newtown-connecticut': countries.us,
  'academia-anzoategui': countries.ve,
  'academia-cantolao-callao': countries.pe,
  'academia-puerto-cabello-puerto-cabello': countries.ve,
  'academica-coimbra': countries.pt,
  'academica-do-lobito-lobito': countries.ao,
  'academica-vitoria-vitoria-de-santo-antao-pernambuco': countries.br,
  'academico-viseu-viseu': countries.pt,
  'acao-cuiaba-mato-grosso': countries.br,
  'acassuso-san-isidro-provincia-de-buenos-aires': countries.ar,
  'accrington-stanley-accrington-lancashire': countries.gb,
  'accrington-stanley-u18': countries.gb,
  'acero-el-port-de-sagunt': countries.es,
  'acheron-kanalaki-kanallaki': countries.gr,
  'achilles-29-groesbeek': countries.nl,
  'achilles-veen-veen': countries.nl,
  'achuapa-jutiapa': countries.gt,
  'acren-lessines-deux-acren': countries.be,
  'acs-gloria-bistrita-bistrita': countries.ro,
  'acv-assen': countries.nl,
  'ad-ceuta-ceuta': countries.es,
  'ada-velipoje-velipoje': countries.al,
  'adana-1954-fk-adana': countries.tr,
  'adana-demirspor-adana': countries.tr,
  'adana-demirspor-u19-adana': countries.tr,
  'adanaspor-adana': countries.tr,
  'adanaspor-u19-adana': countries.tr,
  'adelaide-city': countries.au,
  'adelaide-comets-adelaide': countries.au,
  'adelaide-olympic-adelaide': countries.au,
  'adelaide-united-adelaide-2003': countries.au,
  'adelaide-united-u21-adelaide': countries.au,
  'adiyaman-1954-adiyaman': countries.tr,
  'adiyamanspor-adiyaman': countries.tr,
  'admira-dornbirn-dornbirn': countries.at,
  'admira-maria-enzersdorf': countries.at,
  'admira-praha-praha': countries.cz,
  'admira-u16-maria-enzersdorf': countries.at,
  'ado-20-heemskerk': countries.nl,
  'ado-den-haag-den-haag': countries.nl,
  'ado-den-haag-u19-den-haag': countries.nl,
  'adria-miren': countries.si,
  adriese: countries.it,
  adt: countries.pe,
  'aduana-stars-dormaa-ahenkro': countries.gh,
  'ae-zakakiou-limassol-lemesos': countries.cy,
  'aegir-thorlakshofn': countries.is,
  'aek-athens-athina-athens': countries.gr,
  'aek-athens-ii-athina-athens': countries.gr,
  'aek-athens-u19-athina-athens': countries.gr,
  'aek-larnaca-larnaka-larnaca': countries.cy,
  'ael-limassol-lemesos': countries.cy,
  'ael-u19-limassol-lemesos': countries.cy,
  'aerostar-bacau-bacau': countries.ro,
  'afad-abidjan': countries.ci,
  'afan-lido-port-talbot-porth-talbot-neath-port-talbot': countries.gb,
  'afc-34-alkmaar': countries.nl,
  'afc-amsterdam': countries.nl,
  'afc-bournemouth-bournemouth-dorset': countries.gb,
  'afc-bournemouth-u18': countries.gb,
  'afc-bournemouth-u21': countries.gb,
  'afc-eskilstuna-eskilstuna': countries.se,
  'afc-eskilstuna-u21-upplands-vasby': countries.se,
  'afc-hornchurch-london': countries.gb,
  'afc-kempston-rovers-kempston': countries.gb,
  'afc-rushden-diamonds-wellingborough': countries.gb,
  'afc-sudbury-sudbury-suffolk': countries.gb,
  'afc-telford-united-telford-shropshire': countries.gb,
  'afc-totton-totton-hampshire': countries.gb,
  'afc-wimbledon-kingston-upon-thames-surrey': countries.gb,
  'afjet-afyonspor-afyonkarahisar': countries.tr,
  'afjet-afyonspor-u19-afyonkarahisar': countries.tr,
  'afjet-afyonspor-u21-afyonkarahisar': countries.tr,
  'afogados-afogados-da-ingazeira-pernambuco': countries.br,
  'afturelding-mosfellsbaer': countries.is,
  'afumati-afumati': countries.ro,
  'ag-caennaise': countries.fr,
  'agde-agde': countries.fr,
  'agf-arhus': countries.dk,
  'agios-nikolaos': countries.gr,
  'aglianese-agliana': countries.it,
  'agmk-olmaliq-almalyk': countries.uz,
  'agoncillo-agoncillo': countries.es,
  'agri-1970-agri': countries.tr,
  'agricola-borcea-borcea': countries.ro,
  'agropecuario-carlos-casares-provincia-de-buenos-aires': countries.ar,
  'agrotikos-asteras-thessaloniki': countries.gr,
  'agrupacion-estudiantil-santiago-de-compostela': countries.es,
  'agua-santa-diadema-sao-paulo': countries.br,
  'agudat-sport-ashdod-ashdod': countries.il,
  'aguia-de-maraba-maraba-para': countries.br,
  'aguia-negra-rio-brilhante-mato-grosso-do-sul': countries.br,
  'aguila-san-miguel': countries.sv,
  'aguilas-fc-aguilas': countries.es,
  'ah-zapla-palpala-provincia-de-jujuy': countries.ar,
  'ahal-abadan': countries.tm,
  'ahironas-onisilos-sotira': countries.cy,
  'ahlafors-ahlafors': countries.se,
  'ahli-al-khalil-qalqilyah': countries.ps,
  'ahva-kafr-manda-kafr-manda': countries.il,
  'aias-salamina-salamina': countries.gr,
  'aiglon-lamentin': countries.mq,
  'aik-solna': countries.se,
  'aik-u19-solna': countries.se,
  'aimore-sao-leopoldo-rio-grande-do-sul': countries.br,
  'ain-m-lila-ain-m-lila': countries.dz,
  'ain-sud': countries.fr,
  'aiolikos-mytilene': countries.gr,
  'air-force': countries.lk,
  'airbus-uk-broughton-flintshire': countries.gb,
  'airdrieonians-glasgow': countries.gb,
  'airdrieonians-res-glasgow': countries.gb,
  'aische-aische-en-refail': countries.be,
  'aittitos-spata-spata': countries.gr,
  'aix-aix-les-bains': countries.fr,
  'aizawl-aizawl': countries.in,
  'ajaccio-ajaccio': countries.fr,
  'ajaccio-ii-ajaccio': countries.fr,
  'ajax-amateurs-amsterdam': countries.nl,
  'ajax-amsterdam': countries.nl,
  'ajax-cape-town-cape-town-wc': countries.za,
  'ajax-ii-amsterdam': countries.nl,
  'ajax-tallinna-tallinn': countries.ee,
  'ajax-u19-amsterdam': countries.nl,
  'ajka-ajka': countries.hu,
  'ajman-ajman': countries.ae,
  'akademija-pandev-strumica': countries.mk,
  'akademik-svishtov-svishtov-svistov': countries.bg,
  'akademiya-ontustik': countries.kz,
  'akademiya-tambov-tambov': countries.ru,
  'akhisarspor-akhisar': countries.tr,
  'akhmat-grozny-groznyi': countries.ru,
  'akincilarspor-tavsanli': countries.tr,
  'akritas-chloraka': countries.cy,
  'akron-togliatti': countries.ru,
  'akropolis-sundbyberg': countries.se,
  'aksaray-genclikspor-aksaray': countries.tr,
  'aktobe-aqtobe-aktobe': countries.kz,
  'akwa-united-uyo': countries.ng,
  'akzhayik-oral-ural-sk': countries.kz,
  'al-adalh': countries.sa,
  'al-ahed-beirut': countries.lb,
  'al-ahli': countries.jo,
  'al-ahli-ad-doha-doha': countries.qa,
  'al-ahli-al-manama-manama': countries.bh,
  'al-ahli-benghazi-bingazi-benghazi': countries.ly,
  'al-ahli-jeddah': countries.sa,
  'al-ahli-khartoum-khartoum': countries.sd,
  'al-ahli-san-a-sanaa': countries.ye,
  'al-ahli-tripoli-tripoli': countries.ly,
  'al-ahly-al-qahirah-cairo': countries.eg,
  'al-ahly-merowe-merowe': countries.sd,
  'al-ahly-shendi-shendi-shandi': countries.sd,
  'al-ain-al-atawlah-city': countries.sa,
  'al-ain-al-ayn-al-ain': countries.ae,
  'al-akhaa-al-ahli-aley': countries.lb,
  'al-akhdar-al-bayda': countries.ly,
  'al-ansar-beirut': countries.lb,
  'al-ansar-medina': countries.sa,
  'al-arabi': countries.jo,
  'al-arabi-ad-doha-doha': countries.qa,
  'al-arabi-madinat-al-kuwayt-kuwait-city': countries.kw,
  'al-bataeh': countries.ae,
  'al-batin-hafar-al-batin': countries.sa,
  'al-bidda-ad-doha-doha': countries.qa,
  'al-buqa-a': countries.jo,
  'al-dhafra-al-dhafra': countries.ae,
  'al-diwaniya': countries.iq,
  'al-duhail-ad-doha-doha': countries.qa,
  'al-duhail-ii-ad-doha-doha': countries.qa,
  'al-fahaheel': countries.kw,
  'al-faisaly-amman-amman': countries.jo,
  'al-faisaly-harmah': countries.sa,
  'al-fateh-al-hasa': countries.sa,
  'al-feiha': countries.sa,
  'al-fujairah-dubayy-dubai': countries.ae,
  'al-gharafa-ad-doha-doha': countries.qa,
  'al-hala-muharraq': countries.bh,
  'al-hazm-ar-rass-rass': countries.sa,
  'al-helal-al-sahely': countries.ye,
  'al-hidd-hidd': countries.bh,
  'al-hikma-beirut': countries.lb,
  'al-hilal-ar-riyad-riyadh': countries.sa,
  'al-hilal-omdurman-omdurman': countries.sd,
  'al-hilal-port-sudan-port-sudan': countries.sd,
  'al-hudod-bagdad-bagdad': countries.iq,
  'al-hussein': countries.jo,
  'al-ittifaq-ad-dammam-dammam': countries.sa,
  'al-ittifaq-maqaba-maqaba': countries.bh,
  'al-ittihad-al-iskandariah-alexandria': countries.eg,
  'al-ittihad-alab-aleppo': countries.sy,
  'al-ittihad-e319f43a-2fc8-439d-885b-70a99322632d': countries.bh,
  'al-ittihad-jeddah': countries.sa,
  'al-ittihad-kalba-kalba': countries.ae,
  'al-ittihad-tripoli': countries.ly,
  'al-jabalain': countries.sa,
  'al-jahra-madinat-al-kuwayt-kuwait-city': countries.kw,
  'al-jaish-dimashq-damascus': countries.sy,
  'al-jazeera': countries.jo,
  'al-jazira': countries.sy,
  'al-jazira-abu-zaby-abu-dhabi': countries.ae,
  'al-jazira-al-hamra-ras-al-khaymah-ras-al-khaimah': countries.ae,
  'al-jeel': countries.sa,
  'al-kahrabaa-bagdad-bagdad': countries.iq,
  'al-karama-ims-hims': countries.sy,
  'al-karkh-bagdad-bagdad': countries.iq,
  'al-kawkab': countries.sa,
  'al-khaleej-khor-fakkan': countries.ae,
  'al-khaleej-saihat': countries.sa,
  'al-kharitiyath-al-kharitiyath': countries.qa,
  'al-khor-al-khor': countries.qa,
  'al-kuwait-madinat-al-kuwayt-kuwait-city': countries.kw,
  'al-majd-dimashq-damascus': countries.sy,
  'al-markhiya': countries.qa,
  'al-masry-bur-said-port-said': countries.eg,
  'al-merreikh-omdurman': countries.sd,
  'al-mesaimeer': countries.qa,
  'al-mokawloon-al-qahirah-cairo': countries.eg,
  'al-mu-aidar-muaither': countries.qa,
  'al-nahda-al-buraimi': countries.om,
  'al-nahdha-ad-dammam-dammam': countries.sa,
  'al-najaf-najaj': countries.iq,
  'al-najma-al-manama-manama': countries.bh,
  'al-nasar-al-farwaniyah-ardiyah': countries.kw,
  'al-nasr-bingazi-benghazi': countries.ly,
  'al-nasr-dubayy-dubai': countries.ae,
  'al-nasr-salalah': countries.om,
  'al-nassr-ar-riyad-riyadh': countries.sa,
  'al-nejmeh-beirut': countries.lb,
  'al-oruba': countries.ye,
  'al-oruba-sur': countries.om,
  'al-orubah-skaka-al-jawf': countries.sa,
  'al-qadsia-madinat-al-kuwayt-kuwait-city': countries.kw,
  'al-qaisoma': countries.sa,
  'al-qasim': countries.iq,
  'al-quadisiya-al-khobar-khobar': countries.sa,
  'al-quwa-al-jawiya-bagdad-bagdad': countries.iq,
  'al-raed-buraydah-buraidah': countries.sa,
  'al-ramtha': countries.jo,
  'al-rayyan-al-rayyan': countries.qa,
  'al-riffa-ar-rifa': countries.bh,
  'al-riyadh-riyadh': countries.sa,
  'al-sadd-ad-doha-doha': countries.qa,
  'al-sadd-riyadh': countries.sa,
  'al-sahel-abu-halifa': countries.kw,
  'al-sailiya-ad-doha-doha': countries.qa,
  'al-salmiyah-madinat-al-kuwayt-kuwait-city': countries.kw,
  'al-salt': countries.jo,
  'al-saqr-ta-izz': countries.ye,
  'al-seeb': countries.om,
  'al-sha-ab-ibb': countries.ye,
  'al-shabab-al-ahmadi': countries.kw,
  'al-shabab-al-manama-manama': countries.bh,
  'al-shabab-ar-riyad-riyadh': countries.sa,
  'al-shahaniya': countries.qa,
  'al-shamal-madinat-ash-shamal': countries.qa,
  'al-sharjah-al-sharjah-sharjah': countries.ae,
  'al-sharq': countries.sa,
  'al-shoalah-al-kharj': countries.sa,
  'al-shorta': countries.iq,
  'al-sinaah-bagdad-bagdad': countries.iq,
  'al-sulaibikhat-madinat-al-kuwayt-kuwait-city': countries.kw,
  'al-suwaiq-al-suwaiq': countries.om,
  'al-ta-ee-ha-il-hail': countries.sa,
  'al-taawon-buraydah-buraidah': countries.sa,
  'al-tadamun-buri-buri': countries.bh,
  'al-tadhamon-al-farwaniyah-ardiyah': countries.kw,
  'al-tahaddi-bingazi-benghazi': countries.ly,
  'al-talaba-bagdad-bagdad': countries.iq,
  'al-taliya-amah-hama': countries.sy,
  'al-taqdom': countries.sa,
  'al-thaid-sharjah': countries.ae,
  'al-tilal-aden': countries.ye,
  'al-urooba-al-fujairah': countries.ae,
  'al-waab': countries.qa,
  'al-wahda-abu-zaby-abu-dhabi': countries.ae,
  'al-wahda-dimashq-damascus': countries.sy,
  'al-wahda-makkah-mecca': countries.sa,
  'al-wakrah-al-wakra': countries.qa,
  'al-wasl-dubayy-dubai': countries.ae,
  'al-wihdat-amman-amman': countries.jo,
  'al-yarmuk-al-rawda': countries.ye,
  'al-zawra-a-bagdad-bagdad': countries.iq,
  'al-zulfi-al-zulfi': countries.sa,
  'alacatispor-alacati': countries.tr,
  'alajuelense-alajuela': countries.cr,
  'alamal-atbara-atbara': countries.sd,
  'aland-sund': countries.fi,
  'alaniya-vladikavkaz-ii': countries.ru,
  'alanyaspor-alanya': countries.tr,
  'alanyaspor-u19-alanya': countries.tr,
  'alashkert-alashkert': countries.am,
  'alay-osh': countries.kg,
  'albacete-albacete': countries.es,
  'alberes-argeles-argeles-sur-mer': countries.fr,
  'alberschwende-alberschwende': countries.at,
  'albert-quevy-mons-quevy-le-petit': countries.be,
  'albinoleffe-albino': countries.it,
  'albion-montevideo': countries.uy,
  'albion-rovers-coatbridge': countries.gb,
  'albion-u19-san-diego-california': countries.us,
  'albirex-niigata-niigata': countries.jp,
  'albirex-niigata-s-singapore': countries.sg,
  alcains: countries.pt,
  'alcantarilla-alcantarilla': countries.es,
  'alcides-meppel': countries.nl,
  alcione: countries.it,
  'alcobendas-sport': countries.es,
  'alcorcon-alcorcon': countries.es,
  'alcorcon-ii-alcorcon': countries.es,
  'alcoyano-alcoi-alcoy': countries.es,
  'alcudia-alcudia': countries.es,
  'aldershot-town-aldershot-hampshire': countries.gb,
  'aldosivi-mar-del-plata-provincia-de-buenos-aires': countries.ar,
  'alemannia-aachen-aachen': countries.de,
  'alemannia-waldalgesheim-dietersheim': countries.de,
  'alencon-alencon': countries.fr,
  'alessandria-alessandria': countries.it,
  'alesves-villafranca': countries.es,
  'alexandria-alexandria': countries.ro,
  'alexandria-u19-alexandria-virginia': countries.us,
  'alfaro-alfaro': countries.es,
  'alfonso-ugarte-puno-puno': countries.pe,
  'alfreton-town-alfreton-derbyshire': countries.gb,
  alftanes: countries.is,
  'alga-bishkek': countries.kg,
  algarna: countries.se,
  'algeciras-algeciras': countries.es,
  'aliaga-fas-aliaga': countries.tr,
  'alianca-al-pilar-alagoas': countries.br,
  'alianza-atletico-sullana': countries.pe,
  'alianza-chilibre': countries.pa,
  'alianza-cotopaxi-cotopaxi': countries.ec,
  'alianza-cutral-co-cutral-co-provincia-del-neuquen': countries.ar,
  'alianza-lima-lima': countries.pe,
  'alianza-petrolera-barrancabermeja': countries.co,
  'alianza-san-salvador': countries.sv,
  'alianza-universidad-huanuco': countries.pe,
  'alki-oroklini-oroklini': countries.cy,
  'all-boys-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'all-stars-johannesburg': countries.za,
  'allerheiligen-allerheiligen-bei-wildon': countries.at,
  'allerod-allerod': countries.dk,
  'alloa-athletic-alloa': countries.gb,
  'alloa-athletic-res-alloa': countries.gb,
  'almagro-almagro': countries.es,
  'almagro-tres-de-febrero-provincia-de-buenos': countries.ar,
  'almazan-almazan': countries.es,
  'almere-city-almere': countries.nl,
  'almere-city-u19-almere': countries.nl,
  'almere-city-u21-almere': countries.nl,
  'almeria-almeria': countries.es,
  'almeria-ii-almeria': countries.es,
  'almirante-brown-de-lules-lules-provincia-tucuman': countries.ar,
  'almirante-brown-san-justo-provincia-de-buenos-aires': countries.ar,
  'almopos-aridaea': countries.gr,
  'almudevar-almudevar': countries.es,
  'alondras-cangas': countries.es,
  alpendorada: countries.pt,
  'alphense-boys-alphen-aan-den-rijn': countries.nl,
  'alta-alta': countries.no,
  'altay-izmir': countries.tr,
  'altay-u19-izmir': countries.tr,
  'altglienicke-berlin': countries.de,
  'altindag-belediyesispor-altindag': countries.tr,
  'altinordu-izmir': countries.tr,
  'altinova-belediyespor-altinova': countries.tr,
  'altona-93-hamburg': countries.de,
  'altona-magic-melbourne': countries.au,
  'altos-altos-piaui': countries.br,
  'altrincham-altrincham-cheshire': countries.gb,
  'altyn-asyr-asgabat-ashgabat': countries.tm,
  'aluminij-kidricevo': countries.si,
  'aluminium-arak-arak': countries.ir,
  'alumni-villa-maria-provincia-de-cordoba': countries.ar,
  'alvarado-mar-del-plata-provincia-de-buenos-aires': countries.ar,
  'alvear-intendente-alvear-provincia-de-la-pampa': countries.ar,
  alvechurch: countries.gb,
  'alverca-alverca': countries.pt,
  'always-ready-el-alto': countries.bo,
  'alzira-alzira': countries.es,
  'amarante-amarante': countries.pt,
  amazonas: countries.br,
  'amazulu-durban-kn': countries.za,
  'amed-diyarbakir': countries.tr,
  'america-ciudad-de-mexico-d-f': countries.mx,
  'america-de-cali-santiago-de-cali': countries.co,
  'america-de-quito-quito': countries.ec,
  'america-mineiro-belo-horizonte-minas-gerais': countries.br,
  'america-mineiro-u20-belo-horizonte-minas-gerais': countries.br,
  'america-rn-natal-rio-grande-do-norte': countries.br,
  'america-se-propria-sergipe': countries.br,
  'america-sp-sao-jose-do-rio-preto-sao-paulo': countries.br,
  'america-u20-ciudad-de-mexico-d-f': countries.mx,
  'amiens-ac-amiens': countries.fr,
  'amiens-sc-amiens': countries.fr,
  'amiens-sc-ii-amiens': countries.fr,
  'amkar-perm-perm': countries.ru,
  'ammanford-ammanford': countries.gb,
  'amora-amora': countries.pt,
  'amorebieta-amorebieta-echano': countries.es,
  'amstetten-amstetten': countries.at,
  'anadia-anadia': countries.pt,
  'anadolu-selcukspor-konya': countries.tr,
  'anadolu-universitesi-eskisehir': countries.tr,
  'anagennisi-deryneia-derynias': countries.cy,
  'anagennisi-karditsas-karditsa': countries.gr,
  'anaitasuna-ft-azkoitia': countries.es,
  'anamur-belediyespor-anamur': countries.tr,
  'anapolina-anapolis-goias': countries.br,
  'anapolis-anapolis-goias': countries.br,
  'ancona-1905-ancona': countries.it,
  andelsbuch: countries.at,
  'anderlecht-bruxelles-brussel': countries.be,
  'andijan-andijon-andijan': countries.uz,
  'andino-la-rioja-provincia-de-la-rioja': countries.ar,
  'andorra-andorra-teruel': countries.es,
  andratx: countries.es,
  'andraus-brasil-colonia-dom-pedro-ii-campo-largo': countries.br,
  'andrezieux-andrezieux-boutheon': countries.fr,
  'angelholm-angelholm': countries.se,
  'angered-bk-goteborg': countries.se,
  'angers-sco-angers': countries.fr,
  'angers-sco-ii-angers': countries.fr,
  'angers-u19': countries.fr,
  'anges-notse-notse': countries.tg,
  'anglet-genets-anglet': countries.fr,
  'angostura-puerto-ordaz': countries.ve,
  'angouleme-soyaux': countries.fr,
  angrense: countries.pt,
  'angri-calcio-angri': countries.it,
  'anguiano-anguiano': countries.es,
  'angusht-nazran': countries.ru,
  'anif-salzburg': countries.at,
  'ankara-adliyespor-ankara': countries.tr,
  'ankara-demirspor-ankara': countries.tr,
  'ankara-keciorengucu-u19-ankara': countries.tr,
  'ankara-tki': countries.tr,
  'ankaragucu-ankara': countries.tr,
  'ankaragucu-u19-ankara': countries.tr,
  'ankas-istiklalspor-gaziantep': countries.tr,
  'ann-arbor-ann-arbor-michigan': countries.us,
  'annaba-annaba': countries.dz,
  'annagh-united-portadown': countries.gb,
  'annan-athletic-annan': countries.gb,
  'annecy-annecy': countries.fr,
  'anorthosis-larnaka-larnaca': countries.cy,
  'ansan-greeners-ansan': countries.kr,
  'ansbach-ansbach': countries.de,
  'antakya-belediyespor-antakya-hatay': countries.tr,
  'antalyaspor-antalya': countries.tr,
  'antalyaspor-u19-antalya': countries.tr,
  'antequera-antequera': countries.es,
  'antigua-gfc-antigua': countries.gt,
  'antofagasta-antofagasta': countries.cl,
  'antoniano-lebrija': countries.es,
  'antwerp-deurne': countries.be,
  'anyang-anyang': countries.kr,
  'aparecida-go-aparecida-de-goiania-goias': countries.br,
  'aparecidense-aparecida-de-goiania-goias': countries.br,
  'apea-akrotiri': countries.cy,
  'apeldoorn-apeldoorn': countries.nl,
  'apep-kyperounta': countries.cy,
  'apia-leichhardt-tigers-new-south-wales': countries.au,
  'apoel-nicosia-levkosia': countries.cy,
  'apoel-u19-nicosia-levkosia': countries.cy,
  'apollon-larissa-larissa': countries.gr,
  'apollon-limassol-lemesos': countries.cy,
  'apollon-paralimniou-paralimni': countries.gr,
  'apollon-pontou-thessaloniki': countries.gr,
  'apollon-smirnis-athina-athens': countries.gr,
  'apolonia-fier-fier': countries.al,
  'apr-kigali': countries.rw,
  aprilia: countries.it,
  'aquidauanense-aquidauana-mato-grosso-do-sul': countries.br,
  'ar-sao-martinho-sao-martinho-do-campo': countries.pt,
  'araban-belediyespor-araban': countries.tr,
  'arabe-unido-colon': countries.pa,
  'aragua-maracay': countries.ve,
  'aragvi-dusheti-dusheti': countries.ge,
  'aral-nukus-nukus': countries.uz,
  'arameiska-syrianska-norsborg-botkyrka': countries.se,
  'arandina-aranda-de-duero': countries.es,
  'arapgirspor-arapgir': countries.tr,
  'arar-arar': countries.sa,
  'ararat-armenia-yerevan': countries.am,
  'ararat-yerevan': countries.am,
  'arborg-selfoss': countries.is,
  'arbroath-arbroath': countries.gb,
  'arc-alphen-aan-den-rijn': countries.nl,
  'arcahaie-arcahaie': countries.ht,
  'arconatese-arconate': countries.it,
  'arcos-arcos-de-la-frontera': countries.es,
  'arda-kardzhali': countries.bg,
  'ardagger-ardagger': countries.at,
  'ardoi-zizur-mayor-zizur-nagusia': countries.es,
  'ards-newtonards': countries.gb,
  ardziv: countries.fr,
  'arema-malang': countries.id,
  'arenas-de-armilla': countries.es,
  'arenas-de-getxo-getxo': countries.es,
  'arendal-arendal': countries.no,
  'arenteiro-o-carballino': countries.es,
  'arezzo-arezzo': countries.it,
  'arg-quilmes-rafaela-rafaela-provincia-de-santa-fe': countries.ar,
  'arge-pite-ti': countries.ro,
  'argentino-mendoza-san-jose-provincia-de-mendoza': countries.ar,
  'argentino-merlo-merlo-provincia-de-buenos-aires': countries.ar,
  'argentino-monte-maiz-monte-maiz-cordoba': countries.ar,
  'argentino-penarol-ciudad-de-cordoba-provincia-de-cordoba': countries.ar,
  'argentino-pergamino-pergamino-provincia-de-buenos-aires': countries.ar,
  'argentino-quilmes-quilmes-provincia-de-buenos-aires': countries.ar,
  'argentino-rosario-rosario-provincia-de-santa-fe': countries.ar,
  'argentinos-juniors-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'argon-mijdrecht': countries.nl,
  'arguvan-belediyespor-malatya': countries.tr,
  'arhavispor-arhavi': countries.tr,
  'arifiye-kalaycispor-arifiye': countries.tr,
  'aris-avato-avato': countries.gr,
  'aris-limassol-lemesos': countries.cy,
  'aris-thessaloniki': countries.gr,
  'aris-u19-thessaloniki': countries.gr,
  ariznabarra: countries.es,
  'arka-gdynia-gdynia': countries.pl,
  'armada-pro-u16-17-jacksonville-florida': countries.us,
  'armadale-perth': countries.au,
  'armagh-city-armagh': countries.gb,
  'armed-forces-banjul': countries.gm,
  'arminia-bielefeld-bielefeld': countries.de,
  'army-united-bangkok': countries.th,
  'arnavutkoy-belediyespor-istanbul': countries.tr,
  'arnedo-arnedo': countries.es,
  'arnett-gardens-kingston': countries.jm,
  'arosa-vilagarcia-de-arousa': countries.es,
  'arouca-arouca': countries.pt,
  'arronches-e-benfica-arronches': countries.pt,
  'arroyo-arroyo-de-la-luz': countries.es,
  'arsenal-1be294f4-f2ef-4519-a221-70f9ed85e2f2': countries.by,
  'arsenal-avellaneda-provincia-de-buenos-aires': countries.ar,
  'arsenal-ceska-lipa-ceska-lipa': countries.cz,
  'arsenal-london': countries.gb,
  'arsenal-tivat-tivat': countries.me,
  'arsenal-tula-ii-tula': countries.ru,
  'arsenal-tula-tula': countries.ru,
  'arsenal-u18-london-colney': countries.gb,
  'arsenal-u21-london': countries.gb,
  'arsenal-u23-london': countries.gb,
  'arsenal-viale-viale-m-grande-entre-rios': countries.ar,
  'arta-sihd-djibouti': countries.dj,
  arteixo: countries.es,
  'arterivo-wakayama': countries.jp,
  'artsakh-yerevan': countries.am,
  'artvin-hopaspor-hopa': countries.tr,
  'arucas-arucas': countries.es,
  'arys-arys': countries.kz,
  'arzignano-valchiampo-arzignano': countries.it,
  arzua: countries.es,
  'as-eupen-eupen': countries.be,
  'as-khroub-el-khroub': countries.dz,
  'as-kigali': countries.rw,
  'as-mont-dore-le-mont-dore': countries.nc,
  'as-pontes-puentes-de-garcia-rodriguez': countries.es,
  'asa-aarhus-aarhus': countries.dk,
  'asa-arapiraca-alagoas': countries.br,
  'asan-mugunghwa-asan': countries.kr,
  'asane-ulset': countries.no,
  'asante-kotoko-kumasi': countries.gh,
  'asarum-asarum': countries.se,
  'asas-ali-sabieh': countries.dj,
  'asc-san-diego-san-diego-california': countries.us,
  'ascoli-ascoli': countries.it,
  'asec-mimosas-abidjan': countries.ci,
  'asfa-yennenga-ouagadougou': countries.bf,
  'asfb-bobo-dioulasso': countries.bf,
  'asgabat-asgabat-ashgabat': countries.tm,
  'ashanti-gb-siguiri': countries.gn,
  'ashdod-asdod-ashdod': countries.il,
  'ashdod-u19': countries.il,
  'ashford-town-middlesex-ashford-middlesex': countries.gb,
  'ashton-united-ashton-under-lyne-greater-manchester': countries.gb,
  'asil-lysi-lysi': countries.cy,
  'asker-asker': countries.no,
  'asko-de-kara-kara': countries.tg,
  'asm-oran-oran': countries.dz,
  'aso-chlef-chlef': countries.dz,
  'aspac-cotonou': countries.bj,
  'aspl-2000-port-louis': countries.mu,
  'aspropyrgos-enosis-aspropyrgos': countries.gr,
  'assu-aci-rio-grande-do-norte': countries.br,
  'assyriska-bk-vastra-frolunda': countries.se,
  'assyriska-sodertalje': countries.se,
  'astana-astana': countries.kz,
  'astana-u19-astana': countries.kz,
  'asteras-tripolis-tripoli': countries.gr,
  'asteras-vlachioti-vlachioti': countries.gr,
  'asti-asti': countries.it,
  'aston-villa-birmingham': countries.gb,
  'aston-villa-u18': countries.gb,
  'aston-villa-u21': countries.gb,
  'aston-villa-u23-birmingham': countries.gb,
  'astoria-walldorf-walldorf': countries.de,
  'astra-giurgiu': countries.ro,
  'astrio-halmstad': countries.se,
  'asu-poli-timisoara-timisoara': countries.ro,
  aswan: countries.eg,
  'aswh-hendrik-ido-ambacht': countries.nl,
  'atakum-belediyespor-samsun': countries.tr,
  'atalanta-ciserano': countries.it,
  'atalanta-u19': countries.it,
  'atarfe-industrial-atarfe': countries.es,
  'atenas-ciudad-de-rio-cuarto-provincia-de-cordoba': countries.ar,
  'atenas-san-carlos': countries.uy,
  'atherton-collieries': countries.gb,
  'athletic-club-bilbao': countries.es,
  'athletic-club-ii-bilbao': countries.es,
  'athletic-club-sao-joao-del-rei-minas-gerais': countries.br,
  'athletic-club-u23-bilbao': countries.es,
  'athletico-olympic-bujumbura': countries.bi,
  'athletico-paranaense-curitiba-parana': countries.br,
  'athlone-town-athlone': countries.ie,
  'atios-o-porrino': countries.es,
  'atk-mohun-bagan-kalkata-kolkata': countries.in,
  'atlanta-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'atlanta-jequie-ba': countries.br,
  'atlanta-united-atlanta-georgia': countries.us,
  'atlanta-united-ii-atlanta-georgia': countries.us,
  'atlanta-united-u19-atlanta-georgia': countries.us,
  'atlante-benito-juarez': countries.mx,
  'atlantis-helsinki': countries.fi,
  'atlantis-ii-helsinki': countries.fi,
  'atlas-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'atlas-delmenhorst-delmenhorst': countries.de,
  'atlas-guadalajara': countries.mx,
  'atlas-u20': countries.mx,
  atlasspor: countries.tr,
  'atletic-america-escaldes': countries.ad,
  'atletic-club-d-escaldes-escaldes': countries.ad,
  'atletic-lleida-lleida': countries.es,
  'atletico-acreano-rio-branco-acre': countries.br,
  'atletico-alagoinhas-alagoinhas-bahia': countries.br,
  'atletico-albericia-santander': countries.es,
  'atletico-astorga': countries.es,
  'atletico-baleares-palma-de-mallorca': countries.es,
  'atletico-bucaramanga-bucaramanga': countries.co,
  'atletico-cajazeirense-cajazeiras-paraiba': countries.br,
  'atletico-cearense-fortaleza-ceara': countries.br,
  'atletico-chiriqui-chiriqui': countries.pa,
  'atletico-colegiales-asuncion': countries.py,
  'atletico-colon-junior-san-juan': countries.ar,
  'atletico-cp-lisboa': countries.pt,
  'atletico-de-lugones-lugones': countries.es,
  'atletico-de-pinto-pinto': countries.es,
  'atletico-el-vigia-el-vigia': countries.ve,
  'atletico-espeleno-espiel': countries.es,
  'atletico-gloriense-nossa-senhora-da-gloria-sergipe': countries.br,
  'atletico-go-goiania-goias': countries.br,
  'atletico-granadilla': countries.es,
  'atletico-grau-piura': countries.pe,
  'atletico-huila-neiva': countries.co,
  'atletico-ibanes': countries.es,
  'atletico-la-cruz-maturin': countries.ve,
  'atletico-madrid-ii-madrid': countries.es,
  'atletico-madrid-madrid': countries.es,
  'atletico-madrid-u19-madrid': countries.es,
  'atletico-maronese-neuquen-provincia-de-neuquen': countries.ar,
  'atletico-marte-quezaltepeque': countries.sv,
  'atletico-mineiro-belo-horizonte-minas-gerais': countries.br,
  'atletico-monzon-monzon': countries.es,
  'atletico-nacional': countries.pa,
  'atletico-nacional-medellin': countries.co,
  'atletico-ottawa': countries.ca,
  'atletico-palmaflor': countries.bo,
  'atletico-palmira-palmira-provincia-de-mendoza': countries.ar,
  'atletico-pantoja-santo-domingo': countries.do,
  'atletico-parana-parana-provincia-de-entre-rios': countries.ar,
  'atletico-paso': countries.es,
  'atletico-pe-camaragibe-pernambucano': countries.br,
  'atletico-policial-san-fernando-del-valle-de-catamarca-provincia-de-catamarca':
    'ar',
  'atletico-porcuna': countries.es,
  'atletico-pulpileno-pulpi': countries.es,
  'atletico-rafaela-rafaela-provincia-de-santa-fe': countries.ar,
  'atletico-roraima-boa-vista-roraima': countries.br,
  'atletico-san-jorge-san-jorge-provincia-de-santa-fe': countries.ar,
  'atletico-san-luis-san-luis-potosi': countries.mx,
  'atletico-san-luis-u20': countries.mx,
  'atletico-santiago-de-cali': countries.co,
  'atletico-tacoronte-tacoronte': countries.es,
  'atletico-tomelloso-tomelloso': countries.es,
  'atletico-tordesillas': countries.es,
  'atletico-tubarao-tubarao-santa-catarina': countries.br,
  'atletico-tucuman-san-miguel-de-tucuman-provincia-de-tucuman': countries.ar,
  'atletico-uruguay-concepcion-del-uruguay-provincia-de-entre-rios':
    countries.ar,
  'atletico-victoria-la-victoria-de-acentejo-tenerife': countries.es,
  'atletik-kuklen-kuklen': countries.bg,
  'atm-selayang': countries.my,
  'atmosfera-mazeikiai': countries.lt,
  'atromitos-athina-athens': countries.gr,
  'atromitos-palamas': countries.gr,
  'atsalenios-iraklio-heraklion': countries.gr,
  'atus-ferlach-ferlach': countries.at,
  'atvidaberg-atvidaberg': countries.se,
  'atyrau-atiraw-atyrau': countries.kz,
  'atzeneta-atzeneta-d-albaida': countries.es,
  'aubagne-aubagne': countries.fr,
  'aubervilliers-aubervilliers': countries.fr,
  'aubstadt-aubstadt': countries.de,
  'aucas-quito': countries.ec,
  'auchinleck-talbot-auchinleck': countries.gb,
  'auckland-city-auckland': countries.nz,
  'auckland-united-auckland': countries.nz,
  'auda-kekava': countries.lv,
  'audace-cerignola-cerignola': countries.it,
  'audax-italiano-santiago-de-chile': countries.cl,
  'audax-rio-sao-joao-de-meriti-rio-de-janeiro': countries.br,
  'auerbach-auerbach-vogtland': countries.de,
  'augnablik-kopavogur': countries.is,
  'augsburg-augsburg': countries.de,
  'augsburg-ii-augsburg': countries.de,
  'augsburg-u17-augsburg': countries.de,
  'augsburg-u19-augsburg': countries.de,
  'aumund-vegesack-bremen': countries.de,
  'aurora-ciudad-de-guatemala': countries.gt,
  'aurora-cochabamba': countries.bo,
  'aurrera-de-ondarroa-ondarroa': countries.es,
  'aurrera-de-vitoria-vitoria-gasteiz': countries.es,
  'austin-austin-texas': countries.us,
  'austria-klagenfurt-klagenfurt-am-worthersee': countries.at,
  'austria-klagenfurt-u16-klagenfurt-am-worthersee': countries.at,
  'austria-klagenfurt-u18-klagenfurt-am-worthersee': countries.at,
  'austria-lustenau-ii': countries.at,
  'austria-lustenau-lustenau': countries.at,
  'austria-salzburg-salzburg': countries.at,
  'austria-wien-ii-wien': countries.at,
  'austria-wien-wien': countries.at,
  'austria-xiii-wien': countries.at,
  'auto-esporte-joao-pessoa-paraiba': countries.br,
  'auxerre-auxerre': countries.fr,
  'auxerre-ii-auxerre': countries.fr,
  'avai-florianopolis-santa-catarina': countries.br,
  'avance-ezcabarte-ezcabarte': countries.es,
  'avangard-kursk-kursk': countries.ru,
  'avantul-periam': countries.ro,
  'avantul-reghin-reghin': countries.ro,
  'avarta-rodovre': countries.dk,
  'aveley-aveley-essex': countries.gb,
  'avellino-avellino': countries.it,
  'avenida-santa-cruz-do-sul-rio-grande-do-sul': countries.br,
  'avenir-beggen-bareldeng-bereldange': countries.lu,
  'aversa-normanna-aversa': countries.it,
  'avezzano-avezzano': countries.it,
  'aviles-stadium-aviles': countries.es,
  'aviron-bayonnais-bayonne': countries.fr,
  'avispa-fukuoka-fukuoka': countries.jp,
  'avoine-occ-avoine': countries.fr,
  'avondale-avondale-heights': countries.au,
  'avranches-avranches': countries.fr,
  'avranches-ii-avranches': countries.fr,
  'avrankou-omnisport-avrankou': countries.bj,
  'awassa-kenema-yirgalem': countries.et,
  'awc-wijchen': countries.nl,
  'ayacucho-ayacucho-huamanga': countries.pe,
  'ayamonte-ayamonte': countries.es,
  'aydin-yildizspor': countries.tr,
  'ayeyawady-united-yangon': countries.mm,
  'ayia-napa-ayia-napa': countries.cy,
  'aylesbury-united-leighton-buzzard-bedfordshire': countries.gb,
  'ayr-united-ayr': countries.gb,
  'ayr-united-res-ayr': countries.gb,
  'ayutthaya-united-ayutthaya': countries.th,
  'ayvalikgucu-belediyespor-ayvalik': countries.tr,
  'aywaille-aywaille': countries.be,
  'az-alkmaar': countries.nl,
  'az-ii-alkmaar': countries.nl,
  'az-u19-alkmaar': countries.nl,
  'azam-dar-es-salaam': countries.tz,
  azkoyen: countries.es,
  'azsv-aalten': countries.nl,
  'azuaga-azuaga': countries.es,
  'azuero-chitre': countries.pa,
  'azul-claro-numazu': countries.jp,
  azuqueca: countries.es,
  'azuriz-marmeleiro-parana': countries.br,
  'b-1909-odense': countries.dk,
  'b-1913-odense': countries.dk,
  'b-93-kobenhavn': countries.dk,
  'b36-ii-110-torshavn': countries.fo,
  'b36-torshavn-streymoy': countries.fo,
  'b68-toftir-eysturoy': countries.fo,
  'b71-sandur-sandoy': countries.fo,
  'ba-ba': countries.fj,
  'ba-ria-vung-tau-ba-ria-vung-tau': countries.vn,
  'babaeskispor-babaeski': countries.tr,
  'babelsberg-potsdam': countries.de,
  'babrungas-plunge': countries.lt,
  'backa-palanka-backa-palanka': countries.rs,
  'backa-topola-backa-topola': countries.rs,
  'bad-gleichenberg-bad-gleichenberg': countries.at,
  'bad-sauerbrunn-bad-sauerbrunn': countries.at,
  'badalona-badalona': countries.es,
  'badalona-ii-badalona': countries.es,
  'baden-baden': countries.ch,
  'baerum-sandvika': countries.no,
  'bagcilar-spor-istanbul': countries.tr,
  'baglar-belediyespor-diyarbakir': countries.tr,
  'bagnolese-bagnolo-in-piano': countries.it,
  'bagnols-pont': countries.fr,
  'bahcecikspor-bahcecik': countries.tr,
  'bahcelievlerspor-istanbul': countries.tr,
  'bahia-de-feira-feira-de-santana-bahia': countries.br,
  'bahia-salvador-bahia': countries.br,
  'bahla-bahla': countries.om,
  'bahlinger-sc-bahlingen': countries.de,
  'bahrain-sc-muharraq': countries.bh,
  'bak-07-berlin': countries.de,
  'bak-ankara': countries.tr,
  'bakhmaro-chokhatauri': countries.ge,
  'bala-town-bala-gwynedd': countries.gb,
  'balashikha-balashikha': countries.ru,
  'balassagyarmat-vse-balassagyarmat': countries.hu,
  'balatonfuredi-balatonfured': countries.hu,
  'balestier-khalsa-singapore': countries.sg,
  'bali-united-samarinda': countries.id,
  'balikesirspor-balikesir': countries.tr,
  'balikesirspor-u19-balikesir': countries.tr,
  'balingen-balingen': countries.de,
  'balkan-balkanabat': countries.tm,
  'balkan-botevgrad-botevgrad': countries.bg,
  'ballinamallard-united-ballinamallard': countries.gb,
  'ballkani-suva-reka': countries.xk,
  'ballyclare-comrades-ballyclare': countries.gb,
  'ballymena-united-ballymena': countries.gb,
  'balma-balma': countries.fr,
  balmaseda: countries.es,
  'baltika-bfu-kaliningrad': countries.ru,
  'baltika-kaliningrad': countries.ru,
  'baltimore-armour-u19-baltimore-maryland': countries.us,
  'baltimore-saint-marc': countries.ht,
  'baltyk-gdynia-gdynia': countries.pl,
  'balzan-balzan': countries.mt,
  'balzers-balzers': countries.ch,
  'bamber-bridge-preston-lancashire': countries.gb,
  'banadir-banadir': countries.so,
  'banants-yerevan': countries.am,
  'banbridge-town-banbridge': countries.gb,
  'banbury-united-banbury-oxfordshire': countries.gb,
  'bandeirante-sp-birigui-sao-paulo': countries.br,
  'bandirma-etispor-bandirma': countries.tr,
  'bandirmaspor-bandirma': countries.tr,
  'bandirmaspor-u19-bandirma': countries.tr,
  'bandits-belmopan': countries.bz,
  'banfield-lomas-de-zamora-provincia-de-buenos-aires': countries.ar,
  'banga-gargzdai': countries.lt,
  'bangkok-bangkok': countries.th,
  'bangkok-united-bangkok': countries.th,
  'bangladesh-police': countries.bd,
  'bangor-bangor': countries.gb,
  'bangu-rio-de-janeiro-rio-de-janeiro': countries.br,
  'bani-yas-bani-yas': countries.ae,
  'banik-kalinovo': countries.sk,
  'banik-ostrava-ii-ostrava': countries.cz,
  'banik-ostrava-slezska-ostrava': countries.cz,
  'banik-sokolov-sokolov': countries.cz,
  'banks-o-dee': countries.gb,
  'bansko-bansko': countries.bg,
  'bantu-mafeteng': countries.ls,
  banyoles: countries.es,
  'barakaldo-barakaldo': countries.es,
  'baranovichi-baranovichi': countries.by,
  'barbalha-barbalha-ceara': countries.br,
  'barbastro-barbastro': countries.es,
  'barca-residency-u19-casa-grande-arizona': countries.us,
  'barcelona-ba-ilheus-bahia': countries.br,
  'barcelona-barcelona': countries.es,
  'barcelona-guayaquil': countries.ec,
  'barcelona-ii-barcelona': countries.es,
  'barcelona-u19-barcelona': countries.es,
  barco: countries.es,
  'bare-boa-vista-roraima': countries.br,
  'barendrecht-barendrecht': countries.nl,
  'bari-1908-bari': countries.it,
  'baridhara-dhaka': countries.bd,
  'barito-putera-banjarmasin': countries.id,
  'barmbek-uhlenhorst-hamburg': countries.de,
  'barnechea-santiago-de-chile': countries.cl,
  'barnet-london': countries.gb,
  'barnet-u18': countries.gb,
  'barnsley-barnsley-south-yorkshire': countries.gb,
  'barnsley-u18-barnsley-south-yorkshire': countries.gb,
  'barockstadt-fulda-lehn-fulda-lehnerz': countries.de,
  'baroka-lebowakgomo-lp': countries.za,
  'baronie-breda': countries.nl,
  'barracas-central-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'barranquilla-barranquilla': countries.co,
  'barretos-barretos-sao-paulo': countries.br,
  'barrow-barrow-in-furness-cumbria': countries.gb,
  'barry-town-united-barry-y-barri-vale-of-glamorgan': countries.gb,
  'bartinspor-bartin': countries.tr,
  barwell: countries.gb,
  'basel-basel': countries.ch,
  'basel-ii-basel': countries.ch,
  'basford-united': countries.gb,
  'basford-united-u18': countries.gb,
  'bashley-bashley-hampshire': countries.gb,
  'bashundhara-kings-nilphamari': countries.bd,
  'basildon-united': countries.gb,
  'basingstoke-town-basingstoke-hampshire': countries.gb,
  'bask-beograd': countries.rs,
  'bassecourt-bassecourt': countries.ch,
  bastendorf: countries.lu,
  'bastia-borgo-bastia': countries.fr,
  'bastia-furiani': countries.fr,
  'batatais-batatais-sao-paulo': countries.br,
  'bate-barysaw-borisov': countries.by,
  'bath-city-bath-somerset': countries.gb,
  'batman-72-belediyespor-batman': countries.tr,
  'batman-petrolspor-batman': countries.tr,
  'batu-dua': countries.my,
  'baunatal-baunatal': countries.de,
  'bavois-bavois': countries.ch,
  'bay-cities-redwood-city-california': countries.us,
  bayamon: countries.pr,
  'bayburt-1918-istanbul': countries.tr,
  'bayburt-belediyespor-bayburt': countries.tr,
  'bayburt-ioi-bayburt': countries.tr,
  'bayer-leverkusen-leverkusen': countries.de,
  'bayer-leverkusen-u19-leverkusen': countries.de,
  'bayern-alzenau-alzenau': countries.de,
  'bayern-hof-hof': countries.de,
  'bayern-munchen-ii-munchen': countries.de,
  'bayern-munchen-munchen': countries.de,
  'bayern-munchen-u17-munchen': countries.de,
  'bayern-munchen-u19-munchen': countries.de,
  'bayirkoyspor-bayirkoy-bilecik': countries.tr,
  'baykal-irkutsk': countries.ru,
  'bayrampasaspor-istanbul': countries.tr,
  'bayreuth-bayreuth': countries.de,
  'bayswater-city-bayswater': countries.au,
  'bazenheid-bazenheid': countries.ch,
  'bb-bodrumspor-bodrum': countries.tr,
  'bb-erzurumspor-erzurum': countries.tr,
  'bb-erzurumspor-u19-erzurum': countries.tr,
  'bc-rangers-kowloon': countries.hk,
  'bdf-xi-mogoditshane': countries.bw,
  'bdin-vidin': countries.bg,
  'be-forward-wanderers-blantyre': countries.mw,
  'be-quick-1887-haren': countries.nl,
  'be1-nfa-kaunas': countries.lt,
  'beachside-u19-norwalk-connecticut': countries.us,
  'beaconsfield-town-beaconsfield-buckinghamshire': countries.gb,
  'beasain-beasain': countries.es,
  'beaucouze-beaucouze': countries.fr,
  'beauvais-beauvais': countries.fr,
  'becej-becej': countries.rs,
  'bechem-united-bechem': countries.gh,
  'beckenham-town': countries.gb,
  'bedem-ivankovo-ivankovo': countries.hr,
  'bedfont-sports-bedfont-greater-london': countries.gb,
  'bedford-town-cardington-bedfordshire': countries.gb,
  'bedworth-united-bedworth-warwickshire': countries.gb,
  'beerschot-wilrijk-wilrijk': countries.be,
  'beijing-bg-beijing': countries.cn,
  'beijing-guoan-beijing': countries.cn,
  'beira-mar-aveiro': countries.pt,
  'beitar-jerusalem-jerusalem': countries.il,
  'beitar-tel-aviv-ramla-tel-aviv-yafa-tel-aviv': countries.il,
  'bejar-industrial': countries.es,
  'bekescsaba-bekescsaba': countries.hu,
  'belasica-strumica': countries.mk,
  'belasitsa-petrich': countries.bg,
  'belediye-derincespor-derince': countries.tr,
  'belenenses-ii-lisboa': countries.pt,
  'belenenses-lisboa': countries.pt,
  'belfort-belfort': countries.fr,
  'belgrano-ciudad-de-cordoba-provincia-de-cordoba': countries.ar,
  'belgrano-esquel-esquel-provincia-del-chubut': countries.ar,
  'belgrano-parana-parana-provincia-de-entre-rios': countries.ar,
  'belgrano-zarate-zarate-provincia-de-buenos-aires': countries.ar,
  'bella-vista-bahia-blanca-provincia-de-buenos-aires': countries.ar,
  'bella-vista-montevideo': countries.uy,
  'bellinzona-bellinzona': countries.ch,
  'belo-jardim-belo-jardim-pernambuco': countries.br,
  'belouizdad-al-jaza-ir-algiers': countries.dz,
  'belper-town-belper-derbyshire': countries.gb,
  'belshina-bobrujsk-bobruisk': countries.by,
  'beltinci-beltinci': countries.si,
  'belusa-belusa': countries.sk,
  'bembibre-bembibre': countries.es,
  'ben-guerdane-ben-guerdane': countries.tn,
  'ben-hur-rafaela-provincia-de-santa-fe': countries.ar,
  'benatky-nad-jizerou-benatky-nad-jizerou': countries.cz,
  'benburb-glasgow': countries.gb,
  'benesov-benesov': countries.cz,
  'benevento-benevento': countries.it,
  'benevento-u19-benevento': countries.it,
  benfica: countries.mo,
  'benfica-castelo-branco-castelo-branco': countries.pt,
  'benfica-ii-lisboa': countries.pt,
  'benfica-lisboa': countries.pt,
  'benfica-u19-lisboa': countries.pt,
  'benfica-u23-lisboa': countries.pt,
  'bengaluru-bangalore': countries.in,
  'bennekom-bennekom': countries.nl,
  'bentleigh-greens-melbourne': countries.au,
  'berane-berane': countries.me,
  'berazategui-berazategui-provincia-de-buenos-aires': countries.ar,
  'berceo-logrono': countries.es,
  'berchem-sport-berchem': countries.be,
  'berdenia-berbourg-berbuerg-berbourg': countries.lu,
  'berekum-chelsea-accra': countries.gh,
  'berga-kalmar': countries.se,
  'bergama-belediyespor-bergama': countries.tr,
  'bergantinos-carballo': countries.es,
  'bergerac-bergerac': countries.fr,
  'bergheim-bergheim-a7c48e31-baa3-40bf-8886-69cc5e793e21': countries.at,
  'bergisch-gladbach-bergisch-gladbach': countries.de,
  'berkum-zwolle': countries.nl,
  'berlare-berlare': countries.be,
  'beroe-stara-zagora': countries.bg,
  'berwick-rangers-berwick-upon-tweed': countries.gb,
  'besa-kavaje-kavaje': countries.al,
  'besancon-besancon': countries.fr,
  'beselidhja-lezhe-lezhe': countries.al,
  'besiktas-istanbul': countries.tr,
  'besiktas-u19-istanbul': countries.tr,
  'bethesda-u19-gaithersburg-maryland': countries.us,
  'bethlehem-steel-bethlehem-pennsylvania': countries.us,
  'beti-onak-villava': countries.es,
  betim: countries.br,
  'bezana-santa-cruz-de-bezana': countries.es,
  'beziers-beziers': countries.fr,
  'bfa-vilnius': countries.lt,
  'bfc-dynamo-berlin': countries.de,
  'bg-pathum-united-bangkok': countries.th,
  'bgu-minsk': countries.by,
  'bhawanipore-bhowanipore': countries.in,
  'bhayangkara-surabaya': countries.id,
  bicskei: countries.hu,
  'bideford-bideford-devon': countries.gb,
  'biel-bienne-biel-bienne': countries.ch,
  'biesheim-biesheim': countries.fr,
  'big-bullets-blantyre': countries.mw,
  'bigaspor-biga': countries.tr,
  'biggleswade-town-biggleswade-bedfordshire': countries.gb,
  'bihor-oradea-oradea': countries.ro,
  'bijelo-brdo': countries.hr,
  'bilje-bilje': countries.si,
  'billericay-town-billericay-essex': countries.gb,
  'binche-leval-trahegnies': countries.be,
  'binefar-binefar': countries.es,
  binfield: countries.gb,
  'binga-binga': countries.ml,
  'binh-dinh-qui-nh-n-qui-nhon': countries.vn,
  'binh-duong-th-d-u-m-t-thu-dau-mot': countries.vn,
  'binh-ph-c-binh-ph-c-binh-phuoc': countries.vn,
  binissalem: countries.es,
  'binningen-binningen': countries.ch,
  'biolog-novokubansk': countries.ru,
  'birkenhead-united': countries.nz,
  'birkirkara-birkirkara': countries.mt,
  'birmingham-city-birmingham': countries.gb,
  'birmingham-city-u18': countries.gb,
  'birmingham-legion-birmingham-alabama': countries.us,
  'birzebbuga-birzebbuga': countries.mt,
  'bischofshofen-bischofshofen': countries.at,
  'bischofswerdaer-fv-bischofswerda': countries.de,
  'bishop-s-stortford-bishop-s-stortford-hertfordshire': countries.gb,
  'bistra-donja-bistra': countries.hr,
  'bistrica-slovenska-bistrica': countries.si,
  'bitam-bitam': countries.ga,
  'bitlis-ozguzelderespor-bitlis': countries.tr,
  bizau: countries.at,
  'bizertin-bizerte-banzart': countries.tn,
  'bk-46-raasepori-raseborg': countries.fi,
  bkma: countries.am,
  'bkv-elore-budapest': countries.hu,
  'black-africa-windhoek': countries.na,
  'black-bulls-maputo-maputo': countries.mz,
  'black-leopards-venda': countries.za,
  'black-rhinos-harare': countries.zw,
  'black-rock-great-barrington-massachusetts': countries.us,
  'black-stars': countries.ch,
  'blackburn-rovers-blackburn-lancashire': countries.gb,
  'blackburn-rovers-u18': countries.gb,
  'blackburn-rovers-u21-blackburn': countries.gb,
  'blackburn-rovers-u23-blackburn': countries.gb,
  'blackpool-blackpool-lancashire': countries.gb,
  'blackpool-u18': countries.gb,
  'blacktown-city-blacktown': countries.au,
  blainville: countries.ca,
  'blanc-mesnil-blanc-mesnil': countries.fr,
  'blankenberge-blankenberge': countries.be,
  'blansko-blansko': countries.cz,
  'blau-weiss-90-berlin-berlin': countries.de,
  'blau-weiss-linz-linz': countries.at,
  'blaublitz-akita-nikaho': countries.jp,
  'blauw-geel-38-veghel': countries.nl,
  blejoi: countries.ro,
  'blo-weiss-medernach-diekirch': countries.lu,
  'blois-blois': countries.fr,
  'blooming-santa-cruz-de-la-sierra': countries.bo,
  'blue-star-kalutara': countries.lk,
  'blue-waters-walvis-bay': countries.na,
  'blumenau-blumenau-santa-catarina': countries.br,
  'blyth-spartans-blyth-northumberland': countries.gb,
  'bnei-eilat-eilat': countries.il,
  'bnei-hagolan-vehagalil-nahf': countries.il,
  'bnei-sakhnin-sakhnin': countries.il,
  'bnei-yehuda-tel-aviv-yafa-tel-aviv': countries.il,
  'bo-ness-united-bo-ness': countries.gb,
  'bo-rangers-bo': countries.sl,
  'boa-varginha-minas-gerais': countries.br,
  'boavista-porto': countries.pt,
  'boavista-saquarema-rio-de-janeiro': countries.br,
  'bobigny-bobigny': countries.fr,
  'bobovnya-babownya-bobovnya': countries.by,
  'boca-junior-cristinapolis-se': countries.br,
  'boca-juniors-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'boca-unidos-corrientes-provincia-de-corrientes': countries.ar,
  'bocholt-bocholt': countries.be,
  'bochum-bochum': countries.de,
  'bochum-u17-bochum': countries.de,
  'bochum-u19-bochum': countries.de,
  'bodden-town-george-town': countries.ky,
  'boden-boden': countries.se,
  'bodo-glimt-bodo': countries.no,
  'boeung-ket-kampong-cham': countries.kh,
  'bognor-regis-town-bognor-regis-west-sussex': countries.gb,
  'bogota-bogota-d-c': countries.co,
  'bohemians-dublin': countries.ie,
  'boiro-boiro': countries.es,
  'bokelj-kotor': countries.me,
  'bolivar-ciudad-bolivar': countries.ve,
  'bolivar-la-paz': countries.bo,
  'bollullos-bollullos-par-del-condado': countries.es,
  'bologna-bologna': countries.it,
  'bologna-u19': countries.it,
  'bolton-wanderers-bolton': countries.gb,
  'bolton-wanderers-u18': countries.gb,
  'boluspor-bolu': countries.tr,
  'boluspor-u19-bolu': countries.tr,
  'bolvadin-belediyespor': countries.tr,
  'bong-da-hu-hu-hue': countries.vn,
  'bonita-banana-pasaje': countries.ec,
  'bonner-sc-bonn': countries.de,
  'bonner-sc-u19-bonn': countries.de,
  'bonnyrigg-rose-athletic': countries.gb,
  'bonnyrigg-white-eagles-sydney': countries.au,
  'borac-banja-luka-banja-luka': countries.ba,
  'borac-banja-luka-u19-banja-luka': countries.ba,
  'borac-cacak-cacak': countries.rs,
  'bordeaux-ii-le-haillan': countries.fr,
  'bordeaux-le-haillan': countries.fr,
  'bordeaux-u19-le-haillan': countries.fr,
  'borec-veles': countries.mk,
  'boreham-wood-borehamwood-hertfordshire': countries.gb,
  'borjomi-borjomi': countries.ge,
  'borneo-samarinda': countries.id,
  'borussia-dortmund-dortmund': countries.de,
  'borussia-dortmund-ii-dortmund': countries.de,
  'borussia-dortmund-u17-dortmund': countries.de,
  'borussia-dortmund-u19-dortmund': countries.de,
  'borussia-hildesheim-hildesheim': countries.de,
  'borussia-m-gladbach-ii-monchengladbach': countries.de,
  'borussia-m-gladbach-monchengladbach': countries.de,
  'borussia-m-gladbach-u17-monchengladbach': countries.de,
  'borussia-m-gladbach-u19-monchengladbach': countries.de,
  'boston-bolts-u19-boston-massachusetts': countries.us,
  'boston-city-boston-massachusetts': countries.us,
  'boston-river-montevideo': countries.uy,
  'boston-united-boston-lincolnshire': countries.gb,
  'botafogo-ba-salvador-bahia': countries.br,
  'botafogo-pb-joao-pessoa-paraiba': countries.br,
  'botafogo-rio-de-janeiro-rio-de-janeiro': countries.br,
  'botafogo-sp-b-ribeirao-preto-sao-paulo': countries.br,
  'botafogo-sp-ribeirao-preto-sao-paulo': countries.br,
  'botev-ihtiman-ihtiman': countries.bg,
  'botev-novi-pazar-novi-pazar': countries.bg,
  'botev-plovdiv-ii-plovdiv': countries.bg,
  'botev-plovdiv-plovdiv': countries.bg,
  'botev-vratsa-vratsa': countries.bg,
  'botosani-botosani': countries.ro,
  'boulogne-boulogne-sur-mer': countries.fr,
  'boulogne-ii-boulogne-sur-mer': countries.fr,
  'bourg-en-bresse-peronnas': countries.fr,
  'bourges-foot-18-bourges': countries.fr,
  'bourges-foot-18-ii-bourges': countries.fr,
  'bourgoin-jallieu-bourgoin-jallieu': countries.fr,
  bourj: countries.lb,
  'bowers-pitsea-pitsea-essex': countries.gb,
  'boxing-club-rio-gallegos': countries.ar,
  'boyabat-1868-spor': countries.tr,
  'boyaca-chico-tunja': countries.co,
  'bozcaadaspor-bozcaada': countries.tr,
  'bra-bra': countries.it,
  'bra-ov-steagul-rena-te-brasov': countries.ro,
  'brabrand-brabrand': countries.dk,
  'brackley-town-brackley-northamptonshire': countries.gb,
  'bracknell-town-bracknell-berkshire': countries.gb,
  'bradford-city-bradford-west-yorkshire': countries.gb,
  'bradford-city-u18': countries.gb,
  'bradford-park-avenue-bradford-west-yorkshire': countries.gb,
  'braganca-braganca': countries.pt,
  'bragantino-braganca-paulista-sao-paulo': countries.br,
  'bragantino-pa-braganca-para': countries.br,
  'brage-borlange': countries.se,
  'braine-braine-l-alleud-eigenbrakel': countries.be,
  'braintree-town-braintree-essex': countries.gb,
  'brann-bergen': countries.no,
  'brann-ii-bergen': countries.no,
  'brasil-de-pelotas-pelotas-rio-grande-do-sul': countries.br,
  'brasilia-brasilia-distrito-federal': countries.br,
  'brasiliense-taguatinga-distrito-federal': countries.br,
  'bratstvo-gracanica-gracanica': countries.ba,
  brattvag: countries.no,
  'bravo-ljubljana': countries.si,
  'bray-wanderers-bray': countries.ie,
  'brazos-valley-bryan-texas': countries.us,
  'brda-dobrovo-brda': countries.si,
  'brea-brea-de-aragon': countries.es,
  'breakers-u19-santa-cruz-california': countries.us,
  'brechin-city-brechin': countries.gb,
  'breclav-breclav': countries.cz,
  'bregalnica-stip-stip': countries.mk,
  'breidablik-kopavogur': countries.is,
  'breitenrain-bern': countries.ch,
  'bremer-sv-bremen': countries.de,
  breno: countries.it,
  'brentford-brentford-middlesex': countries.gb,
  'brentford-u21': countries.gb,
  'brentwood-town-brentwood-essex': countries.gb,
  'brescia-brescia': countries.it,
  'brest-brest': countries.fr,
  'brest-ii-brest': countries.fr,
  'bretigny-foot': countries.fr,
  'brezice-brezice': countries.si,
  'brielle-brielle': countries.nl,
  'brighouse-town': countries.gb,
  'brighton-hove-albion-brighton-east-sussex': countries.gb,
  'brighton-u18': countries.gb,
  'brighton-u21-brighton': countries.gb,
  'brighton-u23-brighton': countries.gb,
  'brikama-united-brikama': countries.gm,
  'brinje-grosuplje-brinje-grosuplje': countries.si,
  'brisbane-city-brisbane': countries.au,
  'brisbane-roar-brisbane': countries.au,
  'brisbane-roar-ii': countries.au,
  'brisbane-roar-u21-brisbane': countries.au,
  'brisbane-strikers-brisbane': countries.au,
  'bristol-city-bristol': countries.gb,
  'bristol-city-u18': countries.gb,
  'bristol-manor-farm-bristol': countries.gb,
  'bristol-rovers-bristol-gloucestershire': countries.gb,
  'bristol-rovers-u18': countries.gb,
  'britannia-piedra-plat': countries.aw,
  'brito-guimaraes': countries.pt,
  'briton-ferry-briton-ferry': countries.gb,
  'broadmeadow-magic-newcastle': countries.au,
  'brodarac-novi-beograd': countries.rs,
  'bromley-london': countries.gb,
  'brommapojkarna-stockholm': countries.se,
  'brommapojkarna-u21': countries.se,
  'bromsgrove-sporting': countries.gb,
  'brondby-brondby': countries.dk,
  'bronshoj-bronshoj': countries.dk,
  'brora-rangers-brora': countries.gb,
  'brown-de-adrogue-almirante-brown-provincia-de-buenos-aires': countries.ar,
  'broxburn-athletic-broxburn': countries.gb,
  'bruck-leitha-bruck-an-der-leitha': countries.at,
  'bruhl-st-gallen': countries.ch,
  'brujas-de-salamanca-salamanca': countries.cl,
  'brumunddal-brumunddal': countries.no,
  'brusque-brusque-santa-catarina': countries.br,
  'bryne-bryne': countries.no,
  'bsc-glasgow-glasgow': countries.gb,
  'bsk-borca-beograd': countries.rs,
  'buchbach-buchbach': countries.de,
  'bucheon-1995-bucheon': countries.kr,
  'buckie-thistle-buckie': countries.gb,
  'buckley-town-buckley-bwcle-flintshire': countries.gb,
  'bucovina-radauti-radauti': countries.ro,
  'budafoki-mte-budapest': countries.hu,
  budaiya: countries.bh,
  'budaors-budaors': countries.hu,
  'budissa-bautzen-bautzen': countries.de,
  'buducnost-banovici-banovici': countries.ba,
  'buducnost-dobanovci-dobanovci': countries.rs,
  'buducnost-podgorica': countries.me,
  'buitenpost-buitenpost': countries.nl,
  'buje-buje': countries.hr,
  'bukovyna-chernivtsi': countries.ua,
  'bul-jinja': countries.ug,
  'bulat-temirtau': countries.kz,
  'bulle-bulle': countries.ch,
  'bulleen-lions-melbourne': countries.au,
  'bumamuru-cibitoke': countries.bi,
  'bumprom-homel-gomel': countries.by,
  'bunol-valencia': countries.es,
  'bunyodkor-toshkent-tashkent': countries.uz,
  buochs: countries.ch,
  'bupolsa-burgos': countries.es,
  'burgan-burgan': countries.kw,
  'burgess-hill-town-burgess-hill-west-sussex': countries.gb,
  'burgos-burgos': countries.es,
  'burgos-promesas-burgos': countries.es,
  'burhaniye-belediyespor-burhaniye': countries.tr,
  'buriram-united-bangkok': countries.th,
  burlades: countries.es,
  'burnley-burnley-lancashire': countries.gb,
  'burnley-u18': countries.gb,
  'burnley-u21': countries.gb,
  'burnley-u23-burnley': countries.gb,
  'burreli-burrel': countries.al,
  'burriana-burriana': countries.es,
  'bursaspor-bursa': countries.tr,
  'bursaspor-u19-bursa': countries.tr,
  'burton-albion-burton-upon-trent-staffordshire': countries.gb,
  'burton-albion-u18': countries.gb,
  'bury-town-bury-st-edmunds-suffolk': countries.gb,
  'busaiteen-busaiteen': countries.bh,
  'busan-i-park-busan': countries.kr,
  'busan-transportation-busan': countries.kr,
  'butrinti-sarande-sarande': countries.al,
  'buxoro-buxoro-bukhara': countries.uz,
  'buxton-buxton-derbyshire': countries.gb,
  'buxton-united': countries.gy,
  'buyukc-mimarobaspor-buyukcekmece': countries.tr,
  'bvsc-budapest': countries.hu,
  'bw-gottschee-u19-new-york-city-new-york': countries.us,
  'bw-lohne': countries.de,
  'bx-brussels-bruxelles-brussel': countries.be,
  'byasen-trondheim': countries.no,
  'bylis-ballsh': countries.al,
  'c-chartres-chartres': countries.fr,
  'c-chartres-ii-chartres': countries.fr,
  'ca-batna-batna': countries.dz,
  'ca-bermejo-bermejo': countries.bo,
  'ca-la-paz-la-paz-baja-california-sur': countries.mx,
  'ca-neuville-neuville-de-poitou': countries.fr,
  'cabense-cabo-de-santo-agostinho-pernambuco': countries.br,
  'cacereno-caceres': countries.es,
  'cadiz-cadiz': countries.es,
  'cadiz-ii-cadiz': countries.es,
  'caen-caen': countries.fr,
  'caen-ii-caen': countries.fr,
  'caen-u19-caen': countries.fr,
  'caernarfon-town-caernarfon-gwynedd': countries.gb,
  'cafetaleros-de-tapachula-tapachula-chiapas': countries.mx,
  'cagliari-cagliari': countries.it,
  'cagliari-u19': countries.it,
  'cai-comodoro-rivadavia-provincia-del-chubut': countries.ar,
  'cailungo-borgo-maggiore': countries.sm,
  'calahorra-ii': countries.es,
  'calamocha-calamocha': countries.es,
  'calamonte-calamonte': countries.es,
  'calasancio-logrono': countries.es,
  'calcio-padova-padova': countries.it,
  'caldas-caldas-da-rainha': countries.pt,
  'caldense-pocos-de-caldas-minas-gerais': countries.br,
  'california-utd-strikers': countries.us,
  'calvo-sotelo-puertollano': countries.es,
  'camacha-camacha-ilha-da-madeira': countries.pt,
  'cambodia-tiger-phnom-penh': countries.kh,
  'camboriu-camboriu-santa-catarina': countries.br,
  'cambrian-clydach-penygraig-rhondda-cynon-taf': countries.gb,
  'cambridge-city-cambridge-cambridgeshire': countries.gb,
  'cambridge-united-cambridge-cambridgeshire': countries.gb,
  'cambridge-united-u18-cambridge': countries.gb,
  'cambuslang-rangers-cambuslang': countries.gb,
  'cambuur-leeuwarden': countries.nl,
  'camelon-juniors-camelon': countries.gb,
  'camioneros-argentinos-ciudad-de-salta-provincia-de-salta': countries.ar,
  'campbelltown-city': countries.au,
  'campinense-campina-grande-paraiba': countries.br,
  'campo-grande-campo-grande-rj': countries.br,
  'campodarsego-campodarsego': countries.it,
  'can-tho-c-n-th-can-tho': countries.vn,
  'canadian-montevideo': countries.uy,
  canberra: countries.au,
  'canberra-olympic-canberra': countries.au,
  'canberra-united-u21-canberra': countries.au,
  'cancun-cancun-quintana-roo': countries.mx,
  'candas-candas': countries.es,
  'canelas-2010-canelas': countries.pt,
  'canet-roussillon-canet-en-roussillon': countries.fr,
  'canillas-madrid': countries.es,
  'cankurtaran-istanbul': countries.tr,
  'cannes-cannes': countries.fr,
  'cannet-rocheville-le-cannet': countries.fr,
  'canon-yaounde': countries.cm,
  'canuelas-canuelas-provincia-de-buenos-aires': countries.ar,
  'canvey-island-canvey-island-essex': countries.gb,
  'cap-ciudad-de-murcia-murcia': countries.es,
  'cap-patrocinio-minas-gerais': countries.br,
  'capalaba-brisbane': countries.au,
  'cape-town-city-cape-town': countries.za,
  'capelle-capelle-aan-den-ijssel': countries.nl,
  'capital-brasilia-brasilia-distrito-federal': countries.br,
  'capivariano-capivari-sao-paulo': countries.br,
  'capoise-cap-haitien': countries.ht,
  'cappellen-kappellen': countries.be,
  'caps-united-harare': countries.zw,
  'cara-brazzaville-brazzaville': countries.cg,
  'carabobo-valencia': countries.ve,
  'caracas-caracas': countries.ve,
  'caracas-u20-caracas': countries.ve,
  'cardiff-city-cardiff': countries.gb,
  'cardiff-city-u18': countries.gb,
  'cardiff-city-u21-cardiff': countries.gb,
  'cardiff-mu-cardiff': countries.gb,
  'carl-zeiss-jena-jena': countries.de,
  'carlisle-united-carlisle-cumbria': countries.gb,
  'carlos-manucci-trujillo': countries.pe,
  'carlos-stein': countries.pe,
  'carlton-town-carlton-east-midlands': countries.gb,
  'carmarthen-town-caerfyrddin-carmarthen-dyfed': countries.gb,
  'carmelita-alajuela': countries.cr,
  'carnoustie-panmure-carnoustie': countries.gb,
  'carnoux-carnoux-en-provence': countries.fr,
  'carolina-dynamo-greensboro-north-carolina': countries.us,
  'caronnese-caronno-pertusella': countries.it,
  'carrarese-carrara': countries.it,
  'carrick-rangers-carrickfergus': countries.gb,
  'carroi-andorra-la-vella': countries.ad,
  'carsambaspor-carsamba': countries.tr,
  'carshalton-athletic-carshalton-surrey': countries.gb,
  'carsibasi-belediyespor-trabzon': countries.tr,
  'cartagena-ii-cartagena': countries.es,
  'cartagines-cartago': countries.cr,
  'cartaya-cartaya': countries.es,
  cartes: countries.es,
  'caruaru-city-caruaru': countries.br,
  'casa-pia-lisboa': countries.pt,
  'casa-sport-zinguinchor': countries.sn,
  'casalarreina-casalarreina': countries.es,
  'casale-casale-monferrato': countries.it,
  'cascavel-cascavel-parana': countries.br,
  'cashmere-technical-christchurch': countries.nz,
  'caslav-caslav': countries.cz,
  'caspe-caspe': countries.es,
  'casric-stars-pretoria': countries.za,
  'castanet-castanet': countries.fr,
  'castanhal-castanhal-para': countries.br,
  'castelldefels-castelldefels': countries.es,
  'castellon-castellon-de-la-plana': countries.es,
  'castellon-ii': countries.es,
  castkovce: countries.sk,
  'castro-castro-urdiales': countries.es,
  'castro-daire': countries.pt,
  'castuera-castuera': countries.es,
  casuarina: countries.au,
  'catalcaspor-catalca': countries.tr,
  'catalhuyuk-cumra-bs-catalhuyuk': countries.tr,
  'catanzaro-catanzaro': countries.it,
  'caucaia-caucaia-ceara': countries.br,
  'caudal-mieres-del-camino': countries.es,
  cavalier: countries.jm,
  cavalry: countries.ca,
  'cavaly-leogane': countries.ht,
  'cavese-cava-de-tirreni': countries.it,
  'caxias-caxias-do-sul-rio-grande-do-sul': countries.br,
  'cayelispor-cayeli': countries.tr,
  'cayon-saron-santa-maria-de-cayon': countries.es,
  'cd-alcala-alcala-de-guadaira': countries.es,
  'cd-badajoz-badajoz': countries.es,
  'cd-calahorra-calahorra': countries.es,
  'cd-coria-coria': countries.es,
  'cdfc-la-calzada-santo-domingo-de-la-calzada': countries.es,
  'cdj-tamarite-tamarite-de-litera': countries.es,
  'ceahlaul-piatra-neamt-piatra-neamt': countries.ro,
  'ceara-fortaleza-ceara': countries.br,
  'ceares-gijon': countries.es,
  'cefn-druids-wrexham-wrecsam-clwyd': countries.gb,
  'cegledi-cegled': countries.hu,
  'ceilandia-ceilandia-distrito-federal': countries.br,
  'celaya-celaya': countries.mx,
  'celje-celje': countries.si,
  'celta-de-vigo-ii-vigo': countries.es,
  'celta-de-vigo-vigo': countries.es,
  'celtic-glasgow': countries.gb,
  'celtic-ii-glasgow': countries.gb,
  'celtic-u19-glasgow': countries.gb,
  'celtic-u21-glasgow': countries.gb,
  'celtiga-isla-de-arosa': countries.es,
  'centenario-neuquen': countries.ar,
  central: countries.pf,
  'central-ballester-san-martin-provincia-de-buenos-aires': countries.ar,
  'central-c-mariners-u21-tuggerah': countries.au,
  'central-california': countries.tt,
  'central-caruaru-pernambuco': countries.br,
  'central-coast-honiara': countries.sb,
  'central-coast-mariners-tuggerah': countries.au,
  'central-cordoba-rosario-provincia-de-santa-fe': countries.ar,
  'central-cordoba-sde-santiago-del-estero-provincia-de-santiago-del-estero':
    'ar',
  'central-espanol-montevideo': countries.uy,
  'central-norte-salta-provincia-de-salta': countries.ar,
  'central-valley-fuego-fresno-california': countries.us,
  'centro-barber-barber': countries.cw,
  'centro-dominguito-dominguito': countries.cw,
  'centro-espanol-villa-sarmiento-provincia-de-buenos-aires': countries.ar,
  'ceov-operario-varzea-grande-mato-grosso': countries.br,
  'ceramica-cleopatra-giza': countries.eg,
  'cercle-brugge-brugge': countries.be,
  'cerdanyola-del-valles-cerdanyola-del-valles': countries.es,
  'ceres-bacolod': countries.ph,
  'cerezo-osaka-osaka-osaka': countries.jp,
  'cerkezkoy-1911-tekirdag': countries.tr,
  'cerrito-montevideo': countries.uy,
  'cerro-largo-melo': countries.uy,
  'cerro-montevideo': countries.uy,
  'cerro-porteno-asuncion': countries.py,
  'cerro-porteno-u20-asuncion': countries.py,
  'cesar-vallejo-trujillo': countries.pe,
  'cesena-cesena': countries.it,
  'ceske-budejovice-ceske-budejovice': countries.cz,
  'cesky-brod-cesky-brod': countries.cz,
  'cesme-belediyespor-cesme': countries.tr,
  cesson: countries.fr,
  'cetate-deva-deva': countries.ro,
  'ceuta-ceuta': countries.es,
  'ceuta-ii-ceuta': countries.es,
  'ceyhanspor-ceyhan': countries.tr,
  'cf-os-belenenses-lisboa': countries.pt,
  'cf-talavera-talavera-de-la-reina': countries.es,
  'cffa-antananarivo': countries.mg,
  'cfi-alicante': countries.es,
  'cfr-cluj-cluj-napoca': countries.ro,
  'cfr-pforzheim-pforzheim': countries.de,
  'chabab-mohammedia-mohammedia': countries.ma,
  'chacarita-juniors-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'chacaritas-pelileo': countries.ec,
  'chaco-for-ever-resistencia-provincia-del-chaco': countries.ar,
  'chainat-hornbill-chainat': countries.th,
  'chainat-united': countries.th,
  'chalatenango-chalatenango': countries.sv,
  'challans-challans': countries.fr,
  'cham-cham': countries.ch,
  chamalieres: countries.fr,
  'chambery-chambery': countries.fr,
  'chambly-chambly': countries.fr,
  'chambray-chambray-les-tours': countries.fr,
  'champasak-united-pakse': countries.la,
  'champigneulles-champigneulles': countries.fr,
  'changchun-yatai-changchun': countries.cn,
  'change-change': countries.fr,
  'changwon-city-changwon': countries.kr,
  'chantilly-chantilly': countries.fr,
  chantimelle: countries.gd,
  'chapecoense-chapeco-santa-catarina': countries.br,
  'chargers-u19-tampa-bay-florida': countries.us,
  'charleston-battery-charleston-south-carolina': countries.us,
  'charlestown-city-blues-charlestown': countries.au,
  'charlotte-eagles-matthews-north-carolina': countries.us,
  'charlotte-fc-charlotte-north-carolina': countries.us,
  'charlotte-independence-charlotte-north-carolina': countries.us,
  'charlton-athletic-london-greater-london': countries.gb,
  'charlton-athletic-u18': countries.gb,
  'charlton-athletic-u21-london': countries.gb,
  'chasetown-burntwood-staffordshire': countries.gb,
  'chasselay-mda-chasselay': countries.fr,
  'chateaubriant-chateaubriant': countries.fr,
  'chateauneuf-sur-loire-chateauneuf-sur-loire': countries.fr,
  'chateauroux-chateauroux': countries.fr,
  'chateauroux-ii-chateauroux': countries.fr,
  'chatellerault-chatellerault': countries.fr,
  'chatham-town-chatham-kent': countries.gb,
  'chattanooga-chattanooga': countries.us,
  'chattanooga-red-wolves-chattanooga-tennessee': countries.us,
  chauvigny: countries.fr,
  'chavdar-etropole-etropole': countries.bg,
  'chaves-chaves': countries.pt,
  'chayka-peschanokopskoe': countries.ru,
  'chayka-petropavlivs-ka-borschchahivka': countries.ua,
  'chayka-zelva': countries.by,
  chebba: countries.tn,
  'chelmianka-chelm-chelm': countries.pl,
  'chelmsford-city-chelmsford-essex': countries.gb,
  'chelsea-london': countries.gb,
  'chelsea-u18': countries.gb,
  'chelsea-u19-london': countries.gb,
  'chelsea-u21-london': countries.gb,
  'chelsea-u23-london': countries.gb,
  'cheltenham-town-cheltenham-gloucestershire': countries.gb,
  'cheltenham-town-u18': countries.gb,
  'chelyabinsk-chelyabinsk': countries.ru,
  'chemie-leipzig-leipzig': countries.de,
  'chemnitzer-fc-chemnitz': countries.de,
  'chemnitzer-fc-u19-chemnitz': countries.de,
  'chengdu-qianbao-chengdu': countries.cn,
  'chennaiyin-chennai': countries.in,
  'chenois-chene-bourg': countries.ch,
  'cheonan-city-cheonan': countries.kr,
  'cheongju-cheongju': countries.kr,
  'cherbourg-tourlaville': countries.fr,
  'cherepovets-cherepovets': countries.ru,
  'chernihiv-chernihiv-chernigov': countries.ua,
  'cherno-more-varna': countries.bg,
  'chernolomets-1919': countries.bg,
  'chernomorets-balchik-balchik': countries.bg,
  'chernomorets-novorossiysk': countries.ru,
  'chertanovo-moskva': countries.ru,
  'chertsey-town-chertsey-surrey': countries.gb,
  'chesham-united-chesham-buckinghamshire': countries.gb,
  'cheshunt-cheshunt-hertfordshire': countries.gb,
  'chester-chester-cheshire': countries.gb,
  'chesterfield-chesterfield-derbyshire': countries.gb,
  'chevremont-kerkrade': countries.nl,
  'chiangmai-chiangmai': countries.th,
  'chiangmai-united-chiang-mai': countries.th,
  'chiangrai-united-chiang-rai': countries.th,
  'chiasso-chiasso': countries.ch,
  'chicago-fc-united-chicago-illinois': countries.us,
  'chicago-fire-bridgeview-illinois': countries.us,
  'chicago-fire-ii-bridgeview-illinois': countries.us,
  'chicago-fire-u18-19-chicago-illinois': countries.us,
  'chicago-fire-u19-bridgeview-illinois': countries.us,
  'chichester-city': countries.gb,
  'chicken-inn-bulawayo': countries.zw,
  'chieri-chieri': countries.it,
  'chikhura-sachkhere': countries.ge,
  'china-pr-u20-beijing': countries.cn,
  chinato: countries.es,
  'chindia-targoviste-targoviste': countries.ro,
  'chippa-united-cape-town-wc': countries.za,
  'chippenham-town-chippenham-wiltshire': countries.gb,
  'chipstead-chipstead-surrey': countries.gb,
  'chlumec-nad-cidlinou': countries.cz,
  'choco-redondela': countries.es,
  'chojniczanka-chojnice-chojnice': countries.pl,
  'cholet-cholet': countries.fr,
  'chomutov-chomutov': countries.cz,
  'chonburi-chonburi': countries.th,
  'chorley-chorley-lancashire': countries.gb,
  'chornomorets-odesa-odessa': countries.ua,
  'christchurch-united': countries.nz,
  'chrobry-glogow-glogow': countries.pl,
  'chrudim-chrudim': countries.cz,
  'chuncheon-chuncheon': countries.kr,
  'chungju-citizen-chungju': countries.kr,
  'churchill-brothers-margao-goa': countries.in,
  'ci-kamsar-kamsar': countries.gn,
  'cianorte-cianorte-parana': countries.br,
  'cibalia-vinkovci': countries.hr,
  'cibao-santiago': countries.do,
  'cienciano-cusco': countries.pe,
  'cieza-cieza': countries.es,
  'cigli-belediyespor-cigli': countries.tr,
  'cilegon-united-cilegon': countries.id,
  'cilimli-belediyespor-cilimli': countries.tr,
  'cimarrones-de-sonora-hermosillo': countries.mx,
  'cimarrones-de-sonora-ii-hermosillo': countries.mx,
  'cinar-belediyespor-cinar-diyarbakir': countries.tr,
  'cincinnati-cincinnati-ohio': countries.us,
  'cincinnati-u19-cincinnati-ohio': countries.us,
  'cinderford-town-cinderford-gloucestershire': countries.gb,
  'cipolletti-cipolletti-provincia-de-rio-negro': countries.ar,
  'cirbonero-cintruenigo': countries.es,
  'circulo-deportivo-comandante-nicanor-otamendi-provincia-de-buenos-aires':
    'ar',
  'cirkulane-cirkulane': countries.si,
  'citizen-aa-hong-kong': countries.hk,
  'citta-di-castello-citta-di-castello': countries.it,
  'citta-di-fasano-fasano': countries.it,
  'cittadella-cittadella': countries.it,
  'cittadella-u19': countries.it,
  'city-of-liverpool': countries.gb,
  'city-pirates-antwerpen-merksem': countries.be,
  'ciudad-de-bolivar': countries.ar,
  'ciudad-de-lucena-lucena': countries.es,
  'ciudad-de-torredonjimeno-torredonjimeno': countries.es,
  'civics-windhoek': countries.na,
  'civil-service-strollers-edinburgh': countries.gb,
  'cizre-serhatspor': countries.tr,
  'cjarlins-muzane-muzzana-del-turgnano': countries.it,
  'clachnacuddin-inverness': countries.gb,
  'claypole-claypole-provincia-de-buenos-aires': countries.ar,
  'cleethorpes-town': countries.gb,
  'clemson-university-clemson-south-carolina': countries.us,
  'clermont-clermont-ferrand': countries.fr,
  'clermont-ii-clermont-ferrand': countries.fr,
  'cleveland-sc-cleveland-ohio': countries.us,
  'cliftonville-belfast': countries.gb,
  'clitheroe-clitheroe-lancashire': countries.gb,
  'clodiense-sottomarina': countries.it,
  'club-africain-tunis': countries.tn,
  'club-atletico-guemes-santiago-del-estero-provincia-de-santiago-del-estero':
    'ar',
  'club-atletico-mitre-santiago-del-estero-provincia-de-santiago-del-estero':
    'ar',
  'club-bermeo-bermeo': countries.es,
  'club-brugge-brugge': countries.be,
  'club-brugge-ii-brugge': countries.be,
  'club-brugge-u19-brugge': countries.be,
  'club-colonial-fort-de-france': countries.mq,
  'club-destroyers-santa-cruz': countries.bo,
  'club-franciscain-le-francois': countries.mq,
  'club-sando-san-fernando': countries.tt,
  'club-universitario-sucre': countries.bo,
  'clyde-cumbernauld': countries.gb,
  clydebank: countries.gb,
  'cnaps-sport-itasy': countries.mg,
  'coalville-town-coalville-leicestershire': countries.gb,
  'coastal-spirit-christchurch': countries.nz,
  'cob-bamako': countries.ml,
  'coban-imperial-coban': countries.gt,
  'cobh-ramblers-cobh': countries.ie,
  'cobreloa-calama': countries.cl,
  'cobresal-el-salvador-comuna-diego-de-almagro': countries.cl,
  'coca-cola-cairo': countries.eg,
  'cockburn-city-perth': countries.au,
  'coffrane-coffrane': countries.ch,
  'cofutpa-guanacasteca': countries.cr,
  'colchagua-colchagua': countries.cl,
  'colchester-united-colchester-essex': countries.gb,
  'colchester-united-u18': countries.gb,
  'colchester-united-u21-colchester': countries.gb,
  'colegiales-vicente-lopez-provincia-de-buenos-aires': countries.ar,
  'coleraine-coleraine': countries.gb,
  'coleshill-town-coleshill-warwickshire': countries.gb,
  'colina-colina': countries.cl,
  'collado-villalba': countries.es,
  collerense: countries.es,
  'colmar-colmar': countries.fr,
  'colmenar-viejo': countries.es,
  'colne-colne-lancashire': countries.gb,
  'colo-colo-santiago-de-chile': countries.cl,
  'colo-colo-u20-santiago-de-chile': countries.cl,
  colombo: countries.lk,
  'colomiers-colomiers': countries.fr,
  'colon-ciudad-de-santa-fe-provincia-de-santa-fe': countries.ar,
  'colon-montevideo': countries.uy,
  'colorado-rapids-denver-colorado': countries.us,
  'colorado-rapids-ii-denver-colorado': countries.us,
  'colorado-rapids-u19-denver-colorado': countries.us,
  'colorado-springs-colorado-springs': countries.us,
  'columbus-crew-columbus-ohio': countries.us,
  'columbus-crew-ii-columbus-ohio': countries.us,
  'columbus-crew-u19-columbus-ohio': countries.us,
  'colunga-colunga': countries.es,
  'colwyn-bay-colwyn-bay-bae-colwyn-conwy': countries.gb,
  'comercial-ms-campo-grande-mato-grosso-do-sul': countries.br,
  'comercial-ribeirao-preto-sao-paulo': countries.br,
  'comerciantes-unidos-cutervo': countries.pe,
  'comercio-santa-sylvina-provincia-del-chaco': countries.ar,
  comillas: countries.es,
  'como-como': countries.it,
  'compiegne-compiegne': countries.fr,
  'compostela-santiago-de-compostela': countries.es,
  'comunicaciones-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'comunicaciones-ciudad-de-guatemala': countries.gt,
  'comunicaciones-ii-ciudad-de-guatemala': countries.gt,
  'concarneau-concarneau': countries.fr,
  'concarneau-ii-concarneau': countries.fr,
  'concepcion-concepcion': countries.cl,
  'concord-rangers-canvey-island-essex': countries.gb,
  'concordia-basel-basel': countries.ch,
  'concordia-chiajna-chiajna': countries.ro,
  'concordia-concordia-santa-catarina': countries.br,
  'concordia-elblag-elblag': countries.pl,
  'condal-norena': countries.es,
  'confianca-aracaju-sergipe': countries.br,
  'cong-an-nhan-dan': countries.vn,
  'conil-conil-de-la-frontera': countries.es,
  'connah-s-quay-cei-conna-connah-s-quay-flintshire': countries.gb,
  'conquense-cuenca': countries.es,
  'consadole-sapporo-sapporo': countries.jp,
  'constancia-inca': countries.es,
  'contra-costa-walnut-creek-california': countries.us,
  'conwy-borough-conwy': countries.gb,
  'cooma-tigers-canberra': countries.au,
  'copiapo-copiapo': countries.cl,
  'coquimbo-unido-coquimbo': countries.cl,
  'corby-town-corby-northamptonshire': countries.gb,
  'cordino-barra-do-corda-maranhao': countries.br,
  'cordoba-cordoba': countries.es,
  'cordoba-ii-cordoba': countries.es,
  'cori-sabba-floriano-piaui': countries.br,
  'coria-cf-coria-del-rio': countries.es,
  'corinthian-casuals-london': countries.gb,
  'corinthians-sao-paulo': countries.br,
  'coritiba-curitiba-parana': countries.br,
  'cork-city-cork': countries.ie,
  'corluspor-1947-corlu': countries.tr,
  'cornella-cornella-de-llobregat': countries.es,
  'coronel-aguirre-villa-gobernador-galvez-provincia-de-santa-fe': countries.ar,
  'correcaminos-uat-ciudad-victoria': countries.mx,
  'correcaminos-uat-ii-ciudad-victoria': countries.mx,
  'correggese-correggio': countries.it,
  'corte-corte': countries.fr,
  cortes: countries.es,
  'cortulua-tulua': countries.co,
  'coruchense-coruche': countries.pt,
  'coruxo-vigo': countries.es,
  'corvinul-hunedoara-hunedoara': countries.ro,
  'cosenza-cosenza': countries.it,
  'cosmos-serravalle': countries.sm,
  'costa-del-este': countries.pa,
  'costa-do-sol-maputo': countries.mz,
  'costa-rica-costa-rica-mato-grosso-do-sul': countries.br,
  'coton-sport-ouidah-ouidah': countries.bj,
  'cotonsport-garoua': countries.cm,
  'couvin-mariembourg-mariembourg': countries.be,
  'covadonga-oviedo': countries.es,
  'cove-rangers-aberdeen': countries.gb,
  'coventry-city-coventry': countries.gb,
  'coventry-city-u18': countries.gb,
  'cowdenbeath-fife': countries.gb,
  'cr-khemis-zemamra-zemamra': countries.ma,
  'crac-catalao-goias': countries.br,
  'cracovia-krakow-krakow': countries.pl,
  'crato-crato-ceara': countries.br,
  'crawley-town-crawley-west-sussex': countries.gb,
  'cray-valley-pm': countries.gb,
  'cray-wanderers-london-greater-london': countries.gb,
  'crb-maceio-alagoas': countries.br,
  'crema-crema': countries.it,
  'cremonese-cremona': countries.it,
  'creteil-creteil': countries.fr,
  'creteil-ii-creteil': countries.fr,
  crevillente: countries.es,
  'crewe-alexandra-crewe-cheshire': countries.gb,
  'crewe-alexandra-u18': countries.gb,
  'criacao-shinjuku-tokyo': countries.jp,
  'criciuma-criciuma-santa-catarina': countries.br,
  'crikvenica-crikvenica': countries.hr,
  'crisul-chisineu-cris-chi-ineu-cri': countries.ro,
  'croatia-zmijavci-zmijavci': countries.hr,
  'croix-football-ic-croix': countries.fr,
  'cronenberger-sc-wuppertal': countries.de,
  'crossgates-primrose-crossgates': countries.gb,
  'crossing-schaerbeek-schaerbeek-schaarbeek': countries.be,
  'crotone-crotone': countries.it,
  'crucero-del-norte-garupa-gran-posadas-provincia-de-misiones': countries.ar,
  cruiz: countries.md,
  'crusaders-belfast': countries.gb,
  'cruz-azul-ciudad-de-mexico-d-f': countries.mx,
  'cruz-del-sur-san-carlos-de-bariloche-provincia-de-rio-negro': countries.ar,
  'cruzeiro-arapiraca-arapiraca': countries.br,
  'cruzeiro-belo-horizonte-minas-gerais': countries.br,
  'cruzeiro-rs-porto-alegre-rio-grande-do-sul': countries.br,
  'crvena-zvezda-beograd': countries.rs,
  'crvena-zvezda-u19-beograd': countries.rs,
  'crystal-palace-london': countries.gb,
  'crystal-palace-u18': countries.gb,
  'crystal-palace-u21-london': countries.gb,
  'crystal-palace-u23-london': countries.gb,
  'cs-constantine-constantine': countries.dz,
  'cs-sfaxien-sfax-safaqis': countries.tn,
  'cs-u-craiova-ii-craiova': countries.ro,
  'csa-bergen-u17-carlstadt-new-jersey': countries.us,
  'csa-bergen-u19-carlstadt-new-jersey': countries.us,
  'csa-maceio-alagoas': countries.br,
  'csa-monmouth-u19-tinton-falls-new-jersey': countries.us,
  'csa-steaua-bucuresti': countries.ro,
  'csakvari-tk-csakvar': countries.hu,
  'csc-cayenne-cayenne': countries.gf,
  'cse-palmeira-dos-indios-alagoas': countries.br,
  'csf-bal-i-bal-i': countries.md,
  'csf-cricova-cricova': countries.md,
  'csikszereda-miercurea-ciuc': countries.ro,
  'cska-1948-sofia-ii-sofia': countries.bg,
  'cska-1948-sofia-sofia': countries.bg,
  'cska-moskva-moskva': countries.ru,
  'cska-pomir-dushanbe': countries.tj,
  'cska-sofia-sofia': countries.bg,
  'csm-satu-mare-satu-mare': countries.ro,
  'csornai-se-csorna': countries.hu,
  'csp-joao-pessoa-paraiba': countries.br,
  'cucuta-deportivo-cucuta': countries.co,
  'cuiaba-cuiaba-mato-grosso': countries.br,
  'cukaricki-beograd': countries.rs,
  'culter-peterculter': countries.gb,
  'cultural-guarnizo-guarnizo': countries.es,
  'cultural-leonesa-ii': countries.es,
  'cultural-leonesa-leon': countries.es,
  'cumbaya-quito': countries.ec,
  'cumbernauld-colts-cumbernauld': countries.gb,
  'cumhuriyet-universitesi-sivas': countries.tr,
  'cumnock-juniors-cumnock': countries.gb,
  'cunupia-cunupia': countries.tt,
  'curico-unido-curico': countries.cl,
  'curzon-ashton-ashton-under-lyne-lancashire': countries.gb,
  'customs-united-samut-prakan': countries.th,
  'cwmbran-celtic-cwmbran-monmouthshire': countries.gb,
  'cynthialbalonga-genzano-di-roma': countries.it,
  'da-nang-da-n-ng-da-nang': countries.vn,
  'dabas-dabas': countries.hu,
  'dac-dunajska-streda': countries.sk,
  'dac-u19-dunajska-streda': countries.sk,
  'dacia-buiucani-chisinau': countries.md,
  'dacia-unirea-braila-braila': countries.ro,
  'daegu-daegu': countries.kr,
  'daejeon-citizen-daejeon': countries.kr,
  'daejeon-korail-daejeon': countries.kr,
  'dagenham-redbridge-dagenham-essex': countries.gb,
  'dainava-alytus': countries.lt,
  'dak-lak-d-k-l-k': countries.vn,
  dakkada: countries.ng,
  'dakota-fusion-fargo-north-dakota': countries.us,
  'dakovo-croatia-dakovo': countries.hr,
  'dalbeattie-star-dalbeattie': countries.gb,
  'dalian-yifang-dalian': countries.cn,
  'dalkurd-borlange': countries.se,
  'dallas-frisco-texas': countries.us,
  'dalvik-reynir-dalvik': countries.is,
  'dandenong-city': countries.au,
  'dandenong-thunder-melbourne': countries.au,
  'dandy-town-hornets-pembroke': countries.bm,
  'dangjin-citizen-dangjin': countries.kr,
  'danubio-montevideo': countries.uy,
  'darica-genclerbirligi-darica': countries.tr,
  'darlington-darlington-durham-2012': countries.gb,
  'darmstadt-98-darmstadt': countries.de,
  'darmstadt-98-u19-darmstadt': countries.de,
  'dartford-dartford-kent': countries.gb,
  'darvel-darvel': countries.gb,
  'darwin-olympic': countries.au,
  'dassendorf-dassendorf': countries.de,
  'daugavpils-progress-daugavpils': countries.lv,
  'daventry-town-daventry-northamptonshire': countries.gb,
  'davutlar-belediyespor-davutlar-aydin': countries.tr,
  'dayton-dutch-lions-dayton-ohio': countries.us,
  'dc-united-washington-district-of-columbia': countries.us,
  'de-anza-force-u19-cupertino-california': countries.us,
  'de-bataven-gendt': countries.nl,
  'de-dijk-amsterdam': countries.nl,
  'de-graafschap-doetinchem': countries.nl,
  'de-kempen-tielen': countries.be,
  'de-treffers-groesbeek': countries.nl,
  'de-zouaven-grootebroek': countries.nl,
  'deac-debrecen': countries.hu,
  'debrecen-debrecen': countries.hu,
  'debrecen-ii-debrecen': countries.hu,
  'decic-tuzi': countries.me,
  'decisao-camaragibe-pernambucano': countries.br,
  'def-de-pronunciamiento-pronunciamiento-provincia-de-entre-rios':
    countries.ar,
  'defence-force-chaguaramas': countries.tt,
  defenders: countries.lk,
  'defensa-y-justicia-florencio-varela-provincia-de-buenos-aires': countries.ar,
  'defensor-sporting-montevideo': countries.uy,
  'defensores-belgrano-vr-villa-ramallo-provincia-de-buenos-aires':
    countries.ar,
  'defensores-de-belgrano-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'defensores-de-cambaceres-ensenada-provincia-de-buenos-aires': countries.ar,
  'defensores-de-salto-salto-provincia-de-buenos-aires': countries.ar,
  'defensores-unidos-zarate-provincia-de-buenos-aires': countries.ar,
  'degerfors-degerfors': countries.se,
  'degerfors-u21-degerfors': countries.se,
  'degirmenderespor-kocaeli': countries.tr,
  'deinze-deinze': countries.be,
  'dekani-dekani': countries.si,
  'delbrucker-sc-delbruck': countries.de,
  'delco-u19-downingtown-pennsylvania': countries.us,
  'delemont-delemont': countries.ch,
  'delfin-manta': countries.ec,
  'delhi-dynamos-new-delhi': countries.in,
  'deltras-sidoarjo': countries.id,
  'dem-beverwijk': countries.nl,
  'democrata-gv-governador-valadares-minas-gerais': countries.br,
  'democrata-sl-sete-lagoas-minas-gerais': countries.br,
  'den-bosch-s-hertogenbosch': countries.nl,
  'dender-denderleeuw': countries.be,
  'denia-denia': countries.es,
  'denizlispor-denizli': countries.tr,
  'denizlispor-u19-denizli': countries.tr,
  'denton-diablos': countries.us,
  'deportes-iquique-iquique': countries.cl,
  'deportes-limache': countries.cl,
  'deportes-linares-linares': countries.cl,
  'deportes-quillon-quillon': countries.cl,
  'deportes-quindio-armenia': countries.co,
  'deportes-rengo-rengo': countries.cl,
  'deportes-santa-cruz-santa-cruz': countries.cl,
  'deportes-temuco-temuco': countries.cl,
  'deportes-tolima-ibague': countries.co,
  'deportes-valdivia-valdivia': countries.cl,
  'deportiva-minera-el-llano-del-beal': countries.es,
  'deportivo-achirense-colonia-las-achiras-provincia-de-entre-rios':
    countries.ar,
  'deportivo-alaves-ii': countries.es,
  'deportivo-alaves-vitoria-gasteiz': countries.es,
  'deportivo-armenio-ingeniero-maschwitz-provincia-de-buenos-aires':
    countries.ar,
  'deportivo-binacional-desaguadero': countries.pe,
  'deportivo-cali-santiago-de-cali': countries.co,
  'deportivo-camioneros-esteban-echeverria-provincia-de-buenos-aires':
    countries.ar,
  'deportivo-colon': countries.ar,
  'deportivo-colonia-juan-lacaze': countries.uy,
  'deportivo-coopsol-chancay': countries.pe,
  'deportivo-cuenca-cuenca': countries.ec,
  'deportivo-espanol-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'deportivo-la-coruna-a-coruna': countries.es,
  'deportivo-la-coruna-ii-a-coruna': countries.es,
  'deportivo-la-coruna-u19': countries.es,
  'deportivo-la-guaira-caracas': countries.ve,
  'deportivo-laferrere-gregorio-de-laferrere-provincia-de-buenos-aires':
    countries.ar,
  'deportivo-lara-barquisimeto': countries.ve,
  'deportivo-llacuaba': countries.pe,
  'deportivo-madryn-puerto-madryn-provincia-de-chubut': countries.ar,
  'deportivo-maipu-maipu-provincia-de-mendoza': countries.ar,
  'deportivo-maldonado-maldonado': countries.uy,
  'deportivo-malvinas': countries.ar,
  'deportivo-merlo-parque-san-martin-provincia-de-buenos-aires': countries.ar,
  'deportivo-moron-moron-provincia-de-buenos-aires': countries.ar,
  'deportivo-municipal-lima': countries.pe,
  'deportivo-muniz-muniz-provincia-de-buenos-aires': countries.ar,
  'deportivo-ocotal-ocotal': countries.ni,
  'deportivo-paraguayo-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'deportivo-pasto-san-juan-de-pasto': countries.co,
  'deportivo-pereira-pereira': countries.co,
  'deportivo-quito-quito': countries.ec,
  'deportivo-riestra-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'deportivo-rincon-rinconde-los-sauces': countries.ar,
  'deportivo-roca-general-roca-provincia-de-rio-negro': countries.ar,
  'deportivo-sanarate-sanarate-el-progreso': countries.gt,
  'deportivo-santamarina-tandil-provincia-de-buenos-aires': countries.ar,
  'deportivo-santani-san-estanislao': countries.py,
  'deportivo-saprissa-san-juan-de-tibas-san-jose': countries.cr,
  'deportivo-tachira-san-cristobal': countries.ve,
  'derby-county-derby': countries.gb,
  'derby-county-u18-derby': countries.gb,
  'derby-county-u19': countries.gb,
  'derby-county-u21-derby': countries.gb,
  'derby-county-u23-derby': countries.gb,
  'dereham-town-dereham-norfolk': countries.gb,
  'deren-sumida': countries.mn,
  'dergview-castlederg': countries.gb,
  'derry-city-derry': countries.ie,
  'dersimspor-tunceli': countries.tr,
  'des-moines-menace-west-des-moines-iowa': countries.us,
  'desenzano-calvina-desenzano-del-garda': countries.it,
  'desportiva-es-cariacica-espirito-santo': countries.br,
  'desportiva-pb-guarabira-paraiba': countries.br,
  'desportivo-brasil-porto-feliz-sao-paulo': countries.br,
  'dessel-sport-dessel': countries.be,
  'deto-twenterand-vriezenveen': countries.nl,
  'detroit-city-detroit-michigan': countries.us,
  'deurne-deurne': countries.nl,
  'deusto-bilbao': countries.es,
  'deutschkreutz-deutschkreutz': countries.at,
  'deutschlandsberger-sc-deutschlandsberg': countries.at,
  'develigucu-develi-kyaseri': countries.tr,
  'deveronvale-banff': countries.gb,
  'devolli-bilisht': countries.al,
  'devonport-city-devonport': countries.au,
  'devonshire-cougars-devonshire': countries.bm,
  'devrek-belediyespor-zonguldak': countries.tr,
  'dewa-united-tangerang': countries.id,
  dhamk: countries.sa,
  'dhc-delft-delft': countries.nl,
  'dhofar-salalah': countries.om,
  'dhsc-ondiep': countries.nl,
  'diables-noirs-brazzaville': countries.cg,
  'diablo-valley-wolves-u19-concord-california': countries.us,
  'diagoras-rodos': countries.gr,
  'diambars-mbour': countries.sn,
  'diamond-stars-koidu-town-sefadu': countries.sl,
  'dibba-al-fujairah-al-fujairah': countries.ae,
  'didcot-town-didcot-oxfordshire': countries.gb,
  'diegem-sport-diegem': countries.be,
  'dieppe-saint-aubin-sur-scie': countries.fr,
  'dietikon-dietikon': countries.ch,
  'difaa-el-jadida-el-jadida-mazghan': countries.ma,
  'differdange-03-deifferdeng-differdange': countries.lu,
  'digenis-morphou-morfou-morphou': countries.cy,
  'digenis-ypsonas-ypsonas': countries.cy,
  'dijon-dijon': countries.fr,
  'dijon-ii-dijon': countries.fr,
  dikhil: countries.dj,
  'dikkelvenne-dikkelvenne': countries.be,
  'diksmuide-diksmuide': countries.be,
  'dila-gori': countries.ge,
  dimitrovgrad: countries.bg,
  'dinamo-auto-tiraspol': countries.md,
  'dinamo-barnaul-barnaul': countries.ru,
  'dinamo-batumi-batumi': countries.ge,
  'dinamo-brest-brest': countries.by,
  'dinamo-bryansk-bryansk': countries.ru,
  'dinamo-bucuresti-bucuresti': countries.ro,
  'dinamo-minsk-minsk': countries.by,
  'dinamo-moskva-ii-moskva': countries.ru,
  'dinamo-moskva-moskva': countries.ru,
  'dinamo-pancevo-pancevo': countries.rs,
  'dinamo-riga': countries.lv,
  'dinamo-samarqand-samarqand': countries.uz,
  'dinamo-st-petersburg-sankt-peterburg-st-petersburg': countries.ru,
  'dinamo-stavropol-stavropol': countries.ru,
  'dinamo-tbilisi-ii-tbilisi': countries.ge,
  'dinamo-tbilisi-tbilisi': countries.ge,
  'dinamo-tirana-tirane-tirana': countries.al,
  'dinamo-vladivostok-vladivostok': countries.ru,
  'dinamo-vologda-vologda': countries.ru,
  'dinamo-zagreb-u19-zagreb': countries.hr,
  'dinamo-zagreb-zagreb': countries.hr,
  'dinan-lehon-lehon': countries.fr,
  'dinaz-vyshhorod': countries.ua,
  'dingli-swallows-dingli': countries.mt,
  'diocesano-caceres': countries.es,
  'diosgyor-ii-miskolc': countries.hu,
  'diosgyor-miskolc': countries.hu,
  'diriangen-diriamba': countries.ni,
  'dives-cabourg-dives-sur-mer': countries.fr,
  'diyarbakir-yenisehir-diyarbakir': countries.tr,
  'diyarbekirspor-diyarbakir': countries.tr,
  'djerba-djerba': countries.tn,
  'djk-bamberg-bamberg': countries.de,
  'djoliba-bamako': countries.ml,
  'djurgarden-stockholm': countries.se,
  'dnepr-mogilev-mahilyou-mogilev': countries.by,
  'dnipro-1-dnipropetrovs-k': countries.ua,
  'dob-dob-pri-domzalah': countries.si,
  'dobrovce-dobrovce': countries.si,
  'dobrovice-dobrovice': countries.cz,
  'dobrudzha-1919-dobrich': countries.bg,
  'dock-sud-avellaneda-provincia-de-buenos-aires': countries.ar,
  'dogugucu-bursa': countries.tr,
  'dollingstown-lurgan': countries.gb,
  'dolny-kubin-dolny-kubin': countries.sk,
  'dom-bosco': countries.br,
  'domagnano-domagnano': countries.sm,
  domerat: countries.fr,
  'domzale-domzale': countries.si,
  'domzale-ii-domzale': countries.si,
  'domzale-u19-domzale': countries.si,
  'don-benito-don-benito': countries.es,
  'don-bosco-petionville': countries.ht,
  donaustauf: countries.de,
  'doncaster-rovers-doncaster-south-yorkshire': countries.gb,
  'dong-nai-bien-hoa-bien-hoa': countries.vn,
  'dong-thap-cao-lanh-cao-lanh': countries.vn,
  'dongen-dongen': countries.nl,
  'dorados-culiacan': countries.mx,
  'dorchester-town-dorchester-dorset': countries.gb,
  'dordoi-bishkek-bishkek': countries.kg,
  'dordrecht-dordrecht': countries.nl,
  'dorking-wanderers': countries.gb,
  'dornbirn-dornbirn': countries.at,
  'dornbirner-sv': countries.at,
  dorogi: countries.hu,
  'dorostol-silistra': countries.bg,
  'dos-kampen-kampen': countries.nl,
  'dosko-bergen-op-zoom': countries.nl,
  'douanes-dakar': countries.sn,
  'douanes-ouagadougou': countries.bf,
  'douglas-haig-pergamino-provincia-de-buenos-aires': countries.ar,
  'dover-athletic-dover-kent': countries.gb,
  'dovo-veenendaal': countries.nl,
  'doxa-dramas-drama': countries.gr,
  'doxa-peristerona': countries.cy,
  dpmm: countries.bn,
  'drachtster-boys-drachten': countries.nl,
  'dragon-papeete': countries.pf,
  'dragon-san-miguel': countries.sv,
  'drancy-ja-drancy': countries.fr,
  'drapeau-fougeres-fougeres': countries.fr,
  'drassburg-drassburg': countries.at,
  'dravinja-slovenske-konjice': countries.si,
  'dravograd-dravograd': countries.si,
  'dreams-madina': countries.gh,
  'dreams-metro-gallery-kowloon': countries.hk,
  'drenica-skenderaj-skenderaj-srbica': countries.xk,
  drenovets: countries.bg,
  'drina-zvornik-zvornik': countries.ba,
  'drita-gjilan-gnjilane': countries.xk,
  'drochtersen-assel-drochtersen': countries.de,
  'drogheda-united-drogheda': countries.ie,
  'drouais-dreux': countries.fr,
  'druzhba-maykop': countries.ru,
  'dsi-karadeniz': countries.tr,
  dso: countries.nl,
  'dts-ede-ede': countries.nl,
  'dubnica-dubnica-nad-vahom': countries.sk,
  'dubocica-leskovac': countries.rs,
  'dubrava-zagreb-zagreb': countries.hr,
  'dugopolje-dugopolje': countries.hr,
  'duhok-dohuk': countries.iq,
  'dukagjini-klina': countries.xk,
  'duke-university-durham-north-carolina': countries.us,
  'dukla-banska-bystrica-banska-bystrica': countries.sk,
  'duluth-duluth-minnesota': countries.us,
  'dulwich-hamlet-london': countries.gb,
  'dumbarton-dumbarton': countries.gb,
  'dumbravita-dumbravita': countries.ro,
  'dumiense-braga': countries.pt,
  'dunarea-calarasi-calarasi': countries.ro,
  'dunaujvaros-palhalma-dunaujvaros': countries.hu,
  'dunav-2010-ruse-rousse': countries.bg,
  'dunbar-united-dunbar': countries.gb,
  'dunbeholden-dunbeholden': countries.jm,
  'dundalk-dundalk': countries.ie,
  'dundee-dundee': countries.gb,
  'dundee-north-end-dundee': countries.gb,
  'dundee-u21-dundee': countries.gb,
  'dundee-united-dundee': countries.gb,
  'dundee-united-ii-dundee': countries.gb,
  'dundela-belfast': countries.gb,
  'dundonald-bluebell-dundonald': countries.gb,
  'dunfermline-athletic-dunfermline': countries.gb,
  'dungannon-swifts-dungannon': countries.gb,
  'dunkerque-dunkerque': countries.fr,
  'duno-doorwerth': countries.nl,
  'duque-de-caxias-duque-de-caxias-rio-de-janeiro': countries.br,
  'durango-durango': countries.mx,
  'durango-durango-1919': countries.es,
  'duren-merzenich-merzenich': countries.de,
  'duros-del-balon-salinas': countries.ec,
  'duzcespor-duzce': countries.tr,
  'dvs-33-ermelo': countries.nl,
  'dvur-kralove-dvur-kralove-nad-labem': countries.cz,
  'dynamic-togolais-lome': countries.tg,
  'dynamo-dresden-dresden': countries.de,
  'dynamo-dresden-u17-dresden': countries.de,
  'dynamo-dresden-u19-dresden': countries.de,
  'dynamo-kyiv-kyiv-kiev': countries.ua,
  'dynamo-kyiv-u19-kyiv-kiev': countries.ua,
  'dynamo-unidos-u19-houston-texas': countries.us,
  'dynamos-harare': countries.zw,
  'dyussh-3-pinsk': countries.by,
  'dzc-68': countries.nl,
  'dziugas-telsiai-telsiai': countries.lt,
  eagles: countries.mv,
  'eagles-super-strikers': countries.gd,
  'east-bengal-kalkata-kolkata-pascimba-ga-west-bengal': countries.in,
  'east-end-lions-freetown': countries.sl,
  'east-fife-fife': countries.gb,
  'east-grinstead-town-east-grinstead-west-sussex': countries.gb,
  'east-kilbride-east-kilbride': countries.gb,
  'east-riffa-ar-rifa': countries.bh,
  'east-stirlingshire-falkirk': countries.gb,
  'east-stirlingshire-res-falkirk': countries.gb,
  'east-thurrock-united-corringham-essex': countries.gb,
  'eastbourne-borough-eastbourne-east-sussex': countries.gb,
  'eastern-hong-kong': countries.hk,
  'eastern-lions-burwood': countries.au,
  'eastern-suburbs-auckland': countries.nz,
  'eastern-suburbs-brisbane-brisbane': countries.au,
  'eastleigh-eastleigh-hampshire': countries.gb,
  'eb-streymur-eidi-eysturoy': countries.fo,
  'ebbsfleet-united-northfleet-kent': countries.gb,
  'ebro-zaragoza': countries.es,
  'ec-lemense-leme-sao-paulo': countries.br,
  'ec-sao-bernardo-sao-bernardo-do-campo-sao-paulo': countries.br,
  'ec-sao-jose-porto-alegre-rio-grande-do-sul': countries.br,
  'echallens-echallens': countries.ch,
  'edgeworth-eagles-newcastle': countries.au,
  'edinburgh-city-edinburgh': countries.gb,
  'edinburgh-university-edinburgh': countries.gb,
  'edinet-edinet': countries.md,
  'edirnespor-edirne': countries.tr,
  'edmonton-edmonton-alberta': countries.ca,
  'edusport-academy-glasgow': countries.gb,
  'eemdijk-bunschoten': countries.nl,
  'eendracht-aalst-aalst': countries.be,
  'eendracht-wervik-wervik': countries.be,
  'eendracht-zele-zele': countries.be,
  'efeler-09-spor-aydin': countries.tr,
  'eg-el-palmar-el-palmar': countries.es,
  'egaleo-athina-athens': countries.gr,
  'eger-eger': countries.hu,
  'egersund-egersund': countries.no,
  'egnatia-rrogozhine-rrogozhine': countries.al,
  'egs-gafsa-gafsa': countries.tn,
  'ehc-hoensbroek': countries.nl,
  'ehime-matsuyama': countries.jp,
  'eibar-eibar': countries.es,
  'eichede-steinburg': countries.de,
  'eichstatt-eichstatt': countries.de,
  'eidsvold-eidsvoll': countries.no,
  'eif-raasepori-raseborg': countries.fi,
  'eilenburg-eilenburg': countries.de,
  'eindhoven-eindhoven': countries.nl,
  'einheit-wernigerode': countries.de,
  'einherji-vopnafjordur': countries.is,
  'eintracht-bamberg-bamberg': countries.de,
  'eintracht-braunschweig-braunschweig': countries.de,
  'eintracht-celle-celle': countries.de,
  'eintracht-frankfurt-frankfurt-am-main': countries.de,
  'eintracht-frankfurt-ii-frankfurt-am-main': countries.de,
  'eintracht-frankfurt-u19-frankfurt': countries.de,
  'eintracht-norderstedt-norderstedt': countries.de,
  'eintracht-northeim-northeim': countries.de,
  'eintracht-stadtallendorf-stadtallendorf': countries.de,
  'eintracht-trier-trier': countries.de,
  'ejea-ejea-de-los-caballeros': countries.es,
  'ekibastuz-ekibastyz': countries.kz,
  'ekranas-panevezys': countries.lt,
  'el-alamo-madrid': countries.es,
  'el-bayadh': countries.dz,
  'el-cotillo-la-oliva-fuerteventura': countries.es,
  'el-daklyeh': countries.eg,
  'el-ejido-el-ejido': countries.es,
  'el-eulma-el-eulma': countries.dz,
  'el-farolito-san-francisco-california': countries.us,
  'el-geish-al-qahirah-cairo': countries.eg,
  'el-linqueno-lincoln-provincia-de-buenos-aires': countries.ar,
  'el-nacional-quito': countries.ec,
  'el-palo-el-palo': countries.es,
  'el-paso-locomotive-el-paso-texas': countries.us,
  'el-porvenir-gerli-provincia-de-buenos-aires': countries.ar,
  'elassona-elassona': countries.gr,
  'elazigspor-elazig': countries.tr,
  'elazigspor-u21-elazig': countries.tr,
  'elaziz-belediyespor-elazig': countries.tr,
  'elbistan-belediyespor-elbistan': countries.tr,
  'elche-elche': countries.es,
  'elche-ii-elche': countries.es,
  'eldense-elda': countries.es,
  'elektron-vn-veliky-novgorod': countries.ru,
  'eleven-arrows-walvis-bay': countries.na,
  'elfsborg-boras': countries.se,
  'elfsborg-u19': countries.se,
  'elgeco-plus': countries.mg,
  'elgin-city-elgin': countries.gb,
  'elite-west-bay': countries.ky,
  'elsautoise-henri-chapelle': countries.be,
  'eltersdorf-erlangen': countries.de,
  'elva-elva': countries.ee,
  'elversberg-saarbrucken': countries.de,
  'elverum-elverum': countries.no,
  'em-mahdia-mahdia': countries.tn,
  'emelec-guayaquil': countries.ec,
  'emirates-ras-al-khaymah-ras-al-khaimah': countries.ae,
  'emmen-emmen': countries.nl,
  'empire-club': countries.bb,
  'empire-united-u17-rochester-new-york': countries.us,
  'empire-united-u19-rochester-new-york': countries.us,
  'empoli-empoli': countries.it,
  'empoli-u19': countries.it,
  'en-thoi-lakatamia-lakatamia': countries.cy,
  'enad-polis-chrysochous': countries.cy,
  'encamp-encamp': countries.ad,
  'energeticianul-petro-ani': countries.ro,
  'energetyk-bdu-minsk': countries.by,
  'energie-cottbus-cottbus': countries.de,
  'energie-cottbus-u19-cottbus': countries.de,
  'energosbyt-bsatu': countries.by,
  'enfield-town-london': countries.gb,
  'engordany-escaldes-engordany': countries.ad,
  'enosis-paralimni': countries.cy,
  'enppi-al-qahirah-cairo': countries.eg,
  'enskede-enskede': countries.se,
  'entente-ssg-saint-gratien': countries.fr,
  'enugu-rangers-enugu': countries.ng,
  'envigado-envigado': countries.co,
  'enyimba-aba': countries.ng,
  'eo-sidi-bouzid-sidi-bouzid': countries.tn,
  'epernay-epernay': countries.fr,
  'epila-epila': countries.es,
  'epinal-epinal': countries.fr,
  'episkopi-rethymno': countries.gr,
  'epitsentr-dunayivtsi-dunayivtsi': countries.ua,
  'eppegem-eppegem': countries.be,
  'eps-espoo': countries.fi,
  'eps-ii-espoo': countries.fi,
  'erandio-erandio': countries.es,
  'erbaaspor-erbaa': countries.tr,
  'erbil-arbil': countries.iq,
  'erchim-ulan-bator-ulaanbaatar': countries.mn,
  'erdi-vse-erd': countries.hu,
  'erganispor-ergani': countries.tr,
  'ergene-velimesespor-ergene': countries.tr,
  'erie-commodores-erie': countries.us,
  'erlbach-erlbach': countries.de,
  'ermionida-kranidi': countries.gr,
  'ermis-larnaka-larnaca': countries.cy,
  'erndtebruck-erndtebruck': countries.de,
  'erokspor-istanbul': countries.tr,
  'erokspor-u19': countries.tr,
  'erpeldange-angelduerf-ingeldorf': countries.lu,
  'erzeni-shijak-shijak': countries.al,
  'erzgebirge-aue-aue': countries.de,
  'erzin-belediyespor-hatay': countries.tr,
  'es-doloise-de-bretagne-dol-de-bretagne': countries.fr,
  'es-du-mont-gaillard-le-havre': countries.fr,
  'es-mostaganem-mostaganem': countries.dz,
  'es-setif-stif-setif': countries.dz,
  'es-tunis-tunis': countries.tn,
  'esan-pattaya': countries.th,
  'esbjerg-esbjerg': countries.dk,
  'esbjerg-u19-esbjerg': countries.dk,
  'escazucena-escazu': countries.cr,
  'esch-esch-uelzecht-esch-sur-alzette': countries.lu,
  'eschen-mauren-eschen': countries.ch,
  'escobedo-escobedo': countries.es,
  'escorpiones-belen-belen': countries.cr,
  'eskil-belediyespor-eskil': countries.tr,
  'eskilsminne-helsingborg': countries.se,
  'eskisehir-yunusemrespor-eskisehir': countries.tr,
  'eskisehirspor-eskisehir': countries.tr,
  'eskisehirspor-u19-eskisehir': countries.tr,
  'eskisehirspor-u21-eskisehir': countries.tr,
  eslov: countries.se,
  'esmtk-budapest': countries.hu,
  'espa-espoo-esbo': countries.fi,
  'espanyol-barcelona': countries.es,
  'espanyol-ii-barcelona': countries.es,
  'esperanza-pelt-neerpelt': countries.be,
  'espoli-quito': countries.ec,
  'essentuki-yessentuki': countries.ru,
  'este-este': countries.it,
  'esteghlal-khuzestan-ahvaz-ahwaz': countries.ir,
  'esteghlal-tehran-teheran': countries.ir,
  'estepona-estepona': countries.es,
  'estoril-estoril': countries.pt,
  'estoril-u23-estoril': countries.pt,
  'estradense-a-estrada': countries.es,
  'estrela-amadora': countries.pt,
  'estrela-do-norte-cachoeira-de-itapemirim-es': countries.br,
  estrella: countries.es,
  'estudiantes-caseros-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'estudiantes-de-san-luis-ciudad-de-san-luis-provincia-de-san-luis':
    countries.ar,
  'estudiantes-la-plata-provincia-de-buenos-aires': countries.ar,
  'estudiantes-merida-merida': countries.ve,
  'estudiantes-rio-cuarto-rio-cuarto-provincia-de-cordoba': countries.ar,
  'etar-veliko-tarnovo': countries.bg,
  'ethe-belmont-ethe': countries.be,
  'ethiopia-bunna-addis-ababa': countries.et,
  'ethnikos-achna-dasaki-achnas': countries.cy,
  'ethnikos-assias-assia': countries.cy,
  'ethnikos-piraeus-piraeus': countries.gr,
  'etimesgut-belediyespor-etimesgut': countries.tr,
  'etoile-carouge-carouge': countries.ch,
  'etoile-de-morne-a-l-eau-morne-a-l-eau': countries.gp,
  'etoile-du-congo-brazzaville': countries.cg,
  'etoile-du-sahel-sousse': countries.tn,
  'etoile-filante-ouagadougou': countries.bf,
  'etoile-matoury': countries.gf,
  'etterbeek-etterbeek': countries.be,
  'etzella-ettelbruck-ettelbreck-ettelbruck': countries.lu,
  'eugendorf-eugendorf': countries.at,
  europa: countries.gi,
  'europa-barcelona': countries.es,
  'eutiner-sv-eutin': countries.de,
  'evergreen-leesburg-virginia': countries.us,
  'everton-liverpool': countries.gb,
  'everton-u18': countries.gb,
  'everton-u21-liverpool': countries.gb,
  'everton-u23-liverpool': countries.gb,
  'everton-vina-del-mar': countries.cl,
  'evesham-united-worcester-worcestershire': countries.gb,
  'evreux-evreux': countries.fr,
  'evv-echt-susteren': countries.nl,
  'excelsior-31-rijssen': countries.nl,
  'excelsior-maassluis-maassluis': countries.nl,
  'excelsior-rotterdam': countries.nl,
  'excelsior-saint-joseph': countries.re,
  'excelsior-virton-virton': countries.be,
  'excursionistas-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'exeter-city-exeter-devon': countries.gb,
  'express-kampala': countries.ug,
  'extension-gunners-lobatse': countries.bw,
  'eynesil-belediyespor-giresun': countries.tr,
  'eyupspor-istanbul': countries.tr,
  ezra: countries.la,
  'f91-dudelange-diddeleng-dudelange': countries.lu,
  'fa-siauliai': countries.lt,
  'fabril-barreiro': countries.pt,
  'fach-donaufeld-wien': countries.at,
  fadep: countries.ar,
  'faetano-faetano': countries.sm,
  'fafe-fafe': countries.pt,
  'fagiano-okayama-okayama': countries.jp,
  'fajr-sepasi-shiraz': countries.ir,
  'fakel-voronezh': countries.ru,
  'falcon-barra-dos-coqueiros-sergipe': countries.br,
  'falkenberg-falkenberg': countries.se,
  'falkirk-falkirk': countries.gb,
  'falubaz-zielona-gora-zielona-gora': countries.pl,
  'famalicao-u23': countries.pt,
  'famalicao-vila-nova-de-famalicao': countries.pt,
  'famos-vojkovici-hrasnica': countries.ba,
  'fana-bergen': countries.no,
  'fano-fano': countries.it,
  'far-rabat-rabat': countries.ma,
  'farense-faro': countries.pt,
  'farnborough-farnborough-hampshire': countries.gb,
  'faroe-islands-u18-torshavn': countries.fo,
  'farsley-celtic-farsley-leeds-west-yorkshire': countries.gb,
  'fas-santa-ana': countries.sv,
  'fasil-ketema': countries.et,
  'fast-clube-manaus-amazonas': countries.br,
  'fatih-karagumruk-istanbul': countries.tr,
  'fatih-karagumrukspor-u19-istanbul': countries.tr,
  'fatsa-belediyespor-ordu': countries.tr,
  'fb-ile-rousse-l-ile-rousse': countries.fr,
  'fc-105-libreville': countries.ga,
  'fc-andorra': countries.es,
  'fc-bocholt-bocholt': countries.de,
  'fc-cartagena-cartagena': countries.es,
  'fc-cincinnati-ii-cincinnati-ohio': countries.us,
  'fc-egg-egg': countries.at,
  'fc-espoo-espoo-esbo': countries.fi,
  'fc-hatvan-hatvan': countries.hu,
  'fc-helsingor-helsingor': countries.dk,
  'fc-komeetat': countries.fi,
  'fc-lebbeke-lebbeke': countries.be,
  'fc-motown-morristown-new-jersey': countries.us,
  'fc-pinatar-san-pedro-del-pinatar': countries.es,
  'fc-romania': countries.gb,
  'fc-s-gravenzande-s-gravenzande': countries.nl,
  'fc-santa-coloma-andorra-la-vella': countries.ad,
  'fc-schaffhausen-schaffhausen': countries.ch,
  'fc-serpa-serpa': countries.pt,
  'fc-stauceni': countries.md,
  'fc-tallinn': countries.ee,
  'fc-tokushima': countries.jp,
  'fci-levadia-ii-tallinn': countries.ee,
  'fci-levadia-tallinn': countries.ee,
  'fci-tallinn-tallinn': countries.ee,
  'fcm-portland-portland-oregon': countries.us,
  'fcm-ungheni-ungheni': countries.md,
  'fcsb-bucuresti': countries.ro,
  'fcsb-ii-bucuresti': countries.ro,
  'feignies-aulnoye-feignies': countries.fr,
  'feirense-feira-de-santana-bahia': countries.br,
  'feirense-santa-maria-da-feira': countries.pt,
  'feldkirchen-karnten-feldkirchen-in-karnten': countries.at,
  'felgueiras-1932-felgueiras': countries.pt,
  'felixstowe-walton-utd-felixstowe-suffolk': countries.gb,
  'fello-star-labe': countries.gn,
  'fenerbahce-istanbul': countries.tr,
  'fenerbahce-u19-istanbul': countries.tr,
  'fenix-montevideo': countries.uy,
  'fenix-partido-de-pilar-provincia-de-buenos-aires': countries.ar,
  'feralpisalo-salo': countries.it,
  'ferencvaros-budapest': countries.hu,
  'ferencvaros-ii-budapest': countries.hu,
  'ferikoy-istanbul': countries.tr,
  'ferizaj-ferizaj-urosevac': countries.xk,
  'fermana-fermo': countries.it,
  'fernandez-vial-concepcion': countries.cl,
  'fernando-de-la-mora-fernando-de-la-mora': countries.py,
  'fernandopolis-fernandopolis-sao-paulo': countries.br,
  'fernwald-fernwald': countries.de,
  'ferreiras-ferreiras': countries.pt,
  'ferro-carril-oeste-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'ferro-carril-oeste-lp-general-pico-provincia-la-pampa': countries.ar,
  'ferro-carril-sud-olavarria-provincia-de-buenos-aires': countries.ar,
  'ferroviaria-araraquara-sao-paulo': countries.br,
  'ferroviario-beira-beira': countries.mz,
  'ferroviario-corrientes-corrientes-provincia-de-corrientes': countries.ar,
  'ferroviario-fortaleza-ceara': countries.br,
  'ferroviario-maputo-maputo': countries.mz,
  'ferrymead-bays': countries.nz,
  'fethiyespor-fethiye': countries.tr,
  'feulen-uewerfeelen-oberfeulen': countries.lu,
  feurs: countries.fr,
  'feyenoord-rotterdam': countries.nl,
  'feyenoord-u19-rotterdam': countries.nl,
  'feyenoord-u21-rotterdam': countries.nl,
  'feyenoord-u23-rotterdam': countries.nl,
  'fezzanese-portovenere': countries.it,
  'fgura-united-fgura': countries.mt,
  'fh-hafnarfjordur': countries.is,
  'fidelis-andria-andria': countries.it,
  'figueirense-florianopolis-santa-catarina': countries.br,
  filakovo: countries.sk,
  'filiasi-filiasi': countries.ro,
  'finn-harps-ballybofey': countries.ie,
  'fiorentina-firenze': countries.it,
  'fiorentina-u19': countries.it,
  'fiorentino-fiorentino': countries.sm,
  'fiorenzuola-fiorenzuola-d-arda': countries.it,
  'firat-universitesi-elazig': countries.tr,
  'firpo-san-salvador': countries.sv,
  'first-vienna-wien': countries.at,
  'fisterra-fisterra': countries.es,
  'fjolnir-reykjavik': countries.is,
  'fk-aksu': countries.kz,
  'fk-kosice-kosice': countries.sk,
  'fk-minyor-pernik-pernik': countries.bg,
  'flacara-horezu-horezu': countries.ro,
  'flacara-moreni-moreni': countries.ro,
  'flambeau-du-centre-gitega': countries.bi,
  'flamengo-arcoverde-arcoverde-pernambuco': countries.br,
  'flamengo-ba-guanambi-bahia': countries.br,
  'flamengo-pi-teresina-piaui': countries.br,
  'flamengo-rio-de-janeiro-rio-de-janeiro': countries.br,
  'flamengo-sp-guarulhos-sao-paulo': countries.br,
  'flamengo-u17-rio-de-janeiro-rio-de-janeiro': countries.br,
  'flamengo-u20-rio-de-janeiro-rio-de-janeiro': countries.br,
  'flaminia-civita-castellana': countries.it,
  'flamurtari-vlore-vlora': countries.al,
  'flandria-lujan-provincia-de-buenos-aires': countries.ar,
  'flc-thanh-hoa-thanh-hoa': countries.vn,
  'fleetwood-town-fleetwood-lancashire': countries.gb,
  'fleetwood-town-u18': countries.gb,
  'flekkeroy-kristiansand': countries.no,
  'fleury-91-fleury-merogis': countries.fr,
  'flevo-boys-emmeloord': countries.nl,
  'flint-town-united-y-fflint-flint-flintshire': countries.gb,
  'flora-ii-tallinn': countries.ee,
  'flora-tallinn': countries.ee,
  'floreat-athena-perth': countries.au,
  'floresta-fortaleza-ceara': countries.br,
  'floresti-floresti': countries.md,
  'floriana-floriana': countries.mt,
  'florida-rush-u19-orlando-florida': countries.us,
  'floridsdorfer-ac-wien': countries.at,
  'floro-floro': countries.no,
  'flower-city-union-new-york': countries.us,
  floya: countries.no,
  'fluminense-de-feira-feira-de-santana-bahia': countries.br,
  'fluminense-pi-teresina-piaui': countries.br,
  'fluminense-rio-de-janeiro-rio-de-janeiro': countries.br,
  'focsani-focsani': countries.ro,
  'foggia-foggia': countries.it,
  'fola-esch-esch-uelzecht-esch-sur-alzette': countries.lu,
  'folgore-falciano': countries.sm,
  'folkestone-invicta-folkestone-kent': countries.gb,
  'follo-skien': countries.no,
  'fomat-martin-martin': countries.sk,
  'fontana-fontana-provincia-del-chaco': countries.ar,
  fontinhas: countries.pt,
  'foolad-ahvaz': countries.ir,
  'forca-e-luz-natal-rio-grande-do-norte': countries.br,
  'forest-green-rovers-nailsworth-gloucestershire': countries.gb,
  'forest-rangers-ndola': countries.zm,
  'foresta-suceava-suceava': countries.ro,
  'forfar-athletic-forfar': countries.gb,
  forge: countries.ca,
  'formartine-united-ellon': countries.gb,
  'formentera-sant-francesc-de-formentera': countries.es,
  'forres-mechanics-elgin': countries.gb,
  'fort-william-fort-william': countries.gb,
  'fort-worth-vaqueros': countries.us,
  'fortaleza-ceif-soacha': countries.co,
  'fortaleza-fortaleza-ceara': countries.br,
  'forte-taganrog': countries.ru,
  'fortuna-dusseldorf-dusseldorf': countries.de,
  'fortuna-dusseldorf-ii-dusseldorf': countries.de,
  'fortuna-dusseldorf-u17-dusseldorf': countries.de,
  'fortuna-koln-koln': countries.de,
  'fortuna-sittard-sittard': countries.nl,
  'fortuna-wormerveer-wormerveer': countries.nl,
  'fortune-farato': countries.gm,
  'forward-madison-madison-wisconsin': countries.us,
  'forward-orebro': countries.se,
  'fosa-juniors': countries.mg,
  'fostiras-athina-athens': countries.gr,
  'foutoua-deir-ez-zor': countries.sy,
  'fovu-club-baham': countries.cm,
  'foz-do-iguacu-foz-do-iguacu-parana': countries.br,
  fraga: countries.es,
  'fram-larvik': countries.no,
  'fram-reykjavik': countries.is,
  'francana-franca-sao-paulo': countries.br,
  'francavilla-francavilla-in-sinni': countries.it,
  'francs-borains-boussu': countries.be,
  'fraserburgh-fraserburgh': countries.gb,
  'frauental-frauental': countries.at,
  'fredericia-fredericia': countries.dk,
  'fredrikstad-fredrikstad': countries.no,
  'fredrikstad-ii-fredrikstad': countries.no,
  'frei-paulistano-frei-paulo-sergipe': countries.br,
  'freiburg-freiburg-im-breisgau': countries.de,
  'freiburg-ii-freiburg-im-breisgau': countries.de,
  'freiburg-u19-freiburg': countries.de,
  freienbach: countries.ch,
  'frejus-st-raphael-frejus': countries.fr,
  'frem-kobenhavn': countries.dk,
  'fremad-amager-kobenhavn': countries.dk,
  'fribourg-fribourg': countries.ch,
  'frigg-oslo': countries.no,
  'friska-viljor-ornskoldsvik': countries.se,
  'frome-town-frome-somerset': countries.gb,
  'frosinone-frosinone': countries.it,
  'frosinone-u19-frosinone': countries.it,
  'frydek-mistek-frydek-mistek': countries.cz,
  'fs-jelgava-jelgava': countries.lv,
  'fshm-torpedo-moskva': countries.ru,
  'fsv-frankfurt-frankfurt-am-main': countries.de,
  'ft-braunschweig-braunschweig': countries.de,
  'fuenlabrada-fuenlabrada': countries.es,
  'fuente-de-cantos-fuente-de-cantos': countries.es,
  'fugen-fugen': countries.at,
  'fujieda-myfc-fujieda': countries.jp,
  'fukui-united-fukui': countries.jp,
  'fukushima-united-fukushima': countries.jp,
  'fukuyama-city-fukuyama-hiroshima': countries.jp,
  'fulham-london': countries.gb,
  'fulham-u18': countries.gb,
  'fulham-u21': countries.gb,
  'fulham-u23-london': countries.gb,
  'fundacion-aifi': countries.ve,
  'fundacion-lara-deportiva': countries.ve,
  'furiani-agliani-furiani': countries.fr,
  'fus-rabat-rabat': countries.ma,
  'futura-porvoo': countries.fi,
  'fuzesgyarmati-fuzesgyarmati': countries.hu,
  'fuzinar-ravne-na-koroskem': countries.si,
  'fv-engers-07-neuwied': countries.de,
  'fvc-leeuwarden': countries.nl,
  'fylde-warton-lancashire': countries.gb,
  'fylkir-reykjavik': countries.is,
  'fyllingsdalen-bergen': countries.no,
  'g-ijduvon-g-ijduvon-gijduvon': countries.uz,
  'gabes-gabes': countries.tn,
  'gaborone-united-gaborone': countries.bw,
  'gagauziya-oguzsport-comrat-komrat': countries.md,
  'gagra-tbilisi': countries.ge,
  'gainare-tottori-tottori': countries.jp,
  'gainsborough-trinity-gainsborough-lincolnshire': countries.gb,
  'gais-goteborg': countries.se,
  'gaj-mace-mace': countries.hr,
  'gala-fairydean-rovers-galashiels': countries.gb,
  'galanta-galanta': countries.sk,
  'galapagar-galapagar': countries.es,
  'galatasaray-istanbul': countries.tr,
  'galatasaray-res-istanbul': countries.tr,
  'galatasaray-u19-istanbul': countries.tr,
  'galaxy-jwaneng': countries.bw,
  'galicia-salvador-bahia': countries.br,
  'gallia-lucciana-lucciana': countries.fr,
  'galvez-rio-branco-acre': countries.br,
  'galway-united-galway': countries.ie,
  gama: countries.es,
  'gama-gama-distrito-federal': countries.br,
  'gamba-osaka-suita-shi-suita': countries.jp,
  'gambarogno-contone': countries.ch,
  'gamtel-banjul': countries.gm,
  gandia: countries.es,
  'gandzasar-kapan': countries.am,
  'gandzasar-kapan-2021': countries.am,
  'gangneung-city-gangneung': countries.kr,
  'gangwon-gangneung': countries.kr,
  'ganshoren-ganshoren': countries.be,
  'garbarnia-krakow-krakow': countries.pl,
  'garching-garching': countries.de,
  'gareji-sagarejo': countries.ge,
  garliava: countries.lt,
  'gateshead-gateshead-tyne-and-wear': countries.gb,
  'gaucho-passo-fundo-rio-grande-do-sul': countries.br,
  gauthiod: countries.se,
  'gavorrano-bagno-di-gavorrano': countries.it,
  'gazelec-ajaccio-ajaccio': countries.fr,
  'gazi-mahallesi-istanbul': countries.tr,
  'gazisehir-gaziantep-gaziantep': countries.tr,
  'gazisehir-gaziantep-u19-gaziantep': countries.tr,
  gazovik: countries.by,
  'gbk-kokkola': countries.fi,
  'gc-mascara': countries.dz,
  'gebenbach-gebenbach': countries.de,
  'gebze-tayfunspor-gebze-kocaeli': countries.tr,
  'gebzespor-gebze': countries.tr,
  'geel-geel': countries.be,
  'gefle-gavle': countries.se,
  'geita-gold-geita': countries.tz,
  'gelbison-vallo-della-lucania': countries.it,
  'gemert-gemert': countries.nl,
  'genclerbirligi-ankara': countries.tr,
  'genclerbirligi-u19-ankara': countries.tr,
  'genemuiden-genemuiden': countries.nl,
  'general-caballero-jlm': countries.py,
  'general-lamadrid-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'general-paz-juniors-ciudad-de-cordoba-provincia-de-cordoba': countries.ar,
  'general-velasquez-san-vicente-de-tagua-tagua': countries.cl,
  'generation-foot-dakar': countries.sn,
  'genk-genk': countries.be,
  'genoa-genova': countries.it,
  'genoa-u19': countries.it,
  'gent-gent': countries.be,
  'genus-porto-velho-rondonia': countries.br,
  'george-town-george-town': countries.ky,
  'georgetown-university-georgetown-washington-dc': countries.us,
  'georgia-revolution': countries.us,
  'gerena-gerena': countries.es,
  'germania-egestorf-barsinghausen': countries.de,
  'germania-halberstadt-halberstadt': countries.de,
  'germinal-de-rawson-rawson-provincia-del-chubut': countries.ar,
  'gernika-gernika-lumo': countries.es,
  'getafe-getafe-madrid': countries.es,
  'getafe-ii-getafe': countries.es,
  'geylang-international-singapore': countries.sg,
  ghajnsielem: countries.mt,
  gharghur: countries.mt,
  'ghazl-el-mehalla-el-mahalla-el-kubra': countries.eg,
  'ghiroda-si-giarmata-vii-timi': countries.ro,
  'giana-erminio-gorgonzola': countries.it,
  'giannitsa-giannitsa': countries.gr,
  'gievenbeck-gievenbeck': countries.de,
  'gif-sundsvall-sundsvall': countries.se,
  'gifu-gifu': countries.jp,
  'gigant-saedinenie-saedinenie': countries.bg,
  'gil-vicente-barcelos': countries.pt,
  'gillingham-gillingham-kent': countries.gb,
  'gillingham-u18': countries.gb,
  gilortul: countries.ro,
  'gimhae-city-gimhae': countries.kr,
  'gimnasia-concepcion-concepcion-del-uruguay-provincia-de-entre-rios':
    countries.ar,
  'gimnasia-jujuy-san-salvador-de-jujuy-provincia-de-jujuy': countries.ar,
  'gimnasia-la-plata-la-plata-provincia-de-buenos-aires': countries.ar,
  'gimnasia-mendoza-mendoza-provincia-de-mendoza': countries.ar,
  'gimnasia-y-tiro-salta-provincia-de-salta': countries.ar,
  'gimnastic-tarragona-tarragona': countries.es,
  'gimnastica-segoviana-segovia': countries.es,
  'gimnastica-torrelavega-torrelavega': countries.es,
  'gimpo-citizen-gimpo': countries.kr,
  'giravanz-kitakyushu-kitakyushu': countries.jp,
  'giresunspor-giresun': countries.tr,
  'giresunspor-u19-giresun': countries.tr,
  'girona-girona': countries.es,
  'girona-ii-girona': countries.es,
  'gjilani-gjilan': countries.xk,
  'gjovik-lyn-gjovik': countries.no,
  'gks-katowice-katowice': countries.pl,
  'glacis-united-gibraltar': countries.gi,
  'gladiator-santa-maria-capua-vetere': countries.it,
  'gleisdorf-09-gleisdorf': countries.at,
  'glenavon-lurgan': countries.gb,
  'glentoran-belfast': countries.gb,
  'globo-ceara-mirim-rio-grande-do-norte': countries.br,
  gloggnitz: countries.at,
  'gloria-albesti-albesti': countries.ro,
  'gloria-lunca-teuz-cermei-cermei': countries.ro,
  'gloria-vacaria-rio-grande-do-sul': countries.br,
  'glossop-north-end-glossop': countries.gb,
  'gloucester-city-gloucester-gloucestershire': countries.gb,
  'gmund-gmund': countries.at,
  'gnistan-helsinki': countries.fi,
  'go-ahead-eagles-deventer': countries.nl,
  'go-ahead-kampen': countries.nl,
  'goa-goa': countries.in,
  'gobelins-paris': countries.fr,
  'godoy-cruz-godoy-cruz-provincia-de-mendoza': countries.ar,
  'goes-goes': countries.nl,
  gofis: countries.at,
  'goianesia-goianesia-goias': countries.br,
  'goias-goiania-goias': countries.br,
  'gokulam-kozhikode': countries.in,
  'gol-gohar-sirjan': countries.ir,
  'golbasi-belediyespor-ankara': countries.tr,
  'golcukspor-golcuk': countries.tr,
  'gold-coast-knights-gold-coast': countries.au,
  'gold-coast-united-bundall': countries.au,
  'golden-arrows-durban-kn': countries.za,
  'golden-lion-saint-joseph': countries.mq,
  'golden-star-fort-de-france': countries.mq,
  'golden-state-force-glendora-california': countries.us,
  'golling-golling': countries.at,
  'golspie-sutherland-golspie': countries.gb,
  'gomel-homel': countries.by,
  'gondomar-gondomar': countries.pt,
  'gonen-belediyespor-gonen': countries.tr,
  'gonfreville-gonfreville-l-orcher': countries.fr,
  'gonsenheim-mainz': countries.de,
  'gor-mahia-nairobi': countries.ke,
  'gorazde-gorazde': countries.ba,
  'goree-dakar': countries.sn,
  'gorelespor-giresun': countries.tr,
  'gori-gori': countries.ge,
  'gorica-noca-gorica': countries.si,
  'gorica-velika-gorica': countries.hr,
  gorki: countries.by,
  'gornik-leczna-leczna': countries.pl,
  'gornik-zabrze-zabrze': countries.pl,
  'gorukle-ipekspor-bursa': countries.tr,
  'gosier-le-gosier': countries.gp,
  'gosk-dubrovnik-dubrovnik': countries.hr,
  'gosk-gabela-gabela': countries.ba,
  'gosport-borough-gosport-hampshire': countries.gb,
  'gossau-gossau-sg': countries.ch,
  'gosselies-sports-gosselies': countries.be,
  'gottne-sjalevad': countries.se,
  'goyang-citizen-goyang': countries.kr,
  'goytre-united-port-talbot': countries.gb,
  'goztepe-izmir': countries.tr,
  'goztepe-u19-izmir': countries.tr,
  'gozzano-gozzano': countries.it,
  'gpa-banjul': countries.gm,
  'gradina-srebrenik': countries.ba,
  'graficar-beograd': countries.rs,
  'grama-santa-coloma-de-gramenet': countries.es,
  'gramshi-gramshi': countries.al,
  'gran-pena': countries.es,
  'gran-tarajal': countries.es,
  'granada-granada': countries.es,
  'granada-ii-granada': countries.es,
  'grand-leez-grand-leez': countries.be,
  'grand-quevilly': countries.fr,
  'grand-saconnex': countries.ch,
  'grandvillars-grandvillars': countries.fr,
  'granicar-zupanja-zupanja': countries.hr,
  'granicerul-glodeni': countries.md,
  'grantham-town-grantham-lincolnshire': countries.gb,
  'granville-granville': countries.fr,
  'grasse-grasse': countries.fr,
  'grasshopper-niederhasli': countries.ch,
  'gravina-gravina': countries.it,
  'grays-athletic-grays-essex': countries.gb,
  'grazer-ak-graz': countries.at,
  'grbalj-radanovici': countries.me,
  'great-wakering-rovers-great-wakering-essex': countries.gb,
  'grebbestad-grebbestad': countries.se,
  'grecia-chone': countries.ec,
  'grecia-grecia': countries.cr,
  'green-eagles-kabwe': countries.zm,
  'green-gully-melbourne': countries.au,
  'green-island-green-island-dunedin': countries.nz,
  'green-mamba-malkerns': countries.sz,
  'greenock-morton-greenock': countries.gb,
  'greenville-triumph-greenville-south-carolina': countries.us,
  'greifswalder-fc-greifswald-hansestadt': countries.de,
  'gremio-anapolis-anapolis-goias': countries.br,
  'gremio-porto-alegre-rio-grande-do-sul': countries.br,
  'gremio-saocarlense-sao-carlos-sao-paulo': countries.br,
  'grenoble-foot-38-grenoble': countries.fr,
  'gresford-athletic-gresford-wrexham-county-borough': countries.gb,
  'gresley-church-gresley-derbyshire': countries.gb,
  'gretna-2008-gretna': countries.gb,
  'greuther-furth-furth': countries.de,
  'greuther-furth-ii-furth': countries.de,
  'greve-greve': countries.dk,
  'grevenmacher-greiwemaacher-grevenmacher': countries.lu,
  'grez-doiceau-grez-doiceau': countries.be,
  'grifk-grankulla': countries.fi,
  'grimbergen-grimbergen': countries.be,
  'grimsby-town-grimsby-north-east-lincolnshire': countries.gb,
  'grindavik-grindavik': countries.is,
  grobina: countries.lv,
  'grodig-grodig': countries.at,
  grodnensky: countries.by,
  'groene-ster-heerlerheide': countries.nl,
  'groningen-groningen': countries.nl,
  'groningen-u19-groningen': countries.nl,
  'grorud-oslo': countries.no,
  'grossbardorf-grossbardorf': countries.de,
  'grosseto-grosseto': countries.it,
  'grotta-seltjarnarnes': countries.is,
  'grulla-morioka-morioka': countries.jp,
  'grun-weiss-micheldorf': countries.at,
  'guabira-santa-cruz': countries.bo,
  'guadalajara-guadalajara': countries.es,
  'guadalajara-u20': countries.mx,
  'guadalajara-zapopan-guadalajara': countries.mx,
  'guadalupe-guadalupe': countries.cr,
  'guairena-villarrica': countries.py,
  'gualaceo-gualaceo': countries.ec,
  'guanacasteca-nicoya': countries.cr,
  'guangxi-pingguo-liuzhou-guangxi': countries.cn,
  'guangzhou-evergrande-guangzhou-canton': countries.cn,
  'guangzhou-r-f-guangzhou': countries.cn,
  'guarani-a-franco-posadas-provincia-de-misiones': countries.ar,
  'guarani-asuncion': countries.py,
  'guarani-campinas-sao-paulo': countries.br,
  'guarani-de-juazeiro-juazeiro-do-norte-ceara': countries.br,
  'guarani-de-palhoca-palhoca-santa-catarina': countries.br,
  'guarani-rs-venancio-aires-rio-grande-do-sul': countries.br,
  'guarda-desportiva-guarda': countries.pt,
  guastatoya: countries.gt,
  'guayaquil-city-guayaquil': countries.ec,
  'guayaquil-guayaquil': countries.ec,
  'gubbio-gubbio': countries.it,
  'gubbio-u19': countries.it,
  'gudja-united-gudja': countries.mt,
  'guelleh-gr': countries.dj,
  'guelph-united-guelph': countries.ca,
  gueret: countries.fr,
  'gueugnon-gueugnon': countries.fr,
  'guijuelo-guijuelo': countries.es,
  'guillermo-brown-puerto-madryn-provincia-de-chubut': countries.ar,
  'guilsfield-guilsfield-cegidfa-powys': countries.gb,
  'guimar-santa-cruz-de-tenerife': countries.es,
  'guingamp-guingamp': countries.fr,
  'guingamp-ii-guingamp': countries.fr,
  'guipry-messac-guipry-messac': countries.gb,
  'guiseley-guiseley-west-yorkshire': countries.gb,
  'gullegem-gullegem': countries.be,
  'gumushane-telekom-gumushane': countries.tr,
  'gumushanespor-gumushane': countries.tr,
  'guria-lanchkhuti': countries.ge,
  'gurten-gurten': countries.at,
  'gurupi-gurupi-tocantins': countries.br,
  'gute-visby': countries.se,
  'gutierrez-maipu-provincia-de-mendoza': countries.ar,
  'guyana-defence-force': countries.gy,
  'gvv-unitas-gorinchem': countries.nl,
  'gvvv-veenendaal': countries.nl,
  'gwangju-gwangju': countries.kr,
  'gwelup-croatia-gwelup': countries.au,
  'gyeongju-hnp-seoul': countries.kr,
  'gyeongnam-changwon': countries.kr,
  'gyirmot-gyor': countries.hu,
  'gyori-eto-gyor': countries.hu,
  'gyori-eto-ii-gyor': countries.hu,
  'gzira-united-gzira': countries.mt,
  'h-h-export-sebaco': countries.ni,
  'h-w-welders-belfast': countries.gb,
  'ha-n-i-ii-ha-n-i': countries.vn,
  'ha-noi-ha-n-i-hanoi': countries.vn,
  'hacilar-erciyes-hacilar': countries.tr,
  'hacken-goteborg': countries.se,
  'hades-hasselt': countries.be,
  'hadirligucu-adana': countries.tr,
  'haguenau-haguenau': countries.fr,
  'hai-phong-h-i-phong-hai-phong': countries.vn,
  haitzendorf: countries.at,
  'hajduk-split-split': countries.hr,
  hajer: countries.sa,
  'haka-valkeakoski': countries.fi,
  'hakoah-ramat-gan-ramat-gan': countries.il,
  'hakoah-sydney-city-sydney': countries.au,
  'halesowen-town-halesowen-west-midlands': countries.gb,
  'halic-istanbul': countries.tr,
  'halide-edip-adivar-istanbul': countries.tr,
  'halifax-town-halifax-west-yorkshire': countries.gb,
  'halkanoras-dali': countries.cy,
  'hall-hall-in-tirol': countries.at,
  'hallbergmoos-goldach': countries.de,
  'hallescher-fc-halle-salle': countries.de,
  'halmstad-halmstad': countries.se,
  'halmstad-u21-halmstad': countries.se,
  'halsteren-halsteren': countries.nl,
  'hamar-hveragerdisbaer-hveragerdi': countries.is,
  'hamburger-sv-hamburg': countries.de,
  'hamburger-sv-ii-norderstedt': countries.de,
  'hamburger-sv-u17-hamburg': countries.de,
  'hamburger-sv-u19-hamburg': countries.de,
  'hamilton-academical-hamilton': countries.gb,
  'hamilton-academical-u21-hamilton': countries.gb,
  'hamilton-olympic-newcastle': countries.au,
  'hamilton-wanderers-hamilton': countries.nz,
  'hamkam-hamar': countries.no,
  'hammam-lif-hammam-lif': countries.tn,
  'hammam-sousse-hammam-sousse': countries.tn,
  'hammarby-stockholm': countries.se,
  'hammarby-talang-stockholm': countries.se,
  'hammer-spvg-hamm': countries.de,
  'hamoir-hamoir': countries.be,
  'hampton-richmond-hampton-middlesex': countries.gb,
  'hamrun-spartans-hamrun': countries.mt,
  'hanacka-kromeriz': countries.cz,
  'hang-yuen-new-taipei-city': countries.tw,
  'haninge-stockholm': countries.se,
  'hankofen-hailing-hankofen': countries.de,
  'hannover-96-hannover': countries.de,
  'hannover-96-ii-hannover': countries.de,
  'hannover-96-u17-hannover': countries.de,
  'hannover-96-u19-hannover': countries.de,
  'hannut-hannut': countries.be,
  'hansa-rostock-rostock': countries.de,
  'hansa-rostock-u19-rostock': countries.de,
  'hantharwady-united-yangon': countries.mm,
  'hanwell-town-london': countries.gb,
  hapk: countries.fi,
  'hapoel-acre-akko-accre': countries.il,
  'hapoel-afula-afula': countries.il,
  'hapoel-ashkelon-ashkelon': countries.il,
  'hapoel-be-er-sheva-be-er-sheva-beer-sheva': countries.il,
  'hapoel-bik-at-hayarden-tomer': countries.il,
  'hapoel-bnei-ar-ara-ara-ar-ara': countries.il,
  'hapoel-bnei-lod-bnei-lod': countries.il,
  'hapoel-bnei-musmus': countries.il,
  'hapoel-hadera': countries.il,
  'hapoel-haifa-hefa-haifa': countries.il,
  'hapoel-herzliya-herzliya': countries.il,
  'hapoel-hod-hasharon-hod-hasharon': countries.il,
  'hapoel-katamon-jerusalem': countries.il,
  'hapoel-kaukab-kaukab-abu-al-hija': countries.il,
  'hapoel-kfar-saba-kfar-saba': countries.il,
  'hapoel-kfar-shalem-tel-aviv-yafa-tel-aviv': countries.il,
  'hapoel-marmorek-rehovot': countries.il,
  'hapoel-migdal-haemek-migdal-haemek': countries.il,
  'hapoel-nazareth-illit-nazareth-illit': countries.il,
  'hapoel-petah-tikva-peta-tiqva-petach-tikva': countries.il,
  'hapoel-ra-anana-ra-anana': countries.il,
  'hapoel-ramat-gan-giv-atayim': countries.il,
  'hapoel-ramat-hasharon-ramat-ha-sharon': countries.il,
  'hapoel-rishon-lezion-rishon-le-zion': countries.il,
  'hapoel-tel-aviv-tel-aviv-yafa-tel-aviv': countries.il,
  'hapoel-umm-al-fahm-umm-al-fahm': countries.il,
  'happy-valley-hong-kong': countries.hk,
  'haras-el-hodood-al-iskandariah-alexandria': countries.eg,
  'harb-isspor-golcuk': countries.tr,
  'harbour-view-harbour-view': countries.jm,
  'hard-hard-am-bodensee': countries.at,
  'hard-rock': countries.gd,
  'harelbeke-harelbeke': countries.be,
  'haringey-borough-london': countries.gb,
  'harju-jalgpallikool': countries.ee,
  'harkemase-boys-harkema': countries.nl,
  'harlow-town-harlow-essex': countries.gb,
  haro: countries.es,
  'harrogate-town-harrogate-north-yorkshire': countries.gb,
  'harrow-borough-south-harrow-middlesex': countries.gb,
  'harstad-harstad': countries.no,
  'hartberg-hartberg': countries.at,
  'hartford-athletic-hartford-connecticut': countries.us,
  'hartford-city-hartford-connecticut': countries.us,
  'hartlepool-united-hartlepool-county-durham': countries.gb,
  'hartlepool-united-u18': countries.gb,
  'hartley-wintney': countries.gb,
  'hask-zagreb': countries.hr,
  'hassania-agadir-agadir': countries.ma,
  'hassleholms-if-hassleholm': countries.se,
  'hastedt-bremen': countries.de,
  'hastings-united-hastings-sussex': countries.gb,
  'hatayspor-hatay': countries.tr,
  'hatayspor-u19-hatay': countries.tr,
  'hatayspor-u21-hatay': countries.tr,
  'hatta-dubayy-dubai': countries.ae,
  'haugesund-haugesund': countries.no,
  'haukar-hafnarfjordur': countries.is,
  'hauts-lyonnais-pomeys': countries.fr,
  'havadar-tehran': countries.ir,
  'havant-waterlooville-havant-hampshire': countries.gb,
  'havelock-north-wanderers-hawke-s-bay': countries.nz,
  'havelse-garbsen': countries.de,
  'haverfordwest-county-hwlffordd-haverfordwest-pembrokeshie': countries.gb,
  'hay-al-wadi-nyala': countries.sd,
  'hayes-yeading-united-hayes-middlesex': countries.gb,
  'haywards-heath-town': countries.gb,
  'hb-chelghoum-laid-chelghoum-laid': countries.dz,
  'hb-koge-herfolge': countries.dk,
  'hb-torshavn-streymoy': countries.fo,
  'hbs-den-haag': countries.nl,
  'heartland-owerri': countries.ng,
  'hearts-edinburgh': countries.gb,
  'hearts-of-oak-accra': countries.gh,
  'hearts-u21-edinburgh': countries.gb,
  'hebar-1918-pazardzhik': countries.bg,
  'hebei-cffc-qinhuangdao-hebei': countries.cn,
  hedensted: countries.dk,
  'hednesford-town-hednesford-staffordshire': countries.gb,
  'heerenveen-heerenveen-1920': countries.nl,
  'heerenveen-u19-heerenveen': countries.nl,
  'hegelmann-litauen-kaunas': countries.lt,
  'heidelberg-united-melbourne': countries.au,
  'heidenheim-heidenheim-an-der-brenz': countries.de,
  'heidenheim-u19-heidenheim': countries.de,
  'heider-sv-heide': countries.de,
  'heilongjiang-lava-spring-harbin': countries.cn,
  'heimstetten-heimstetten': countries.de,
  'heist-heist-op-den-berg': countries.be,
  'hekari-united-port-moresby': countries.pg,
  'hekimoglu-trabzon-trabzon': countries.tr,
  'hellas-verona-verona': countries.it,
  'hellenic-athletic': countries.au,
  'helmond-sport-helmond': countries.nl,
  'helsingborg-helsingborg': countries.se,
  'helson-helchteren-houthalen': countries.be,
  'hemel-hempstead-town-hemel-hempstead-hertfordshire': countries.gb,
  'henan-jianye-zhengzhou': countries.cn,
  'hendek-derekoyspor-derekoy-hendek-sakarya': countries.tr,
  'hendek-spor-hendek': countries.tr,
  'henderson-eels-honiara': countries.sb,
  'hendon-london': countries.gb,
  'hennef-05-hennef': countries.de,
  'hennef-05-u17-hennef': countries.de,
  'heracles-almelo': countries.nl,
  'hercilio-luz-tubarao-santa-catarina': countries.br,
  'hercules-alicante': countries.es,
  'hercules-ii': countries.es,
  'herediano-heredia': countries.cr,
  'hereford-hereford': countries.gb,
  hergiswil: countries.ch,
  'herk-herk-de-stad': countries.be,
  'herlev-herlev': countries.dk,
  'hermannstadt-sibiu': countries.ro,
  'hermanos-colmenares-cabudare': countries.ve,
  'hernan-cortes-hernan-cortes': countries.es,
  'herne-bay-herne-bay-kent': countries.gb,
  'heroes-de-falcon-coro': countries.ve,
  'hertha-bsc-berlin': countries.de,
  'hertha-bsc-u17-berlin': countries.de,
  'hertha-bsc-u21-berlin': countries.de,
  'hertha-wels': countries.at,
  'hertha-zehlendorf-berlin': countries.de,
  'hessen-dreieich-dreieich': countries.de,
  'hessen-kassel-kassel': countries.de,
  'heybridge-swifts-maldon-essex': countries.gb,
  'hfk-olomouc-olomouc': countries.cz,
  'hfx-wanderers': countries.ca,
  'hhc-hardenberg': countries.nl,
  'hibernian-edinburgh': countries.gb,
  'hibernians-paola': countries.mt,
  'hienghene-sport-hienghene': countries.nc,
  'hifk-2-helsinki': countries.fi,
  'hifk-helsinki': countries.fi,
  'highlanders-bulawayo': countries.zw,
  'highlanders-mbabane': countries.sz,
  'hik-gentofte': countries.dk,
  'hilal-al-quds-al-ram': countries.ps,
  'hill-of-beath-hawthorn-hill-of-beath': countries.gb,
  'hillerod-hillerod': countries.dk,
  'hills-brumbies': countries.au,
  'hirnyk-kryvyi-rih-krivoj-rog': countries.ua,
  'hirnyk-sport-horishni-plavni': countries.ua,
  'hitchin-town-hitchin-hertfordshire': countries.gb,
  hittarp: countries.se,
  'hjk-helsinki': countries.fi,
  'hjs-akatemia-hameenlinna': countries.fi,
  'hk-kopavogur': countries.is,
  'hk-u23-hong-kong': countries.hk,
  'hlucin-hlucin': countries.cz,
  'ho-chi-minh-city-thanh-ph-h-chi-minh-ho-chi-minh-city': countries.vn,
  'hoang-anh-gia-lai-plei-cu-pleiku': countries.vn,
  'hobro-hobro': countries.dk,
  'hochst-hochst': countries.at,
  'hodd-ulsteinvik': countries.no,
  'hodmezovasarhely-hodmezovasarhely': countries.hu,
  'hodonin-sardice-hodonin': countries.cz,
  'hoffenheim-ii-hoffenheim': countries.de,
  'hoffenheim-sinsheim': countries.de,
  'hoffenheim-u19-hoffenheim': countries.de,
  'hoganas-hoganas': countries.se,
  'hohenems-hohenems': countries.at,
  'hoi-king-hong-kong': countries.hk,
  'hokkaido-tokachi-obihiro': countries.jp,
  'holbaek-b-i-holbaek': countries.dk,
  'hollandia-hoorn': countries.nl,
  'holon-yermiyahu-holon': countries.il,
  'holstein-kiel-ii-kiel': countries.de,
  'holstein-kiel-kiel': countries.de,
  'holstein-kiel-u19-kiel': countries.de,
  'holyhead-hotspur-holyhead-isle-of-anglesey': countries.gb,
  'holywell-holywell': countries.gb,
  'homberg-duisburg': countries.de,
  'homburg-homburg': countries.de,
  'home-united-singapore': countries.sg,
  'homurlu-1951-mersin': countries.tr,
  'honda-hamamatsu': countries.jp,
  'honda-lock-miyazaki': countries.jp,
  'honduras-progreso-el-progreso': countries.hn,
  'honefoss-honefoss': countries.no,
  'hong-kong-hong-kong': countries.hk,
  'honka-akatemia-espoo': countries.fi,
  'honka-espoo-esbo': countries.fi,
  'honka-u19': countries.fi,
  'honved-budapest': countries.hu,
  'honved-ii-budapest': countries.hu,
  'honved-u19-budapest': countries.hu,
  'hoogeveen-hoogeveen': countries.nl,
  'hoogezand-hoogezand': countries.nl,
  'hoogland-hoogland': countries.nl,
  'hoogstraten-hoogstraten': countries.be,
  'hordur-i': countries.is,
  'horizonte-horizonte-ceara': countries.br,
  'horn-horn': countries.at,
  'horoya-conakry': countries.gn,
  horseed: countries.so,
  'horsens-horsens': countries.dk,
  'horsham-worthing-west-sussex': countries.gb,
  'horta-barcelona': countries.es,
  'hostert-ueweraanwen-oberanven': countries.lu,
  'hottain-jizan': countries.sa,
  'hottin-latakia': countries.sy,
  'hottur-huginn': countries.is,
  'hougang-united-singapore': countries.sg,
  'houston-dynamo-fc-ii-houston-texas': countries.us,
  'houston-dynamo-houston-texas': countries.us,
  'houston-dynamo-u18-19-houston-texas': countries.us,
  'houston-dynamo-u19-houston-texas': countries.us,
  'houtvenne-houtvenne': countries.be,
  'hoyvik-torshavn': countries.fo,
  'hradec-kralove-hradec-kralove': countries.cz,
  'hranice-hranice': countries.cz,
  'hrvatski-dragovoljac-zagreb': countries.hr,
  'hsc-21-haaksbergen': countries.nl,
  'hsc-hannover-hannover': countries.de,
  'hsv-hoek-hoek': countries.nl,
  'huachipato-talcahuano': countries.cl,
  'huai-thalaeng-united-nakhon-ratchasima': countries.th,
  'huddersfield-town-huddersfield-west-yorkshire': countries.gb,
  'huddersfield-town-u18-huddersfield-west-yorkshire': countries.gb,
  'huddersfield-town-u21-huddersfield-west-yorkshire': countries.gb,
  'huddinge-huddinge': countries.se,
  'hudiksvall-hudiksvall': countries.se,
  'huesca-huesca': countries.es,
  'huesca-ii-huesca': countries.es,
  'huetor-tajar-huetor-tajar': countries.es,
  'huetor-vega-huetor-vega': countries.es,
  'huizen-huizen': countries.nl,
  'hull-city-hull': countries.gb,
  'hull-city-u18': countries.gb,
  'hull-city-u21-hull': countries.gb,
  'humaita-porto-acre-acre': countries.br,
  'humble-lions-may-pen': countries.jm,
  'hume-city-melbourne': countries.au,
  'humenne-humenne': countries.sk,
  'hungerford-town-hungerford-berkshire': countries.gb,
  'hungry-lions': countries.za,
  'huntly-huntly': countries.gb,
  'huracan-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'huracan-de-comodoro-comodoro-rivadavia-provincia-del-chubut': countries.ar,
  'huracan-goya-goya-provincia-de-corrientes': countries.ar,
  'huracan-las-heras-las-heras-provincia-de-mendoza': countries.ar,
  'huracan-melilla-melilla': countries.es,
  'huracan-montevideo': countries.uy,
  'huracan-san-rafael-san-rafael-provincia-de-mendoza': countries.ar,
  'huracan-tres-arroyos-tres-arroyos-provincia-de-buenos-aires': countries.ar,
  'hurricanes-victoria': countries.gd,
  'husqvarna-huskvarna': countries.se,
  'hutnik-krakow-krakow': countries.pl,
  'huy-huy': countries.be,
  'hv-cv-quick-den-haag': countries.nl,
  hvch: countries.nl,
  'hvidovre-hvidovre': countries.dk,
  'hwaseong-hwaseong': countries.kr,
  'hyde-united-hyde-cheshire': countries.gb,
  hyderabad: countries.in,
  'hyeres-hyeres': countries.fr,
  'hythe-town-hythe-kent': countries.gb,
  'i-kissat-tampere': countries.fi,
  'ia-akranes': countries.is,
  'ia-u19-akranes': countries.is,
  'ialysos-rhodes': countries.gr,
  'iape-sao-luis-maranhao': countries.br,
  'ibarra-arona-tenerife': countries.es,
  'iberia-los-angeles': countries.cl,
  'ibis-paulista-pernambuco': countries.br,
  'ibiza-islas-pitiusas-eivissa-ibiza': countries.es,
  'ibv-vestmannaeyjar': countries.is,
  'icasa-juazeiro-do-norte-ceara': countries.br,
  'icelspor-mersin': countries.tr,
  'if-fuglafjordur-eysturoy': countries.fo,
  'if-karlstad': countries.se,
  'if-karlstad-ii': countries.se,
  'ifk-eskilstuna-eskilstuna': countries.se,
  'ifk-goteborg-goteborg': countries.se,
  'ifk-goteborg-u21-goteborg': countries.se,
  'ifk-hassleholm-hassleholm': countries.se,
  'ifk-lulea-lulea': countries.se,
  'ifk-malmo-malmo': countries.se,
  'ifk-osteraker': countries.se,
  'ifk-ostersund-ostersund': countries.se,
  'igalo-igalo': countries.me,
  'igdir-es-igdir': countries.tr,
  'igman-konjic-konjic': countries.ba,
  igualada: countries.es,
  'iguatu-iguatu-ceara': countries.br,
  'ih-hafnarfjordur': countries.is,
  'iii-keruleti-tue-budapest': countries.hu,
  'ijsselmeervogels-bunschoten': countries.nl,
  'ilimtepe-kocaeli': countries.tr,
  'ilioupoli-ilioupoli': countries.gr,
  'ilirija-ljubljana': countries.si,
  'ilkeston-town': countries.gb,
  'illertissen-illertissen': countries.de,
  'illes-akademia-u19': countries.hu,
  illescas: countries.es,
  'illkirch-graffenstaden': countries.fr,
  'illueca-illueca': countries.es,
  'ilpar-ilyinskiy': countries.ru,
  'ilshofen-ilshofen': countries.de,
  'ilvamaddalena-la-maddalena': countries.it,
  'ilves-ii-tampere': countries.fi,
  'ilves-tampere': countries.fi,
  imabari: countries.jp,
  'imbabura-ibarra': countries.ec,
  'img-academy-u17-bradenton-florida': countries.us,
  'img-academy-u19-bradenton-florida': countries.us,
  'imolese-imola': countries.it,
  'imortal-albufeira-albufeira': countries.pt,
  'imperatriz-imperatriz-maranhao': countries.br,
  'imst-imst': countries.at,
  'imt-novi-beograd-novi-beograd': countries.rs,
  'inainte-modelu-modelu': countries.ro,
  'incheon-united-incheon': countries.kr,
  'independente-pa-tucurui-para': countries.br,
  'independente-sp-limeira-sao-paulo': countries.br,
  independiente: countries.cl,
  'independiente-avellaneda-provincia-de-buenos-aires': countries.ar,
  'independiente-chivilcoy-chivilcoy-provincia-de-buenos-aires': countries.ar,
  'independiente-del-valle-sangolqui': countries.ec,
  'independiente-fbc-luque': countries.py,
  'independiente-fsj-sao-jose-dos-pinhais-parana': countries.br,
  'independiente-la-chorrera': countries.pa,
  'independiente-medellin-medellin': countries.co,
  'independiente-neuquen-neuquen-provincia-de-neuquen': countries.ar,
  'independiente-petrolero-chuquisaca': countries.bo,
  'independiente-rivadavia-mendoza-provincia-de-mendoza': countries.ar,
  'independiente-s-cayetano-san-cayetano': countries.ar,
  'independiente-u20-sangolqui': countries.ec,
  indera: countries.bn,
  'indian-arrows-kalkata-kolkata-west-bengal': countries.in,
  'indiana-fire-u19-westfield-indiana': countries.us,
  'indija-indija': countries.rs,
  'indy-eleven-indianapolis-indiana': countries.us,
  'inegol-kafkas-genclik-bursa': countries.tr,
  'inegolspor-inegol': countries.tr,
  'ineu-ineu': countries.ro,
  'ingolstadt-ii-ingolstadt': countries.de,
  'ingolstadt-ingolstadt': countries.de,
  'ingolstadt-u19-ingolstadt': countries.de,
  'inhulets-petrove': countries.ua,
  'institute-drumahoe': countries.gb,
  'instituto-ciudad-de-cordoba-provincia-de-cordoba': countries.ar,
  'inter-bratislava-bratislava': countries.sk,
  'inter-club-d-escaldes-andorra-la-vella': countries.ad,
  'inter-de-bebedouro-bebedouro-sao-paulo': countries.br,
  'inter-de-limeira-limeira': countries.br,
  'inter-manacor-manacor': countries.es,
  'inter-miami': countries.us,
  'inter-miami-ii-miami-florida': countries.us,
  'inter-moengotapoe-moengo': countries.sr,
  'inter-playa-del-carmen-playa-del-carmen': countries.mx,
  'inter-santa-maria-santa-maria-rio-grande-do-sul': countries.br,
  'inter-star-bujumbura': countries.bi,
  'inter-turku-ii-turku-abo': countries.fi,
  'inter-turku-turku-abo': countries.fi,
  intercity: countries.es,
  'interclube-luanda': countries.ao,
  'internacional-porto-alegre-rio-grande-do-sul': countries.br,
  'internacional-sc-lages-santa-catarina': countries.br,
  'internacional-u20-porto-alegre-rio-grande-do-sul': countries.br,
  'internationals-u19-bedford-heights-ohio': countries.us,
  'internazionale-milano': countries.it,
  'internazionale-u19-milano': countries.it,
  'invergordon-invergordon': countries.gb,
  'inverness-athletic-inverness': countries.gb,
  'inverness-ct-inverness': countries.gb,
  'inverurie-loco-works-inverurie': countries.gb,
  'ionikos-nikea': countries.gr,
  'ipatinga-ipatinga-minas-gerais': countries.br,
  'ipora-ipora-goias': countries.br,
  'ipswich-town-ipswich-suffolk': countries.gb,
  'ipswich-town-u18-ipswich-suffolk': countries.gb,
  'ir-reykjavik': countries.is,
  'iraklis-1908-thessaloniki': countries.gr,
  'iraklis-larissa': countries.gr,
  'iraklis-yerolakkou-yerolakkou': countries.cy,
  'irodotos-nea-alikarnassos': countries.gr,
  'ironi-baka-el-garbiya-baqa-al-gharbiyye': countries.il,
  'ironi-kiryat-shmona-kiryat-shmona': countries.il,
  'ironi-nesher-nesher': countries.il,
  'ironi-tiberias-tiberias': countries.il,
  'irtysh-omsk': countries.ru,
  'is-selongey-selongey': countries.fr,
  'isa-town-isa-town': countries.bh,
  'isidro-metapan-metapan': countries.sv,
  'iskenderun-belediyespor-iskenderun': countries.tr,
  'iskra-danilovgrad': countries.me,
  'iskra-ribnita': countries.md,
  'isloch-minsk': countries.by,
  'ismaily-ismailia-ismailia': countries.eg,
  'ismaning-ismaning': countries.de,
  'isparta-davrazspor-isparta': countries.tr,
  'istanbul-basaksehir-istanbul': countries.tr,
  'istanbul-basaksehir-res-istanbul': countries.tr,
  'istanbul-basaksehir-u19-istanbul': countries.tr,
  'istanbul-beylikduzuspor-istanbul': countries.tr,
  'istanbul-sinopspor-istanbul': countries.tr,
  'istanbulspor-istanbul': countries.tr,
  'istanbulspor-u19-istanbul': countries.tr,
  'istiqlol-dushanbe': countries.tj,
  'istra-1961-pula': countries.hr,
  'istres-istres': countries.fr,
  'itabaiana-itabaiana-sergipe': countries.br,
  'itabuna-itabuna-bahia': countries.br,
  italien: countries.ch,
  'itapipoca-itapipoca-ceara': countries.br,
  'itapirense-itapira-sao-paulo': countries.br,
  'ittihad-al-ramtha': countries.jo,
  'ittihad-khemisset-al-khmissat-khemisset': countries.ma,
  'ittihad-tanger-tanger': countries.ma,
  'ituano-itu-sao-paulo': countries.br,
  'itumbiara-itumbiara-goias': countries.br,
  'ituzaingo-ituzaingo-provincia-de-buenos-aires': countries.ar,
  ivancsa: countries.hu,
  'ivatsevichi-ivatsevichi': countries.by,
  'ivry-ivry-sur-seine': countries.fr,
  iwaki: countries.jp,
  'izarra-estella-lizarra': countries.es,
  'iztapa-puerto-iztapa': countries.gt,
  'j-j-moreno-puerto-madryn-chubut': countries.ar,
  'jabal-al-mukaber-jerusalem': countries.ps,
  'jabala-jabala': countries.sy,
  'jablonec-ii-jablonec-nad-nisou': countries.cz,
  'jablonec-jablonec-nad-nisou': countries.cz,
  'jacksonville-u19-jacksonville-florida': countries.us,
  'jacobina-jacobina-bahia': countries.br,
  'jacuipense-riachao-do-jacuipe-bahia': countries.br,
  'jacyoba-pao-de-acucar-alagoas': countries.br,
  'jadran-porec-porec': countries.hr,
  'jagiellonia-bialystok-bialystok': countries.pl,
  'jagodina-jagodina': countries.rs,
  'jaguares-de-cordoba-monteria': countries.co,
  'jahn-regensburg-ii': countries.de,
  'jahn-regensburg-regensburg': countries.de,
  jalapa: countries.ni,
  'jamalco-clarendon': countries.jm,
  'jammerbugt-pandrup': countries.dk,
  'jamshedpur-jamshedpur': countries.in,
  'japs-jarvenpaa': countries.fi,
  'jaraaf-dakar': countries.sn,
  'jarabacoa-salcedo': countries.do,
  'jaragua-ec-jaragua-goias': countries.br,
  'jarfalla-jarfalla': countries.se,
  'jaro-pietarsaari-jakobstad': countries.fi,
  'jarota-jarocin-jarocin': countries.pl,
  'jarun-zagreb': countries.hr,
  'jarville-jarville-la-malgrange': countries.fr,
  'jastrzebie-jastrzebie-zdroj': countries.pl,
  'jaszberenyi-vasas-jaszbereny': countries.hu,
  'jataiense-jatai-goias': countries.br,
  'javor-ivanjica-ivanjica': countries.rs,
  'jazz-pori': countries.fi,
  'jbk-pietarsaari': countries.fi,
  'jd-somorrostro-muskiz': countries.es,
  'jdfs-alberts-riga-riga': countries.lv,
  'jdr-stars': countries.za,
  'jeanfield-swifts-perth': countries.gb,
  'jeanne-d-arc-le-port': countries.re,
  'jeddeloh-edewecht': countries.de,
  'jedinstvo-bihac-bihac': countries.ba,
  'jedinstvo-bijelo-polje': countries.me,
  'jedinstvo-ub-ub': countries.rs,
  'jednota-banova': countries.sk,
  'jef-united-chiba': countries.jp,
  'jeju-united-seogwipo-jeju-do': countries.kr,
  'jendouba-sport-jendouba': countries.tn,
  'jeonbuk-motors-jeonju': countries.kr,
  'jeonju-jeonju': countries.kr,
  'jeonnam-dragons-gwangyang': countries.kr,
  'jequie-jequie-bahia': countries.br,
  'jerba-jerba': countries.tn,
  jerudong: countries.bn,
  'jerv-grimstad': countries.no,
  'jette-jette': countries.be,
  'jeunesse-canach-kanech-canach': countries.lu,
  'jeunesse-d-esch-esch-uelzecht-esch-sur-alzette': countries.lu,
  'jeunesse-freylangeoise-arlon': countries.be,
  'jeunesse-junglinster-gonnereng-gonderange': countries.lu,
  'jeunesse-tamines-sambreville': countries.be,
  'jezero-plav': countries.me,
  'jiangxi-liansheng': countries.cn,
  'jicaral-sercoba': countries.cr,
  'jinju-citizen-jinju-si': countries.kr,
  'jippo-joensuu': countries.fi,
  'jiskra-domazlice-domazlice': countries.cz,
  'jiskra-rymarov-rymarov': countries.cz,
  'jiul-petrosani-petrosani': countries.ro,
  'jj-urquiza-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'jjk-jyvaskyla': countries.fi,
  jocoro: countries.sv,
  'jodan-boys-gouda': countries.nl,
  'jodoigne-jodoigne': countries.be,
  'johor-darul-ta-zim-ii-johor-bahru': countries.my,
  'johor-darul-ta-zim-pasir-gudang': countries.my,
  'joinville-joinville-santa-catarina': countries.br,
  'jonava-jonava': countries.lt,
  'jong-lede-lede': countries.be,
  'jonkopings-sodra-jonkoping': countries.se,
  'jonkopings-sodra-u21-jonkoping': countries.se,
  'jorge-newbery-cr-comodoro-rivadavia-provincia-del-chubut': countries.ar,
  'jorge-newbery-vm-villa-mercedes-provincia-de-san-luis': countries.ar,
  'jos-watergraafsmeer-amsterdam': countries.nl,
  'joseense-sao-jose-dos-campos-sao-paulo': countries.br,
  'jove-espanol-san-vicente-del-raspeig': countries.es,
  'jps-jyvaskyla': countries.fi,
  'js-hercules-oulu': countries.fi,
  'jsm-skikda-skikda': countries.dz,
  'juan-aurich-chiclayo': countries.pe,
  'juarez-ciudad-juarez-chihuahua': countries.mx,
  'juarez-u20': countries.mx,
  'juazeirense-juazeiro-bahia': countries.br,
  'juazeiro-ba-juazeiro-bahia': countries.br,
  'jubilo-iwata-iwata': countries.jp,
  'juliana-31-malden': countries.nl,
  'junak-sinj': countries.hr,
  'junior-barranquilla': countries.co,
  'junior-lone-star': countries.us,
  'junkeren-bodo': countries.no,
  'jura-dolois-dole': countries.fr,
  'jura-sud-foot-molinges': countries.fr,
  'juve-stabia-castellammare-di-stabia': countries.it,
  'juvenes-dogana-dogana': countries.sm,
  'juventud-alianza-santa-lucia-provincia-de-san-juan': countries.ar,
  'juventud-antoniana-ciudad-de-salta-provincia-de-salta': countries.ar,
  'juventud-las-piedras': countries.uy,
  'juventud-pergamino-pergamino-provincia-de-buenos-aires': countries.ar,
  'juventud-torremolinos-torremolinos': countries.es,
  'juventud-unida-g-gualeguaychu-provincia-de-entre-rios': countries.ar,
  'juventud-unida-san-miguel-provincia-de-buenos-aires': countries.ar,
  'juventud-unida-univ-san-luis-provincia-de-san-luis': countries.ar,
  'juventude-caxias-do-sul-rio-grande-do-sul': countries.br,
  'juventude-evora-evora': countries.pt,
  'juventude-ma-caxais-ma': countries.br,
  'juventus-ii-torino': countries.it,
  'juventus-managua-managua': countries.ni,
  'juventus-sao-paulo-sao-paulo': countries.br,
  'juventus-sc-jaragua-do-sul-santa-catarina': countries.br,
  'juventus-torino': countries.it,
  'juventus-u19-torino': countries.it,
  'k-jarve-jk-jarve': countries.ee,
  'k-jarve-jk-jarve-ii': countries.ee,
  'ka-akureyri': countries.is,
  'ka-hafnarfjordur': countries.is,
  'kaa-gent-ii-gent': countries.be,
  'kaan-marienborn-siegen': countries.de,
  'kaapo-kaarina': countries.fi,
  'kabel-novi-sad-novi-sad': countries.rs,
  'kabwe-warriors-kabwe': countries.zm,
  'kabylie-tizi-ouzou': countries.dz,
  'kafr-qasim-kafr-qasim': countries.il,
  'kagoshima-united-kagoshima': countries.jp,
  'kahramanmaras-istiklalsp-kahramanmaras': countries.tr,
  'kahramanmarasspor-kahramanmaras': countries.tr,
  'kahta-02-spor': countries.tr,
  'kairat-almati-almaty': countries.kz,
  'kairouan-kairouan': countries.tn,
  'kaisar-kyzylorda': countries.kz,
  'kaiserslautern-ii-kaiserslautern': countries.de,
  'kaiserslautern-kaiserslautern': countries.de,
  'kaizer-chiefs-johannesburg-ga': countries.za,
  'kajha-kajaani': countries.fi,
  'kalaa-sport': countries.tn,
  'kalamata-kalamata': countries.gr,
  'kale-belediyespor-denizli': countries.tr,
  'kalisz-kalisz': countries.pl,
  kalkara: countries.mt,
  'kallithea-athens': countries.gr,
  'kallon-freetown': countries.sl,
  'kalmar-kalmar': countries.se,
  'kalna-nad-hronom-kalna-nad-hronom': countries.sk,
  'kaloum-star-conakry': countries.gn,
  'kalsdorf-kalsdorf-bei-graz': countries.at,
  'kalteng-putra-palangkaraya': countries.id,
  'kaluga-kaluga': countries.ru,
  'kamatamare-sanuki-takamatsu': countries.jp,
  'kamaz-naberezhnye-chelny': countries.ru,
  kanchanaburi: countries.th,
  'kano-pillars-kano': countries.ng,
  'kapa-helsinki': countries.fi,
  'kapadokya-goremespor-nevsehir': countries.tr,
  'kapaz-g-nc': countries.az,
  'kapfenberger-sv-kapfenberg': countries.at,
  'kaposvari-rakoczi-kaposvar': countries.hu,
  'kara-kara': countries.tg,
  'karabuk-idman-yurdu-karabuk': countries.tr,
  'karacabey-belediyespor-bursa': countries.tr,
  'karadeniz-eregli-bsk-eregli': countries.tr,
  'karakopru-belediyespor-sanliurfa': countries.tr,
  'karaman-belediyespor-karaman': countries.tr,
  'karasamsunspor-samsun': countries.tr,
  'karbala-karbala': countries.iq,
  'kardemir-karabukspor-karabuk': countries.tr,
  kari: countries.is,
  kariya: countries.jp,
  'karlberg-stockholm': countries.se,
  'karlovac-1919-karlovac': countries.hr,
  'karlovy-vary-karlovy-vary': countries.cz,
  'karlskrona-karlskrona': countries.se,
  'karlslund-orebro': countries.se,
  'karlslunde-karlslunde': countries.dk,
  'karlsruher-sc-karlsruhe': countries.de,
  'karlsruher-sc-u17-karlsruhe': countries.de,
  'karlsruher-sc-u19-karlsruhe': countries.de,
  'karmiotissa-pano-polemidia': countries.cy,
  'karpaty-l-viv': countries.ua,
  'kars-36-kars': countries.tr,
  'karsiyaka-izmir': countries.tr,
  'kartal-bulvarspor-istanbul': countries.tr,
  'kartalspor-istanbul': countries.tr,
  'karvina-karvina-hranice': countries.cz,
  'kasem-bundit-university-bangkok': countries.th,
  'kasetsart-bangkok': countries.th,
  'kashima-antlers-kashima-ibaraki': countries.jp,
  'kashiwa-reysol-kashiwa-chiba': countries.jp,
  'kasimpasa-istanbul': countries.tr,
  'kasimpasa-res-istanbul': countries.tr,
  'kasimpasa-u19-istanbul': countries.tr,
  'kaspiy-aqtau-aktau': countries.kz,
  'kastamonu-ozel-idare-khs-kastamonu': countries.tr,
  'kastamonuspor-1966-kastamonu': countries.tr,
  'kastrioti-kruje-kruje': countries.al,
  kasuka: countries.bn,
  'kataller-toyama-toyama': countries.jp,
  'katsina-united-katsina': countries.ng,
  'katwijk-katwijk': countries.nl,
  'kauno-zalgiris-kaunas': countries.lt,
  'kavacikspor-istanbul': countries.tr,
  'kavakliderespor-ankara': countries.tr,
  'kavaklispor-kavakli': countries.tr,
  'kavala-kavala': countries.gr,
  'kawasaki-frontale-kawasaki': countries.jp,
  'kaya-iloilo': countries.ph,
  'kayserispor-kayseri': countries.tr,
  'kayserispor-u19-kayseri': countries.tr,
  'kazimpasa-birlikspor-kazimpasa-serdivan-sakarya': countries.tr,
  'kazincbarcika-kazincbarcika': countries.hu,
  'kazma-madinat-al-kuwayt-kuwait-city': countries.kw,
  kb: countries.is,
  'kcca-kampala': countries.ug,
  'keciborlu-belediyespor-keciborlu': countries.tr,
  'keciorengucu-ankara': countries.tr,
  'kecskemeti-te-kecskemet': countries.hu,
  'kedah-alor-setar': countries.my,
  'kedus-giorgis-addis-ababa': countries.et,
  'keflavik-keflavik': countries.is,
  'kehlen-kielen-kehlen': countries.lu,
  'keila-keila': countries.ee,
  'keith-keith': countries.gb,
  'kelantan-kota-bharu': countries.my,
  kelen: countries.hu,
  'kelkit-belediye-hurriyet-gumushane': countries.tr,
  'kelty-hearts-kelty': countries.gb,
  'kemerspor-2003-tekirova': countries.tr,
  'kenkre-mumbai-mumbai-mahara-ra': countries.in,
  'kepez-belediyespor-antalya': countries.tr,
  'kerala-blasters-kochi': countries.in,
  'kercem-ajax': countries.mt,
  'kesl-baki-baku': countries.az,
  kestelspor: countries.tr,
  'kettering-town-kettering-northamptonshire': countries.gb,
  'kf-gostivari-gostivar': countries.mk,
  'kf-siglufjordur-fjallabyggd': countries.is,
  'kfg-gardabaer': countries.is,
  kfs: countries.is,
  'kfum-oslo': countries.no,
  'kfum-roskilde-roskilde': countries.dk,
  kh: countries.is,
  'khaitan-madinat-al-kuwayt-kuwait-city': countries.kw,
  khalidiya: countries.bh,
  'khangarid-erdenet': countries.mn,
  'khatlon-bokhtar': countries.tj,
  khenchela: countries.dz,
  'kheybar-khorramabad-khorramabad': countries.ir,
  'khimik-avgust-vurnary': countries.ru,
  'khimik-dzerzhinsk-dzerzhinsk': countries.ru,
  'khimki-ii-khimki': countries.ru,
  'khimki-khimki': countries.ru,
  'khon-kaen-united-khon-kaen': countries.th,
  'khonkaen-khon-kaen': countries.th,
  'khonkaen-mordindang-khon-kaen': countries.th,
  'khoromkhon-ulan-bator-ulaanbaatar': countries.mn,
  'khouribga-khouribga': countries.ma,
  'khujand-khujand': countries.tj,
  'khust-city-khust': countries.ua,
  'ki-klaksvik': countries.fo,
  'kickers-emden-emden': countries.de,
  'kickers-offenbach-offenbach': countries.de,
  'kidderminster-harriers-kidderminster-worcestershire': countries.gb,
  'kids-tampa-brasov-brasov': countries.ro,
  'kidsgrove-athletic-kidsgrove-staffordshire': countries.gb,
  kiffen: countries.fi,
  kifisia: countries.gr,
  'kiisto-vaasa': countries.fi,
  'kilb-kilb': countries.at,
  'kilis-belediyespor-kilis': countries.tr,
  'kilmarnock-kilmarnock': countries.gb,
  'kimberley-mar-del-plata-mar-del-plata-provincia-de-buenos-aires':
    countries.ar,
  'king-faisal-kumasi': countries.gh,
  'king-s-lynn-town-king-s-lynn-norfolk': countries.gb,
  'kings-langley': countries.gb,
  kingston: countries.au,
  'kingston-stockade-kingston-new-york': countries.us,
  'kingstonian-kingston-upon-thames-surrey': countries.gb,
  'kirchanschoring-kirchanschoring': countries.de,
  'kirikkale-buyuk-anadolu-kirikkale': countries.tr,
  'kirivong-sok-sen-chey': countries.kh,
  'kirklarelispor-kirklareli': countries.tr,
  'kirsehir-belediyespor-kirsehir': countries.tr,
  'kiruna-ff-kiruna': countries.se,
  'kissamikos-kissamos': countries.gr,
  'kitchee-hong-kong': countries.hk,
  'kitzbuhel-kitzbuhel': countries.at,
  'kiyovu-sports-kigali': countries.rw,
  'kizilcabolukspor-kizilcaboluk': countries.tr,
  'kjellerup-kjellerup': countries.dk,
  'kjelsas-oslo': countries.no,
  'kjp-kouvola': countries.fi,
  'kladno-kladno': countries.cz,
  'klatovy-klatovy': countries.cz,
  'kletsk-kletsk': countries.by,
  'kleve-kleve': countries.de,
  'kloetinge-kloetinge': countries.nl,
  'klubi-04-helsinki': countries.fi,
  'kluczbork-kluczbork': countries.pl,
  'knockbreda-belfast': countries.gb,
  'knokke-knokke-heist': countries.be,
  'kobenhavn-kobenhavn': countries.dk,
  'kobenhavn-u19': countries.dk,
  'koblenz-koblenz': countries.de,
  'kobrin-kobrin': countries.by,
  'kocaelispor-izmet': countries.tr,
  'kochi-united-kochi': countries.jp,
  'koeppchen-rued-sir-roodt-sur-syre': countries.lu,
  'kokand-1912-qo-qon-kokand': countries.uz,
  'kolding-if-kolding': countries.dk,
  'kolin-kolin': countries.cz,
  'kolkheti-khobi-ii-khobi': countries.ge,
  'kolkheti-khobi-khobi': countries.ge,
  'kolkheti-poti-poti': countries.ge,
  'koln-ii-koln': countries.de,
  'koln-koln': countries.de,
  'koln-u19-koln': countries.de,
  'kolomna-kolomna': countries.ru,
  'kolos-kovalivka-kovalivka': countries.ua,
  'kolubara-lazarevac': countries.rs,
  'kom-podgorica': countries.me,
  'komarno-komarno': countries.sk,
  'komarom-komarom': countries.hu,
  'kongsvinger-kongsvinger': countries.no,
  'koninklijke-hfc-haarlem': countries.nl,
  'koniz-liebefeld': countries.ch,
  'kontu-helsinki': countries.fi,
  'konya-ereglispor-konya': countries.tr,
  'konyaspor-konya': countries.tr,
  'koper-koper': countries.si,
  'kopetdag-asgabat-asgabat': countries.tm,
  'koprivnica-koprivnica': countries.hr,
  'korabi-peshkopi-peshkopi': countries.al,
  'korba-korba': countries.tn,
  kordrengir: countries.is,
  'korkuteli-belediyespor-korkuteli-antalya': countries.tr,
  'korona-kielce-kielce': countries.pl,
  korosladany: countries.hu,
  korsnas: countries.se,
  'kortrijk-kortrijk': countries.be,
  'kosmos-dolgoprudny-dolgoprudny': countries.ru,
  'kosova-zurich': countries.ch,
  'kossa-honiara': countries.sb,
  'kottingbrunn-kottingbrunn': countries.at,
  'kottmannsdorf-kottmannsdorf': countries.at,
  'kotwica-kolobrzeg-kolobrzeg': countries.pl,
  'kozakken-boys-werkendam': countries.nl,
  'kozani-kozani': countries.gr,
  'kozara-gradiska-gradiska': countries.ba,
  'kozarmisleny-kozarmisleny': countries.hu,
  'kozlu-belediyespor-kozlu': countries.tr,
  'kozuv-gevgelija-gevgelija': countries.mk,
  'kpv-kokkola': countries.fi,
  'kr-reykjavik': countries.is,
  'krabi-krabi': countries.th,
  'kraft-bole': countries.fi,
  'kraluv-dvur-kraluv-dvur': countries.cz,
  'krasnodar-ii-krasnodar': countries.ru,
  'krasnodar-krasnodar': countries.ru,
  'kray-essen': countries.de,
  'krc-genk-ii-genk': countries.be,
  'krc-gent-oostakker': countries.be,
  'kremin-kremenchuk': countries.ua,
  krems: countries.at,
  kria: countries.is,
  'kriens-kriens': countries.ch,
  'kristianstad-kristianstad': countries.se,
  'kristiansund-kristiansund': countries.no,
  'krizevci-krizevci': countries.hr,
  'krk-krk': countries.hr,
  'krka-krka': countries.si,
  'krl-rawalpindi': countries.pk,
  kronon: countries.by,
  'krsko-krsko': countries.si,
  'krumkachy-minsk': countries.by,
  'krupa-na-vrbasu-krupa-na-vrbasu': countries.ba,
  'krylya-sovetov-samara': countries.ru,
  'ksk-tambun-tulang': countries.my,
  'ksr-sains': countries.my,
  'kszo-ostrowiec-swietokrzyski': countries.pl,
  'ktp-kotka': countries.fi,
  'kuala-lumpur-kuala-lumpur': countries.my,
  'kuala-lumpur-rovers': countries.my,
  'kuban-kholding': countries.ru,
  'kuching-fa-kuching': countries.my,
  'kuchl-kuchl': countries.at,
  'kufstein-kufstein': countries.at,
  'kufu-98-kuopio': countries.fi,
  'kukesi-kukes': countries.al,
  'kultsu-juotseno': countries.fi,
  kumla: countries.se,
  'kumluca-belediyespor-kumluca': countries.tr,
  'kundl-kundl': countries.at,
  kunshan: countries.cn,
  'kups-akatemia': countries.fi,
  'kups-kuopio': countries.fi,
  'kuressaare-kuressaare': countries.ee,
  'kurilovec-kurilovec': countries.hr,
  'kurucesmespor-istanbul': countries.tr,
  'kusadasispor-kusadasi': countries.tr,
  'kustepespor-kustepe': countries.tr,
  'kustosija-zagreb': countries.hr,
  'kutahyaspor-kutahya': countries.tr,
  'kv-oostende-oostende': countries.be,
  'kv-reykjavik': countries.is,
  'kvant-obninsk': countries.ru,
  'kvarnsveden-borlange': countries.se,
  'kvik-halden-halden': countries.no,
  'kwai-tsing-hong-kong': countries.hk,
  'kwara-united-ilorin': countries.ng,
  'kyoto-sanga-kyoto-kyoto': countries.jp,
  'kyran-simkent-shymkent': countries.kz,
  'kyzyl-zhar-petropavlovsk': countries.kz,
  'l-entregu-el-entrego': countries.es,
  'l-hospitalet-l-hospitalet-de-llobregat': countries.es,
  'la-almunia-la-almunia-de-dona-godina': countries.es,
  'la-amistad-cipolletti': countries.ar,
  'la-baneza-la-baneza': countries.es,
  'la-calamine-kelmis-la-calamine': countries.be,
  'la-chaux-de-fonds-la-chaux-de-fonds': countries.ch,
  'la-cuadra-fuerteventura': countries.es,
  'la-emilia-la-emilia-provincia-de-buenos-aires': countries.ar,
  'la-equidad-bogota-d-c': countries.co,
  'la-fama-savaneta': countries.aw,
  'la-familia': countries.md,
  'la-fiorita-montegiardino': countries.sm,
  'la-force': countries.us,
  'la-galaxy-ii-carson-california': countries.us,
  'la-galaxy-los-angeles-california': countries.us,
  'la-linguere-saint-louis': countries.sn,
  'la-louviere-centre-la-louviere': countries.be,
  'la-louviere-la-louviere': countries.be,
  'la-luz-montevideo': countries.uy,
  'la-madalena-de-morcin-morcin': countries.es,
  'la-massana': countries.ad,
  'la-nucia-la-nucia': countries.es,
  'la-palma-la-palma-del-condado': countries.es,
  'la-roche-sur-yon-la-roche-sur-yon': countries.fr,
  'la-roche-vf-la-roche-sur-yon': countries.fr,
  'la-sarraz-eclepens': countries.ch,
  'la-serena-la-serena': countries.cl,
  'la-tour-le-paquier-la-tour-de-treme': countries.ch,
  'la-united-fa-u19-los-angeles-california': countries.us,
  'laanemaa-haapsalu': countries.ee,
  'laberia-vlore': countries.al,
  'laci-lac': countries.al,
  'lafnitz-lafnitz': countries.at,
  'lagarto-lagarto-sergipe': countries.br,
  'lagos-lagos': countries.pt,
  lagow: countries.pl,
  'lagun-onak-azpeitia': countries.es,
  'lahti-lahti': countries.fi,
  'lajeadense-lajeado-rio-grande-do-sul': countries.br,
  'laktasi-laktasi': countries.ba,
  'lambresienne-lambres-lez-douai': countries.fr,
  lamia: countries.gr,
  'lampang-lampang': countries.th,
  'lamphun-warrior-lamphun': countries.th,
  'lancaster-city-lancaster-lancashire': countries.gb,
  'lancy-petit-lancy': countries.ch,
  'landskrona-landskrona': countries.se,
  'landvetter-is': countries.se,
  'langenrohr-langenrohr': countries.at,
  'langreo-la-felguera-langreo': countries.es,
  'lannion-lannion': countries.fr,
  'lanus-lanus-provincia-de-buenos-aires': countries.ar,
  'lanzarote-arrecife-lanzarote': countries.es,
  'lao-army': countries.la,
  'laracha-a-laracha': countries.es,
  'laredo-heat-laredo-texas': countries.us,
  'laredo-laredo': countries.es,
  'largs-thistle-largs': countries.gb,
  'larissa-larissa': countries.gr,
  'larkhall-athletic': countries.gb,
  'larne-larne': countries.gb,
  'las-palmas-ciudad-de-cordoba-provincia-de-cordoba': countries.ar,
  'las-palmas-ii-las-palmas-de-gran-canaria': countries.es,
  'las-palmas-las-palmas-de-gran-canaria': countries.es,
  'las-rozas': countries.es,
  'las-vegas-legends-fc-las-vegas-nevada': countries.us,
  'las-vegas-lights-las-vegas-nevada': countries.us,
  'lask-juniors-linz': countries.at,
  'lask-linz': countries.at,
  'latina-latina': countries.it,
  'laudio-llodio-laudio': countries.es,
  'lausanne-sport-lausanne': countries.ch,
  'lautaro-de-buin': countries.cl,
  lauterach: countries.at,
  'lautoka-lautoka': countries.fj,
  'laval-ii-laval': countries.fr,
  'laval-laval': countries.fr,
  'lavello-lavello': countries.it,
  'lazio-formello': countries.it,
  'lazio-u19': countries.it,
  'lcs-maseru': countries.ls,
  'ld-maputo-maputo': countries.mz,
  ldf: countries.ls,
  'ldu-portoviejo-portoviejo': countries.ec,
  'ldu-quito-quito': countries.ec,
  'le-geldar-kourou': countries.gf,
  'le-havre-harfleur': countries.fr,
  'le-havre-ii-harfleur': countries.fr,
  'le-mans-ii-le-mans': countries.fr,
  'le-mans-le-mans': countries.fr,
  'le-mee': countries.fr,
  'le-messager-ngozi-buye-ngozi': countries.bi,
  'le-poire-sur-vie-le-poire-sur-vie': countries.fr,
  'le-puy-foot-ii': countries.fr,
  'le-puy-foot-le-puy-en-velay': countries.fr,
  'lealtad-villaviciosa-asturias': countries.es,
  'leamington-royal-leamington-spa-warwickshire': countries.gb,
  'leandro-niceforo-alem-general-rodriguez-provincia-de-buenos-aires':
    countries.ar,
  'leatherhead-leatherhead-surrey': countries.gb,
  'lebrijana-lebrija': countries.es,
  lebring: countries.at,
  'leca-leca-de-palmeira': countries.pt,
  'lecce-lecce': countries.it,
  'lecce-u19-lecce': countries.it,
  'lecco-lecco': countries.it,
  'lech-poznan-ii-poznan': countries.pl,
  'lech-poznan-poznan': countries.pl,
  'lech-poznan-u19-poznan': countries.pl,
  'lechia-gdansk-gdansk': countries.pl,
  'lechia-t-mazowiecki-tomaszow-mazowiecki': countries.pl,
  'leeds-united-leeds-west-yorkshire': countries.gb,
  'leeds-united-u18': countries.gb,
  'leeds-united-u21-leeds': countries.gb,
  'leeds-united-u23-leeds': countries.gb,
  'leek-town-leek-staffordshire': countries.gb,
  'lefkimmi-lefkimmi': countries.gr,
  'leganes-ii-leganes': countries.es,
  'leganes-leganes': countries.es,
  'lege-cap-ferret-lege-cap-ferret': countries.fr,
  'legia-warszawa-ii-warszawa': countries.pl,
  'legia-warszawa-u19-warszawa': countries.pl,
  'legia-warszawa-u23-warszawa': countries.pl,
  'legia-warszawa-warszawa': countries.pl,
  'legion-dynamo-machackala-makhachkala': countries.ru,
  'legion-tallinn': countries.ee,
  'legionovia-legionowo-legionowo': countries.pl,
  'legnano-legnano': countries.it,
  'leher-ts': countries.de,
  'lehigh-valley-utd-sonic': countries.us,
  'leicester-city-leicester': countries.gb,
  'leicester-city-u18': countries.gb,
  'leicester-city-u21-leicester': countries.gb,
  'leicester-city-u23-leicester': countries.gb,
  'leiknir-reykjavik-reykjavik': countries.is,
  'leioa-leioa': countries.es,
  'leiston-leiston-suffolk': countries.gb,
  'leixoes-matosinhos': countries.pt,
  'lendorf-lendorf': countries.at,
  'leningradets-roshchino-leningrad-oblast': countries.ru,
  'lens-avion': countries.fr,
  'lens-ii-avion': countries.fr,
  'lentigione-lentigione': countries.it,
  'leo-victor': countries.sr,
  'leoben-leoben': countries.at,
  leobendorf: countries.at,
  'leon-leon-de-los-aldamas': countries.mx,
  'leones-de-america-san-miguelito': countries.pa,
  'leones-medellin': countries.co,
  'leopards-de-dolisie-dolisie': countries.cg,
  'leopold-uccle-ukkel': countries.be,
  'leotar-trebinje': countries.ba,
  'lepel-lyepyel-lepel': countries.by,
  'lernayin-artsakh': countries.am,
  'les-astres-douala': countries.cm,
  'les-herbiers-ii-les-herbiers': countries.fr,
  'les-herbiers-les-herbiers': countries.fr,
  'les-mureaux': countries.fr,
  'les-ulis-paris': countries.fr,
  'leskhoz-homel-gomel': countries.by,
  'letohrad-letohrad': countries.cz,
  lettir: countries.is,
  'levadia-u19-tallinn': countries.ee,
  'levadiakos-levadia': countries.gr,
  'levanger-levanger': countries.no,
  'levante-ii-valencia': countries.es,
  'levante-valencia': countries.es,
  'levico-levico-terme': countries.it,
  'levski-karlovo-karlovo': countries.bg,
  'levski-krumovgrad-krumovgrad': countries.bg,
  'levski-rakovski-sofia': countries.bg,
  'levski-sofia-sofia': countries.bg,
  'lewes-lewes-east-sussex': countries.gb,
  'leyton-orient-london': countries.gb,
  'leyton-orient-u18': countries.gb,
  'libertad-ambato': countries.ec,
  'libertad-asuncion': countries.py,
  'libertad-sunchales-provincia-de-santa-fe': countries.ar,
  'libertador-caracas': countries.ve,
  'libertas-borgo-maggiore': countries.sm,
  'libourne-libourne': countries.fr,
  'lichtenberg-berlin': countries.de,
  'lida-lida': countries.by,
  'lidingo-lidingo': countries.se,
  'lidkoping-lidkoping': countries.se,
  'liefering-salzburg': countries.at,
  'liege-rocourt': countries.be,
  'liepaja-liepaja': countries.lv,
  'lierse-kempenzonen-lier': countries.be,
  'lija-athletic-lija': countries.mt,
  'lille-ii-camphin-en-pevele': countries.fr,
  'lille-lille': countries.be,
  'lille-u19-lille': countries.fr,
  'lille-villeneuve-d-ascq': countries.fr,
  'lillehammer-lillehammer': countries.no,
  'lillestrom-kjeller': countries.no,
  'limavady-united-limavady': countries.gb,
  'limon-limon': countries.cr,
  limonest: countries.fr,
  linare: countries.ls,
  'linares-deportivo-linares': countries.es,
  'linas-montlhery': countries.fr,
  'lincoln-city-lincoln-lincolnshire': countries.gb,
  'lincoln-city-u18': countries.gb,
  'lincoln-red-imps-gibraltar': countries.gi,
  'lincoln-united-lincoln-lincolnshire': countries.gb,
  'lindome-lindome': countries.se,
  'linense-la-linea-de-la-concepcion': countries.es,
  'linense-lins-sao-paulo': countries.br,
  'linfield-belfast': countries.gb,
  'liniers-bahia-blanca-bahia-blanca-provincia-de-buenos-aires': countries.ar,
  'liniers-la-matanza-provincia-de-buenos-aires': countries.ar,
  'linlithgow-rose-linlithgow': countries.gb,
  linth: countries.ch,
  'lioli-teyateyaneng': countries.ls,
  'lipany-lipany': countries.sk,
  'lippstadt-08-lippstadt': countries.de,
  'liptovsky-mikulas-liptovsky-mikulas': countries.sk,
  'lisburn-distillery-lambeg-lisburn': countries.gb,
  'liscr-monrovia': countries.lr,
  'lisen-brno': countries.cz,
  'lisse-lisse': countries.nl,
  livadija: countries.by,
  'liverpool-liverpool': countries.gb,
  'liverpool-montevideo': countries.uy,
  'liverpool-u18': countries.gb,
  'liverpool-u19-liverpool': countries.gb,
  'liverpool-u21': countries.gb,
  'liverpool-u23-liverpool': countries.gb,
  liversedge: countries.gb,
  'livingston-livingston': countries.gb,
  'livingston-res-livingston': countries.gb,
  'livorno-livorno': countries.it,
  'ljungskile-ljungskile': countries.se,
  'ljutomer-ljutomer': countries.si,
  'lks-lodz-lodz': countries.pl,
  'llandudno-llandudno-conwy-county-borough': countries.gb,
  'llanelli-town-llanelli': countries.gb,
  'llanera-posada-de-llanera': countries.es,
  'llaneros-de-guanare-guanare': countries.ve,
  'llaneros-villavicencio': countries.co,
  'llanes-llanes': countries.es,
  'llapi-podujeve': countries.xk,
  'lleida-esportiu-lleida': countries.es,
  'llerenense-llerena': countries.es,
  llosetense: countries.es,
  'lnz-cherkasy-cherkasy': countries.ua,
  'lobi-stars-makurdi': countries.ng,
  'lochee-united': countries.gb,
  'logan-lightning-brisbane': countries.au,
  'logrones-ii-logrono': countries.es,
  'loja-loja': countries.es,
  'lok-stendal': countries.de,
  'lokeren-temse-temse': countries.be,
  'loko-vltavin-praha': countries.cz,
  'lokomotiv-g-oryahovitsa-gorna-oryahovitsa': countries.bg,
  'lokomotiv-gomel-homel': countries.by,
  'lokomotiv-mezdra-mezdra': countries.bg,
  'lokomotiv-moskva-moskva': countries.ru,
  'lokomotiv-plovdiv-plovdiv': countries.bg,
  'lokomotiv-sofia-1929-sofia': countries.bg,
  'lokomotiv-toshkent-tashkent': countries.uz,
  'lokomotiva-zagreb-u19-zagreb': countries.hr,
  'lokomotiva-zagreb-zagreb': countries.hr,
  'lokomotive-leipzig-leipzig': countries.de,
  'lokomotivi-tbilisi-ii-tbilisi': countries.ge,
  'lokomotivi-tbilisi-tbilisi': countries.ge,
  'lommel-lommel': countries.be,
  'londerzeel-londerzeel': countries.be,
  'londrina-londrina-parana': countries.br,
  'long-an-tan-an-tan-an': countries.vn,
  'long-island-rough-riders-south-huntington-new-york': countries.us,
  'longa-30-lichtenvoorde': countries.nl,
  'longford-town-longford-town': countries.ie,
  'longlier-longlier': countries.be,
  'longueau-longueau': countries.fr,
  'lorca-deportiva-lorca': countries.es,
  'lorca-lorca': countries.es,
  'lorenskog-lorenskog': countries.no,
  'lorient-ii-lorient': countries.fr,
  'lorient-ploemeur': countries.fr,
  'lorraine-arlon-arlon': countries.be,
  'los-andes-lomas-de-zamora-provincia-de-buenos-aires': countries.ar,
  'los-angeles-fc-los-angeles-california': countries.us,
  'los-angeles-u19-los-angeles-california': countries.us,
  'lossi-noumea': countries.nc,
  'lossiemouth-lossiemouth': countries.gb,
  'lota-schwager-coronel': countries.cl,
  'lothian-hutchison-edinburgh': countries.gb,
  'loudoun-united-leesburg-virginia': countries.us,
  'loughgall-loughgall': countries.gb,
  'louhans-cuiseaux-louhans': countries.fr,
  'louisville-city-louisville-kentucky': countries.us,
  'lourdes-tudela': countries.es,
  'loures-loures': countries.pt,
  'lovech-lovech': countries.bg,
  'lowestoft-town-lowestoft-suffolk': countries.gb,
  'loznica-loznica': countries.rs,
  'lprc-oilers-monrovia': countries.lr,
  'lps-helsinki': countries.fi,
  lsf: countries.dk,
  'lsk-hansa-luneburg': countries.de,
  'luarca-luarca': countries.es,
  'lubeck-ii-lubeck': countries.de,
  'lubeck-lubeck': countries.de,
  'lucchese-lucca': countries.it,
  'luckenwalde-luckenwalde': countries.de,
  'lucko-lucko': countries.hr,
  'ludogorets-ii-razgrad': countries.bg,
  'ludogorets-razgrad': countries.bg,
  'ludogorets-u19-razgrad': countries.bg,
  'lugano-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'lugano-lugano': countries.ch,
  'lugo-lugo': countries.es,
  'lujan-lujan-provincia-de-buenos-aires': countries.ar,
  'luki-energiya-velikiye-luki': countries.ru,
  'lumezzane-lumezzane': countries.it,
  'lund-lund': countries.se,
  'luninets-luninets': countries.by,
  'lupa-roma-roma': countries.it,
  'lupo-martini-wolfsburg': countries.de,
  'luqa-st-andrew-s-luqa': countries.mt,
  'lusail-city-lusail-city': countries.qa,
  'lushnja-lushnje': countries.al,
  'lusitania-lourosa-lourosa': countries.pt,
  'lusitano-evora-1911-evora': countries.pt,
  'lustenau-lustenau': countries.at,
  'luton-town-luton-bedfordshire': countries.gb,
  'luton-town-u18': countries.gb,
  'luverdense-lucas-do-rio-verde-mato-grosso': countries.br,
  'luzern-luzern': countries.ch,
  'luzi-2008-kavaje': countries.al,
  'luziania-luziania-goias': countries.br,
  'lviv-l-viv': countries.ua,
  'lyn-oslo': countries.no,
  'lyngby-lyngby-kongens-lyngby': countries.dk,
  'lyon-duchere-ii-lyon': countries.fr,
  'lyon-duchere-lyon': countries.fr,
  'lyra-lierse-berlaar': countries.be,
  lysekloster: countries.no,
  'm-saken-m-saken': countries.tn,
  'maardu-maardu': countries.ee,
  'macapa-macapa-ap': countries.br,
  'macara-ambato': countries.ec,
  'maccabi-ahi-nazareth-nazareth': countries.il,
  'maccabi-ashdod-asdod-ashdod': countries.il,
  'maccabi-be-er-sheva-be-er-sheva-beer-sheva': countries.il,
  'maccabi-bnei-raina-reine': countries.il,
  'maccabi-haifa-hefa-haifa': countries.il,
  'maccabi-haifa-u19': countries.il,
  'maccabi-herzliya-herzliya': countries.il,
  'maccabi-ironi-sderot-sderot': countries.il,
  'maccabi-kabilio-jaffa-tel-aviv-yafa-tel-aviv': countries.il,
  'maccabi-kiryat-malachi': countries.il,
  'maccabi-netanya-netanya': countries.il,
  'maccabi-petah-tikva-petah-tiqva-petach-tikva': countries.il,
  'maccabi-petah-tikva-u19-petah-tikva': countries.il,
  'maccabi-sha-arayim-sha-arayim': countries.il,
  'maccabi-tel-aviv-tel-aviv-yafa-tel-aviv': countries.il,
  'maccabi-tel-aviv-u19': countries.il,
  'maccabi-yavne-yavne': countries.il,
  'machhindra-kathmandu': countries.np,
  'machico-machico': countries.pt,
  'machida-zelvia-tokyo': countries.jp,
  'macon-macon': countries.fr,
  'macva-sabac-sabac': countries.rs,
  'madrid-2021-fp-madrid': countries.es,
  madridejos: countries.es,
  'madura-united-bandung': countries.id,
  'madureira-rio-de-janeiro-rio-de-janeiro': countries.br,
  'mafra-mafra': countries.pt,
  'magallanes-santiago-de-chile': countries.cl,
  'magdeburg-magdeburg': countries.de,
  'magdeburg-u17-magdeburg': countries.de,
  'magdeburg-u19': countries.de,
  'magenta-noumea': countries.nc,
  'magesi-limpopo': countries.za,
  'maghreb-fes-fes': countries.ma,
  'magni-grenivik': countries.is,
  magpies: countries.gi,
  'magpies-crusaders-mackay': countries.au,
  'mahasarakham-united-mahasarakham': countries.th,
  'maidenhead-united-maidenhead-berkshire': countries.gb,
  'maidstone-united-maidstone-kent': countries.gb,
  'mainz-05-ii-mainz': countries.de,
  'mainz-05-mainz': countries.de,
  'mainz-05-u17-mainz': countries.de,
  'mainz-05-u19-mainz': countries.de,
  'maitland-maitland': countries.au,
  'makedonija-gjp-shishevo': countries.mk,
  makhachkala: countries.ru,
  'mako-mako': countries.hu,
  'malacateco-malacatan': countries.gt,
  'malaga-city-velez-malaga': countries.es,
  'malaga-ii-malaga': countries.es,
  'malaga-malaga': countries.es,
  'malaga-u19-malaga': countries.es,
  'malatya-idman-yurdu-malatya': countries.tr,
  'malavan-bandar-e-anzali': countries.ir,
  'maldon-tiptree-maldon': countries.gb,
  'maliqi-maliq': countries.al,
  'malisheva-malisheva-malisevo': countries.xk,
  'malkiya-malkiya': countries.bh,
  'mallorca-ii-palma-de-mallorca': countries.es,
  'mallorca-palma-de-mallorca': countries.es,
  'malmo-ff-malmo': countries.se,
  'malmo-ff-u19-malmo': countries.se,
  'malmundaria-malmedy': countries.be,
  'malzenice-malzenice': countries.sk,
  'mamelodi-sundowns-midrand-ga': countries.za,
  'mamer-mamer': countries.lu,
  'manacor-manacor': countries.es,
  'managua-managua': countries.ni,
  'manama-al-manama-manama': countries.bh,
  'manang-marshyangdi-kathmandu': countries.np,
  'manaus-manaus-amazonas': countries.br,
  'manavgat-belediyespor-manavgat': countries.tr,
  'mancha-real-mancha-real': countries.es,
  'manchego-ciudad-real-ciudad-real': countries.es,
  'manchester-city-manchester': countries.gb,
  'manchester-city-u18': countries.gb,
  'manchester-city-u19-manchester': countries.gb,
  'manchester-city-u21-manchester': countries.gb,
  'manchester-city-u23-manchester': countries.gb,
  'manchester-united-manchester': countries.gb,
  'manchester-united-u18': countries.gb,
  'manchester-united-u19-manchester': countries.gb,
  'manchester-united-u21': countries.gb,
  'manchester-united-u23-manchester': countries.gb,
  'mandalskameratene-mandal': countries.no,
  'mandel-united-izegem': countries.be,
  'mangasport-moanda': countries.ga,
  'maniema-union': countries.cd,
  'manisa-bbsk-manisa': countries.tr,
  'manisa-sanayispor': countries.tr,
  'manly-united-sydney': countries.au,
  'mannsdorf-grossenzersdorf-mannsdorf': countries.at,
  manresa: countries.es,
  'mansfield-town-mansfield-nottinghamshire': countries.gb,
  'mansfield-town-u18': countries.gb,
  'manta-manta': countries.ec,
  'manthiqueira-guaratingueta-sao-paulo': countries.br,
  'mantova-mantova': countries.it,
  'manukau-united-mangere': countries.nz,
  'manzini-wanderers': countries.sz,
  'maps-masku': countries.fi,
  'maqtaaral-jetisay-zhetisay': countries.kz,
  'mar-menor-mar-menor': countries.es,
  'maracana-maracanau-ceara': countries.br,
  'maracena-maracena': countries.es,
  'maranguape-maranguape-ceara': countries.br,
  'marathon-san-pedro-sula': countries.hn,
  'marbella-marbella': countries.es,
  marchamalo: countries.es,
  'marcilio-dias-itajai-santa-catarina': countries.br,
  'marconi-stallions-sydney': countries.au,
  'mardin-bb-mardin': countries.tr,
  'marek-dupnitsa': countries.bg,
  'margate-margate-kent': countries.gb,
  'maria-da-fonte-povoa-de-lanhoso': countries.pt,
  'maria-saal-maria-saal': countries.at,
  'marianske-lazne-marianske-lazne': countries.cz,
  'maribor-maribor': countries.si,
  'mariehamn-maarianhamina-mariehamn-ahvenanmaa-aland': countries.fi,
  'mariekerke-mariekerke': countries.be,
  'marienlyst-odense': countries.dk,
  'marignane-gignac-cb-marignane': countries.fr,
  'marignane-gignac-ii-marignane': countries.fr,
  'marilia-marilia-sao-paulo': countries.br,
  'marine-crosby-merseyside': countries.gb,
  'maringa-maringa-parana': countries.br,
  marinhense: countries.pt,
  'marino-de-luanco-luanco': countries.es,
  'marino-marino': countries.es,
  'marisca-mersch-miersch-mersch': countries.lu,
  'marist-fire-honiara': countries.sb,
  'maritimo-funchal-ilha-da-madeira': countries.pt,
  'maritimo-ii-funchal-ilha-da-madeira': countries.pt,
  'maritsa-plovdiv-plovdiv': countries.bg,
  'maritzburg-united-pietermaritzburg-kn': countries.za,
  'markez-balata': countries.ps,
  'markt-allhau-markt-allhau': countries.at,
  'marloie-sport-marche-en-famenne': countries.be,
  'marlow-marlow-buckinghamshire': countries.gb,
  'marquense-san-marcos': countries.gt,
  'marsa-la-marsa': countries.tn,
  'marsa-marsa': countries.mt,
  marsaskala: countries.mt,
  'marsaxlokk-marsaxlokk': countries.mt,
  'marske-united-marske-by-the-sea': countries.gb,
  'martigny-sports-martigny': countries.ch,
  'martigues-martigues': countries.fr,
  'martin-ledesma-capiata': countries.py,
  'martina-franca-martina-franca': countries.it,
  'maruinense-maruim-se': countries.br,
  'maruyasu-okazaki-nagoya': countries.jp,
  'marvilde-veldhoven': countries.nl,
  'maryland-bobcats-montgomery-county-maryland': countries.us,
  'mash-al-muborak-mubarek': countries.uz,
  'mashuk-kmv-pyatigorsk': countries.ru,
  'master-7': countries.la,
  'master-s-futbol-academy': countries.ca,
  'matagalpa-matagalpa': countries.ni,
  'matera-matera': countries.it,
  'matlama-maseru': countries.ls,
  'matlock-town-matlock-derbyshire': countries.gb,
  'matonense-matao-sao-paulo': countries.br,
  'matoury-matoury': countries.gf,
  'matsue-city-matsue': countries.jp,
  'matsumoto-yamaga-matsumoto': countries.jp,
  'maua-maua-sao-paulo': countries.br,
  'maubeuge-maubeuge': countries.fr,
  'mauerwerk-wien': countries.at,
  'mawjhai-amu-kabul': countries.af,
  'mazatlan-mazatlan': countries.mx,
  'mazidagi-fosfatspor-mazidagi-mardin': countries.tr,
  'maziya-male-north-male-atoll-kaafu-atoll': countries.mv,
  'mbabane-swallows-mbabane': countries.sz,
  'mc-alger-cheraga': countries.dz,
  'mc-oran-wahran-oran': countries.dz,
  'mc-saida-saida': countries.dz,
  'meap-nisou-nisou': countries.cy,
  'mechelen-mechelen-malines': countries.be,
  'mechtersheim-romerberg': countries.de,
  'medeama-tarkwa': countries.gh,
  'mehmet-akif-ersoy-uni': countries.tr,
  meinerzhagen: countries.de,
  'meix-devant-virton-meix-devant-virton': countries.be,
  'meizhou-hakka-meizhou': countries.cn,
  'melaka-united-fc-melaka-city': countries.my,
  'melaka-united-melaka': countries.my,
  'melbourne-city-melbourne': countries.au,
  'melbourne-city-u21-melbourne': countries.au,
  'melbourne-knights-melbourne': countries.au,
  'melbourne-victory-melbourne': countries.au,
  'melbourne-victory-u21-melbourne': countries.au,
  'melgar-arequipa': countries.pe,
  'melilla-cd': countries.es,
  'melilla-ii-melilla': countries.es,
  'melilla-melilla': countries.es,
  'melipilla-melipilla': countries.cl,
  'melita-san-giljan-st-julian-s': countries.mt,
  'melksham-town': countries.gb,
  'mellieha-mellieha': countries.mt,
  'melville-united': countries.nz,
  'memmingen-memmingen': countries.de,
  'memphis-901-memphis-tennessee': countries.us,
  'mendrisio-mendrisio': countries.ch,
  'menemen-belediyespor-menemen': countries.tr,
  'mensajero-santa-cruz-de-la-palma-la-palma': countries.es,
  'meppen-meppen': countries.de,
  'meppen-u19': countries.de,
  'meram-kara-kartallarspor-meram-konya': countries.tr,
  'merani-martvili-martvili': countries.ge,
  'mercedes-mercedes-provincia-de-buenos-aires': countries.ar,
  'merelbeke-merelbeke': countries.be,
  'merelinense-merelim-de-sao-pedro': countries.pt,
  'merida-ad-merida': countries.es,
  'merstham-merstham-surrey': countries.gb,
  'merthyr-town-merthyr-tydfil': countries.gb,
  'mes-kerman-kerman': countries.ir,
  'mes-rafsanjan': countries.ir,
  'meshakhte-tkibuli': countries.ge,
  'messina-messina': countries.it,
  'mestre-venezia': countries.it,
  'met-oval-u19-new-york-city-new-york': countries.us,
  'metal-kharkiv-kharkiv-kharkov': countries.ua,
  'metalac-gm-gornji-milanovac': countries.rs,
  'metalist-1925-kharkiv-kharkiv-kharkov': countries.ua,
  'metallurg-asha': countries.ru,
  'metallurg-lipetsk-lipetsk': countries.ru,
  'metaloglobus-bucuresti': countries.ro,
  'metalourg-bekobod-bekabad': countries.uz,
  'metalul-buzau-buzau': countries.ro,
  'metalurgistul-cugir-cugir': countries.ro,
  'metalurh-zaporizhya-ii-zaporizhya-zaporozh-ye-40f18a61-f5f1-49a6-862b-787c7b1e2b7d':
    'ua',
  'metalurh-zaporizhya-zaporizhya': countries.ua,
  'meteor-praha-praha': countries.cz,
  'metlaoui-metlaoui': countries.tn,
  'metropolitan-police-east-molesey-surrey': countries.gb,
  'metropolitan-san-juan': countries.pr,
  'metropolitano-blumenau-santa-catarina': countries.br,
  'metropolitanos-caracas': countries.ve,
  metrostars: countries.au,
  'metta-lu-kekava': countries.lv,
  'mettersdorf-mettersdorf': countries.at,
  'metz-ii-metz': countries.fr,
  'metz-metz': countries.fr,
  'meulebeke-meulebeke': countries.be,
  'meux-meux': countries.be,
  'meyrin-meyrin': countries.ch,
  'mezokovesd-zsory-mezokovesd': countries.hu,
  'mezzolara-budrio': countries.it,
  'mfm-lagos': countries.ng,
  'miajadas-miajadas': countries.es,
  'miami-fc-2-miami-florida': countries.us,
  'miami-rush-u19-miami-florida': countries.us,
  'miami-united-miami': countries.us,
  'michigan-bucks-pontiac-michigan': countries.us,
  'michigan-stars': countries.us,
  'michigan-wolves-u19-livonia-michigan': countries.us,
  'mictlan-jutiapa': countries.gt,
  'middlesbrough-middlesbrough': countries.gb,
  'middlesbrough-u18': countries.gb,
  'middlesbrough-u21-middlesbrough': countries.gb,
  'middlesbrough-u23-middlesbrough': countries.gb,
  'midland-merlo-provincia-de-buenos-aires': countries.ar,
  'midtjylland-herning': countries.dk,
  'midtjylland-u19': countries.dk,
  'midwest-united-u19-grand-rapids-michigan': countries.us,
  'miedz-legnica-ii-legnica': countries.pl,
  'miedz-legnica-legnica': countries.pl,
  mifa: countries.my,
  'mika-yerevan-yerevan': countries.am,
  'mil-mugan': countries.az,
  'milan-milano': countries.it,
  'milan-u19-milan': countries.it,
  milerock: countries.gy,
  'millonarios-bogota-d-c': countries.co,
  'millonarios-u20-bogota-d-c': countries.co,
  'millwall-london': countries.gb,
  'millwall-u18': countries.gb,
  'milsami-orhei': countries.md,
  'milton-keynes-dons-milton-keynes-buckinghamshire': countries.gb,
  'milton-keynes-dons-u18': countries.gb,
  'milwaukee-torrent-milwaukee-wisconsin': countries.us,
  'minai-mynai': countries.ua,
  'minaur-baia-mare-baia-mare': countries.ro,
  'mindil-aces-mindil-darwin': countries.au,
  'mineros-de-guayana-ciudad-guayana': countries.ve,
  'mineros-de-zacatecas-zacatecas': countries.mx,
  'minerul-ocna-dej-cluj-napoca': countries.ro,
  'minerva-lintgen-lentgen-lintgen': countries.lu,
  'minerva-punjab-chandigarh': countries.in,
  'minija-kretinga': countries.lt,
  'minneapolis-city-minneapolis-minnesota': countries.us,
  'minnesota-united-ii-minneapolis-saint-paul-minnesota': countries.us,
  'minnesota-united-minneapolis-saint-paul-minnesota': countries.us,
  'minnesota-united-u19-saint-paul-minnesota': countries.us,
  'minor-nuth': countries.nl,
  'minsk-minsk': countries.by,
  'mioveni-mioveni': countries.ro,
  'mipk-mikkeli': countries.fi,
  miramar: countries.nz,
  'miramar-misiones-montevideo': countries.uy,
  'mirandes-ii-mirandes': countries.es,
  'mirandes-miranda-de-ebro': countries.es,
  'mirassol-mirassol-sao-paulo': countries.br,
  'mirebalais-mirebalais': countries.ht,
  'missile-libreville': countries.ga,
  'mississippi-brilla-jackson-mississippi': countries.us,
  'mito-hollyhock-mito': countries.jp,
  mixco: countries.gt,
  'mjallby-solvesborg': countries.se,
  'mjolby-mjolby': countries.se,
  'mjolner-narvik': countries.no,
  'mjondalen-mjondalen': countries.no,
  'mkk-dnepr-rahachow-rohachev': countries.by,
  'mladi-radnik-pozarevac': countries.rs,
  'mladost-dg-podgorica': countries.me,
  'mladost-doboj-kakanj-kakanj': countries.ba,
  'mladost-ljeskopolje-donja-gorica': countries.me,
  'mladost-lucani-lucani': countries.rs,
  'mladost-novi-sad-novi-sad': countries.rs,
  'mladost-petrinja': countries.hr,
  'mls-next-east-u17-19': countries.us,
  'mls-next-west-u17-19': countries.us,
  'mm-platinum-johannesburg': countries.za,
  'mnk-izola-izola': countries.si,
  'mnpz-mazyr-mozyr': countries.by,
  'mo-constantine-constantine': countries.dz,
  moca: countries.do,
  'modafenspor-istanbul': countries.tr,
  'modbury-jets': countries.au,
  'modena-modena': countries.it,
  'modrica-modrica': countries.ba,
  'moghreb-tetouan-tetouan': countries.ma,
  'mohammedan-dhaka-dhaka': countries.bd,
  'mohammedan-kalkata-kolkata-west-bengal': countries.in,
  'moik-baki-baku': countries.az,
  'mojocoya-sucre': countries.bo,
  moknine: countries.tn,
  'mokpo-city-mokpo': countries.kr,
  'molde-molde': countries.no,
  'molde-u19-molde': countries.no,
  'molina-molina-de-segura': countries.es,
  'mollerussa-mollerussa': countries.es,
  'molodechno-dyussh-4-molodechno': countries.by,
  'molynes-united': countries.jm,
  'monaco-monaco': countries.fr,
  'monaco-u19-monaco': countries.fr,
  'monaco-u23': countries.fr,
  'monagas-maturin': countries.ve,
  'monaro-panthers-queanbeyan': countries.au,
  'monastir-monastir': countries.tn,
  'moncao-moncao': countries.pt,
  'moncarapachense-moncarapacho': countries.pt,
  'mondercange-monnerech-mondercange': countries.lu,
  'mondorf-les-bains-munneref-mondorf-les-bains': countries.lu,
  'monopoli-monopoli': countries.it,
  'monori-se': countries.hu,
  'mons-calpe-gibraltar': countries.gi,
  'mont-royal-outremont': countries.ca,
  'montagnarde-inzinzac': countries.fr,
  'montalegre-montalegre': countries.pt,
  'montana-montana': countries.bg,
  'montanesa-barcelona': countries.es,
  'montargis-montargis': countries.fr,
  'montceau-montceau-les-mines': countries.fr,
  'monte-azul-monte-azul-paulista-sao-paulo': countries.br,
  'monte-carlo': countries.mo,
  'montebelluna-montebelluna': countries.it,
  'montedio-yamagata-tendo-tendo': countries.jp,
  'montego-bay-united-st-james': countries.jm,
  'monterey-bay-seaside-california': countries.us,
  'monterrey-monterrey': countries.mx,
  'monterrey-u20': countries.mx,
  'montevarchi-calcio-montevarchi': countries.it,
  'montfoort-montfoort': countries.nl,
  monthey: countries.ch,
  'montijo-montijo': countries.es,
  'montlouis-montlouis-sur-loire': countries.fr,
  'montlucon-montlucon': countries.fr,
  'montpellier-ii-montpellier': countries.fr,
  'montpellier-montpellier': countries.fr,
  'montpellier-u19-montpellier': countries.fr,
  'montreal-impact-montreal-quebec': countries.us,
  'montreal-impact-u17-montreal-quebec': countries.us,
  'montrose-montrose': countries.gb,
  'montrouge-montrouge': countries.fr,
  'monza-monza': countries.it,
  'moralo-navalmoral-de-la-mata': countries.es,
  'morecambe-morecambe-lancashire': countries.gb,
  'moreirense-moreira-de-conegos': countries.pt,
  'moreland-zebras-melbourne': countries.au,
  'morelia-morelia': countries.mx,
  'moreton-bay-united-jets-moreton-bay': countries.au,
  'mori-se-mor': countries.hu,
  'morlautern-kaiserslautern': countries.de,
  'mormont-mormont': countries.be,
  'mornar-bar': countries.me,
  'morobe-wawens-lae': countries.pg,
  'moroka-swallows-johannesburg-ga': countries.za,
  'morpeth-town': countries.gb,
  'morrinhos-morrinhos-goias': countries.br,
  mortagua: countries.pt,
  'morteau-montlebon-morteau': countries.fr,
  'morvant-caledonia-united-port-of-spain': countries.tt,
  'moss-moss': countries.no,
  'mossley-mossley': countries.gb,
  'mosta-mosta': countries.mt,
  mostoles: countries.es,
  'motagua-tegucigalpa': countries.hn,
  'motala-motala': countries.se,
  'motema-pembe-kinshasa': countries.cd,
  'motherwell-motherwell': countries.gb,
  'moto-club-ma-sao-jose-do-ribamar-maranhao': countries.br,
  'motor-lublin-lublin': countries.pl,
  'motorlet-praha-praha': countries.cz,
  'motril-motril': countries.es,
  'moulien-le-moule': countries.gp,
  'moulins-yzeure-foot-yzeure': countries.fr,
  'mouloudia-oujda-oujda': countries.ma,
  'mounana-mounana': countries.ga,
  'mount-pleasant-academy-mount-pleasant': countries.jm,
  'moyola-park-castledawson': countries.gb,
  'mp-mikkeli': countries.fi,
  'mpkb-bri-u-bes-kelantan': countries.my,
  'mps-atletico-malmi': countries.fi,
  'mps-helsinki': countries.fi,
  'mqabba-mqabba': countries.mt,
  'ms-abdb': countries.bn,
  'msida-st-joseph-msida': countries.mt,
  'msk-tesla-stropkov': countries.sk,
  'msv-duisburg-duisburg': countries.de,
  'msv-duisburg-u17-duisburg': countries.de,
  'msv-duisburg-u19-duisburg': countries.de,
  'mt-druitt-town-emerton': countries.au,
  'mte-1904-mosonmagyarovar': countries.hu,
  'mtibwa-sugar-morogoro': countries.tz,
  'mtk-budapest': countries.hu,
  'mtk-ii-budapest': countries.hu,
  'mtk-u19-budapest': countries.hu,
  'muang-loei-united': countries.th,
  'muang-thong-united-bangkok': countries.th,
  'mucurgucu-mucur': countries.tr,
  'muglaspor-mugla': countries.tr,
  'muharraq-muharraq': countries.bh,
  'muktijoddha-skc-dhaka': countries.bd,
  'mumbai-city-mumbai': countries.in,
  'municipal-ciudad-de-guatemala': countries.gt,
  'municipal-liberia-liberia': countries.cr,
  'municipal-mejillones-mejillones': countries.cl,
  'municipal-santiago-santiago': countries.cl,
  'munsingen-munsingen': countries.ch,
  'mura-murska-sobota': countries.si,
  'murallas-de-ceuta': countries.es,
  'murata-murata': countries.sm,
  'muri-muri': countries.ch,
  'murici-murici-alagoas': countries.br,
  'murlani-shkoder': countries.al,
  'muro-muro-de-alcoy-muro-d-alcoi': countries.es,
  'murom-murom': countries.ru,
  'mus-menderesspor-mus': countries.tr,
  'musa-pori': countries.fi,
  musanze: countries.rw,
  'mushuc-runa-ambato': countries.ec,
  'musongati-musongati': countries.bi,
  'musselburgh-athletic-musselburgh': countries.gb,
  'mutilvera-mutilva-baja': countries.es,
  'muttenz-muttenz': countries.ch,
  'mvv-maastricht': countries.nl,
  'mypa-kouvola': countries.fi,
  'na-hussein-dey-al-jaza-ir-algiers': countries.dz,
  'nac-breda-breda': countries.nl,
  'nac-breda-u19-breda': countries.nl,
  'nacional-ac-mg-muriae-minas-gerais': countries.br,
  'nacional-am-manaus-amazonas': countries.br,
  'nacional-asuncion-asuncion': countries.py,
  'nacional-de-patos-patos-paraiba': countries.br,
  'nacional-funchal-ilha-da-madeira': countries.pt,
  'nacional-montevideo': countries.uy,
  'nacional-potosi-potosi': countries.bo,
  'nacional-sp-sao-paulo-sao-paulo': countries.br,
  'nadezhda-baranovichy-baranavichy-baranovichy': countries.by,
  'nadezhda-dobroslavtsi-dobroslavtsi': countries.bg,
  'nadi-nadi': countries.fj,
  'nadur-youngsters-nadur': countries.mt,
  'naestved-naestved': countries.dk,
  'naft-al-janoob-basra': countries.iq,
  'naft-al-wasat-najaf': countries.iq,
  'naft-bagdad-bagdad': countries.iq,
  'naft-masjed-soleyman-masjed-soleyman': countries.ir,
  'naft-maysan': countries.iq,
  'nafta-lendava': countries.si,
  'naftan-navapolatsk-novopolotsk': countries.by,
  'naftetari-kucove-kucove': countries.al,
  'naga-world-phnom-penh': countries.kh,
  'nagoya-grampus-nagoya': countries.jp,
  'nagykanizsai-ule-nagykanizsa': countries.hu,
  'nairn-county-inverness': countries.gb,
  'najran-najran': countries.sa,
  'nakhon-pathom-nakhon-pathom': countries.th,
  'nakhon-ratchasima-nakhon-ratchasima': countries.th,
  'nakhon-si-thammarat-nakhon-si-thammarat': countries.th,
  'nam-dinh-nam-d-nh-nam-dinh': countries.vn,
  'namestovo-namestovo': countries.sk,
  namungo: countries.tz,
  'nancy-ii-nancy': countries.fr,
  'nancy-nancy': countries.fr,
  'nanjing-city-nanjing': countries.cn,
  'nantes-ii-la-chapelle-sur-erdre': countries.fr,
  'nantes-la-chapelle-sur-erdre': countries.fr,
  'nantes-u19-la-chapelle-sur-erdre': countries.fr,
  'nantong-zhiyun-guangxi': countries.cn,
  'nantwich-town-nantwich-cheshire': countries.gb,
  'napier-city-rovers': countries.nz,
  'napoli-castel-volturno': countries.it,
  'napoli-u19-napoli': countries.it,
  'napredak-krusevac': countries.rs,
  'napsa-stars': countries.zm,
  'nardo-nardo': countries.it,
  'nardo-trondheim': countries.no,
  'nasaf-qarshi-karshi': countries.uz,
  'nasarawa-united': countries.ng,
  'nashville-sc': countries.us,
  'nassaji-mazandaran-mazandaran': countries.ir,
  'naters-naters': countries.ch,
  'national-bank-of-egypt': countries.eg,
  'national-defense-phnom-penh': countries.kh,
  'nauas-cruzeiro-do-sul-acre': countries.br,
  'nautico-recife-pernambuco': countries.br,
  'nautico-rr-boa-vista-roraima': countries.br,
  'naval-reinosa': countries.es,
  'navalcarnero-navalcarnero': countries.es,
  'navbahor-namangan': countries.uz,
  'naviraiense-navirai-mato-grosso-do-sul': countries.br,
  'navy-sattahip': countries.th,
  'naxara-najera': countries.es,
  'naxxar-lions-naxxar': countries.mt,
  'nazilli-belediyespor-aydin': countries.tr,
  'nc-magra': countries.dz,
  'nd-slovan-ljubljana': countries.si,
  'ne-drehu': countries.nc,
  'nea-salamis-larnaka-larnaca': countries.cy,
  'nec-nijmegen': countries.nl,
  'nec-top-oss-u19-nijmegen': countries.nl,
  'necaxa-aguascalientes': countries.mx,
  'necaxa-u20-aguascalientes': countries.mx,
  'neckarsulmer-su': countries.de,
  'needham-market-needham-market-suffolk': countries.gb,
  'neftchi-farg-ona-fergana': countries.uz,
  'neftchi-kochkor-ata': countries.kg,
  'neftci-baki-baku': countries.az,
  'neftekhimik-nizhnekamsk': countries.ru,
  'negeri-sembilan-seremban': countries.my,
  'nehaj-senj': countries.hr,
  'nelson-suburbs-nelson': countries.nz,
  'neman-belkard-hrodna-grodno': countries.by,
  'neman-grodno-hrodna-grodno': countries.by,
  'neman-mosty-mosty': countries.by,
  nemelaer: countries.nl,
  'nepal-army-kathmandu': countries.np,
  'nepal-police-kathmandu': countries.np,
  'neptuna-klaipeda-klaipeda': countries.lt,
  'neretvanac-opuzen-opuzen': countries.hr,
  'neroca-imphal': countries.in,
  'nesebar-nesebar': countries.bg,
  'nestos-chrisoupolis-chrysoupoli': countries.gr,
  'neuchatel-xamax-bevaix': countries.ch,
  'neumarkt-neumarkt-am-wallersee': countries.at,
  'neusiedl-neusiedl-am-see': countries.at,
  'neustrelitz-neustrelitz': countries.de,
  'nevezis-kedainiai': countries.lt,
  'nevsehir-belediyespor-nevsehir': countries.tr,
  'new-england-foxborough-massachusetts': countries.us,
  'new-england-ii': countries.us,
  'new-england-u19-foxborough-massachusetts': countries.us,
  'new-jersey-copa-metuchen-new-jersey': countries.us,
  'new-mexico-united-albuquerque-new-mexico': countries.us,
  'new-road-team-kathmandu': countries.np,
  'new-york-city-ii-new-york-new-york': countries.us,
  'new-york-city-new-york-new-york': countries.us,
  'new-york-magic-fa-euro-brooklyn-new-york': countries.us,
  'new-york-rb-ii-new-york': countries.us,
  'new-york-rb-iii-secaucus-new-jersey': countries.us,
  'new-york-rb-secaucus-new-jersey': countries.us,
  'new-york-rb-u17-secaucus-new-jersey': countries.us,
  'new-york-rb-u19-secaucus-new-jersey': countries.us,
  'new-york-sc-u19-purchase-new-york': countries.us,
  'newcastle-jets-newcastle-west': countries.au,
  'newcastle-jets-u21-newcastle-west': countries.au,
  'newcastle-town-newcastle-under-lyme-staffordshire': countries.gb,
  'newcastle-united-newcastle-upon-tyne': countries.gb,
  'newcastle-united-u18': countries.gb,
  'newcastle-united-u21-newcastle': countries.gb,
  'newcastle-united-u23-newcastle-upon-tyne': countries.gb,
  'newell-s-old-boys-rosario-santa-fe': countries.ar,
  'newington-youth-newington': countries.gb,
  'newport-county-newport-casnewydd': countries.gb,
  'newport-county-u18': countries.gb,
  'newry-city-afc-newry': countries.gb,
  'newton-stewart-newton-stewart': countries.gb,
  'newtongrange-star-newtongrange': countries.gb,
  'newtown-united-newtown': countries.kn,
  'newtown-ydrenewydd-newtown-powys': countries.gb,
  'nice-ii-nice': countries.fr,
  'nice-nice': countries.fr,
  'nico-united': countries.bw,
  'nieciecza-zabno': countries.pl,
  'nifs-kanoya-kanoya': countries.jp,
  'nigde-anadolu-nigde': countries.tr,
  'nigde-belediyesispor-nigde': countries.tr,
  nikao: countries.ck,
  'niki-volos-volos': countries.gr,
  'niksar-belediyespor': countries.tr,
  'nilufer-belediye-bursa': countries.tr,
  'nimes-nimes': countries.fr,
  'ninove-meerbeeke': countries.be,
  'niort-ii-niort': countries.fr,
  'niort-niort': countries.fr,
  'nisantasispor-istanbul': countries.tr,
  'niva-dolbizno-dolbizno': countries.by,
  'nizhny-novgorod-nizhny-novgorod': countries.ru,
  'njardvik-njardvik-reykjanesbaer': countries.is,
  'njs-nurmijarvi': countries.fi,
  'nkana-kitwe': countries.zm,
  'nkp-podhale-nowy-targ': countries.pl,
  'nomads-u19-la-jolla-california': countries.us,
  'nomme-kalju-ii-tallinn': countries.ee,
  'nomme-kalju-tallinn': countries.ee,
  'nomme-united-nomme': countries.ee,
  'nong-bua-pitchaya-nong-bua-lamphu': countries.th,
  nonthaburi: countries.th,
  'noordwijk-noordwijk': countries.nl,
  'noorus-96-jogeva': countries.ee,
  'norchi-dinamo-tbilisi': countries.ge,
  'norden-elwen-troisvierges-ulflingen': countries.lu,
  'nordia-jerusalem-jerusalem': countries.il,
  'nordsjaelland-farum': countries.dk,
  'nordvarmland-ambjorby': countries.se,
  'noroeste-bauru-sao-paulo': countries.br,
  'norrby-boras': countries.se,
  'norresundby-norresundby': countries.dk,
  'norrkoping-norrkoping': countries.se,
  'north-carolina-cary-north-carolina': countries.us,
  'north-east-stars-sangre-grande': countries.tt,
  'north-leigh-north-leigh-oxfordshire': countries.gb,
  'north-shore-mariners-northbridge': countries.au,
  'north-shore-united': countries.nz,
  'north-texas-frisco-texas': countries.us,
  'north-village-rams-hamilton': countries.bm,
  'north-wellington-wellington': countries.nz,
  'northampton-town-northampton-east-midlands': countries.gb,
  'northampton-town-u18': countries.gb,
  'northcote-city-melbourne': countries.au,
  'northeast-united-guwahati': countries.in,
  'northern-colorado-windsor-colorado': countries.us,
  'northwood-london': countries.gb,
  'norwich-city-norwich-norfolk': countries.gb,
  'norwich-city-u18': countries.gb,
  'norwich-city-u21-norwich': countries.gb,
  'norwich-city-u23-norwich': countries.gb,
  'nosaby-kristianstad': countries.se,
  'nosta-novotroitsk': countries.ru,
  'notodden-notodden': countries.no,
  'nottingen-remchingen': countries.de,
  'nottingham-forest-nottingham': countries.gb,
  'nottingham-forest-u18': countries.gb,
  'nottingham-forest-u21-nottingham': countries.gb,
  'nottingham-forest-u23-nottingham': countries.gb,
  'notts-county-nottingham-nottinghamshire': countries.gb,
  'nouadhibou-nouadhibou': countries.mr,
  'nouakchott-king-s-nouakchott': countries.mr,
  'nova-iguacu-nova-iguacu-rio-de-janeiro': countries.br,
  'nova-mutum-ec': countries.br,
  'nova-venecia': countries.br,
  'novara-novara': countries.it,
  'nove-mesto-nad-vahom-nove-mesto-nad-vahom': countries.sk,
  'nove-zamky-nove-zamky': countries.sk,
  'novelda-novelda': countries.es,
  'novi-pazar-novi-pazar': countries.rs,
  'novi-sad-novi-sad': countries.rs,
  'novigrad-novigrad': countries.hr,
  'novo-hamburgo-novo-hamburgo-rio-grande-do-sul': countries.br,
  'novohrad-lucenec-lucenec': countries.sk,
  'novorizontino-novo-horizonte-sao-paulo': countries.br,
  novosibirsk: countries.ru,
  'novosibirsk-ii-novosibirsk': countries.ru,
  'ns-drava-ptuj': countries.si,
  'nsi-saltangara-eysturoy': countries.fo,
  'nublense-chillan': countries.cl,
  'nueva-chicago-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'nueva-concepcion': countries.gt,
  'nueva-esparta-pampatar': countries.ve,
  'numancia-ii-soria': countries.es,
  'numancia-soria': countries.es,
  'nuneaton-borough-nuneaton-warwickshire': countries.gb,
  'nuova-monterosi-monterosi': countries.it,
  'nurnberg-ii-nurnberg': countries.de,
  'nurnberg-nurnberg': countries.de,
  'nurnberg-u17-nurnberg': countries.de,
  'nurnberg-u19-nurnberg': countries.de,
  'nyiregyhaza-spartacus-nyiregyhaza': countries.hu,
  'nykobing-nykobing-falster': countries.dk,
  'nykoping-nykoping': countries.se,
  'nyva-buzova-buzova': countries.ua,
  'nyva-ternopil-ternopil': countries.ua,
  'nyva-vinnytsya-vinnytsya': countries.ua,
  'o-higgins-rancagua': countries.cl,
  'oakland-roots': countries.us,
  'oakleigh-cannons-melbourne': countries.au,
  'oakwood-u19-glastonbury-connecticut': countries.us,
  'ob-odense': countries.dk,
  oberachern: countries.de,
  'oberena-pamplona': countries.es,
  'oberlausitz-neugersdorf-ebersbach-neugersdorf': countries.de,
  'oberneuland-bremen': countries.de,
  'obolon-brovar-kyiv-kiev': countries.ua,
  'oborishte-panagyurishte': countries.bg,
  'ocean-city-nor-easters-ocean-city-new-jersey': countries.us,
  'ocna-mure-ocna-mure': countries.ro,
  'ococias-kyoto-kyoto': countries.jp,
  'odd-ii-skien': countries.no,
  'odd-skien': countries.no,
  odder: countries.dk,
  'oddevold-uddevalla': countries.se,
  'odin-59-heemskerk': countries.nl,
  'odorheiu-secuiesc-odorheiu-secuiesc': countries.ro,
  'odra-opole-opole': countries.pl,
  'odra-wodzislaw-slaski-wodzislaw-slaski': countries.pl,
  'oedt-oedt': countries.at,
  'oeirense-oeiras-piaui': countries.br,
  'oeste-itapolis-sao-paulo': countries.br,
  'of-ierapetra': countries.gr,
  'ofc-oostzaan': countries.nl,
  'offenburger-fv-offenburg': countries.de,
  'ofi-iraklio-heraklion': countries.gr,
  'ofk-beograd-beograd': countries.rs,
  'ofk-pirin-blagoevgrad': countries.bg,
  'ofk-vrsac-vrsac': countries.rs,
  'ofspor-of': countries.tr,
  'oh-leuven-heverlee': countries.be,
  'ohod-madinah': countries.sa,
  'ohrid-ohrid': countries.mk,
  'oimii-lipova-lipova': countries.ro,
  'oissel-oissel': countries.fr,
  'oita-trinita-oita-oita': countries.jp,
  'ojc-rosmalen-rosmalen': countries.nl,
  'okinawa-sv-uruma': countries.jp,
  oks: countries.dk,
  'okzhetpes-kokshetau-kokchetav-kokshetau': countries.kz,
  'olancho-juticalpa': countries.hn,
  'olbia-olbia': countries.it,
  'old-boys-basel': countries.ch,
  'olde-veste-54': countries.nl,
  'oldham-athletic-oldham-greater-manchester': countries.gb,
  'oleksandria-oleksandriya-aleksandriya': countries.ua,
  'olhanense-olhao': countries.pt,
  'olimpia-asuncion': countries.py,
  'olimpia-elblag-elblag': countries.pl,
  'olimpia-grudziadz-grudziadz': countries.pl,
  'olimpia-olimpia-sao-paulo': countries.br,
  'olimpia-tegucigalpa': countries.hn,
  'olimpia-zambrow-zambrow': countries.pl,
  'olimpic-cetate-rasnov': countries.ro,
  'olimpija-ljubljana': countries.si,
  'olimpo-bahia-blanca-provincia-de-buenos-aires': countries.ar,
  'oliveira-hospital': countries.pt,
  olivenza: countries.es,
  'olmedo-riobamba': countries.ec,
  'olot-olot': countries.es,
  'ols-oulu': countries.fi,
  'olsa-brakel-brakel': countries.be,
  'olympia-warriors-north-hobart': countries.au,
  'olympia-wijgmaal-wijgmaal': countries.be,
  'olympiakos-nicosia-levkosia': countries.cy,
  'olympiakos-piraeus-ii-pireas-piraeus': countries.gr,
  'olympiakos-piraeus-pireas-piraeus': countries.gr,
  'olympiakos-piraeus-u19-piraeus': countries.gr,
  'olympiakos-volos-volos': countries.gr,
  'olympic-brisbane': countries.au,
  'olympic-charleroi-montignies-sur-sambre': countries.be,
  'olympic-malmo': countries.se,
  'olympic-safi-safi': countries.ma,
  'olympic-toshkent-tashkent': countries.uz,
  'olympique-beja-beja': countries.tn,
  'olympique-d-ales-ales': countries.fr,
  'olympique-dcheira-dcheira-el-jihadia': countries.ma,
  'olympique-de-geneve-geneve': countries.ch,
  'olympique-lyonnais-ii-lyon': countries.fr,
  'olympique-lyonnais-lyon': countries.fr,
  'olympique-lyonnais-u19-lyon': countries.fr,
  'olympique-marcquois': countries.fr,
  'olympique-marseille-ii-marseille-cedex-12': countries.fr,
  'olympique-marseille-marseille': countries.fr,
  'olympique-marseille-u19-marseille': countries.fr,
  'olympique-medea-medea': countries.dz,
  'olympique-st-quentin-saint-quentin': countries.fr,
  'omarska-omarska': countries.ba,
  'omiya-ardija-saitama': countries.jp,
  'omonia-29is-maiou-kofinou': countries.cy,
  'omonia-aradippou-aradippou': countries.cy,
  'omonia-nicosia-nicosia-levkosia': countries.cy,
  'omonia-nicosia-u19-nicosia-levkosia': countries.cy,
  'once-caldas-manizales': countries.co,
  'once-municipal-ahuachapan': countries.sv,
  'once-tigres-nueve-de-julio-provincia-buenos-aires': countries.ar,
  'onet-le-chateau': countries.fr,
  'onhaye-onhaye': countries.be,
  'ons-sneek-sneek': countries.nl,
  'onsala-onsala': countries.se,
  'onze-bravos-luena': countries.ao,
  'onze-createurs-bamako': countries.ml,
  'opava-opava': countries.cz,
  'operario-fc-ms-campo-grande-ms': countries.br,
  'operario-pr-ponta-grossa-parana': countries.br,
  'oppagne-weris-weris': countries.be,
  'oppsal-oslo': countries.no,
  'ops-oulu': countries.fi,
  'optik-rathenow-rathenow': countries.de,
  'orange-county-sc-costa-mesa-california': countries.us,
  'oranje-nassau-groningen': countries.nl,
  'oranje-wit-dordrecht': countries.nl,
  'orapa-united-orapa': countries.bw,
  'oratorio-macapa-amapa': countries.br,
  'oravske-vesele-oravske-vesele': countries.sk,
  'ordabasy-simkent-shymkent': countries.kz,
  'ordino-ordino': countries.ad,
  ordizia: countries.es,
  'orduspor-1967-ordu': countries.tr,
  'orebro-orebro': countries.se,
  'orebro-syrianska-orebro': countries.se,
  'orebro-u21-orebro': countries.se,
  'orenburg-ii-orenburg': countries.ru,
  'orenburg-orenburg': countries.ru,
  'orense-machala': countries.ec,
  'orense-u20-machala': countries.ec,
  'orgryte-goteborg': countries.se,
  'orgryte-u21-goteborg': countries.se,
  'oriental-dragon-pinhal-novo': countries.pt,
  'oriental-la-paz': countries.uy,
  'oriente-petrolero-santa-cruz-de-la-sierra': countries.bo,
  'orihuela-orihuela': countries.es,
  'orijent-1919-rijeka': countries.hr,
  'oriku-vlore': countries.al,
  'oriolik-oriovac-oriovac': countries.hr,
  'orlando-city-ii-sanford-florida': countries.us,
  'orlando-city-lake-mary-florida': countries.us,
  'orlando-city-u19-orlando-florida': countries.us,
  'orlando-pirates-johannesburg-ga': countries.za,
  'orlando-pirates-windhoek': countries.na,
  'orlando-youth-soccer-u19-orlando-florida': countries.us,
  'orleans-ii-orleans': countries.fr,
  'orleans-orleans': countries.fr,
  'orleta-radzyn-podlaski-radzyn-podlaski': countries.pl,
  'orn-horten-horten': countries.no,
  'orsha-orsha': countries.by,
  'orsomarso-palmira': countries.co,
  'ortakoyspor-istanbul': countries.tr,
  osaka: countries.jp,
  'osasco-audax-sao-paulo-sao-paulo': countries.br,
  'osasuna-ii-pamplona': countries.es,
  'osasuna-pamplona-irunea': countries.es,
  'osijek-osijek': countries.hr,
  osipovichy: countries.by,
  'oskarshamns-aik': countries.se,
  'osmancik-belediyespor': countries.tr,
  'osmaniye-istiklalspor-osmaniye': countries.tr,
  'osmaniyespor-osmaniye': countries.tr,
  'osmanlispor-ankara': countries.tr,
  'osmanlispor-u19-ankara': countries.tr,
  'osnabruck-osnabruck': countries.de,
  'oss-20-oss': countries.nl,
  'ossett-united-ossett-west-yorkshire': countries.gb,
  'oster-vaxjo': countries.se,
  'osterlen-brantevik': countries.se,
  'ostersunds-fk-ostersund': countries.se,
  'ostrovets-fc': countries.by,
  'osvaldo-cruz-osvaldo-cruz-sao-paulo': countries.br,
  'otelul-galati': countries.ro,
  'othellos-athienou': countries.cy,
  'otoho-d-oyo-oyo': countries.cg,
  otp: countries.fi,
  'otrant-olympic-ulcinj': countries.me,
  'oud-heverlee-leuven-ii-heverlee': countries.be,
  'oudenaarde-oudenaarde': countries.be,
  'ouest-tourangeau-tours': countries.fr,
  'oulu-oulu': countries.fi,
  'ourense-cf-ourense': countries.es,
  'oxford-city-oxford-oxfordshire': countries.gb,
  'oxford-united-oxford-oxfordshire': countries.gb,
  'oxford-united-u18': countries.gb,
  oygarden: countries.no,
  'oyonesa-oyon': countries.es,
  'ozcamdibispor-camdibi-bornova-izmir': countries.tr,
  'p-iirot-rauma': countries.fi,
  'pac-buzet-pont-a-celles': countries.be,
  'pacajus-pacajus-ceara': countries.br,
  'pachuca-pachuca-de-soto': countries.mx,
  'pachuca-u20': countries.mx,
  'pacific-411d621a-5bbf-40ea-948d-a0c2e8af88c5': countries.ca,
  'pacifico-general-alvear-provincia-de-mendoza': countries.ar,
  'pacos-de-ferreira-pacos-de-ferreira': countries.pt,
  'paderborn-07-ii-paderborn': countries.de,
  'paderborn-paderborn': countries.de,
  'paderborn-u17-paderborn': countries.de,
  'paderborn-u19-paderborn': countries.de,
  'padura-arrigorriaga': countries.es,
  'paeek-kyrenia': countries.cy,
  'pahang-kuantan': countries.my,
  'paide-ii': countries.ee,
  'paide-iii': countries.ee,
  'paide-tallinn': countries.ee,
  'paiosaco-a-laracha': countries.es,
  'paju-citizen-paju': countries.kr,
  'pakhtakor-toshkent-tashkent': countries.uz,
  'paksi-se-ii': countries.hu,
  'paksi-se-paks': countries.hu,
  'palamos-palamos': countries.es,
  'palandoken-belediyesi-palandoken': countries.tr,
  'palencia-cf-palencia': countries.es,
  'palencia-cristo-atletico-cristo': countries.es,
  'palermo-palermo': countries.it,
  'palestino-santiago-de-chile': countries.cl,
  'palmas-palmas-tocantins': countries.br,
  'palmeiras-sao-paulo-sao-paulo': countries.br,
  'paloma-hamburg': countries.de,
  'pamplemousses-belle-vue': countries.mu,
  'panachaiki-patra-patras': countries.gr,
  'panaderia-pulido-vega-de-san-mateo': countries.es,
  'panaitolikos-agrinio': countries.gr,
  'panathinaikos-athina-athens': countries.gr,
  'panathinaikos-ii-athina-athens': countries.gr,
  'panathinaikos-u19-athina-athens': countries.gr,
  'panegialios-aigio': countries.gr,
  'panelefsiniakos-elefsina': countries.gr,
  'panevezys-panevezys': countries.lt,
  'panionios-athina-athens': countries.gr,
  'panserraikos-serres': countries.gr,
  'pao-rouf-rouf': countries.gr,
  'paok-ii-thessaloniki': countries.gr,
  'paok-thessaloniki': countries.gr,
  'paok-u19-thessaloniki': countries.gr,
  'paphos-paphos': countries.cy,
  'para-hills-knights': countries.au,
  'paracatu-paracatu-minas-gerais': countries.br,
  paradise: countries.gd,
  'paradiso-paradiso': countries.ch,
  'paradou-ac-al-jaza-ir-algiers': countries.dz,
  'paragominas-paragominas-para': countries.br,
  'parana-curitiba-parana': countries.br,
  'parceiro-nagano-nagano': countries.jp,
  'pardubice-pardubice': countries.cz,
  'paredes-paredes': countries.pt,
  'paris-ii-paris': countries.fr,
  'paris-paris': countries.fr,
  'parma-parma': countries.it,
  'parma-u19': countries.it,
  'parnahyba-parnaiba-piaui': countries.br,
  'parndorf-parndorf': countries.at,
  'parnu-parnu-f64f0ab3-0fca-4a4b-9dd0-ff864b3a6a50': countries.ee,
  paro: countries.bt,
  'pars-jam-bushehr-jam': countries.ir,
  'partick-thistle-glasgow': countries.gb,
  'partizan-bardejov-bardejov': countries.sk,
  'partizan-beograd': countries.rs,
  'partizan-cherven-bryag': countries.bg,
  'partizan-salihorsk-salihorsk-soligorsk': countries.by,
  'partizani-tirana-tirane-tirana': countries.al,
  'pas-giannina-ioannina': countries.gr,
  'pasaia-ke-pasajes-pasaia': countries.es,
  'pascoe-vale-melbourne': countries.au,
  'patacona-alboraia': countries.es,
  'patagones-viedma-carmen-de-patagones-provincia-de-buenos-aires':
    countries.ar,
  'patnos-04': countries.tr,
  'patriotas-boyaca-tunja': countries.co,
  'patro-eisden-maasmechelen': countries.be,
  'patronato-parana-provincia-de-entre-rios': countries.ar,
  'pau-ii-pau': countries.fr,
  'pau-pau': countries.fr,
  'paulista-jundiai-sao-paulo': countries.br,
  'paulton-rovers-paulton-somerset': countries.gb,
  'pawlowice-slaskie': countries.pl,
  'paykan-tehran': countries.ir,
  'paysandu-belem-para': countries.br,
  'pazarspor-pazar': countries.tr,
  'pdhae-pont-saint-martin': countries.it,
  'pdrm-selayang': countries.my,
  'pe-sant-jordi-sant-josep-de-sa-talaia': countries.es,
  'pec-zwolle-zwolle': countries.nl,
  'pecsi-mfc-pecs': countries.hu,
  'pedras-salgadas-pedras-salgadas': countries.pt,
  'pedroneras-las-pedroneras': countries.es,
  'peimari-united': countries.fi,
  peka: countries.fi,
  'pelican-lambarene': countries.ga,
  'pelikan-lowicz-lowicz': countries.pl,
  'pelister-bitola': countries.mk,
  'pelotas-pelotas-rio-grande-do-sul': countries.br,
  'pembroke-athleta-pembroke': countries.mt,
  'pena-azagresa-azagra': countries.es,
  'pena-deportiva': countries.es,
  'pena-sport-tafalla': countries.es,
  'penafiel-penafiel': countries.pt,
  'penapolense-penapolis-sao-paulo': countries.br,
  'penarol-montevideo': countries.uy,
  'penarol-u20': countries.uy,
  'pendikspor-istanbul': countries.tr,
  'penicuik-athletic-penicuik': countries.gb,
  'peninsula-power-brisbane': countries.au,
  'pennarossa-chiesanuova': countries.sm,
  'penrhyncoch-penrhyncoch-ceredigion': countries.gb,
  'penya-encarnada-andorra-la-vella': countries.ad,
  'penya-independent-sant-miquel-de-balansat': countries.es,
  'penybont-bridgend': countries.gb,
  'penzugyor-budapest': countries.hu,
  'pepo-lappeenranta': countries.fi,
  'perak-ipoh': countries.my,
  'peralada-peralada': countries.es,
  'peresvet-domodedovo': countries.ru,
  'peresvet-ii-domodedovo': countries.ru,
  'peresvet-podolsk-podolsk': countries.ru,
  'perez-zeledon-san-isidro-de-el-general': countries.cr,
  'pergolettese-crema': countries.it,
  'perilima-campina-grande-pb': countries.br,
  'pero-pinheiro-pero-pinheiro': countries.pt,
  'perolas-negras-rio-de-janeiro': countries.br,
  'persebaya-surabaya-surabaya': countries.id,
  'persegres-gresik-united-gresik': countries.id,
  'persela-lamongan': countries.id,
  'persepolis-teheran': countries.ir,
  'persib-bandung': countries.id,
  'persiba-balikpapan-balikpapan': countries.id,
  'persija-jakarta': countries.id,
  'persijap-jepara': countries.id,
  'persik-kediri-kediri': countries.id,
  'persipura-jayapura': countries.id,
  'persiraja-banda-aceh-banda-aceh': countries.id,
  'persis-solo-solo': countries.id,
  'persita-tangerang': countries.id,
  'perth-glory-perth-1995': countries.au,
  'perth-glory-u21-perth': countries.au,
  'perth-perth': countries.au,
  'perugia-perugia': countries.it,
  'pescara-pescara': countries.it,
  'pescara-u19': countries.it,
  'pesqueira-pesqueira-pernambuco': countries.br,
  'petare-caracas': countries.ve,
  'peterborough-sports-peterborough': countries.gb,
  'peterborough-u18': countries.gb,
  'peterborough-united-peterborough': countries.gb,
  'peterborough-united-u21': countries.gb,
  'peterborough-united-u23-peterborough': countries.gb,
  'peterhead-peterhead': countries.gb,
  'petone-lower-hutt': countries.nz,
  'petro-de-luanda-luanda': countries.ao,
  'petrocub-hincesti': countries.md,
  'petrolina-petrolina-pernambuco': countries.br,
  'petrolul-52-ploiesti': countries.ro,
  'petrovac-petrovac-na-moru': countries.me,
  'petrzalka-akademia-bratislava': countries.sk,
  pevidem: countries.pt,
  'peyia-pegeia-peyia': countries.cy,
  'pfeddersheim-worms': countries.de,
  'pharco-al-iskandariah-alexandria': countries.eg,
  'phare-du-canal-petit-canal': countries.gp,
  'philadelphia-u-u18-19-philadelphia-pennsylvania': countries.us,
  'philadelphia-union-chester-pennsylvania': countries.us,
  'phitsanulok-phitsanulok': countries.th,
  'phnom-penh-crown-phnom-penh': countries.kh,
  'pho-hien-hung-yen': countries.vn,
  'phoenix-johvi': countries.ee,
  'phoenix-rising-phoenix-arizona': countries.us,
  'phonix-lubeck': countries.de,
  'phrae-united-phrae': countries.th,
  'phu-dong': countries.vn,
  'phu-th-phu-th': countries.vn,
  'piacenza-piacenza': countries.it,
  'pianese-piancastagnaio': countries.it,
  'piast-gliwice-gliwice': countries.pl,
  pib: countries.my,
  'picerno-picerno': countries.it,
  'pierikos-katerini': countries.gr,
  'pieta-hotspurs-pieta': countries.mt,
  pif: countries.fi,
  'pikine-pikine': countries.sn,
  'pinheiro-pinheiro-maranhao': countries.br,
  'pinkafeld-pinkafeld': countries.at,
  'pinzgau-saalfelden-saalfelden-am-steinernen-meer': countries.at,
  'pipinsried-pipinsried': countries.de,
  pirae: countries.pf,
  pirata: countries.pe,
  'pirin-gotse-delchev-gotse-delchev': countries.bg,
  'pirin-razlog-razlog': countries.bg,
  'pirmasens-pirmasens': countries.de,
  'pisa-pisa': countries.it,
  'pisek-pisek': countries.cz,
  'pistoiese-pistoia': countries.it,
  'pitea-pitea': countries.se,
  'pittsburgh-riverhounds-pittsburgh-pennsylvania': countries.us,
  'pk-35-helsinki': countries.fi,
  'pk-37-iisalmi': countries.fi,
  'pkc-83-groningen': countries.nl,
  'pkku-tuusula': countries.fi,
  'pkns-petaling-jaya': countries.my,
  'placido-de-castro-placido-de-castro-acre': countries.br,
  'plateau-united-jos': countries.ng,
  platense: countries.sv,
  'platense-vicente-lopez-provincia-de-buenos-aires': countries.ar,
  'platges-de-calvia': countries.es,
  'platinum-city-rovers-johannesburg': countries.za,
  'platinum-zvishavane': countries.zw,
  'plauen-plauen': countries.de,
  'plaza-amador-ciudad-de-panama': countries.pa,
  'plaza-colonia-colonia-del-sacramento': countries.uy,
  'plopeni-plopeni': countries.ro,
  'plymouth-argyle-plymouth': countries.gb,
  'plymouth-argyle-u18': countries.gb,
  'plymouth-parkway': countries.gb,
  'pobeda-prilep': countries.mk,
  'pobla-mafumet-la-pobla-de-mafumet': countries.es,
  'poblense-sa-pobla': countries.es,
  'pocheon-pocheon': countries.kr,
  'podbeskidzie-bielsko-biala': countries.pl,
  'podbrezova-podbrezova': countries.sk,
  podkonice: countries.sk,
  'podlasie-biala-podlaska-biala-podlaska': countries.pl,
  'podravac-virje-virje': countries.hr,
  'podravina-ludbreg-ludbreg': countries.hr,
  'poggibonsi-poggibonsi': countries.it,
  'pogon-grod-mazowiecki-grodzisk-mazowiecki': countries.pl,
  'pogon-siedlce-siedlce': countries.pl,
  'pogon-szczecin-ii-szczecin': countries.pl,
  'pogon-szczecin-szczecin': countries.pl,
  'pogon-szczecin-u19-szczecin': countries.pl,
  'pogradeci-pogradec': countries.al,
  'pohang-steelers-pohang': countries.kr,
  'pohronie-ziar-nad-hronom': countries.sk,
  'pohu-helsinki': countries.fi,
  'point-fortin-point-fortin': countries.tt,
  'poissy-poissy': countries.fr,
  'poitiers-buxerolles': countries.fr,
  polessya: countries.ua,
  'polet-ljubic-cacak': countries.rs,
  'polet-sveti-martin-na-muri': countries.hr,
  police: countries.rw,
  'police-saint-james': countries.tt,
  'police-tero-bangkok': countries.th,
  'police-xi-gaborone': countries.bw,
  'polideportivo-almeria-almeria': countries.es,
  'polimer-barnaul': countries.ru,
  'politehnica-ia-i-iasi': countries.ro,
  'polkowice-polkowice': countries.pl,
  'pollok-glasgow': countries.gb,
  'polokwane-city-polokwane': countries.za,
  'polonia-bytom-bytom': countries.pl,
  'polonia-nysa': countries.pl,
  'polonia-sroda-sroda-wielkopolska': countries.pl,
  'polonia-warszawa-warszawa': countries.pl,
  'polotsk-polotsk': countries.by,
  'polvorin-lugo': countries.es,
  'ponferradina-ii': countries.es,
  'ponferradina-ponferrada': countries.es,
  ponikve: countries.hr,
  'pontardawe-town-pontardawe-neath-port-talbot': countries.gb,
  'pontarlier-pontarlier': countries.fr,
  'ponte-preta-campinas-sao-paulo': countries.br,
  'pontedera-pontedera': countries.it,
  'pontefract-collieries': countries.gb,
  pontellas: countries.es,
  'pontevedra-pontevedra': countries.es,
  'pontisola-ponte-san-pietro': countries.it,
  'pontivy-gsi-pontivy': countries.fr,
  'pontypridd-town-afc-pontypridd': countries.gb,
  'poole-town-poole-dorset': countries.gb,
  'pope-ti-leordeni-pope-ti-leordeni': countries.ro,
  'poprad-poprad': countries.sk,
  'pordenone-pordenone': countries.it,
  'porrino-industrial-o-porrino': countries.es,
  'pors-grenland-porsgrunn': countries.no,
  'port-bangkok': countries.th,
  'port-melbourne-melbourne': countries.au,
  'port-vale-stoke-on-trent-staffordshire': countries.gb,
  'port-vale-u18': countries.gb,
  'portadown-portadown': countries.gb,
  'portalban-gletterens': countries.ch,
  'portes-entre-deux-mers-camblanes-et-meynac-nouvelle-aquitaine': countries.fr,
  'portimonense-portimao': countries.pt,
  'portimonense-u23-portimao': countries.pt,
  'portland-timbers-ii-portland-oregon': countries.us,
  'portland-timbers-portland-oregon': countries.us,
  'portland-timbers-u19-portland-oregon': countries.us,
  portmany: countries.es,
  'portmore-united-portmore': countries.jm,
  'porto-caruaru-pernambuco': countries.br,
  'porto-ii-porto': countries.pt,
  'porto-porto': countries.pt,
  'porto-u19-porto': countries.pt,
  'porto-u23-porto': countries.pt,
  'porto-velho': countries.br,
  'portosummaga-portogruaro': countries.it,
  'portsmouth-portsmouth': countries.gb,
  'portsmouth-u18': countries.gb,
  'portstewart-portstewart': countries.gb,
  'portugalete-portugalete': countries.es,
  'portuguesa-acarigua': countries.ve,
  'portuguesa-rj-rio-de-janeiro-rio-de-janeiro': countries.br,
  'portuguesa-santista-santos-sao-paulo': countries.br,
  'portuguesa-sao-paulo-sao-paulo': countries.br,
  post: countries.at,
  'posta-rangers-nairobi': countries.ke,
  'posusje-posusje': countries.ba,
  'potencia-montevideo': countries.uy,
  'potenza-calcio-potenza': countries.it,
  'potiguar-mossoro-mossoro-rio-grande-do-norte': countries.br,
  'potters-bar-town-potters-bar-hertfordshire': countries.gb,
  'potyguar-currais-novos-rio-grande-do-norte': countries.br,
  'pouso-alegre': countries.br,
  'pouzauges-reaumur-pouzauges': countries.fr,
  'povazska-bystrica': countries.sk,
  'power-dynamos-kitwe': countries.zm,
  'pozoblanco-pozoblanco': countries.es,
  'pozuelo-alarcon': countries.es,
  ppj: countries.fi,
  prachuap: countries.th,
  'praiense-praia-de-vitoria': countries.pt,
  'prat-el-prat-de-llobregat': countries.es,
  'prato-prato': countries.it,
  'praviano-pravia': countries.es,
  'prepere-prepere': countries.cz,
  'prescot-cables-prescot-merseyside': countries.gb,
  'prestatyn-town-prestatyn-denbighshire': countries.gb,
  'prestice-prestice': countries.cz,
  'preston-lions': countries.au,
  'preston-north-end-preston': countries.gb,
  'preston-north-end-u18-preston-lancashire': countries.gb,
  'pretoria-callies-atteridgeville-pretoria': countries.za,
  'preussen-munster-munster': countries.de,
  'preussen-munster-u17-munster': countries.de,
  'preussen-munster-u19-munster': countries.de,
  'pribram-pribram': countries.cz,
  'pribram-u19-pribram': countries.cz,
  'primavera-sp-indaiatuba-sao-paulo': countries.br,
  'primorac-biograd-biograd-na-moru': countries.hr,
  'primorje-ajdovscina': countries.si,
  'princesa-solimoes-manacapuru-amazonas': countries.br,
  'prishtina-prishtine-pristina': countries.xk,
  'prix-les-mezieres': countries.fr,
  'pro-patria-busto-arsizio': countries.it,
  'pro-sesto-sesto-san-giovanni': countries.it,
  'pro-vercelli-vercelli': countries.it,
  'progres-niederkorn-niederkorn': countries.lu,
  'progreso-montevideo': countries.uy,
  'progress-afa-olaine': countries.lv,
  'progresul-pecica': countries.ro,
  'progresul-spartac-bucure-ti': countries.ro,
  'proodeftiki-neo-ikonio': countries.gr,
  'prospera-criciuma-criciuma': countries.br,
  'prostejov-prostejov': countries.cz,
  'provincial-osorno-osorno': countries.cl,
  'provincial-ovalle-ovalle': countries.cl,
  'provincial-ranco-la-union': countries.cl,
  'prudentopolis-prudentopolis-parana': countries.br,
  'prykarpattia-ivano-frankivsk': countries.ua,
  'ps-tira-bantul': countries.id,
  'psg-ii-paris': countries.fr,
  'psg-paris': countries.fr,
  'psg-u19-paris': countries.fr,
  'psg-u21-paris': countries.fr,
  'psis-semarang-semarang': countries.id,
  'psm-makassar': countries.id,
  'psms-medan': countries.id,
  'psni-belfast': countries.gb,
  'psps-pekanbaru': countries.id,
  'pss-sleman-sleman': countries.id,
  'pstc-londrina-parana': countries.br,
  'psv-eindhoven': countries.nl,
  'psv-ii-eindhoven': countries.nl,
  'psv-u19-eindhoven': countries.nl,
  'psv-u21': countries.nl,
  'psv-u23-eindhoven': countries.nl,
  puaikura: countries.ck,
  'puchov-puchov': countries.sk,
  'pucioasa-aninoasa': countries.ro,
  'puebla-puebla': countries.mx,
  'puente-genil-puente-genil': countries.es,
  'puerto-montt-puerto-montt': countries.cl,
  'puerto-nuevo-campana-provincia-de-buenos-aires': countries.ar,
  'puerto-quito-puerto-quito': countries.ec,
  'pulau-pinang-penang': countries.my,
  'puma-generalena-san-isidro-de-el-general': countries.cr,
  'pumas-tabasco-villahermosa-tabasco': countries.mx,
  'pumas-unam-delegacion-coyoacan-ciudad-de-mexico-d-f': countries.mx,
  'pumas-unam-u20': countries.mx,
  'puntarenas-puntarenas': countries.cr,
  'purmersteijn-purmerend': countries.nl,
  'puskas-akademia-u19-felcsut': countries.hu,
  'puskas-felcsut': countries.hu,
  'puszcza-niepolomice-niepolomice': countries.pl,
  'puteolana-internapoli-pozzuoli': countries.it,
  'putnok-vse': countries.hu,
  'pwd-bamenda': countries.cm,
  'pyeongtaek-citizen-pyeongtaek': countries.kr,
  'pyramids-asyut': countries.eg,
  'pyunik-u19-yerevan': countries.am,
  'pyunik-yerevan': countries.am,
  'q-b-l-q-b-l-qabala': countries.az,
  'qabala-u19-q-b-l-qabala': countries.az,
  qalali: countries.bh,
  'qarabag-baki-baku': countries.az,
  'qaradag-lokbatan-baki-baku': countries.az,
  'qatar-sc-ad-doha-doha': countries.qa,
  'qingdao-jonoon-qingdao': countries.cn,
  'qingdao-youth-island-qingdao-shandong': countries.cn,
  'qizilqum-zarafshon': countries.uz,
  'qormi-qormi': countries.mt,
  'qrendi-qrendi': countries.mt,
  'quality-dededo': countries.gu,
  'quang-nam-tam-k-tam-ky': countries.vn,
  'queen-of-the-south-dumfries': countries.gb,
  'queen-of-the-south-res-dumfries': countries.gb,
  'queen-s-park-glasgow': countries.gb,
  'queen-s-park-res-glasgow': countries.gb,
  'queen-s-university-belfast': countries.gb,
  'queens-park-rangers': countries.gd,
  'queens-park-rangers-london': countries.gb,
  'queens-park-rangers-u18': countries.gb,
  'queens-park-rangers-u21-london': countries.gb,
  'queensland-lions-brisbane': countries.au,
  'queimadense-queimadas-paraiba': countries.br,
  'queretaro-queretaro': countries.mx,
  'queretaro-u20': countries.mx,
  quetigny: countries.fr,
  'quevilly-ii-le-petit-quevilly': countries.fr,
  'quevilly-rouen-le-petit-quevilly': countries.fr,
  'quick-20-oldenzaal': countries.nl,
  'quick-boys-katwijk': countries.nl,
  'quilmes-quilmes-provincia-de-buenos-aires': countries.ar,
  'quintanar-del-rey': countries.es,
  'quintero-unido-quintero': countries.cl,
  'qviding-fif-goteborg': countries.se,
  'rabat-ajax-rabat': countries.mt,
  'rabo-peixe': countries.pt,
  'rabotnicki-skopje': countries.mk,
  'raca-bratislava': countries.sk,
  'racing-besancon-besancon-1904': countries.fr,
  'racing-club-avellaneda-provincia-de-buenos-aires': countries.ar,
  'racing-club-haitien-port-au-prince': countries.ht,
  'racing-colombes-92-colombes': countries.fr,
  'racing-cordoba-ciudad-de-cordoba-provincia-de-cordoba': countries.ar,
  'racing-d-abidjan-abidjan': countries.ci,
  'racing-des-gonaives-gonaives': countries.ht,
  'racing-ferrol-ferrol': countries.es,
  'racing-lermeno': countries.es,
  'racing-letzebuerg-luxembourg': countries.lu,
  'racing-mechelen-mechelen': countries.be,
  'racing-montevideo': countries.uy,
  'racing-murcia': countries.es,
  'racing-olavarria-olavarria-provincia-de-buenos-aires': countries.ar,
  'racing-rioja-ii-logrono': countries.es,
  'racing-rioja-logrono': countries.es,
  'racing-santander-ii-santander': countries.es,
  'racing-santander-santander': countries.es,
  'racing-trelew-trelew-provincia-de-chubut': countries.ar,
  'racing-union-u19-letzebuerg-luxembourg': countries.lu,
  'racing-waregem-waregem': countries.be,
  'rad-beograd-beograd': countries.rs,
  'radcliffe-borough-radcliffe-greater-manchester': countries.gb,
  'rades-rades': countries.tn,
  'radnicki-kragujevac-kragujevac': countries.rs,
  'radnicki-lukavac-lukavac': countries.ba,
  'radnicki-nis-nis': countries.rs,
  'radnicki-novi-beograd-beograd': countries.rs,
  'radnicki-pirot-pirot': countries.rs,
  'radnicki-sombor-sombor': countries.rs,
  'radnicki-sr-mitrovica-sremska-mitrovica': countries.rs,
  'radnik-bijeljina-bijeljina': countries.ba,
  'radnik-surdulica-surdulica': countries.rs,
  'radomiak-radom-radom': countries.pl,
  'radomlje-radomlje': countries.si,
  'radunia-stezyca-stezyca': countries.pl,
  'ragsved-stockholm': countries.se,
  'rahimo-bama': countries.bf,
  'rahmatgonj-mfs-dhaka': countries.bd,
  'rain-lech-rain': countries.de,
  'raith-rovers-kirkcaldy': countries.gb,
  'raj-pracha-nonthaburi': countries.th,
  'raja-beni-mellal-beni-mellal': countries.ma,
  'raja-casablanca-casablanca': countries.ma,
  rajasthan: countries.in,
  'rakow-czestochowa-czestochowa': countries.pl,
  'rakvere-tarvas-rakvere': countries.ee,
  'ramnicu-sarat-ramnicu-sarat': countries.ro,
  'rampla-juniors-montevideo': countries.uy,
  'ramsbottom-united-ramsbottom': countries.gb,
  'ramsgate-ramsgate-kent': countries.gb,
  'rancagua-sur-rancagua': countries.cl,
  'randers-randers': countries.dk,
  'rangers-glasgow': countries.gb,
  'rangers-talca': countries.cl,
  'rangers-u19': countries.gb,
  'rangers-u21-glasgow': countries.gb,
  'ranheim-ranheim': countries.no,
  'ranong-united-ranong': countries.th,
  'raon-l-etape-raon-l-etape': countries.fr,
  'rapid-bucuresti-bucuresti': countries.ro,
  'rapid-symphorinois-saint-symphorien': countries.be,
  'rapid-wien-ii-wien': countries.at,
  'rapid-wien-u18-wien': countries.at,
  'rapid-wien-wien': countries.at,
  'rapide-oued-zem': countries.ma,
  'rapido-bouzas-vigo': countries.es,
  'rapitenca-sant-carles-de-la-rapita': countries.es,
  'raplamaa-rapla': countries.ee,
  'rappe-vaxjo': countries.se,
  'rapperswil-jona-rapperswil': countries.ch,
  'raspadskaya-mezhdurechensk': countries.ru,
  'ratchaburi-ratchaburi': countries.th,
  'ratnam-colombo': countries.lk,
  'raufoss-raufoss': countries.no,
  'ravenna-ravenna': countries.it,
  'ravensburg-ravensburg': countries.de,
  'ravshan-kulob-kulyab': countries.tj,
  'raya2-monterrey': countries.mx,
  'rayo-ibense-alicante': countries.es,
  'rayo-majadahonda-majadahonda': countries.es,
  'rayo-vallecano-ii-madrid': countries.es,
  'rayo-vallecano-madrid': countries.es,
  'rayo-zuliano-maracaibo': countries.ve,
  'rayon-sports-kigali': countries.rw,
  'rayong-rayong': countries.th,
  'rb-brasil-campinas-sao-paulo': countries.br,
  'rb-leipzig-leipzig': countries.de,
  'rb-leipzig-u19-leipzig': countries.de,
  'rc-arbaa-larbaa': countries.dz,
  'rc-bobo-dioulasso-bobo-dioulasso': countries.bf,
  'rc-kouba-kouba': countries.dz,
  'rck-kadiogo': countries.bf,
  'reading-reading-berkshire': countries.gb,
  'reading-u18': countries.gb,
  'reading-u21-reading': countries.gb,
  'reading-u23-reading': countries.gb,
  'reading-united-reading-pennsylvania': countries.us,
  'real-ariquemes-ariquemes-rondonia': countries.br,
  'real-avila-avila': countries.es,
  'real-aviles-aviles': countries.es,
  'real-bamako-bamako': countries.ml,
  'real-betis-ii-sevilla': countries.es,
  'real-betis-sevilla': countries.es,
  'real-burgos-burgos': countries.es,
  'real-cartagena-cartagena-de-indias': countries.co,
  'real-colorado-u19-centennial-colorado': countries.us,
  'real-de-banjul-banjul': countries.gm,
  'real-espana-san-pedro-sula': countries.hn,
  'real-esteli-esteli': countries.ni,
  'real-frontera-san-antonio-del-tachira': countries.ve,
  'real-garcilaso-cuzco': countries.pe,
  'real-jaen-jaen': countries.es,
  'real-jersey-u19-medford-new-jersey': countries.us,
  'real-kashmir-kashmir': countries.in,
  'real-kings-durban': countries.za,
  'real-madrid-ii-madrid': countries.es,
  'real-madrid-madrid': countries.es,
  'real-madrid-u19-madrid': countries.es,
  'real-massama': countries.pt,
  'real-monarchs-sandy': countries.us,
  'real-murcia-ii-murcia': countries.es,
  'real-murcia-murcia': countries.es,
  'real-noroeste-aguia-branca-espirito-santo': countries.br,
  'real-oviedo-ii-oviedo': countries.es,
  'real-oviedo-oviedo': countries.es,
  'real-pharm-ovidiopol': countries.ua,
  'real-pilar-pilar-provincia-de-buenos-aires': countries.ar,
  'real-salt-lake-salt-lake-city-utah': countries.us,
  'real-salt-lake-u17': countries.us,
  'real-salt-lake-u19-salt-lake-city-utah': countries.us,
  'real-san-andres-bucaramanga': countries.co,
  'real-san-joaquin-san-joaquin': countries.cl,
  'real-sao-luiz-do-anaua-roraima': countries.br,
  'real-sociedad-donostia-san-sebastian': countries.es,
  'real-sociedad-ii-san-sebastian': countries.es,
  'real-sociedad-tocoa': countries.hn,
  'real-sociedad-u19-san-sebastian': countries.es,
  'real-succes-chisinau': countries.md,
  'real-tamale-tamale': countries.gh,
  'real-tapia': countries.es,
  'real-titanico-laviana': countries.es,
  'real-tomayapo': countries.bo,
  'real-union-irun': countries.es,
  'real-valladolid-ii-valladolid': countries.es,
  'real-valladolid-valladolid': countries.es,
  'real-zaragoza-ii-zaragoza': countries.es,
  'real-zaragoza-u19': countries.es,
  'real-zaragoza-zaragoza': countries.es,
  'rebecq-rebecq': countries.be,
  'reboceros-la-piedad-la-piedad': countries.mx,
  'rebordosa-rebordosa': countries.pt,
  'recanatese-recanati': countries.it,
  'recoleta-recoleta-santiago': countries.cl,
  'recolta-gheorghe-doja': countries.ro,
  'recreativo-do-libolo-calulo': countries.ao,
  'recreativo-huelva-huelva': countries.es,
  'red-arrows-lusaka': countries.zm,
  'red-star-saint-ouen': countries.fr,
  'red-star-zurich-zurich': countries.ch,
  'redditch-united-redditch-worcestershire': countries.gb,
  'redlands-united-brisbane': countries.au,
  'regar-tadaz-tursunzoda': countries.tj,
  'reggiana-regio-emilia': countries.it,
  'reggina-reggio-calabria': countries.it,
  'reggina-u19': countries.it,
  'reims-ii-reims': countries.fr,
  'reims-reims': countries.fr,
  'reims-sainte-anne': countries.fr,
  'reims-u19-reims': countries.fr,
  'reinmeer-aomori-aomori': countries.jp,
  'reipas-lahti': countries.fi,
  rejiche: countries.tn,
  'rekord-bielsko-biala-bielsko-biala': countries.pl,
  'relizane-relizane': countries.dz,
  'remo-belem-para': countries.br,
  'remo-stars-remo': countries.ng,
  'renaissance-ngoumou': countries.cm,
  'renate-renate': countries.it,
  'rennes-ii-rennes': countries.fr,
  'rennes-rennes': countries.fr,
  'renofa-yamaguchi': countries.jp,
  'renova-dzepiste-dzepishte': countries.mk,
  renown: countries.lk,
  'rentistas-montevideo': countries.uy,
  requena: countries.es,
  'res-durbuy-barvaux': countries.be,
  resende: countries.pt,
  'resende-resende-rio-de-janeiro': countries.br,
  'resistencia-asuncion': countries.py,
  'resistencia-central-resistencia-provincia-del-chaco': countries.ar,
  'resources-capital-hong-kong': countries.hk,
  'resovia-rzeszow-rzeszow': countries.pl,
  retro: countries.br,
  'retz-retz': countries.at,
  'reutlingen-reutlingen': countries.de,
  'rewa-nausori': countries.fj,
  'reynir-sandgerdi': countries.is,
  'rezekne-bjss-rezekne': countries.lv,
  'rfc-seraing-seraing': countries.be,
  'rfc-wetteren-wetteren': countries.be,
  'rgv-toros-u19-edinburg-texas': countries.us,
  'rheindorf-altach-altach': countries.at,
  'rheindorf-altach-ii-altach': countries.at,
  'riadi-salmi-salmi': countries.ma,
  'ribadumia-ribadumia': countries.es,
  'ribarroja-riba-roja-de-turia-ribarroja-del-turia': countries.es,
  'richards-bay-richards-bay': countries.za,
  'richmond-kickers-richmond-virginia': countries.us,
  'ried-ii-ried': countries.at,
  'ried-ried-im-innkreis': countries.at,
  'ried-u18': countries.at,
  'rielasingen-arlen': countries.de,
  'riga-riga': countries.lv,
  'rigas-fs-riga-riga': countries.lv,
  'rijeka-rijeka': countries.hr,
  'rijnsburgse-boys-rijnsburg': countries.nl,
  'rijnvogels-katwijk': countries.nl,
  'rimavska-sobota-rimavska-sobota': countries.sk,
  'rimini-rimini': countries.it,
  'rincon-malaga': countries.es,
  'rinconeda-polanco-polanco': countries.es,
  ringkobing: countries.dk,
  'rio-ave-ii-vila-do-conde': countries.pt,
  'rio-ave-vila-do-conde': countries.pt,
  'rio-branco-es-vitoria-espirito-santo': countries.br,
  'rio-branco-pr-paranagua-parana': countries.br,
  'rio-branco-rio-branco-acre': countries.br,
  'rio-branco-sp-americana-sao-paulo': countries.br,
  'rio-branco-vn-venda-nova-do-imigrante-espirito-santo': countries.br,
  'rio-claro-rio-claro-sao-paulo': countries.br,
  'rio-grande-valley-edinburg-texas': countries.us,
  'rio-maior-sc-rio-maior': countries.pt,
  'rio-preto-sao-jose-do-rio-preto-sao-paulo': countries.br,
  'rionegro-rionegro': countries.co,
  'ripensia-timisoara-timisoara': countries.ro,
  'rips-riihimaki': countries.fi,
  'ritzing-ritzing': countries.at,
  'rivadavia-lincoln-lincoln-provincia-de-buenos-aires': countries.ar,
  'rivadavia-venado-tuerto-venado-tuerto-provincia-de-santa-fe': countries.ar,
  'river-ebro-rincon-de-soto': countries.es,
  'river-ega-andosilla': countries.es,
  'river-melilla-melilla': countries.es,
  'river-plate-asuncion': countries.py,
  'river-plate-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'river-plate-montevideo': countries.uy,
  'river-teresina-piaui': countries.br,
  riverball: countries.fi,
  'rivers-united-port-harcourt': countries.ng,
  'rize-ozel-idarespor-rize': countries.tr,
  'rizespor-rize': countries.tr,
  'rizespor-u19-rize': countries.tr,
  'rkav-volendam-volendam': countries.nl,
  'rkavv-leidschendam': countries.nl,
  'rkc-waalwijk-waalwijk': countries.nl,
  'rkhvv-huissen': countries.nl,
  'rksv-nuenen-nuenen': countries.nl,
  'rkvv-velsen-driehuis': countries.nl,
  'rkzvc-zieuwent': countries.nl,
  'rm-hamm-benfica-letzebuerg-luxembourg': countries.lu,
  'roasso-kumamoto-kumamoto': countries.jp,
  'robi-shapira-haifa': countries.il,
  'robin-hood-a62a4b74-db3e-4988-852d-53a530b25516': countries.sr,
  robres: countries.es,
  'robur-siena-siena': countries.it,
  'rocafuerte-guayaquil': countries.ec,
  'rocha-rocha': countries.uy,
  'rochdale-rochdale-greater-manchester': countries.gb,
  'rochdale-u18': countries.gb,
  'rochefort-rochefort': countries.be,
  'rochester-rhinos-rochester-new-york': countries.us,
  'rochling-volklingen-volklingen': countries.de,
  'rockdale-city-suns-sydney': countries.au,
  'roda-46-zat-leusden': countries.nl,
  'roda-castello-de-la-plana': countries.es,
  'roda-jc-kerkrade': countries.nl,
  rodange: countries.lu,
  'rodelindo-roman-santiago-de-chile': countries.cl,
  'rodeo-del-medio-rodeo-del-medio-provincia-de-mendoza': countries.ar,
  'rodez-ii-onet-le-chateau': countries.fr,
  'rodez-onet-le-chateau': countries.fr,
  'rodina-moskva': countries.ru,
  'rodina-moskva-ii-moskva': countries.ru,
  'rodina-moskva-iii-moskva': countries.ru,
  'rodinghausen-rodinghausen': countries.de,
  'rodopa-smolyan-smolyan': countries.bg,
  'rodos-rodos': countries.gr,
  'rogaska-rogaska': countries.si,
  'rohda-raalte-raalte': countries.nl,
  'roi-united-rovaniemi': countries.fi,
  'rokycany-rokycany': countries.cz,
  'roma-roma': countries.it,
  'roma-u19-roma': countries.it,
  'romorantin-romorantin-lanthenay': countries.fr,
  'rondoniense-vilhena-rondonia': countries.br,
  'rops-ii-rovaniemi': countries.fi,
  'rops-rovaniemi': countries.fi,
  'rosario-central-rosario-provincia-de-santa-fe': countries.ar,
  'rosenborg-ii-trondheim': countries.no,
  'rosenborg-trondheim': countries.no,
  'rosenborg-u19-trondheim': countries.no,
  'rosengard-malmo': countries.se,
  'roskilde-roskilde': countries.dk,
  'ross-county-dingwall': countries.gb,
  'rostov-rostov-na-donu': countries.ru,
  'rot-weiss-ahlen-ahlen': countries.de,
  'rot-weiss-erfurt-erfurt': countries.de,
  'rot-weiss-essen-essen': countries.de,
  'rot-weiss-essen-u17-essen': countries.de,
  'rot-weiss-essen-u19-essen': countries.de,
  'rot-weiss-oberhausen-oberhausen': countries.de,
  'rot-weiss-rankweil-rankweil': countries.at,
  'rotenberg-lingenau': countries.at,
  'rotherham-u18': countries.gb,
  'rotherham-united-rotherham': countries.gb,
  'rothes-rothes': countries.gb,
  rothis: countries.at,
  rotkreuz: countries.ch,
  'rotor-volgograd-volgograd': countries.ru,
  'rouen-le-petit-quevilly': countries.fr,
  'rousset-ste-victoire': countries.fr,
  'rovers-dededo': countries.gu,
  'royal-antwerp-ii-deurne': countries.be,
  'royal-leopards-mbabane': countries.sz,
  'royal-pari-santa-cruz-de-la-sierra': countries.bo,
  'royal-thai-fleet-rayong': countries.th,
  'royston-town-royston-hertfordshire': countries.gb,
  'rozova-dolina-kazanlak': countries.bg,
  'rsb-berkane-berkane': countries.ma,
  'rsc-anderlecht-ii-bruxelles-brussel': countries.be,
  'rsc-internacional-madrid': countries.es,
  'rsd-alcala-alcala-de-henares': countries.es,
  'rubi-rubi': countries.es,
  'rubikon-kyiv-kiev': countries.ua,
  'rubin-kazan-kazan': countries.ru,
  'rubio-nu-asuncion': countries.py,
  'ruch-chorzow-chorzow': countries.pl,
  'rudar-kakanj-kakanj': countries.ba,
  'rudar-labin-labin': countries.hr,
  'rudar-mursko-sredisce-mursko-sredisce': countries.hr,
  'rudar-pljevlja': countries.me,
  'rudar-prijedor-prijedor': countries.ba,
  'rudar-velenje': countries.si,
  'rudes-zagreb': countries.hr,
  'rukh-vynnyky-lviv': countries.ua,
  'rumelange-remeleng-rumelange': countries.lu,
  'rumilly-vallieres-vallieres': countries.fr,
  'rupel-boom-boom': countries.be,
  'rushall-olympic-walsall-west-midlands': countries.gb,
  'rustavi-rustavi': countries.ge,
  'rutherglen-glencairn-rutherglen': countries.gb,
  'ruthin-town-ruthin-rhuthun-denbighshire': countries.gb,
  'ruzomberok-ii-ruzomberok': countries.sk,
  'ruzomberok-ruzomberok': countries.sk,
  'rvvh-ridderkerk': countries.nl,
  'rwdm-brussels-molenbeek-saint-jean': countries.be,
  'ryazan-ryazan': countries.ru,
  'ryukyu-naha': countries.jp,
  's-bail-baki-baku': countries.az,
  'saarbrucken-saarbrucken': countries.de,
  'sabadell-sabadell': countries.es,
  'sabah-baki-baku': countries.az,
  'sabah-kota-kinablu': countries.my,
  'sable-sable-sur-sarthe': countries.fr,
  'saburtalo-ii-tbilisi': countries.ge,
  'saburtalo-tbilisi': countries.ge,
  'sacachispas-chiquimula': countries.gt,
  'sacachispas-villa-soldati-provincia-de-buenos-aires': countries.ar,
  'sacramento-gold': countries.us,
  'sacramento-rep-u19-sacramento-california': countries.us,
  'sacramento-republic-sacramento-california': countries.us,
  'safa-beirut': countries.lb,
  'sagamihara-sagamihara': countries.jp,
  'sagan-tosu-tosu': countries.jp,
  'sagrada-esperanca-dundo': countries.ao,
  'saguntino-sagunto-sagunt': countries.es,
  'sai-gon-thanh-ph-h-chi-minh-ho-chi-minh-city': countries.vn,
  'saif-dhaka': countries.bd,
  'saint-apollinaire-saint-apollinaire': countries.fr,
  'saint-colomban-locmine-locmine': countries.fr,
  'saint-denis-saint-clotilde': countries.re,
  'saint-eloi-lupopo-lubumbashi': countries.cd,
  'saint-etienne-ii-l-etrat': countries.fr,
  'saint-etienne-saint-etienne': countries.fr,
  'saint-georges-cayenne': countries.gf,
  'saint-jean-le-blanc-saint-jean-le-blanc': countries.fr,
  'saint-lo-manche-saint-lo': countries.fr,
  'saint-louis-fenton-missouri': countries.us,
  'saint-louis-u19-saint-louis-missouri': countries.us,
  'saint-malo-saint-malo': countries.fr,
  'saint-nazaire-af-saint-nazaire': countries.fr,
  'saint-pauloise-saint-paul': countries.re,
  'saint-pierre-milizac': countries.fr,
  'saint-pierroise-saint-pierre': countries.re,
  'saint-priest-saint-priest': countries.fr,
  'sainte-genevieve-sainte-genevieve-des-bois': countries.fr,
  'sainte-marienne-sainte-marie': countries.re,
  'sainte-suzanne-sainte-suzanne': countries.re,
  'saipa-karaj-karadj': countries.ir,
  'sak-klagenfurt-klagenfurt-am-worthersee': countries.at,
  'sakaryaspor-adapazari': countries.tr,
  'sakhalin-yuzhno-sakhalinsk': countries.ru,
  sakhalinets: countries.ru,
  'salam-zgharta': countries.lb,
  'salamanca-ii': countries.es,
  'salamanca-uds-salamanca': countries.es,
  'salaspils-salaspils': countries.lv,
  'salernitana-salerno': countries.it,
  'salford-city-salford-greater-manchester': countries.gb,
  'salford-city-u18': countries.gb,
  'salgueiro-salgueiro-pernambuco': countries.br,
  'salgueiros-pedroucos': countries.pt,
  'salihli-belediyespor-salihli': countries.tr,
  'salinieres-aigues-mortes': countries.fr,
  'salisbury-salisbury-wiltshire': countries.gb,
  salitas: countries.bf,
  'salmrohr-wittlich': countries.de,
  'salpa-salo': countries.fi,
  'salyut-belgorod-belgorod': countries.ru,
  'salzburg-u19-salzburg': countries.at,
  'salzburg-wals-siezenheim': countries.at,
  'salzburger-ak': countries.at,
  'samandag-genclerbirligi-samandag': countries.tr,
  'samano-castro-urdiales': countries.es,
  samaritaine: countries.mq,
  'sambenedettese-san-benedetto-del-tronto': countries.it,
  'samgurali-ii-tskaltubo': countries.ge,
  'samgurali-tskaltubo': countries.ge,
  'samobor-samobor': countries.hr,
  'samorin-samorin': countries.sk,
  'sampaio-correa-sao-luis-maranhao': countries.br,
  'sampdoria-genova': countries.it,
  'sampdoria-u19': countries.it,
  'sampion-celje-celje': countries.si,
  'samsun-buyuksehir-bs-samsun': countries.tr,
  'samsunspor-samsun': countries.tr,
  'samtredia-samtredia': countries.ge,
  'samut-prakan-city-pattaya': countries.th,
  'samut-songkhram-samut-songkhram': countries.th,
  'san-agustin-del-guadalix-madrid': countries.es,
  'san-antonio-san-antonio-texas': countries.us,
  'san-antonio-unido-san-antonio': countries.cl,
  'san-carlos-ciudad-quesada': countries.cr,
  'san-claudio-san-claudio-oviedo': countries.es,
  'san-cristobal-terrassa': countries.es,
  'san-diego-loyal': countries.us,
  'san-donato-tavarnelle-tavarnelle-val-di-pesa': countries.it,
  'san-fernando-cd-san-fernando': countries.es,
  'san-fernando-de-henares-san-fernando-de-henares': countries.es,
  'san-francisco-city-san-francisco': countries.us,
  'san-francisco-san-francisco': countries.pa,
  'san-giovanni-san-giovanni': countries.sm,
  'san-gwann-san-gwann': countries.mt,
  'san-ignacio-vitoria-gasteiz': countries.es,
  'san-jose-earthquakes-ii-santa-clara-california': countries.us,
  'san-juan-jabloteh-san-juan': countries.tt,
  'san-juan-pamplona': countries.es,
  'san-lorenzo-alem-catamarca-provincia-de-catamarca': countries.ar,
  'san-lorenzo-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'san-luis-quillota': countries.cl,
  'san-marcos-arica': countries.cl,
  'san-martin-burzaco-burzaco-provincia-de-buenos-aires': countries.ar,
  'san-martin-formosa-ciudad-de-formosa-provincia-de-formosa': countries.ar,
  'san-martin-mendoza-san-martin-provincia-de-mendoza': countries.ar,
  'san-martin-san-juan-san-juan-provincia-de-san-juan': countries.ar,
  'san-martin-tucuman-san-miguel-de-tucuman-provincia-de-tucuman': countries.ar,
  'san-miguel-los-polvorines-provincia-de-buenos-aires': countries.ar,
  'san-nicolo-notaresco': countries.it,
  'san-pedro-martir': countries.es,
  'san-pedro-san-pedro-59eb86dd-1be6-43aa-a2dd-2099487e8b5e': countries.ci,
  'san-roque-de-lepe-lepe': countries.es,
  'san-telmo-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'sanat-naft-abadan': countries.ir,
  'sanatatea-cluj-cluj-napoca': countries.ro,
  'sancaktepe-belediyespor-istanbul': countries.tr,
  'sandecja-nowy-sacz-nowy-sacz': countries.pl,
  'sandefjord-sandefjord': countries.no,
  'sandhausen-sandhausen': countries.de,
  'sandiklispor-sandikli': countries.tr,
  'sandnes-ulf-ii': countries.no,
  'sandnes-ulf-sandnes': countries.no,
  'sandviken-sandviken': countries.se,
  'sandviks-holmsund': countries.se,
  'sandweiler-sandweiler': countries.lu,
  'sanem-sanem': countries.lu,
  'sanfrecce-hiroshima-hiroshima': countries.jp,
  'sanga-balende-mbuji-mayi': countries.cd,
  'sangiuliano-city': countries.it,
  'sangju-sangmu-sangju': countries.kr,
  sanjoanense: countries.pt,
  'sanliurfaspor-sanliurfa': countries.tr,
  'sanluqueno-sanlucar-de-barrameda': countries.es,
  'sanmartin-sanmartin': countries.ro,
  'sanna-khanh-hoa-nha-trang': countries.vn,
  'sannat-lions-sannat': countries.mt,
  'sanremo-san-remo': countries.it,
  'sansinena-general-daniel-cerri-provincia-de-buenos-aires': countries.ar,
  'sant-andreu-barcelona': countries.es,
  'sant-julia-sant-julia-de-loria': countries.ad,
  'santa-ana-santa-ana': countries.cr,
  'santa-barbara-u15-goleta-california': countries.us,
  'santa-catalina-atletico-palma': countries.es,
  'santa-clara-ponta-delgada-ilha-de-sao-miguel-acores': countries.pt,
  'santa-cruz': countries.bo,
  'santa-cruz-de-natal-rn-natal-rio-grande-do-norte': countries.br,
  'santa-cruz-recife-pernambuco': countries.br,
  'santa-cruz-rs-santa-cruz-do-sul-rio-grande-do-sul': countries.br,
  'santa-fe-bogota-d-c': countries.co,
  'santa-lucia-cotzumalguapa': countries.gt,
  'santa-lucia-santa-lucija': countries.mt,
  'santa-rosa-pnp-andahuaylas': countries.pe,
  'santa-tecla-santa-tecla': countries.sv,
  'santa-ursula-santa-ursula': countries.es,
  'santacruzense-santa-cruz-do-rio-pardo-sao-paulo': countries.br,
  'santana-santana-amapa': countries.br,
  'santanyi-santanyi': countries.es,
  'santboia-sant-boi-de-llobregat': countries.es,
  'santfeliuenc-barcelona': countries.es,
  'santiago-morning-santiago-de-chile': countries.cl,
  'santiago-wanderers-valparaiso': countries.cl,
  'santo-andre-santo-andre-sao-paulo': countries.br,
  'santos-ap-macapa-amapa': countries.br,
  'santos-b-santos-sao-paulo': countries.br,
  'santos-de-guapiles-guapiles': countries.cr,
  'santos-departamento-de-ica': countries.pe,
  'santos-laguna-torreon': countries.mx,
  'santos-laguna-u20': countries.mx,
  'santos-santos-sao-paulo': countries.br,
  santurtzi: countries.es,
  'sao-bento-sorocaba-sao-paulo': countries.br,
  'sao-bernardo-sao-bernardo-do-campo-sao-paulo': countries.br,
  'sao-caetano-sao-caetano-do-sul-sao-paulo': countries.br,
  'sao-carlos-sao-carlos-sao-paulo': countries.br,
  'sao-gabriel-sao-gabriel-rs': countries.br,
  'sao-joao-ver': countries.pt,
  'sao-jose-ec-sao-jose-dos-campos-sao-paulo': countries.br,
  'sao-jose-ma-sao-jose-de-ribamar-maranhao': countries.br,
  'sao-luiz-ijui-rio-grande-do-sul': countries.br,
  'sao-paulo-crystal': countries.br,
  'sao-paulo-rs-rio-grande-rio-grande-do-sul': countries.br,
  'sao-paulo-sao-paulo-sao-paulo': countries.br,
  'sao-paulo-u20-sao-paulo-sao-paulo': countries.br,
  'sao-raimundo-am-manaus-amazonas': countries.br,
  'sao-raimundo-rr-boa-vista-roraima': countries.br,
  'saoura-meridja': countries.dz,
  'sapancaspor-sapanca': countries.tr,
  'sarajevo-sarajevo': countries.ba,
  'saran-municipal': countries.fr,
  'saraykoyspor-saraykoy': countries.tr,
  'sarayonu-belediyespor-sarayonu': countries.tr,
  'sarinena-sarinena': countries.es,
  'sariyer-istanbul': countries.tr,
  'sarmiento-de-la-banda-la-banda-provincia-de-santiago-del-estero':
    countries.ar,
  'sarmiento-junin-provincia-de-buenos-aires': countries.ar,
  'sarmiento-leones-leones-provincia-de-cordoba': countries.ar,
  'sarmiento-res-junin-provincia-de-buenos-aires': countries.ar,
  'sarmiento-resistencia-resistencia-provincia-del-chaco': countries.ar,
  'sarpsborg-08-sarpsborg': countries.no,
  'sarre-union-sarre-union': countries.fr,
  'sassuolo-sassuolo': countries.it,
  'sassuolo-u19': countries.it,
  'saturn-ramenskoye-ramenskoe': countries.ru,
  'sauchie-juniors-sauchie': countries.gb,
  saue: countries.ee,
  'saumur-saumur': countries.fr,
  'savedalen-savedalen': countries.se,
  'savu-savonlinna': countries.fi,
  'sayana-haskovo-haskovo': countries.bg,
  'sc-feyenoord': countries.nl,
  'sc-united-bantams-greenwood-south-carolina': countries.us,
  'scandicci-scandicci': countries.it,
  'scarborough-athletic-scarborough-north-yorkshire': countries.gb,
  schaesberg: countries.nl,
  'schalding-heining-passau': countries.de,
  'schalke-04-gelsenkirchen': countries.de,
  'schalke-04-ii-gelsenkirchen': countries.de,
  'schalke-04-u17-gelsenkirchen': countries.de,
  'schalke-04-u19-gelsenkirchen': countries.de,
  'schattendorf-schattendorf': countries.at,
  'scheiblingkirchen-scheiblingkirchen': countries.at,
  'scheveningen-den-haag': countries.nl,
  'schifflange-scheffleng-schifflange': countries.lu,
  'schiltigheim-schiltigheim': countries.fr,
  schoftland: countries.ch,
  'schott-mainz-mainz': countries.de,
  'schotz-schotz': countries.ch,
  'schwabmunchen-schwabmunchen': countries.de,
  'schwarz-weiss-bregenz-bregenz': countries.at,
  'schwarz-weiss-essen-essen': countries.de,
  'schwarz-weiss-rehden-rehden': countries.de,
  'schwaz-schwaz': countries.at,
  'schwechat-schwechat': countries.at,
  'schweinfurt-schweinfurt': countries.de,
  'scm-gloria-buzau': countries.ro,
  'scm-zalau': countries.ro,
  'scolar-resita-resita': countries.ro,
  'scunthorpe-united-scunthorpe': countries.gb,
  'sd-logrones-logrono': countries.es,
  'sd-san-pedro-sestao': countries.es,
  'sdc-putten-putten': countries.nl,
  'sdjushor-3': countries.by,
  'sdo-bussum': countries.nl,
  'sdv-barneveld-barneveld': countries.nl,
  'seacoast-united-phantoms-manchester-new-hampshire': countries.us,
  'seacoast-united-u19-hampton-new-hampshire': countries.us,
  'seattle-sounders-ii-renton-washington': countries.us,
  'seattle-sounders-renton-washington': countries.us,
  'seattle-sounders-u18-19-seattle-washington': countries.us,
  'sebat-genclikspor-trabzon': countries.tr,
  'sedan-sedan': countries.fr,
  'seekirchen-seekirchen-am-wallersee': countries.at,
  'segesta-sisak-sisak': countries.hr,
  'sekhukhune-united-kempton-park-gauteng': countries.za,
  'sektzia-nes-tziona-nes-tziona': countries.il,
  'selangor-kuala-lumpur': countries.my,
  'selangor-united-hulu-langat-selangor': countries.my,
  'selenge-press-ulan-bator-ulaanbaatar': countries.mn,
  'selfoss-selfoss': countries.is,
  'seligenporten-seligenporten': countries.de,
  'semassi-sokode': countries.tg,
  'semen-padang-padang': countries.id,
  'sencur-sencur': countries.si,
  'senglea-athletic-senglea': countries.mt,
  'senja-silsand-lenvik': countries.no,
  'senyo-carnifex-senyo': countries.hu,
  'seongnam-seongnam': countries.kr,
  'seoul-e-land-seoul': countries.kr,
  'seoul-seoul': countries.kr,
  'seoul-united-seoul': countries.kr,
  'sepahan-esfahan-isfahan': countries.ir,
  'sepsi-sfantu-gheorghe': countries.ro,
  'septemvri-simitli-simitli': countries.bg,
  'septemvri-sofia-sofia': countries.bg,
  'septemvri-sofia-u19-sofia': countries.bg,
  'septemvri-tervel-tervel': countries.bg,
  'serdivanspor-sakarya': countries.tr,
  'sered-sered': countries.sk,
  'seregno-seregno': countries.it,
  'sergipe-aracaju-sergipe': countries.br,
  'serhat-ardahanspor-ardahan': countries.tr,
  'serik-belediyespor-serik': countries.tr,
  'serra-macaense-macae-rio-de-janeiro': countries.br,
  'serra-serra-espirito-santo': countries.br,
  'serrano-pb-campina-grande-paraiba': countries.br,
  'serrano-rj-petropolis-rio-de-janeiro': countries.br,
  sertanense: countries.pt,
  'sertaozinho-sertaozinho-sao-paulo': countries.br,
  'servette-carouge': countries.ch,
  'sestao-river-sestao': countries.es,
  'sete-de-setembro-pe-garanhuns-pernambuco': countries.br,
  'sete-sete': countries.fr,
  'sevenoaks-town': countries.gb,
  'sevilla-ii-sevilla': countries.es,
  'sevilla-iii-sevilla': countries.es,
  'sevilla-sevilla-1890': countries.es,
  'sevilla-u19-sevilla': countries.es,
  'sevlievo-sevlievo': countries.bg,
  sexypoxyt: countries.fi,
  'sf-baumberg-monheim': countries.de,
  'sfc-minerva-alumbres-cartagena': countries.es,
  'sfintul-gheorghe-suruceni': countries.md,
  'sg-tertre-hautrage-tertre': countries.be,
  'sgv-freiberg-freiberg': countries.de,
  'shaanxi-chang-an': countries.cn,
  'shabab-al-ahli-dubai-dubayy-dubai': countries.ae,
  'shabab-al-am-ari-ramallah': countries.ps,
  'shabab-al-dhahiriya-al-dhahiriya': countries.ps,
  'shabab-al-ghazieh': countries.lb,
  'shabab-al-khaleel': countries.ps,
  'shabab-al-ordon': countries.jo,
  'shabab-al-sahel-beirut': countries.lb,
  'shaheen-asmayee-kabul': countries.af,
  'shakhtar-donetsk-donets-k': countries.ua,
  'shakhtar-donetsk-u19-donets-k': countries.ua,
  'shakhter-karagandy-qaragandy-karaganda': countries.kz,
  'shakhtyor-petrikov': countries.by,
  'shakhtyor-salihorsk-soligorsk': countries.by,
  'sham-shui-po': countries.hk,
  'shamrock-rovers-dublin': countries.ie,
  'shamrock-rovers-res': countries.ie,
  'shamrock-rovers-u19-dublin': countries.ie,
  'shan-united-yangon': countries.mm,
  'shandong-luneng-ji-nan': countries.cn,
  'shanghai-jiading': countries.cn,
  'shanghai-shenhua-shanghai': countries.cn,
  'shanghai-sipg-shanghai': countries.cn,
  'shatin-hong-kong': countries.hk,
  'shattuck-st-mary-s-u19-faribault-minnesota': countries.us,
  'sheffield-dronfield-north-east-derbyshire': countries.gb,
  'sheffield-united-sheffield': countries.gb,
  'sheffield-united-u18': countries.gb,
  'sheffield-wednesday-sheffield': countries.gb,
  'sheffield-wednesday-u18': countries.gb,
  'sheikh-jamal-dhaka': countries.bd,
  'sheikh-russel-dhaka': countries.bd,
  'shelbourne-dublin': countries.ie,
  'shenyang-urban-shenyang': countries.cn,
  'shenzhen-shenzhen': countries.cn,
  'shepshed-dynamo-shepshed-leicestershire': countries.gb,
  'sheriff-tiraspol': countries.md,
  'sheriff-u19-tiraspol': countries.md,
  'shevardeni-1906-tbilisi': countries.ge,
  'shijiazhuang-ever-bright-shijiazhuang': countries.cn,
  'shijiazhuang-kungfu-shijiazhuang-hebei': countries.cn,
  'shikun-hamizrah-rishon-lezion': countries.il,
  'shimizu-s-pulse-shizuoka': countries.jp,
  'shinnik-yaroslavl': countries.ru,
  'shirak-gyumri': countries.am,
  'shkendija-tetovo': countries.mk,
  'shkumbini-peqin-peqin': countries.al,
  'shkupi-skopje': countries.mk,
  'sho-oud-beijerland': countries.nl,
  'sholing-southampton-hampshire': countries.gb,
  'shonan-bellmare-hiratsuka': countries.jp,
  'shooting-stars-ibadan': countries.ng,
  'shortan-g-uzor': countries.uz,
  'shrewsbury-town-shrewsbury-shropshire': countries.gb,
  'shukura-kobuleti': countries.ge,
  'siarka-tarnobrzeg-tarnobrzeg': countries.pl,
  'sibenik-sibenik': countries.hr,
  sichuan: countries.cn,
  'siegendorf-siegendorf': countries.at,
  siero: countries.es,
  'siete-villas-castillo-siete-villas': countries.es,
  'siggiewi-siggiewi-siggiewi': countries.mt,
  'sighetu-marmatiei-sighetu-marmatiei': countries.ro,
  'sigma-olomouc-ii-olomouc': countries.cz,
  'sigma-olomouc-olomouc': countries.cz,
  'sigma-olomouc-u19-olomouc': countries.cz,
  'siheung-citizen-siheung': countries.kr,
  'siirt-genclerbirligi-siirt': countries.tr,
  'siirt-il-ozel-idaresi-siirt': countries.tr,
  'silas-kazlu-ruda': countries.lt,
  'sile-yildizspor-istanbul': countries.tr,
  'sileks-kratovo': countries.mk,
  'silifke-belediyespor-silifke': countries.tr,
  'silivrispor-istanbul': countries.tr,
  'silkeborg-silkeborg': countries.dk,
  'silla-silla': countries.es,
  'sillamae-kalev-sillamae': countries.ee,
  'silva-silva': countries.es,
  'silver-strikers-lilongwe': countries.mw,
  'silvolde-silvolde': countries.nl,
  'silz-motz-motz': countries.at,
  'simba-dar-es-salaam': countries.tz,
  'simmeringer-sc': countries.at,
  'sincan-belediyespor-ankara': countries.tr,
  'sindelic-beograd-beograd': countries.rs,
  'sindri-hofn-i-hornafirdi': countries.is,
  'sinnamary-sinnamary': countries.gf,
  'sint-eloois-winkel-sint-eloois-winkel': countries.be,
  'sint-lenaarts-sint-lenaarts': countries.be,
  'sint-niklaas-sint-niklaas': countries.be,
  'sint-truiden-sint-truiden-st-trond': countries.be,
  sintrense: countries.pt,
  'siofok-siofok': countries.hu,
  'sion-ii-martigny-croix': countries.ch,
  'sion-martigny-croix': countries.ch,
  'sioni-bolnisi': countries.ge,
  sirens: countries.mt,
  'sirius-uppsala': countries.se,
  'sirnak-idmanyurdu-sirnak': countries.tr,
  'siroki-brijeg-siroki-brijeg': countries.ba,
  'sisaket-sisaket': countries.th,
  'sitra-sitra': countries.bh,
  'sivas-belediyespor-sivas': countries.tr,
  'sivasspor-sivas': countries.tr,
  'sivasspor-u19-sivas': countries.tr,
  'siverek-belediyespor-sanliurfa': countries.tr,
  'sj-earthquakes-santa-clara-california': countries.us,
  'sjc-noordwijk': countries.nl,
  'sjk-akatemia-seinajoki': countries.fi,
  'sjk-seinajoki': countries.fi,
  'sk-poltava-poltava': countries.ua,
  'ska-energiya-ii-khabarovsk': countries.ru,
  'ska-khabarovsk-khabarovsk': countries.ru,
  'ska-rostov-rostov-na-donu': countries.ru,
  'skala-skali': countries.fo,
  'skanste-riga': countries.lv,
  'skeid-oslo': countries.no,
  'skelleftea-skelleftea': countries.se,
  'skelmersdale-united-skelmersdale-lancashire': countries.gb,
  'skenderbeu-korce-korce': countries.al,
  'skiljebo-vasteras': countries.se,
  'skive-skive': countries.dk,
  'skjold-kobenhavn': countries.dk,
  'skopje-skopje': countries.mk,
  'skoruk-tomakivka-tomakivka': countries.ua,
  'skovde-aik-skovde': countries.se,
  'skra-czestochowa-czestochowa': countries.pl,
  'slagelse-b-i-slagelse': countries.dk,
  'slask-wroclaw-ii-wroclaw': countries.pl,
  'slask-wroclaw-wroclaw': countries.pl,
  slatina: countries.ro,
  'slaven-koprivnica-koprivnica': countries.hr,
  'slavia-mazyr-mozyr': countries.by,
  'slavia-praha-ii-praha': countries.cz,
  'slavia-praha-praha': countries.cz,
  'slavia-praha-u19-praha': countries.cz,
  'slavia-sofia-sofia': countries.bg,
  'slavia-sofia-u19-sofia': countries.bg,
  'slavia-tu-kosice': countries.sk,
  'slavicin-slavicin': countries.cz,
  'slavija-istocno-sarajevo': countries.ba,
  'slavija-pleternica-pleternica': countries.hr,
  'slavoj-trebisov-trebisov': countries.sk,
  'slavonija-pozega-pozega': countries.hr,
  'sleipner-norrkoping': countries.se,
  'sliedrecht-sliedrecht': countries.nl,
  'sliema-wanderers-sliema': countries.mt,
  'sligo-rovers-sligo': countries.ie,
  'slimane-slimane': countries.tn,
  'slimbridge-cambridge-gloucestershire': countries.gb,
  'slivnishki-geroy-slivnitsa': countries.bg,
  'sloboda-novi-grad-novi-grad-bosanska-krajina': countries.ba,
  'sloboda-tuzla-tuzla': countries.ba,
  'sloboda-uzice-uzice': countries.rs,
  'sloga-doboj-doboj': countries.ba,
  'sloga-kraljevo-kraljevo': countries.rs,
  'sloga-nova-gradiska-nova-gradiska': countries.hr,
  'sloga-pozega-pozega': countries.rs,
  'slonim-slonim': countries.by,
  'slough-town-slough-buckinghamshire': countries.gb,
  'slovacko-uherske-hradiste': countries.cz,
  'slovan-bratislava-bratislava': countries.sk,
  'slovan-bratislava-ii-bratislava': countries.sk,
  'slovan-bratislava-u19-bratislava': countries.sk,
  'slovan-duslo-sala-sala': countries.sk,
  'slovan-liberec-liberec': countries.cz,
  'slovan-rosice-rosice': countries.cz,
  'slovan-velvary-velvary': countries.cz,
  'slutsk-slutsk': countries.by,
  'smartno-smartno-ob-paki': countries.si,
  'smedby-norrkoping': countries.se,
  'smiltene-smiltene': countries.lv,
  'smitshoek-barendrecht': countries.nl,
  'smorgon-smorgon': countries.by,
  'smouha-al-iskandariah-alexandria': countries.eg,
  'snim-cansado': countries.mr,
  snina: countries.sk,
  'sochaux-ii-montbeliard': countries.fr,
  'sochaux-montbeliard': countries.fr,
  'sochi-sochi': countries.ru,
  'sockers-u19-palatine-illinois': countries.us,
  'socuellamos-socuellamos': countries.es,
  'sodupe-sodupe': countries.es,
  'sofapaka-nairobi': countries.ke,
  'sogdiana-jizzax': countries.uz,
  'sogndal-sogndal': countries.no,
  'sokespor-soke': countries.tr,
  'sokol-brozany-brozany-nad-ohri': countries.cz,
  'sokol-hostoun-hostoun': countries.cz,
  'sokol-kleczew-kleczew': countries.pl,
  'sokol-markovo-markovo': countries.bg,
  'sokol-ostroda': countries.pl,
  'sokol-saratov-saratov': countries.ru,
  'sokol-tasovice-tasovice': countries.cz,
  'sokol-zapy-brandys-nad-labem': countries.cz,
  'sokol-zivanice-zivanice': countries.cz,
  'sol-de-america-asuncion': countries.py,
  'sol-de-america-formosa-provincia-de-formosa': countries.ar,
  'sol-de-mayo-viedma-provincia-de-rio-negro': countries.ar,
  'sola-sola': countries.no,
  'solidarite-scolaire': countries.gp,
  'solieres-sport-huy': countries.be,
  'solihull-moors-solihull-west-midlands': countries.gb,
  'solin-solin': countries.hr,
  'sollentuna-sollentuna': countries.se,
  'soller-soller': countries.es,
  'solola-solola': countries.gt,
  'solomon-warriors': countries.sb,
  'solothurn-solothurn': countries.ch,
  'solre-sur-sambre-solre-sur-sambre': countries.be,
  'somaspor-soma': countries.tr,
  'someren-someren': countries.nl,
  'somozas-as-somozas': countries.es,
  'somuz-falticeni-falticeni': countries.ro,
  'sona-sona': countries.it,
  'sonabel-ouagadougou': countries.bf,
  'sonderjyske-haderslev': countries.dk,
  'soneja-soneja': countries.es,
  'song-lam-nghe-an-vinh': countries.vn,
  songkhla: countries.th,
  songo: countries.mz,
  'sonnenhof-grossaspach-aspach': countries.de,
  'sonoma-county-sol-santa-rosa-california': countries.us,
  'sopoti-librazhd-librazhd': countries.al,
  'soroksar-sc-budapest': countries.hu,
  'sorrento-perth': countries.au,
  'sorrento-sorrento': countries.it,
  'sotra-sotra': countries.no,
  'sousa-sousa-paraiba': countries.br,
  'south-china-hong-kong': countries.hk,
  'south-florida-u19-boca-raton-florida': countries.us,
  'south-hobart-south-hobart': countries.au,
  'south-melbourne-melbourne': countries.au,
  'south-park-reigate-surrey': countries.gb,
  'south-shields': countries.gb,
  'south-west-united-sydney': countries.au,
  'southampton-southampton-hampshire': countries.gb,
  'southampton-u21-southampton': countries.gb,
  'southampton-u23-southampton': countries.gb,
  'southend-united-southend-on-sea-essex': countries.gb,
  'southern-district-hong-kong': countries.hk,
  'southern-states-hattiesburg': countries.us,
  'southport-southport-merseyside': countries.gb,
  'sozopol-sozopol': countries.bg,
  spaeri: countries.ge,
  'spakenburg-bunschoten': countries.nl,
  'spal-ferrara': countries.it,
  'spal-u19': countries.it,
  'spalding-united-spalding-lincolnshire': countries.gb,
  'sparta-nijkerk-nijkerk': countries.nl,
  'sparta-petegem-deinze': countries.be,
  'sparta-praha-praha': countries.cz,
  'sparta-praha-u21-praha': countries.cz,
  'sparta-praha-u23-praha': countries.cz,
  'sparta-rotterdam-ii-rotterdam': countries.nl,
  'sparta-rotterdam-rotterdam': countries.nl,
  'sparta-rotterdam-u19-rotterdam': countries.nl,
  'sparta-selemet-selemet': countries.md,
  'spartak-kostroma-kostroma': countries.ru,
  'spartak-moskva-ii-moskva': countries.ru,
  'spartak-moskva-moskva': countries.ru,
  'spartak-myjava-myjava': countries.sk,
  'spartak-nal-chik-nal-chik': countries.ru,
  'spartak-pleven-pleven': countries.bg,
  'spartak-plovdiv-plovdiv': countries.bg,
  'spartak-shklou-shklov': countries.by,
  'spartak-subotica-subotica': countries.rs,
  'spartak-varna-varna': countries.bg,
  'spartak-vladikavkaz-vladikavkaz': countries.ru,
  'spartaks-jurmala-jurmala': countries.lv,
  'spartans-edinburgh': countries.gb,
  spartax: countries.br,
  'spennymoor-town-spennymoor-county-durham': countries.gb,
  'speran-a-drochia-drochia': countries.md,
  'spezia-la-spezia': countries.it,
  'spezia-u19': countries.it,
  'spijkenisse-spijkenisse': countries.nl,
  'spisska-nova-ves-spisska-nova-ves': countries.sk,
  'spittal-spittal-an-der-drau': countries.at,
  spjelkavik: countries.no,
  'split-split': countries.hr,
  'sport-academy-kairat-almaty': countries.kz,
  'sport-boys-callao': countries.pe,
  'sport-chavelines-juniors': countries.pe,
  'sport-huancayo-huancayo': countries.pe,
  'sport-pb': countries.br,
  'sport-recife-recife-pernambuco': countries.br,
  'sport-sinop-sinop-mato-grosso': countries.br,
  'sportfreunde-lotte-lotte': countries.de,
  'sportfreunde-siegen-siegen': countries.de,
  'sporting-ben-arous-ben-arous': countries.tn,
  'sporting-braga-braga': countries.pt,
  'sporting-braga-ii-braga': countries.pt,
  'sporting-braga-u19-braga': countries.pt,
  'sporting-braga-u21-braga': countries.pt,
  'sporting-charleroi-charleroi': countries.be,
  'sporting-charleroi-ii-charleroi': countries.be,
  'sporting-covilha-covilha': countries.pt,
  'sporting-cp-ii-lisboa': countries.pt,
  'sporting-cp-lisboa': countries.pt,
  'sporting-cp-u19-lisboa': countries.pt,
  'sporting-cristal-lima': countries.pe,
  'sporting-cristal-u20-lima': countries.pe,
  'sporting-gagnoa-gagnoa': countries.ci,
  'sporting-gijon-gijon': countries.es,
  'sporting-gijon-ii-leorio-gijon': countries.es,
  'sporting-hasselt-hasselt': countries.be,
  'sporting-kc-kansas-city-kansas': countries.us,
  'sporting-kc-u19-kansas-city-kansas': countries.us,
  'sporting-kristina-kristiinankaupunki-kristinestad': countries.fi,
  'sporting-liesti-liesti': countries.ro,
  'sporting-mertzig-maerzeg-mertzig': countries.lu,
  'sporting-praia-cruz-sao-tome': countries.st,
  'sporting-rosiori-rosiori-de-vede': countries.ro,
  'sporting-san-jose-san-jose': countries.cr,
  'sporting-san-miguelito-san-miguelito': countries.pa,
  'sportist-svoge-svoge': countries.bg,
  'sportivo-ameliano': countries.py,
  'sportivo-baradero-baradero': countries.ar,
  'sportivo-barracas-barracas-provincia-de-buenos-aires': countries.ar,
  'sportivo-barracas-colon-colon-provincia-de-buenos-aires': countries.ar,
  'sportivo-belgrano-san-francisco-provincia-de-cordoba': countries.ar,
  'sportivo-del-bono-rivadavia-provincia-de-san-juan': countries.ar,
  'sportivo-desamparados-san-juan-provincia-de-san-juan': countries.ar,
  'sportivo-guzman-san-miguel-de-tucuman': countries.ar,
  'sportivo-italiano-vicente-lopez-provincia-de-buenos-aires': countries.ar,
  'sportivo-iteno-ita': countries.py,
  'sportivo-las-parejas-las-parejas-provincia-de-santa-fe': countries.ar,
  'sportivo-luqueno-luque': countries.py,
  'sportivo-penarol-chimbas-provincia-de-san-juan': countries.ar,
  'sportivo-trinidense-asuncion': countries.py,
  'sportlust-46-woerden': countries.nl,
  'sportul-simleu': countries.ro,
  'spouwen-mopertingen-mopertingen': countries.be,
  'spratzern-st-polten': countries.at,
  'sprimont-sprimont': countries.be,
  'sprockhovel-sprockhovel': countries.de,
  'sr-municipal-brasov-brasov': countries.ro,
  'sr-reykjavik': countries.is,
  'sreenidi-deccan-visakhapatnam-andhra-pradesh': countries.in,
  'sriwijaya-palembang': countries.id,
  'ss-reyes-san-sebastian-de-los-reyes': countries.es,
  'ssc-farul-constanta': countries.ro,
  'ssc-giugliano-giugliano': countries.it,
  'ssvg-velbert-velbert': countries.de,
  'st-albans-city-st-albans-hertfordshire': countries.gb,
  'st-albans-saints': countries.au,
  'st-andrews-pembroke': countries.mt,
  'st-ann-s-rangers-san-juan': countries.tt,
  'st-anna': countries.at,
  'st-cuthbert-wanderers-kirkcudbright': countries.gb,
  'st-duthus-tain': countries.gb,
  'st-gallen-ii-st-gallen': countries.ch,
  'st-gallen-st-gallen': countries.ch,
  'st-george-s-cospicua': countries.mt,
  'st-ives-town-st-ives-cambridgeshire': countries.gb,
  'st-jakob-rosental-rosental': countries.at,
  'st-johnstone-perth': countries.gb,
  'st-joseph-s-gibraltar': countries.gi,
  'st-louis-city-ii-st-louis-missouri': countries.us,
  'st-louis-sg-u19-st-louis-missouri': countries.us,
  'st-martin-i-m-st-martin-im-muhlkreis': countries.at,
  'st-maur-lusitanos-st-maur-des-fosses': countries.fr,
  'st-mirren-paisley': countries.gb,
  'st-mirren-u21-paisley': countries.gb,
  'st-neots-town-st-neots-cambridgeshire': countries.gb,
  'st-patrick-s-athl-dublin': countries.ie,
  'st-patrick-s-u19-dublin': countries.ie,
  'st-pauli-hamburg': countries.de,
  'st-pauli-ii-hamburg': countries.de,
  'st-pauli-u17-hamburg': countries.de,
  'st-pauli-u19-hamburg': countries.de,
  'st-pauls-united': countries.kn,
  'st-philbert-gd-lieu-saint-philbert-de-grand-lieu': countries.fr,
  'st-polten-ii-sankt-polten': countries.at,
  'st-polten-sankt-polten': countries.at,
  'st-polten-u18-sankt-polten': countries.at,
  'st-pryve-st-hilaire-saint-pryve-saint-mesmin': countries.fr,
  'staal-jorpeland': countries.no,
  'stabaek-bekkestua': countries.no,
  'stabaek-ii-bekkestua': countries.no,
  'stade-beaucairois-beaucaire': countries.fr,
  'stade-bierbeek-bierbeek': countries.be,
  'stade-bordelais-le-bouscat': countries.fr,
  'stade-briochin-ii-saint-brieuc': countries.fr,
  'stade-briochin-saint-brieuc': countries.fr,
  'stade-disonais-dison': countries.be,
  'stade-gabesien-gabes': countries.tn,
  'stade-lausanne-ouchy-lausanne': countries.ch,
  'stade-malien-bamako-bamako': countries.ml,
  'stade-marocain-rabat': countries.ma,
  'stade-montois-mont-de-marsan': countries.fr,
  'stade-nyonnais-nyon': countries.ch,
  'stade-plabennec-plabennec': countries.fr,
  'stade-tunisien-tunis': countries.tn,
  'stadl-paura': countries.at,
  stadlau: countries.at,
  'stafford-rangers-stafford-staffordshire': countries.gb,
  'stal-brzeg-brzeg': countries.pl,
  'stal-mielec-mielec': countries.pl,
  'stal-rzeszow-rzeszow': countries.pl,
  'stal-stalowa-wola-stalowa-wola': countries.pl,
  'stallion-binan': countries.ph,
  'stalybridge-celtic-stalybridge-greater-manchester': countries.gb,
  'stamford-stamford-lincolnshire': countries.gb,
  'standard-liege-ii-liege-luik': countries.be,
  'standard-liege-liege-luik': countries.be,
  'staphorst-staphorst': countries.nl,
  'stargard-szczecinski-stargard-szczecinski': countries.pl,
  'starogard-gdanski-starogard-gdanski': countries.pl,
  'start-brno': countries.cz,
  'start-kristiansand': countries.no,
  'starye-dorogi-starye-dorogi': countries.by,
  'stedoco-hoornaar': countries.nl,
  'steinkjer-steinkjer': countries.no,
  'stellenbosch-stellenbosch-wc': countries.za,
  'stenhousemuir-stenhousemuir': countries.gb,
  'stenungsund-stenungsund': countries.se,
  'stevenage-stevenage-hertfordshire': countries.gb,
  'stevenage-u18': countries.gb,
  'stiinta-miroslava-miroslava': countries.ro,
  'stilon-gorzow-gorzow-wielkopolski': countries.pl,
  'stirling-albion-stirling': countries.gb,
  'stirling-lions-perth': countries.au,
  'stirling-university-stirling': countries.gb,
  'stjarnan-gardabaer': countries.is,
  'stjordals-blink-stjordalshalsen': countries.no,
  'stockholm-internazionale-stockholm': countries.se,
  'stockport-county-stockport-greater-manchester': countries.gb,
  'stocksbridge-park-steels-stocksbridge-south-yorkshire': countries.gb,
  'stocksund-stocksund': countries.se,
  'stoke-city-stoke-on-trent': countries.gb,
  'stoke-city-u18': countries.gb,
  'stoke-city-u21-stoke': countries.gb,
  'stoke-city-u23-stoke-on-trent': countries.gb,
  'stomil-olsztyn-olsztyn': countries.pl,
  'stourbridge-stourbridge-west-midlands': countries.gb,
  'stowmarket-town': countries.gb,
  'straelen-straelen': countries.de,
  'stranraer-stranraer': countries.gb,
  'strasbourg-ii-strasbourg': countries.fr,
  'strasbourg-strasbourg': countries.fr,
  strasswalchen: countries.at,
  'stratford-town-stratford-upon-avon-warwickshire': countries.gb,
  'strathspey-thistle-grantown-on-spey': countries.gb,
  'strindheim-trondheim': countries.no,
  'stripfing-stripfing': countries.at,
  'strogino-moskva': countries.ru,
  'strommen-strommen': countries.no,
  'stromsgodset-drammen': countries.no,
  'stromsgodset-ii-drammen': countries.no,
  'struga-struga': countries.mk,
  'strumska-slava-radomir': countries.bg,
  'sturm-graz-graz': countries.at,
  'sturm-graz-ii-graz': countries.at,
  'sturm-graz-u18-graz': countries.at,
  'stuttgart-ii-stuttgart': countries.de,
  'stuttgart-stuttgart': countries.de,
  'stuttgart-u17-stuttgart': countries.de,
  'stuttgart-u19-stuttgart': countries.de,
  'stuttgarter-kickers-stuttgart': countries.de,
  'stuttgarter-kickers-u17-stuttgart': countries.de,
  'subiza-subiza': countries.es,
  'suchitepequez-mazatenango': countries.gt,
  'sud-america-montevideo': countries.uy,
  'sudeva-moonlight-delhi': countries.in,
  'sudtirol-bolzano-brixen': countries.it,
  'suduroy-vagur-suduroy': countries.fo,
  'suduva-marijampole': countries.lt,
  'sukhothai-sukhothai': countries.th,
  'sultanbeyli-belediyespor-istanbul': countries.tr,
  'sultangazispor-istanbul': countries.tr,
  'suluova-sebatspor-suluova': countries.tr,
  'sumqayit-sumqayit': countries.az,
  'sunderland-sunderland': countries.gb,
  'sunderland-u18': countries.gb,
  'sunderland-u21-sunderland': countries.gb,
  'sunderland-u23-sunderland': countries.gb,
  'sunshine-coast-coolum-beach': countries.au,
  'sunshine-coast-wanderers-sunshine-coast': countries.au,
  'sunshine-stars-akure': countries.ng,
  'super-nova-riga': countries.lv,
  'supersport-united-pretoria-ga': countries.za,
  'suphanburi-suphanburi': countries.th,
  'sur-sur': countries.om,
  'surkhon-termez': countries.uz,
  sursee: countries.ch,
  'susteren-susteren': countries.nl,
  'sutherland-sharks-sydney': countries.au,
  'sutjeska-niksic': countries.me,
  'sutton-coldfield-town-sutton-coldfield-west-midlands': countries.gb,
  'sutton-united-sutton-surrey': countries.gb,
  'sutton-united-u18-sutton-surrey': countries.gb,
  'suva-suva': countries.fj,
  'suwon-bluewings-suwon': countries.kr,
  'suwon-suwon': countries.kr,
  'suzhou-dongwu-suzhou': countries.cn,
  'suzuka-unlimited-suzuka': countries.jp,
  'sv-innsbruck-innsbruck': countries.at,
  'sv-wallern-wallern': countries.at,
  'svay-rieng-phnom-penh': countries.kh,
  'svelta-melsele-melsele': countries.be,
  'svetkavitsa-targovishte-targovishte': countries.bg,
  'svg-reichenau-innsbruck': countries.at,
  svl: countries.nl,
  'svzw-wierden': countries.nl,
  'swadhinata-ks-dhaka': countries.bd,
  'swansea-city-swansea': countries.gb,
  'swansea-city-u18': countries.gb,
  'swansea-city-u21-swansea': countries.gb,
  'swansea-city-u23-swansea': countries.gb,
  'swansea-university': countries.gb,
  'swieqi-united': countries.mt,
  'swift-amsterdam': countries.nl,
  'swift-hesperange-betten-op-der-mess-bettange-sur-mess': countries.lu,
  'swindon-supermarine-swindon-wiltshire': countries.gb,
  'swindon-town-swindon-wiltshire': countries.gb,
  'swindon-town-u18': countries.gb,
  'swit-nowy-dwor-mazowiecki': countries.pl,
  'swit-skolwin-szczecin': countries.pl,
  'swope-park-rangers-kansas-city-missouri': countries.us,
  'swq-thunder-dalby': countries.au,
  'sydney-ii': countries.au,
  'sydney-moore-park': countries.au,
  'sydney-olympic-sydney': countries.au,
  'sydney-u21-sydney': countries.au,
  'sydney-united-sydney': countries.au,
  'sylvia-norrkoping': countries.se,
  'syra-mensdorf-mensdorf': countries.lu,
  'syracuse-pulse-new-york': countries.us,
  'syrianska-sodertalje': countries.se,
  'szeged-2011-szeged': countries.hu,
  'szekszard-szekszard': countries.hu,
  'szentlorinc-se-szentlorinc': countries.hu,
  'szolnoki-mav-szolnok': countries.hu,
  'szombathelyi-haladas-szombathely': countries.hu,
  'ta-rennes-rennes': countries.fr,
  'tabasalu-charma': countries.ee,
  'tabor-sezana-sezana': countries.si,
  'taborsko-sezimovo-usti': countries.cz,
  'taby-taby': countries.se,
  'tacuarembo-tacuarembo': countries.uy,
  'tacuary-asuncion': countries.py,
  'tadamon-sour-tyre': countries.lb,
  'tadcaster-albion-tadcaster-north-yorkshire': countries.gb,
  'taff-s-well-taff-s-well-ffynnnon-taf-rhondda-cynon-taf': countries.gb,
  'tainan-city': countries.tw,
  'taipower-kaohsiung': countries.tw,
  'talasgucu-belediyespor-kayseri': countries.tr,
  'talavera-ii-talavera-de-la-reina': countries.es,
  'talleres-cordoba-ciudad-de-cordoba-provincia-de-cordoba': countries.ar,
  'talleres-de-perico-perico-provincia-de-jujuy': countries.ar,
  'talleres-remedios-remedios-escalada-provincia-de-buenos-aires': countries.ar,
  'tallinna-kalev-ii-tallinn': countries.ee,
  'tallinna-kalev-tallinn': countries.ee,
  'tamaraceite-las-palmas-de-gran-canaria': countries.es,
  'tammeka-ii-tartu': countries.ee,
  'tammeka-tartu': countries.ee,
  'tampa-bay-rowdies-st-petersburg-florida': countries.us,
  'tampere-united-tampere': countries.fi,
  'tampines-rovers-singapore': countries.sg,
  'tamworth-tamworth-staffordshire': countries.gb,
  'tanjong-pagar-singapore': countries.sg,
  'tapatio-guadalajara-jalisco': countries.mx,
  'taquaritinga-taquaritinga-sao-paulo': countries.br,
  'taraji-wadi-al-nes': countries.ps,
  'tarancon-tarancon': countries.es,
  'taranto-taranto': countries.it,
  'taraz-taraz': countries.kz,
  'tarazona-tarazona': countries.es,
  'tarnby-ff': countries.dk,
  'tarsus-idman-yurdu-tarsus': countries.tr,
  'tartu-kalev-tartu': countries.ee,
  'tartu-welco-tartu': countries.ee,
  'tarup-paarup': countries.dk,
  'tarxien-rainbows-tarxien': countries.mt,
  'tasmania-berlin-berlin': countries.de,
  'tatabanya-tatabanya': countries.hu,
  tataouine: countries.tn,
  'tatran-presov-presov': countries.sk,
  'tatung-taipei-city': countries.tw,
  'taubate-taubate-sao-paulo': countries.br,
  'taunton-town-taunton-somerset': countries.gb,
  'tauro-ciudad-de-panama': countries.pa,
  'tavsanli-linyitspor-tavsanli': countries.tr,
  'tb-tvoroyri': countries.fo,
  'tb-uphusen-achim': countries.de,
  'tbilisi-city': countries.ge,
  'tc-sports-club': countries.mv,
  'team-altamura-altamura': countries.it,
  'team-thoren-umea': countries.se,
  'tec-tiel': countries.nl,
  'tecnico-universitario-ambato': countries.ec,
  'tecos-zapopan-guadalajara': countries.mx,
  'tefana-faa-a': countries.pf,
  'tegevajaro-miyazaki-miyazaki': countries.jp,
  'tekirdagspor-tekirdag': countries.tr,
  'tekstilshchik-ivanovo': countries.ru,
  'telavi-telavi': countries.ge,
  'teleoptik-zemun': countries.rs,
  'telfs-telfs': countries.at,
  'telstar-velsen-zuid': countries.nl,
  temouchent: countries.dz,
  'temp-barnaul-barnaul': countries.ru,
  'temperley-temperley-provincia-de-buenos-aires': countries.ar,
  'tempete-saint-marc': countries.ht,
  'tempo-overijse-overijse': countries.be,
  'tenerife-ii-santa-cruz-de-tenerife-tenerife-islas-canarias': countries.es,
  'tenerife-santa-cruz-de-tenerife': countries.es,
  'tennis-borussia-berlin': countries.de,
  'tepatitlan-de-morelos-tepatitlan-de-morelos': countries.mx,
  'tepecikspor-istanbul': countries.tr,
  'teplice-teplice': countries.cz,
  'ter-leede-sassenheim': countries.nl,
  'terbuni-puke-puke': countries.al,
  'terengganu-kuala-terengganu': countries.my,
  'termez-surkhon-termez': countries.uz,
  'termoli-calcio-termoli': countries.it,
  'ternana-terni': countries.it,
  'ternesse-wommelgem': countries.be,
  'terrassa-terrassa': countries.es,
  'terre-sainte': countries.ch,
  'tertons-thimphu': countries.bt,
  'teruel-teruel': countries.es,
  teskand: countries.hu,
  'teteks-tetovo': countries.mk,
  'teungueth-rufisque-dakar': countries.sn,
  'teuta-durres-durres': countries.al,
  'teutonia-hamburg-hamburg': countries.de,
  'teutonia-pohlheim': countries.de,
  'tevragh-zeine': countries.mr,
  'texas-united-u19-houston-texas': countries.us,
  'tfc-academy-u19-toronto-ontario': countries.us,
  'thame-united-thame-oxfordshire': countries.gb,
  'thaon-thaon-les-vosges': countries.fr,
  'thatcham-town-thatcham-berkshire': countries.gb,
  'the-new-saints-oswestry-croesoswallt-shropshire': countries.gb,
  'the-strongest-la-paz': countries.bo,
  'the-villages-the-villages-florida': countries.us,
  'thes-sport-tessenderlo': countries.be,
  'thespakusatsu-gunma-maebashi': countries.jp,
  'thesprotos-igoumenitsa': countries.gr,
  'thimphu-city-thimphu': countries.bt,
  'thisted-thisted': countries.dk,
  'thonon-evian': countries.fr,
  'thor-akureyri': countries.is,
  'threave-rovers-castle-douglas': countries.gb,
  'three-star-lalitpur': countries.np,
  'throttur-reykjavik-reykjavik': countries.is,
  'throttur-vogar-vogar': countries.is,
  'thun-thun': countries.ch,
  'tiamo-hirakata-hirakata': countries.jp,
  'tianjin-teda-tianjin': countries.cn,
  'tiare-tahiti-tahiti': countries.pf,
  'tienen-tienen-tirlemont': countries.be,
  'tigers-windhoek': countries.na,
  'tigre-victoria-provincia-de-buenos-aires': countries.ar,
  'tigres-uanl-san-nicolas-de-los-garza': countries.mx,
  'tigres-uanl-u20-san-nicolas-de-los-garza': countries.mx,
  'tigres-zipaquira': countries.co,
  'tijuana-tijuana': countries.mx,
  'tijuana-u20': countries.mx,
  'tikves-kavadarci': countries.mk,
  'tilbury-tilbury-essex': countries.gb,
  'tilikratis-lefkada': countries.gr,
  'tiller-trondheim': countries.no,
  'timok-zajecar': countries.rs,
  'tindastoll-saudarkrokur': countries.is,
  'tips-vantaa': countries.fi,
  'tira-tira': countries.il,
  'tiradentes-ce-fortaleza-ceara': countries.br,
  'tirana-tirane-tirana': countries.al,
  'tire-1922-spor-tire': countries.tr,
  'tire-2021-fk-izmir': countries.tr,
  'tiro-gimnasia-san-pedro-san-pedro-provincia-de-jujuy': countries.ar,
  'tirol-u15-innsbruck': countries.at,
  'tirsense-santo-tirso': countries.pt,
  'tishreen-latakia': countries.sy,
  'tiszafuredi-vse': countries.hu,
  'tiszakecske-tiszakecske': countries.hu,
  'tiszaujvaros-tiszaujvaros': countries.hu,
  'titanes-maracaibo': countries.ve,
  'tiverton-town-tiverton-devon': countries.gb,
  'tivoli-gardens-kingston': countries.jm,
  'tjk-legion-ii-tallinn': countries.ee,
  tlaxcala: countries.mx,
  'tobermore-united-tobermore': countries.gb,
  'tobol-kostanai-kustanay-kostanay': countries.kz,
  'tocantinopolis-tocantinopolis-tocantins': countries.br,
  'tocantins-miracema-miracema-do-tocantins-tocantins': countries.br,
  'tochigi-city-tochigi': countries.jp,
  'tochigi-utsunomiya': countries.jp,
  'todesfelde-todesfelde': countries.de,
  togb: countries.nl,
  'togo-port-lome': countries.tg,
  'tokat-bld-plevnespor-tokat': countries.tr,
  'tokushima-vortis-tokushima': countries.jp,
  'tokyo-musashino-city-musashino': countries.jp,
  'tokyo-tokyo': countries.jp,
  'tokyo-verdy-tokyo': countries.jp,
  'toledo-toledo': countries.es,
  'toledo-toledo-parana': countries.br,
  'tollygunge-kolkata': countries.in,
  'tolmin-tolmin': countries.si,
  'toluca-toluca-de-lerdo': countries.mx,
  'tombense-tombos-minas-gerais': countries.br,
  'tomori-berat-berat': countries.al,
  'tona-tona': countries.es,
  'tonbridge-angels-tonbridge-kent': countries.gb,
  'tondela-tondela': countries.pt,
  'tondela-u19-tondela': countries.pt,
  'tongeren-tongeren': countries.be,
  'tonnerre-abomey': countries.bj,
  'tooting-mitcham-united-imperial-fields': countries.gb,
  'top-oss-amateurs-oss': countries.nl,
  'top-oss-oss': countries.nl,
  'torhout-torhout': countries.be,
  'torina-barcena-de-pie-de-concha': countries.es,
  'torino-torino': countries.it,
  'torino-u19': countries.it,
  'tormenta-ii': countries.us,
  'tormenta-statesboro-georgia': countries.us,
  'torns-lund': countries.se,
  'toronto-ii-vaughan': countries.us,
  'toronto-toronto': countries.us,
  'torpedo-belaz-zhodzina-zhodino': countries.by,
  'torpedo-kutaisi-kutaisi': countries.ge,
  'torpedo-miass-miass': countries.ru,
  'torpedo-moskva-ii-moskva': countries.ru,
  'torpedo-moskva-moskva': countries.ru,
  'torpedo-vladimir-vladimir': countries.ru,
  'torquay-united-torquay-devon': countries.gb,
  'torque-montevideo': countries.uy,
  'torre-del-mar-torre-del-mar': countries.es,
  'torre-levante-valencia': countries.es,
  'torreense-torres-vedras': countries.pt,
  'torrejon-torrejon-de-ardoz': countries.es,
  'torrellano-torrellano': countries.es,
  'torrent-torrent': countries.es,
  'torreperogil-torreperogil': countries.es,
  'torres-sassari': countries.it,
  torrijos: countries.es,
  'torslanda-torslanda': countries.se,
  'tosk-tesanj-tesanj': countries.ba,
  'total-fa-u19-los-angeles-california': countries.us,
  'tote-helsinki': countries.fi,
  'toti-city-lae': countries.pg,
  'tottenham-hotspur-london': countries.gb,
  'tottenham-hotspur-u18': countries.gb,
  'tottenham-hotspur-u19-london': countries.gb,
  'tottenham-hotspur-u21': countries.gb,
  'tottenham-hotspur-u23-london': countries.gb,
  'toulon-ii-toulon': countries.fr,
  'toulon-toulon': countries.fr,
  'toulouse-ii-toulouse': countries.fr,
  'toulouse-toulouse': countries.fr,
  'touring-renteria': countries.es,
  'tournai-kain': countries.be,
  'tours-tours': countries.fr,
  'township-rollers-gaborone': countries.bw,
  'tp-47-tornio': countries.fi,
  'tp-mazembe-lubumbashi': countries.cd,
  'tp-t': countries.fi,
  'tpk-turku': countries.fi,
  'tps-turku-abo': countries.fi,
  'tpv-tampere': countries.fi,
  'trabzonspor-trabzon': countries.tr,
  'trabzonspor-u19-trabzon': countries.tr,
  'tractor-sazi-tabriz': countries.ir,
  'traeff-molde': countries.no,
  'trafford-flixton-greater-manchester': countries.gb,
  'traiskirchen-traiskirchen': countries.at,
  'trajal-krusevac-krusevac': countries.rs,
  'trakai-trakai': countries.lt,
  'tranent-juniors-tranent-east-lothian': countries.gb,
  'tranmere-rovers-birkenhead-merseyside': countries.gb,
  'trans-narva': countries.ee,
  'transport-united-thimphu': countries.bt,
  'trasandino-los-andes': countries.cl,
  'trastevere-roma': countries.it,
  'trat-trat': countries.th,
  'trau-imphal-west': countries.in,
  'travnik-travnik': countries.ba,
  'tre-fiori-fiorentino': countries.sm,
  'tre-penne-citta-di-san-marino': countries.sm,
  'treaty-united-limerick': countries.ie,
  trefelin: countries.gb,
  'treibach-althofen': countries.at,
  'trelissac-trelissac': countries.fr,
  'trelleborg-trelleborg': countries.se,
  'trelleborg-u21-trelleborg': countries.se,
  'trem-macapa-amapa': countries.br,
  'trencin-u19-trencin': countries.sk,
  'trento-trento': countries.it,
  'trepca-89-mitrovice-kosovska-mitrovica': countries.xk,
  'treze-campina-grande-paraiba': countries.br,
  'triestina-trieste': countries.it,
  'triglav-kranj': countries.si,
  'trinec-trinec': countries.cz,
  'tripoli-sc-beirut': countries.lb,
  'tristan-suarez-ezeiza-provincia-de-buenos-aires': countries.ar,
  'trival-valderas': countries.es,
  'trofense-trofa': countries.pt,
  'trollhattan-trollhattan': countries.se,
  'tromsdalen-tromsdalen': countries.no,
  'tromso-ii-tromso': countries.no,
  'tromso-tromso': countries.no,
  'tropezon-tanos': countries.es,
  'trosa-vagnharad-sk': countries.se,
  'troyes-ii-troyes': countries.fr,
  'troyes-troyes': countries.fr,
  'trujillanos-valera': countries.ve,
  'trujillo-trujillo': countries.es,
  'truro-city-truro-cornwall': countries.gb,
  'ts-galaxy-kwandebele': countries.za,
  'tsf-academy-u19-lincoln-park-new-jersey': countries.us,
  'tshakhuma-madzivhadila-limpopo': countries.za,
  'tsv-st-johann-st-johann-im-pongau': countries.at,
  'tsv-steinbach-haiger': countries.de,
  'ttm-thohoyandou': countries.za,
  'tubize-tubize': countries.be,
  'tucson-tucson-arizona': countries.us,
  'tudelano-tudela': countries.es,
  'tuggen-tuggen': countries.ch,
  'tuilla-tuilla': countries.es,
  'tukums-tukums': countries.lv,
  'tulevik-viljandi': countries.ee,
  'tulsa-athletic-tulsa-oklahoma': countries.us,
  'tulsa-roughnecks-tulsa': countries.us,
  'tun-razak-city': countries.my,
  'tuna-luso-belem-para': countries.br,
  'tunari-tunari': countries.ro,
  'tuntum-tuntum-maranhao': countries.br,
  'tupapa-maraerenga': countries.ck,
  'tupi-juiz-de-fora-minas-gerais': countries.br,
  'tupynambas-juiz-de-fora-minas-gerais': countries.br,
  'tura-magic': countries.na,
  'turan-tovuz': countries.az,
  'turan-yaypan': countries.uz,
  'turbina-cerrik-cerrik': countries.al,
  'turgutluspor-turgutlu': countries.tr,
  'turkgucu-ataspor-munchen': countries.de,
  'turnhout-turnhout': countries.be,
  'turriff-united-turriff': countries.gb,
  'turris-neapolis-torre-del-greco': countries.it,
  'tus-haltern-haltern-am-see': countries.de,
  'tus-rw-koblenz-koblenz': countries.de,
  'tusker-nairobi': countries.ke,
  'tuzla-city-simin-han': countries.ba,
  'tuzlaspor-istanbul': countries.tr,
  'tvaaker-tvaaker': countries.se,
  'tver-tver': countries.ru,
  'twente-enschede': countries.nl,
  'twente-u19-enschede': countries.nl,
  'twl-elektra-wien': countries.at,
  'txantrea-pamplona': countries.es,
  'tychy-71-tychy': countries.pl,
  'tynecastle-edinburgh': countries.gb,
  'tyumen-tyumen': countries.ru,
  'tzeirey-taibe-taibe': countries.il,
  'tzeirey-tira-tira': countries.il,
  'u-craiova-1948-craiova': countries.ro,
  'uai-urquiza-general-san-martin-provincia-de-buenos-aires': countries.ar,
  'uberaba-uberaba-minas-gerais': countries.br,
  'uberlandia-uberlandia-minas-gerais': countries.br,
  'ucam-murcia-ii-sangonera-la-verde': countries.es,
  'ucam-murcia-sangonera-la-verde': countries.es,
  'ucd-dublin': countries.ie,
  'ucd-u19-dublin': countries.ie,
  'ucv-caracas': countries.ve,
  'ud-caravaca-caravaca-de-la-cruz': countries.es,
  'ud-da-serra': countries.pt,
  'ud-ibiza-ibiza-town': countries.es,
  'ud-logrones-logrono': countries.es,
  'ud-oliveirense-oliveira-de-azemeis': countries.pt,
  'ud-ourense-ourense': countries.es,
  'ud-san-fernando-maspalomas': countries.es,
  'ud-santa-marta-santa-marta': countries.es,
  'udi-19-uden': countries.nl,
  'udinese-u19': countries.it,
  'udinese-udine': countries.it,
  'udon-thani-udon-thani': countries.th,
  'ue-santa-coloma-sant-julia-de-loria': countries.ad,
  'uerdingen-krefeld': countries.de,
  'ufa-ufa': countries.ru,
  'ufc-hallein-hallein': countries.at,
  'ugs-geneve': countries.ch,
  'ugyen-academy': countries.bt,
  'uitm-shah-alam': countries.my,
  'ujpest-budapest': countries.hu,
  'ujpest-ii': countries.hu,
  'ula-merida': countries.ve,
  'ulaanbaatar-city-ulan-bator-ulaanbaatar': countries.mn,
  'ulaanbaatar-ulan-bator-ulaanbaatar': countries.mn,
  'ulalar-bld-genclik-erzincan': countries.tr,
  'ulfarnir-akureyri': countries.is,
  'ulinzi-stars-nakuru': countries.ke,
  'uljanik-pula': countries.hr,
  'ullared-ullared': countries.se,
  'ullensaker-kisa-jessheim': countries.no,
  'ullern-oslo': countries.no,
  'ulm-ulm': countries.de,
  'ulsan-citizen-ulsan': countries.kr,
  'ulsan-ulsan': countries.kr,
  ultimate: countries.my,
  'ulubey-belediyespor-ordu': countries.tr,
  'umea-fc-umea': countries.se,
  'umm-salal-umm-salal': countries.qa,
  'umraniyespor-istanbul': countries.tr,
  'umraniyespor-u19-istanbul': countries.tr,
  'ums-de-loum-loum': countries.cm,
  'un-kaerjeng-nidderkaerjeng-bascharage': countries.lu,
  'una-strassen-strassen': countries.lu,
  'una-zeelst': countries.nl,
  'unami-cp-segovia': countries.es,
  'unan-managua-managua': countries.ni,
  'undrid-torshavn': countries.fo,
  'uni-minsk-minsk': countries.by,
  'unia-janikowo-janikowo': countries.pl,
  'unia-skierniewice-skierniewice': countries.pl,
  'unia-tarnow': countries.pl,
  'uniao-abc-campo-grande-mato-grosso-do-sul': countries.br,
  'uniao-de-leiria-leiria': countries.pt,
  'uniao-luziense-santa-luzia-minas-gerais': countries.br,
  'uniao-pr-francisco-beltrao-parana': countries.br,
  'uniao-rondonopolis-rondonopolis-mato-grosso': countries.br,
  'uniao-rs-frederico-westphalen-rio-grande-do-sul': countries.br,
  'uniao-santarem': countries.pt,
  'uniao-sao-joao-araras-sao-paulo': countries.br,
  'unicov-unicov': countries.cz,
  'unidos-lisandro-olmos': countries.ar,
  'union-adarve': countries.es,
  'union-berlin-berlin': countries.de,
  'union-berlin-u19-union-berlin': countries.de,
  'union-comercio-nueva-cajamarca': countries.pe,
  'union-companias': countries.cl,
  'union-cosnoise-cosne-cours-sur-loire': countries.fr,
  'union-douala-douala': countries.cm,
  'union-edelweiss-linz': countries.at,
  'union-espanola-santiago-de-chile': countries.cl,
  'union-furstenwalde-furstenwalde': countries.de,
  'union-huaral-huaral': countries.pe,
  'union-innsbruck-aldrans': countries.at,
  'union-kayl-tetange-teiteng-tetange': countries.lu,
  'union-la-calera-la-calera': countries.cl,
  'union-magdalena-santa-marta': countries.co,
  'union-mertert-waasserbelleg-wasserbillig': countries.lu,
  'union-molinense-molina-de-segura-murcia': countries.es,
  'union-omaha': countries.us,
  'union-oncativo-oncativo-provincia-de-cordoba': countries.ar,
  'union-saint-gilloise-bruxelles-brussels': countries.be,
  'union-saint-jean': countries.fr,
  'union-san-felipe-san-felipe': countries.cl,
  'union-santa-fe-ciudad-de-santa-fe-provincia-de-santa-fe': countries.ar,
  'union-santiago-santiago-del-estero-prov-de-santiago-del-estero':
    countries.ar,
  'union-sunchales-sunchales-provincia-de-santa-fe': countries.ar,
  'union-sur-yaiza-yaiza-lanzarote': countries.es,
  'union-villa-krause-villa-krause-provincia-de-san-juan': countries.ar,
  'union-vocklamarkt-vocklamarkt': countries.at,
  'unionistas-de-salamanca-salamanca': countries.es,
  unirb: countries.br,
  'unirea-alba-iulia-alba-iulia': countries.ro,
  'unirea-bascov-bascov': countries.ro,
  'unirea-constan-a-constan-a': countries.ro,
  'unirea-dej-dej': countries.ro,
  'unirea-slobozia-slobozia': countries.ro,
  'unirea-ungheni-ungheni': countries.ro,
  'united-kalkata-kolkata-pascimba-ga-west-bengal': countries.in,
  'united-of-manchester-manchester': countries.gb,
  'united-u19-glenview-illinois': countries.us,
  'univ-concepcion-concepcion': countries.cl,
  'universidad-catolica-quito': countries.ec,
  'universidad-catolica-santiago-de-chile': countries.cl,
  'universidad-chile-santiago-de-chile': countries.cl,
  'universidad-guadalajara-guadalajara': countries.mx,
  'universidad-o-m-santo-domingo': countries.do,
  'universidad-san-martin-lima': countries.pe,
  'universitario-beni-trinidad': countries.bo,
  'universitario-ciudad-de-panama': countries.pa,
  'universitario-de-vinto-vinto': countries.bo,
  'universitario-lima': countries.pe,
  'universitario-popayan-popayan': countries.co,
  'universitatea-cluj-cluj-napoca': countries.ro,
  'universitatea-craiova-craiova': countries.ro,
  'university-of-n-carolina-chapel-hill-north-carolina': countries.us,
  'university-of-pretoria-pretoria': countries.za,
  'university-of-virginia-charlottesville-virginia': countries.us,
  'unterhaching-u17-unterhaching': countries.de,
  'unterhaching-u19-unterhaching': countries.de,
  'unterhaching-unterhaching': countries.de,
  'unye-1957-ordu': countries.tr,
  'up-plasencia-plasencia': countries.es,
  'upnfm-tegucigalpa': countries.hn,
  'uppsala-uppsala': countries.se,
  'ura-kampala': countries.ug,
  'ural-ekaterinburg': countries.ru,
  'ural-ii': countries.ru,
  'urawa-reds-saitama': countries.jp,
  urduliz: countries.es,
  'urena-urena': countries.ve,
  'urganlispor-manisa': countries.tr,
  'urk-urk': countries.nl,
  'urozhay-krasnodar': countries.ru,
  'ursaria-madrid': countries.es,
  'ursl-vise-lixhe': countries.be,
  'ursus-warszawa': countries.pl,
  'urt-patos-de-minas-minas-gerais': countries.br,
  'uruguay-montevideo-montevideo': countries.uy,
  'uruguay-san-jose': countries.cr,
  'us-biskra-biskra': countries.dz,
  'us-bitonto': countries.it,
  'us-givry-flamierge': countries.be,
  'usac-suzano-sao-paulo': countries.br,
  'usm-alger-al-jaza-ir-algiers': countries.dz,
  'usm-el-harrach-al-jaza-ir-algiers': countries.dz,
  'ussa-vertou-vertou': countries.fr,
  'ussk-porto-novo': countries.bj,
  'usti-nad-labem-usti-nad-labem': countries.cz,
  'usti-nad-orlici-usti-nad-orlici': countries.cz,
  'usv-hercules-utrecht': countries.nl,
  'ut-petange-peiteng-petange': countries.lu,
  'uta-arad-arad': countries.ro,
  'utas-usakspor-usak': countries.tr,
  'utc-cajamarca-cajamarca': countries.pe,
  utebo: countries.es,
  'uthai-thani-uthai-thani': countries.th,
  'uthongathi-tongaat': countries.za,
  'utrecht-ii-utrecht': countries.nl,
  'utrecht-u19-utrecht': countries.nl,
  'utrecht-utrecht': countries.nl,
  'utrera-sevilla': countries.es,
  'utrillas-utrillas': countries.es,
  'uts-rabat-rabat': countries.ma,
  'utsikten-u21-vastra-frolunda': countries.se,
  'utsikten-vastra-frolunda': countries.se,
  'uvs-leiden': countries.nl,
  'uxbridge-london': countries.gb,
  'uzda-uzda': countries.by,
  'uzunkopruspor-uzunkopru': countries.tr,
  'v-varen-nagasaki-isahaya': countries.jp,
  'vaajakoski-vaajakoski': countries.fi,
  'vado-vado-ligure': countries.it,
  'vaduz-vaduz': countries.ch,
  'vaengir-jupiters': countries.is,
  'valadares-gaia-vila-nova-de-gaia': countries.pt,
  'valasske-mezirici-valasske-mezirici': countries.cz,
  'valdefierro-zaragoza': countries.es,
  'valdesoto-valdesoto': countries.es,
  'vale-of-leithen-innerleithen': countries.gb,
  'valencia-ii-valencia': countries.es,
  'valencia-male-north-male-atoll-kaafu-atoll': countries.mv,
  'valencia-u19-valencia': countries.es,
  'valencia-u21-valencia': countries.es,
  'valencia-u23': countries.es,
  'valencia-valencia': countries.es,
  'valenciennes-ii-valenciennes-cedex': countries.fr,
  'valenciennes-valenciennes': countries.fr,
  'valerenga-ii-oslo': countries.no,
  'valerenga-oslo': countries.no,
  'valle-egues-egues': countries.es,
  'valledupar-valledupar': countries.co,
  'valletta-valletta': countries.mt,
  'valley-united-phoenix': countries.us,
  vallobin: countries.es,
  'valls-valls': countries.es,
  'valmiera-bss-valmiera': countries.lv,
  valour: countries.ca,
  'valur-reykjavik-1911': countries.is,
  van: countries.am,
  'van-bb-van': countries.tr,
  'vancouver-whitecaps-vancouver-british-columbia': countries.us,
  'vancouver-whitecaps-vancouver-british-columbia-1986': countries.it,
  'vandra-parnumaa': countries.ee,
  'vanersborgs-fk-vanersborg': countries.se,
  'vanersborgs-if-vanersborg': countries.se,
  'vangolu-fk-van': countries.tr,
  'vannes-vannes': countries.fr,
  'vanraure-hachinohe': countries.jp,
  'vaprus-parnu': countries.ee,
  'varazdin-varazdin': countries.hr,
  'varberg-varberg': countries.se,
  'varbergs-gif-varberg': countries.se,
  'varbergs-u21-varberg': countries.se,
  'vard-haugesund': countries.no,
  'varda-se-kisvarda': countries.hu,
  'vardar-skopje': countries.mk,
  'vardar-u19-rochester-michigan': countries.us,
  'varde-if-elite-varde': countries.dk,
  'varde-varde': countries.dk,
  'varea-varea': countries.es,
  'varketili-tbilisi': countries.ge,
  'varmbols-katrineholm': countries.se,
  'varnamo-u21-varnamo': countries.se,
  'varnamo-varnamo': countries.se,
  'varzim-povoa-de-varzim': countries.pt,
  'vasalund-solna': countries.se,
  'vasas-budapest': countries.hu,
  'vasco-da-gama-rio-de-janeiro-rio-de-janeiro': countries.br,
  'vasco-da-gama-vidigueira-vidigueira': countries.pt,
  'vast-mykolaiv': countries.ua,
  'vasteras-sk-vasteras': countries.se,
  'vastra-frolunda-vastra-frolunda': countries.se,
  'vatan-sport': countries.de,
  'vaughan-azzurri': countries.ca,
  vaulx: countries.fr,
  'vaux-noville-bastogne': countries.be,
  'vcd-athletic-crayford-kent': countries.gb,
  'vedita-colonesti': countries.ro,
  'vefa-istanbul': countries.tr,
  'vega-real-la-vega': countries.do,
  'vegalta-sendai-sendai': countries.jp,
  'vejgaard-b-aalborg': countries.dk,
  'vejle-vejle': countries.dk,
  velarde: countries.es,
  'veleciku-koplik-koplik': countries.al,
  'veles-moskva': countries.ru,
  'velez-de-san-ramon-santiago-del-estero': countries.ar,
  'velez-mostar': countries.ba,
  'velez-sarsfield-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'velez-velez-malaga': countries.es,
  'velke-ludince-velke-ludince': countries.sk,
  'velke-mezirici-velke-mezirici': countries.cz,
  'velo-clube-rio-claro-sao-paulo': countries.br,
  'venados-merida-yucatan': countries.mx,
  'venda-fc': countries.za,
  'vendee-fontenay-fontenay-le-comte': countries.fr,
  'vendsyssel-hjorring': countries.dk,
  'venezia-mestre': countries.it,
  venray: countries.nl,
  'ventforet-kofu-kofu': countries.jp,
  'ventura-county-fusion-ventura-california': countries.us,
  'venus-mahina': countries.pf,
  'vera-cruz-vitoria-de-santo-antao-pernambuco': countries.br,
  'veranopolis-veranopolis-rio-grande-do-sul': countries.br,
  'verdes-san-ignacio-town': countries.bz,
  'veres-rivne-rivne': countries.ua,
  'vereya-stara-zagora': countries.bg,
  'veria-veroia-veria': countries.gr,
  'verl-u19-verl': countries.de,
  'verl-verl': countries.de,
  'verlaine-verlaine': countries.be,
  'verona-u19': countries.it,
  versailles: countries.fr,
  'verspah-oita-oita-oita': countries.jp,
  'vertykal-kalinkavicy-kalinkavichy': countries.by,
  vestri: countries.is,
  'veszprem-veszprem': countries.hu,
  'vevey-sports-vevey': countries.ch,
  'vfb-oldenburg-oldenburg': countries.de,
  'vfl-oldenburg-oldenburg': countries.de,
  'vianense-viana-do-castelo': countries.pt,
  'vibonese-vibo-valentia': countries.it,
  'viborg-viborg': countries.dk,
  'viby-viby-j': countries.dk,
  'vicenza-virtus-vicenza': countries.it,
  'victoria-chisinau': countries.md,
  'victoria-hamburg-hamburg': countries.de,
  'victoria-hotspurs-victoria': countries.mt,
  'victoria-kings-victoria': countries.gy,
  'victoria-la-ceiba': countries.hn,
  'victoria-rosport-rousport-rosport': countries.lu,
  'victoriano-arenas-lanus-provincia-de-buenos-aires': countries.ar,
  'vida-la-ceiba': countries.hn,
  'vidar-stavanger': countries.no,
  'videm-videm-pri-ptuju': countries.si,
  'vidi-ii-szekesfehervar': countries.hu,
  'vidi-szekesfehervar': countries.hu,
  'vidir-gardur': countries.is,
  'vierzon-fc-vierzon': countries.fr,
  viettel: countries.vn,
  'vieux-habitants-vieux-habitants': countries.gp,
  'vifk-vaasa': countries.fi,
  'vihren-sandanski': countries.bg,
  'viimsi-ii-viimsi': countries.ee,
  'viimsi-viimsi': countries.ee,
  'viitorul-dae-ti': countries.ro,
  'viitorul-ianca-ianca': countries.ro,
  'viitorul-mamaia': countries.ro,
  'viitorul-selimbar': countries.ro,
  'viitorul-simian-simian': countries.ro,
  'viitorul-u19-mamaia': countries.ro,
  'viking-stavanger': countries.no,
  'vikingur-leirvik-eysturoy': countries.fo,
  'vikingur-olafsvik-olafsvik': countries.is,
  'vikingur-reykjavik-reykjavik': countries.is,
  'viktoria-aschaffenburg-aschaffenburg': countries.de,
  'viktoria-berlin-berlin': countries.de,
  'viktoria-berlin-u19-berlin': countries.de,
  'viktoria-koln-koln': countries.de,
  'viktoria-koln-u19-koln': countries.de,
  'viktoria-otrokovice-otrokovice': countries.cz,
  'viktoria-plzen-ii-plzen': countries.cz,
  'viktoria-plzen-plzen': countries.cz,
  'viktoria-plzen-u19-plzen': countries.cz,
  'viktoria-zizkov-praha': countries.cz,
  'viktoriya-maryina-horka-maryina-gorka-maryina-horka': countries.by,
  'vila-mea-vila-mea': countries.pt,
  'vila-nova-goiania-goias': countries.br,
  'vilafranca-vilafranca-del-penedes': countries.es,
  vilafranquense: countries.pt,
  'vilamarxant-vilamarxant': countries.es,
  'vilar-de-perdizes': countries.pt,
  'vilassar-de-mar-vilassar-de-mar': countries.es,
  'vilaverdense-vila-verde': countries.pt,
  vilemov: countries.cz,
  'villa-alvear-resistencia-provincia-de-chaco': countries.ar,
  'villa-cubas-san-fernando-del-valle-de-catamarca-catamarca': countries.ar,
  'villa-dalmine-campana-provincia-de-buenos-aires': countries.ar,
  'villa-de-fortuna-fortuna': countries.es,
  'villa-espanola-montevideo': countries.uy,
  'villa-mitre-bahia-blanca-provincia-de-buenos-aires': countries.ar,
  'villa-nova-nova-lima-minas-gerais': countries.br,
  'villa-san-carlos-berisso-provincia-de-buenos-aires': countries.ar,
  'villa-santa-brigida-santa-brigida': countries.es,
  'villa-teresa-montevideo': countries.uy,
  'villacanas-villacanas': countries.es,
  villafranca: countries.es,
  'villafranca-villafranca-di-verona': countries.it,
  'villalbes-villalba': countries.es,
  'villanovense-villanueva-de-la-serena': countries.es,
  'villanueva-cf-villanueva-de-gallego': countries.es,
  'villanueva-del-pardillo-villanueva-del-pardillo': countries.es,
  'villarreal-ii-villarreal': countries.es,
  'villarreal-u23-villarreal': countries.es,
  'villarreal-villarreal': countries.es,
  'villarrobledo-villarrobledo': countries.es,
  villarrubia: countries.es,
  'villefranche-villefranche-sur-saone': countries.fr,
  'villingen-villingen-schwenningen': countries.de,
  'vilzing-cham': countries.de,
  'vimenor-viono-de-pielagos': countries.es,
  'vimy-vimy': countries.fr,
  'vindbjart-vennesla': countries.no,
  'vinodol-novi-vinodolski': countries.hr,
  'violette-ac': countries.ht,
  'vipers-wakiso-town': countries.ug,
  'viransehir-sanayispor-viransehir-sanliurfa': countries.tr,
  'vire-vire': countries.fr,
  'virginia-beach-city': countries.us,
  'virtus-acquaviva': countries.sm,
  'virtus-casarano-casarano': countries.it,
  'virtus-entella-chiavari': countries.it,
  'virtus-francavilla-francavilla-fontana': countries.it,
  'virtus-verona-verona': countries.it,
  'vis-artena-artena': countries.it,
  'vis-pesaro-pesaro': countries.it,
  'visakha-phnom-penh': countries.kh,
  'visoko-visoko': countries.si,
  'vissel-kobe-kobe': countries.jp,
  'vita-club-kinshasa': countries.cd,
  'vital-o-bujumbura': countries.bi,
  'vitebsk-vitsyebsk-vitebsk': countries.by,
  'viterbese-castrense-viterbo': countries.it,
  'vitesse-arnhem': countries.nl,
  'vitesse-ii-arnhem': countries.nl,
  'vitesse-u19-arnhem': countries.nl,
  'vitkovice-ostrava-vitkovice': countries.cz,
  'vitoria-da-conquista-vitoria-da-conquista-bahia': countries.br,
  'vitoria-es-vitoria-espirito-santo': countries.br,
  'vitoria-guimaraes-guimaraes': countries.pt,
  'vitoria-guimaraes-ii-guimaraes': countries.pt,
  'vitoria-salvador-bahia': countries.br,
  'vitoria-sao-tome': countries.st,
  'vitoria-setubal-ii': countries.pt,
  'vitoria-setubal-setubal': countries.pt,
  'vitoria-vitoria-gasteiz': countries.es,
  'vitosha-bistritsa-bistritsa-bistrica': countries.bg,
  'vitre-vitre': countries.fr,
  vitry: countries.fr,
  'vittoriosa-stars-vittoriosa-birgu': countries.mt,
  'viveiro-viveiro': countries.es,
  'viven-bornova-izmir': countries.tr,
  'vizela-caldas-de-vizela': countries.pt,
  'vizela-u23-vizela': countries.pt,
  'vjs-vantaa': countries.fi,
  'vlasim-vlasim': countries.cz,
  'vllaznia-shkoder-shkoder': countries.al,
  'voitsberg-voitsberg': countries.at,
  'vojvodina-novi-sad': countries.rs,
  'volendam-ii-volendam': countries.nl,
  'volendam-volendam': countries.nl,
  'volga-ulyanovsk-ulyanovsk': countries.ru,
  'volgar-astrakhan-astrakhan': countries.ru,
  'volkermarkt-volkermarkt': countries.at,
  'volna-pinsk': countries.by,
  'volos-nfc-volos': countries.gr,
  'volsungur-husavik': countries.is,
  'volta-redonda-volta-redonda-rio-de-janeiro': countries.br,
  'voluntari-ii-voluntari': countries.ro,
  'voluntari-voluntari': countries.ro,
  'vonds-ichihara-ichihara': countries.jp,
  'voorschoten-97-voorschoten': countries.nl,
  'vora-vora': countries.al,
  'vorarlberg-u15-bregenz': countries.at,
  'vorskla-poltava': countries.ua,
  'voru-fc-helios-voru': countries.ee,
  'vorwarts-steyr-steyr': countries.at,
  'voska-sport-ohrid': countries.mk,
  'vosselaar-vosselaar': countries.be,
  'votuporanguense-votuporanga-sao-paulo': countries.br,
  'vozdovac-beograd': countries.rs,
  'vps-ii-vaasa': countries.fi,
  'vps-vaasa': countries.fi,
  vrakuna: countries.sk,
  'vranov-nad-toplou-vranov-nad-toplou': countries.sk,
  'vrapce-zagreb-zagreb': countries.hr,
  'vrbovec-vrbovec': countries.hr,
  'vrchovina-vrchovina': countries.cz,
  'vukovar-vukovar': countries.hr,
  'vuteks-sloga': countries.hr,
  'vv-groningen-groningen': countries.nl,
  'vv-scherpenzeel-scherpenzeel': countries.nl,
  'vvog-harderwijk': countries.nl,
  'vvsb-noordwijkerhout': countries.nl,
  'vvv-venlo': countries.nl,
  'vw-hamme-hamme': countries.be,
  'vysocina-jihlava-jihlava': countries.cz,
  'w-connection-point-lisas': countries.tt,
  'wa-all-stars-wa': countries.gh,
  'wa-boufarik': countries.dz,
  'wa-tlemcen-tilimsan-tlemcen': countries.dz,
  'waasland-beveren-beveren-waas': countries.be,
  'wacker-burghausen-burghausen': countries.de,
  'wacker-innsbruck-ii-innsbruck': countries.at,
  'wacker-innsbruck-innsbruck': countries.at,
  'wacker-nordhausen-nordhausen': countries.de,
  'wake-forest-university-winston-salem': countries.us,
  'wakirya-sangaredi': countries.gn,
  'waldhof-mannheim-mannheim': countries.de,
  'wallonne-ciney-ciney': countries.be,
  'wals-grunau-wals': countries.at,
  'walsall-walsall-west-midlands': countries.gb,
  'walter-ferretti-managua': countries.ni,
  'wanderers-montevideo': countries.uy,
  'wanze-bas-oha-bas-oha': countries.be,
  'wapda-lahore': countries.pk,
  'ware-ware-hertfordshire': countries.gb,
  'waremme-waremme': countries.be,
  'warkaus-warkaus': countries.fi,
  'warnant-warnant-dreye': countries.be,
  'warrenpoint-town-warrenpoint': countries.gb,
  'warrington-town-latchford-warrington': countries.gb,
  'warriors-tseung-kwan-o': countries.hk,
  'warta-poznan-poznan': countries.pl,
  'warta-sieradz': countries.pl,
  'wasquehal-wasquehal': countries.fr,
  'watanga-monrovia': countries.lr,
  'waterford-waterford': countries.ie,
  'waterhouse-kingston': countries.jm,
  'waterside-karori-wellington': countries.nz,
  'watford-u18-watford-hertfordshire': countries.gb,
  'watford-u21-watford': countries.gb,
  'watford-watford': countries.gb,
  'wathba-ims-hims': countries.sy,
  'wattens-ii': countries.at,
  'wattens-wattens': countries.at,
  'wattenscheid-09-bochum': countries.de,
  'wealdstone-ruislip-middlesex': countries.gb,
  'weg-ta-if': countries.sa,
  'wegberg-beeck-wegberg-beeck': countries.de,
  'wehen-wiesbaden-wiesbaden': countries.de,
  'weiche-flensburg-flensburg': countries.de,
  'weiden-weiden': countries.de,
  'weiz-weiz': countries.at,
  'wellen-wellen': countries.be,
  'welling-united-welling-kent': countries.gb,
  'wellington-olympic': countries.nz,
  'wellington-phoenix-res-wellington': countries.nz,
  'wellington-phoenix-wellington': countries.nz,
  'wels-wels': countries.at,
  'welwyn-garden-city': countries.gb,
  'werder-bremen-bremen': countries.de,
  'werder-bremen-ii-bremen': countries.de,
  'werder-bremen-u19-bremen': countries.de,
  'west-armenia': countries.am,
  'west-bromwich-albion-u18': countries.gb,
  'west-bromwich-albion-u21-west-bromwich': countries.gb,
  'west-bromwich-albion-u23-west-bromwich': countries.gb,
  'west-bromwich-albion-west-bromwich': countries.gb,
  'west-chester-united-westchester-new-york': countries.us,
  'west-ham-united-london': countries.gb,
  'west-ham-united-u18': countries.gb,
  'west-ham-united-u21-london': countries.gb,
  'west-ham-united-u23-london': countries.gb,
  'west-virginia-chaos-charleston-west-virginia': countries.us,
  westerhausen: countries.de,
  'westerlo-westerlo': countries.be,
  'western-knights-perth': countries.au,
  'western-mass-pioneers-ludlow-massachusetts': countries.us,
  'western-springs': countries.nz,
  'western-suburbs-porirua-whitby': countries.nz,
  'western-sydney-w-ii': countries.au,
  'western-sydney-w-u21-sydney': countries.au,
  'western-sydney-wanderers-sydney': countries.au,
  'western-united-ccc89e00-a4e1-4e9e-97f0-256bcd003c07': countries.au,
  'westfalia-rhynern-hamm': countries.de,
  'westhoek-ieper': countries.be,
  'westlandia-naaldwijk': countries.nl,
  'weston-super-mare-weston-super-mare-somerset': countries.gb,
  'weston-u19-weston-florida': countries.us,
  'wettswil-bonstetten': countries.ch,
  'wevelgem-city-wevelgem': countries.be,
  'wexford-dublin': countries.ie,
  'weymouth-weymouth-dorset': countries.gb,
  'wezel-mol': countries.be,
  'whc-wezep': countries.nl,
  'whitby-town-whitby-north-yorkshire': countries.gb,
  'white-city-woodville': countries.au,
  'whitecaps-ii-vancouver-british-columbia': countries.us,
  'whitehawk-brighton-east-sussex': countries.gb,
  'whitehill-welfare-rosewell': countries.gb,
  'whyteleafe-london': countries.gb,
  'wichita-wichita': countries.us,
  'wick-academy-wick': countries.gb,
  widnau: countries.ch,
  'widzew-lodz-lodz': countries.pl,
  'wieczysta-krakow-krakow': countries.pl,
  'wiedenbruck-rheda-wiedenbruck': countries.de,
  'wielsbeke-wielsbeke': countries.be,
  'wiener-neustadt-wiener-neustadt': countries.at,
  'wiener-sc-wien': countries.at,
  'wiener-viktoria-wien': countries.at,
  'wienerberg-wien': countries.at,
  'wigan-athletic-wigan': countries.gb,
  'wigan-u18': countries.gb,
  'wigtown-bladnoch-wigtown': countries.gb,
  'wikki-tourist-bauchi': countries.ng,
  'wil-wil-sg': countries.ch,
  'wildon-wildon': countries.at,
  'willem-ii-tilburg': countries.nl,
  'willem-ii-u19-tilburg': countries.nl,
  'wilstermann-cochabamba': countries.bo,
  'wilstermann-u20-cochabamba': countries.bo,
  'wiltz-wolz-wiltz': countries.lu,
  'wimborne-town-wimborne-minster-dorset': countries.gb,
  'winchester-city-winchester-hampshire': countries.gb,
  'wingate-finchley-london': countries.gb,
  'winterthur-winterthur': countries.ch,
  'wisla-krakow-krakow': countries.pl,
  'wisla-plock-plock': countries.pl,
  'wisla-pulawy-pulawy': countries.pl,
  'wisla-sandomierz-sandomierz': countries.pl,
  'wisloka-debica-debica': countries.pl,
  'wit-georgia-ii-tbilisi': countries.ge,
  'wit-georgia-tbilisi': countries.ge,
  'witgoor-sport-dessel': countries.be,
  'witham-town-witham': countries.gb,
  'wittenhorst-horst': countries.nl,
  'witton-albion-northwich-cheshire': countries.gb,
  'wofoo-tai-po-hong-kong': countries.hk,
  'wohlen-wohlen': countries.ch,
  'woking-woking-surrey': countries.gb,
  'wolfsberg-wolfsberg': countries.at,
  'wolfsberger-ac-ii': countries.at,
  'wolfsberger-ac-u15-wolfsberg': countries.at,
  'wolfsberger-ac-u18-wolfsberg': countries.at,
  'wolfsberger-ac-wolfsberg': countries.at,
  'wolfsburg-u17-wolfsburg': countries.de,
  'wolfsburg-wolfsburg': countries.de,
  wolfurt: countries.at,
  'wollongong-wolves-sydney': countries.au,
  'wolverhampton-wanderers-wolverhampton': countries.gb,
  'wolves-u18': countries.gb,
  'wolves-u21-wolverhampton': countries.gb,
  'wolves-u23-wolverhampton': countries.gb,
  'wong-tai-sin-hong-kong': countries.hk,
  'worgl-worgl': countries.at,
  'workington-carlisle-cumbria': countries.gb,
  'worksop-town-retford-nottinghamshire': countries.gb,
  'wormatia-worms-worms': countries.de,
  'worthing-worthing-west-sussex': countries.gb,
  'wrexham-wrexham-wrecsam': countries.gb,
  'wsa-winnipeg-winnipeg-manitoba': countries.us,
  'wuhan-three-towns': countries.cn,
  'wuhan-zall-wuhan': countries.cn,
  'wuppertaler-sv-wuppertal': countries.de,
  'wurzburger-kickers-wurzburg': countries.de,
  'wv-hedw-amsterdam': countries.nl,
  'wycombe-wanderers-high-wycombe-buckinghamshire': countries.gb,
  'wydad-casablanca-casablanca': countries.ma,
  'xelaju-quetzaltenango': countries.gt,
  'xerez-deportivo-xerez': countries.es,
  'xerez-jerez-de-la-frontera': countries.es,
  'xerxesdzb-zat-rotterdam': countries.nl,
  'xewkija-tigers': countries.mt,
  'xinabajul-huehuetenango': countries.gt,
  'xinjiang-tianshan-urumqi': countries.cn,
  'xorazm-urganch-urgench': countries.uz,
  'xv-de-jau-jau-sao-paulo': countries.br,
  'xv-de-piracicaba-piracicaba-sao-paulo': countries.br,
  'xylotympou-xylotympou': countries.cy,
  'yadanarbon-mandalay': countries.mm,
  'yadro-st-petersburg-sankt-peterburg-st-petersburg': countries.ru,
  yague: countries.es,
  'yahsihanspor-yahsihan': countries.tr,
  'yalovaspor-yalova': countries.tr,
  'yambol-1915-yambol': countries.bg,
  'yangiyer-yangiyer': countries.uz,
  'yangju-citizen-yangju': countries.kr,
  'yangon-united-yangon': countries.mm,
  'yangpyeong-yangpyeong-county': countries.kr,
  'yantra-1919-gabrovo': countries.bg,
  'yaracuyanos-san-felipe': countries.ve,
  'yarmouk-madinat-al-kuwayt-kuwait-city': countries.kw,
  'yarud-mariupol-mariupol': countries.ua,
  'yasothon-yasothon': countries.th,
  'yataganspor-yatagan': countries.tr,
  'yate-town-yate-south-gloucestershire': countries.gb,
  'yeclano-yecla': countries.es,
  'yellow-boys-weiler-weiler-zum-tuerm-weiler-la-tour': countries.lu,
  'yeni-amasyaspor-amasya': countries.tr,
  'yeni-corumspor-corum': countries.tr,
  'yeni-malatyaspor-malatya': countries.tr,
  'yeni-malatyaspor-u19-malatya': countries.tr,
  'yeni-orduspor-ordu': countries.tr,
  'yenicagaspor-bolu': countries.tr,
  'yenisey-ii-krasnoyarsk': countries.ru,
  'yenisey-krasnoyarsk': countries.ru,
  'yeovil-town-yeovil-somerset': countries.gb,
  'yesil-cinarspor-denizli': countries.tr,
  'yesil-vartospor-varto-mus': countries.tr,
  'yesilovaspor-izmir': countries.tr,
  'yesilyurt-belediyespor-malatya': countries.tr,
  'yf-juventus-zurich': countries.ch,
  'yildirim-belediyesispor-yildirim': countries.tr,
  ymir: countries.is,
  'yokohama-f-marinos-yokohama': countries.jp,
  'yokohama-yokohama': countries.jp,
  'yomraspor-yomra': countries.tr,
  'york-city-york-north-yorkshire': countries.gb,
  york9: countries.ca,
  'young-africans-dar-es-salaam': countries.tz,
  'young-boys-bern': countries.ch,
  'young-boys-u19-bern': countries.ch,
  'young-buffaloes-mliba': countries.sz,
  'young-elephant': countries.la,
  'young-lions-singapore': countries.sg,
  'young-sport-academy-bamenda': countries.cm,
  'youssoufia-berrechid-berrechid': countries.ma,
  'yozgat-bld-bozokspor-yozgat': countries.tr,
  'yozgatspor-1959-yozgat': countries.tr,
  'ypiranga-erechim-erechim-rio-grande-do-sul': countries.br,
  'ypiranga-pe-santa-cruz-do-capibaribe-pernambuco': countries.br,
  'yscc-yokohama': countries.jp,
  ytterhogdal: countries.se,
  'yuen-long-hong-kong': countries.hk,
  'yuksekova-belediyespor-yuksekova': countries.tr,
  'yunusemre-belediyespor-manisa': countries.tr,
  'yupanqui-capital-federal-ciudad-de-buenos-aires': countries.ar,
  'yuvacikspor-kocaeli': countries.tr,
  'yverdon-sport-yverdon-les-bains': countries.ch,
  'zaamin-zaamin-zomin': countries.uz,
  'zabbar-st-patrick-zabbar': countries.mt,
  'zacatepec-siglo-xxi-zacatepec': countries.mx,
  'zaferspor-bursa': countries.tr,
  'zaglebie-lubin-ii-lubin': countries.pl,
  'zaglebie-lubin-lubin': countries.pl,
  'zaglebie-sosnowiec-sosnowiec': countries.pl,
  'zagora-unesic': countries.hr,
  'zagorets-nova-zagora': countries.bg,
  'zakho-duhok': countries.iq,
  'zalaegerszegi-te-zalaegerszeg': countries.hu,
  'zalgiris-u19-vilnius': countries.lt,
  'zalgiris-vilnius': countries.lt,
  'zamalek-al-qahirah-cairo': countries.eg,
  'zamora-barinas': countries.ve,
  'zamora-zamora': countries.es,
  'zamudio-zamudio': countries.es,
  'zanaco-lusaka': countries.zm,
  'zaqatala-zaqatala': countries.az,
  'zarzis-zarzis': countries.tn,
  'zavrc-zavrc': countries.si,
  'zawisza-bydgoszcz-bydgoszcz': countries.pl,
  'zbuzany-zbuzany': countries.cz,
  'zebbug-rangers-zebbug': countries.mt,
  'zejtun-corinthians-zejtun': countries.mt,
  'zeleznicar-pancevo-pancevo': countries.rs,
  'zeljeznicar-sarajevo': countries.ba,
  'zelzate-zelzate': countries.be,
  'zemplin-michalovce-michalovce': countries.sk,
  'zemun-zemun': countries.rs,
  'zenit-ii-sankt-peterburg-st-petersburg': countries.ru,
  'zenit-izhevsk-izhevsk': countries.ru,
  'zenit-penza-penza': countries.ru,
  'zenit-sankt-peterburg-st-petersburg': countries.ru,
  'zesco-united-ndola': countries.zm,
  'zestafoni-zestafoni': countries.ge,
  'zeta-golubovci': countries.me,
  'zfc-meuselwitz-meuselwitz': countries.de,
  'zhejiang-greentown-hangzhou': countries.cn,
  'zhetysu-taldiqorgan-taldykorgan': countries.kz,
  'zhlobin-zhlobin': countries.by,
  'zhodino-yuzhnoe-zhodzina-zhodino': countries.by,
  'zibo-cuju': countries.cn,
  'zilina-ii-zilina': countries.sk,
  'zilina-u19-zilina': countries.sk,
  'zilina-zilina': countries.sk,
  'zimbru-chisinau': countries.md,
  'zira-zir': countries.az,
  'ziri-ziri': countries.si,
  'zirl-zirl': countries.at,
  'zlatibor-cajetina-cajetina': countries.rs,
  'zlin-zlin': countries.cz,
  'zmaj-makarska-makarska': countries.hr,
  'znamya-noginsk-noginsk': countries.ru,
  'znamya-truda-orekhovo-zuyevo': countries.ru,
  'znicz-pruszkow-pruszkow': countries.pl,
  'znojmo-znojmo': countries.cz,
  'zob-ahan-folad-shahr-fooladshahr': countries.ir,
  'zonguldak-komurspor-zonguldak': countries.tr,
  'zorkiy-krasnogorsk': countries.ru,
  'zorya-luhans-k-lugansk': countries.ua,
  'zorya-luhansk-u21-luhans-k-lugansk': countries.ua,
  'zrinjski-mostar': countries.ba,
  'zsv-deurne': countries.nl,
  'zug-zug': countries.ch,
  'zugdidi-zugdidi': countries.ge,
  'zuidvogels-huizen': countries.nl,
  'zulia-maracaibo': countries.ve,
  'zulte-waregem-waregem': countries.be,
  'zurich-ii-zurich': countries.ch,
  'zurich-zurich': countries.ch,
  'zvezda-st-petersburg-sankt-peterburg-st-petersburg': countries.ru,
  'zvijezda-09-bijeljina': countries.ba,
  'zvijezda-gradacac-gradacac': countries.ba,
  'zvyahel-novohrad-volyns-kyi': countries.ua,
  'zwaluwen-vlaardingen': countries.nl,
  'zwarte-leeuw-rijkevorsel': countries.be,
  'zweigen-kanazawa-kanazawa': countries.jp,
  'zwettl-zwettl': countries.at,
  'zwickau-zwickau': countries.de,

  // MLB & NBA
  'arizona-diamondbacks': { lat: 33.44527778, lng: -112.0669444 },
  'atlanta-braves': { lat: 33.73527778, lng: -84.38944444 },
  'atlanta-hawks': { lat: 33.75722222, lng: -84.39638889 },
  'baltimore-orioles': { lat: 39.28388889, lng: -76.62166667 },
  'boston-celtics': { lat: 42.36630278, lng: -71.06222778 },
  'boston-red-sox': { lat: 42.34638889, lng: -71.0975 },
  'brooklyn-nets': { lat: 40.68265, lng: -73.97468889 },
  'charlotte-hornets': { lat: 29.94888889, lng: -90.08194444 },
  'chicago-bulls': { lat: 41.88055556, lng: -87.67416667 },
  'chicago-cubs': { lat: 41.94833333, lng: -87.65555556 },
  'chicago-white-sox': { lat: 41.83, lng: -87.63388889 },
  'cincinnati-reds': { lat: 39.0975, lng: -84.50666667 },
  'cleveland-cavaliers': { lat: 41.49638889, lng: -81.68805556 },
  'cleveland-guardians': { lat: 41.49638889, lng: -81.68805556 },
  'colorado-rockies': { lat: 39.75611111, lng: -104.9941667 },
  'dallas-mavericks': { lat: 32.79055556, lng: -96.81027778 },
  'denver-nuggets': { lat: 39.74861111, lng: -105.0075 },
  'detroit-pistons': { lat: 42.69694444, lng: -83.24555556 },
  'detroit-tigers': { lat: 42.33916667, lng: -83.04861111 },
  'golden-state-warriors': { lat: 37.75027778, lng: -122.2030556 },
  'houston-astros': { lat: 29.75694444, lng: -95.35555556 },
  'houston-rockets': { lat: 29.75694444, lng: -95.35555556 },
  'indiana-pacers': { lat: 39.76388889, lng: -86.15555556 },
  'kansas-city-royals': { lat: 39.05138889, lng: -94.48055556 },
  'la-clippers': { lat: 34.04305556, lng: -118.2672222 },
  'los-angeles-angels': { lat: 33.80027778, lng: -117.8827778 },
  'los-angeles-dodgers': { lat: 33.80027778, lng: -117.8827778 },
  'los-angeles-lakers': { lat: 33.80027778, lng: -117.8827778 },
  'memphis-grizzlies': { lat: 35.13833333, lng: -90.05055556 },
  'miami-heat': { lat: 25.78138889, lng: -80.18805556 },
  'miami-marlins': { lat: 25.77805556, lng: -80.21972222 },
  'milwaukee-brewers': { lat: 43.02833333, lng: -87.97111111 },
  'milwaukee-bucks': { lat: 43.04361111, lng: -87.91694444 },
  'minnesota-timberwolves': { lat: 44.97944444, lng: -93.27611111 },
  'minnesota-twins': { lat: 44.98166667, lng: -93.27833333 },
  'new-orleans-pelicans': { lat: 29.95083333, lng: -90.08111111 },
  'new-york-knicks': { lat: 40.75055556, lng: -73.99361111 },
  'new-york-mets': { lat: 40.75694444, lng: -73.84583333 },
  'new-york-yankees': { lat: 40.82916667, lng: -73.92638889 },
  'oakland-athletics': { lat: 37.75166667, lng: -122.2005556 },
  'oklahoma-city-thunder': { lat: 35.46333333, lng: -97.515 },
  'orlando-magic': { lat: 28.53916667, lng: -81.38361111 },
  'philadelphia-76ers': { lat: 39.90111111, lng: -75.17194444 },
  'philadelphia-phillies': { lat: 39.90583333, lng: -75.16638889 },
  'phoenix-suns': { lat: 33.44583333, lng: -112.0713889 },
  'pittsburgh-pirates': { lat: 40.44694444, lng: -80.00583333 },
  'portland-trail-blazers': { lat: 45.53166667, lng: -122.6666667 },
  'sacramento-kings': { lat: 38.64916667, lng: -121.5180556 },
  'san-antonio-spurs': { lat: 29.42694444, lng: -98.4375 },
  'san-diego-padres': { lat: 32.70729983, lng: -117.1565998 },
  'san-francisco-giants': { lat: 37.77833333, lng: -122.3894444 },
  'seattle-mariners': { lat: 47.59138889, lng: -122.3325 },
  'st-louis-cardinals': { lat: 38.6225, lng: -90.19305556 },
  'tampa-bay-rays': { lat: 27.76833333, lng: -82.65333333 },
  'texas-rangers': { lat: 32.75138889, lng: -97.08277778 },
  'toronto-blue-jays': { lat: 43.64138889, lng: -79.38916667 },
  'toronto-raptors': { lat: 43.64138889, lng: -79.38916667 },
  'utah-jazz': { lat: 40.76833333, lng: -111.9011111 },
  'washington-nationals': { lat: 38.87277778, lng: -77.0075 },
  'washington-wizards': { lat: 38.89805556, lng: -77.02083333 },
};

export default clubs;
