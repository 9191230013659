import 'regenerator-runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import CookieConsentBanner from '@sorare/core/src/components/user/CookieConsentBanner';
import DeviceFingerprintProvider from '@sorare/core/src/contexts/deviceFingerprint/Provider';
import AnalyticsProvider from '@sorare/core/src/contexts/events/Provider';
import GrapqhqlProvider from '@sorare/core/src/contexts/graphql/Provider';
import { IntlProvider } from '@sorare/core/src/contexts/intl/Provider';
import ResetPrivateKeyProvider from '@sorare/core/src/contexts/resetPrivateKey/Provider';
import MockSentryProvider from '@sorare/core/src/contexts/sentry/Provider.mock';
import SnackNotificationProvider from '@sorare/core/src/contexts/snackNotification/Provider';
import WebfontsProvider from '@sorare/core/src/contexts/webfonts/Provider';
import { useBasenameLocale } from '@sorare/core/src/hooks/useBasenameLocale';
import ThemeProvider from '@sorare/core/src/style/theme';

import App from 'App';

import '@sorare/core/src/style/style.css';

const Root = () => {
  const { locale, setLocale } = useBasenameLocale();

  return (
    <MockSentryProvider>
      <WebfontsProvider
        config={{
          custom: {
            families: ['apercu-pro:n4,n7,n9,i9'],
          },
        }}
      >
        <IntlProvider locale={locale} setLocale={setLocale}>
          <ThemeProvider>
            <CookieConsentBanner />
            <AnalyticsProvider>
              <ResetPrivateKeyProvider>
                <SnackNotificationProvider>
                  <DeviceFingerprintProvider>
                    <GrapqhqlProvider locale={locale}>
                      <App />
                    </GrapqhqlProvider>
                  </DeviceFingerprintProvider>
                </SnackNotificationProvider>
              </ResetPrivateKeyProvider>
            </AnalyticsProvider>
          </ThemeProvider>
        </IntlProvider>
      </WebfontsProvider>
    </MockSentryProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </React.StrictMode>
);
