import { createContext, useContext } from 'react';

export interface WebfontsContext {
  active: boolean;
  loading: boolean;
}

export const webfontsContext = createContext<WebfontsContext | null>(null);

export const useWebfontsContext = () => useContext(webfontsContext)!;

export default webfontsContext.Provider;
